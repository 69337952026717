import React, {useState} from 'react'
import { useHistory } from 'react-router'
import {message, Modal} from 'antd'
import apiInstance from '../../../api';

function BlogItem(props) {

    const history = useHistory();
    const [removeLoadingState, setRemoveLoadingState] = useState(false);

    function handleDelete() {
        Modal.warning({
            title: 'Are you sure to remove the blog?',
            content: null,
            okText: "Remove",
            className: "apps",
            okButtonProps: {load: removeLoadingState, type: "danger", className: "px-5 text-md text-white rounded-md cursor-pointer mx-3"},
            onOk() {
                removeBlog();
            }
        });
    }

    const removeBlog = () => {
        setRemoveLoadingState(true);
        apiInstance
        .delete(`/feed/${props?.id}`)
        .then(response => {
            message.success('Blog Removed Successfully');
            props.handleBlogGet();
        })
        .catch(e => {
            console.log(e);
            message.error('Internal Server Error');
        })
        .finally(() => setRemoveLoadingState(false));
    };

    return (
        <div
            className="w-full flex flex-row items-start rounded-sm border shadow hover:bg-gray-200 p-3 my-2">
            <div className="w-full flex flex-col items-start">
                <h2 className="w-full cursor-pointer text-left font-medium text-black py-1">
                    <span className="hover:underline" onClick={() => history.push(`/b/${props.url_key}`)} >{props.title}</span> 
                    <span onClick={() => history.push(`/blog/create?type=edit&id=${props.url_key}`)} className="px-2 text-blue-500 underline cursor-pointer text-sm">Edit</span>
                    <span onClick={() => handleDelete()} className="px-1 text-red-500 underline cursor-pointer text-sm">Delete</span>
                </h2>
                <p className="w-full text-left py-1 text-sm text-gray-600">
                    {props.description}
                </p>
            </div>
        </div>
    )
}

export default BlogItem
