import React,{ useEffect, useState } from 'react'
import { Input, message, Checkbox, Modal } from 'antd'
import '../stylesheets/Login.css'
import { Link, useHistory } from 'react-router-dom'
import apiInstance from '../api'
import { validateEmail } from '../utils/Helper'
import googleLogo from '../assets/img/google-icon.svg'
import facebookLogo from '../assets/img/facebook-icon.svg'
import Header from '../components/User/v2/Header'
import Footer from '../components/User/v2/Footer'

var CryptoJS = require('crypto-js')

function Register() {
    let history = useHistory();
    
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [load, setLoad] = useState(false);
    const [agree, setAgree] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Create Account');  
    const [termsModal, setTermsModal] = useState(false);  
    
    useEffect(() => {
        document.title = "Join Assistance | Assistance";                  
    }, []);

    const register = (e) => {
        e.preventDefault();       
           if (agree) {
            if(username && email && password) {
                if (password.length >= 6) {
                if(email.indexOf('@') >=0 && validateEmail(email)) {                
                    let user = {
                        email_address: email,
                        username: username?.toLowerCase(),
                        password: password,
                        account_type: 'default',
                        agree: 0
                    };
                    createUserWithEmailAndPassword(user);
                } else message.warning('Invalid Email Address');
            } else message.warning('Password must be atleast 6 characters long');
            } else {
                message.warning("Input is badly formatted");
            }        
        } else message.warning("Please agree before signing up!");
    }

    const createUserWithEmailAndPassword = (user) => {
                    // START REQUESTING API
                    setLoad(true);
                    setButtonLabel('Creating ...');
                    apiInstance.post("/person/create/user", user)
                    .then(response => {
                        if(response.data.status) {
                            
                            let userRegistered = username;

                            // CLEAR TO GO !!!                            
                            setUsername('');
                            setEmail('');
                            setPassword('');  
                            setRePassword('');   
                            
                            let hashCode = CryptoJS.MD5(`account_for_${userRegistered}`);
                            sessionStorage.setItem('assistance_current_registration_helper_flag', hashCode);
                            history.push({ pathname: "/registration-complete", search: `?c=${hashCode}&u=${userRegistered}`});

                        }                                                           
                        setLoad(false);
                        setButtonLabel('Create Account'); 
                    }).catch(e => {                
                        console.log(e.response);
                        if(e.response || e.response?.data) {                                    
                            if(e.response.status === 404 || e.response.status === 300 || e.response.status === 400) {
                                message.warning(e.response.data?.message);
                            } else message.error(e.response.data?.message|| 'Something went wrong');
                        } else message.error("Internal Server Error");
                        setLoad(false);
                        setButtonLabel('Create Account');
                        setPassword('');
                        setRePassword('');                                
                    });
    }

    return (
        <>
        <Header />
        <div    
            className="w-full max-h-full lg:h-screen flex flex-col items-center py-12 bg-white lg:px-12 overflow-y-auto"
            >
            
            <div 
                className="lg:w-1/3 md:w-1/2 bg-white px-3 py-4
                            flex flex-col items-center"
            >
                <h1 className="text-lg text-black py-2 font-medium">
                    Join Assistance
                </h1>
                <form className="login">
                    <Input 
                        autoComplete="new-password"
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        type="text" 
                        placeholder="Username"
                        className="p-2 my-2 rounded-sm text-black"
                    />
                    <Input 
                        autoComplete="new-password"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required                                                
                        type="email" 
                        placeholder="Email address"
                        className="p-2 my-2 rounded-sm text-black"
                    />
                    <Input.Password 
                        autoComplete="new-password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Password"
                        className="p-2 my-2 rounded-sm text-black"
                    />
                    <Input.Password 
                        autoComplete="new-password"
                        required
                        value={rePassword}
                        onChange={(e) => setRePassword(e.target.value)}
                        type="password"
                        placeholder="Confirm Password"
                        className="p-2 my-2 rounded-sm text-black"
                    />
                    {
                        (password?.length && rePassword.length) ? (
                            <>
                                <p className={`text-xs w-full text-left py-1 font-bold ${password === rePassword ? 'text-green-400' : 'text-red-400'}`}>
                                    {password===rePassword ? 'Password match' : 'Password do not match'}
                                </p>
                            </>
                        ) : null
                    }
                    <Checkbox
                        className="my-3 text-gray-600"
                        onChange={(e) => setAgree(e.target.checked)}
                    > By continuing, you agree to Assistance's <span onClick={() => setTermsModal(true)} className="underline cursor-pointer">Terms and Conditions</span>.                    
                    </Checkbox>
                    <button     
                        onClick={register}                       
                        disabled={load}                                      
                        type="submit"                                      
                        className={`w-full text-white font-medium rounded-sm text-lg block primary-btn text-center
                                p-2 my-2 cursor-pointer mb-4 focus:outline-none`}
                    >{buttonLabel}                   
                    </button>
                </form>
                <Link
                    to="/signin"
                    className="w-full text-center py-1 text-md
                                font-medium primary-color"
                >Already have an account? <strong>Sign In</strong></Link>
            </div>
            <Modal
                width={800}    
                onCancel={() => setTermsModal(false)}            
                title="Terms and Conditions"
                visible={termsModal}
                footer={null}
            >                
                <h1 className="text-left font-extrabold text-xl py-3">Privacy</h1>                
                <p className="text-gray-700 py-3 text-justify text-lg">
                    At certain points in the <Link className="hover:text-underline" to="/">assistance.org</Link> website navigation, you may be asked to share your email address or other personal identifying information with us. As provided in these Terms and Conditions, such information will never be distributed to a third party and it will never be publicly visible without your express written consent.

                    Your email address will only be used to send you the <Link className="hover:text-underline" to="/">assistance.org</Link> newsletter and/or to alert you to any information that you have specifically requested you be notified about.
                </p>
                <h2 className="text-left font-extrabold text-xl py-3 ">Use of the Site</h2>                
                <p className="text-gray-700 py-3 text-justify  text-lg">
                    The <Link to="/" className="hover:text-underline">assistance.org</Link> website hosts a blog and a public forum, both of which are equipped with commenting facilities. While we invite you to share your opinions and questions in this way, they must not be used to distribute spam messages, post commercial advertisements, or spread links to malicious or dangerous websites. We do retain the right to moderate any comment or written content submitted to the <Link to="/" className="hover:text-underline">assistance.org</Link> website and to remove any content we deem to have violated our policies.
                </p>
                <h2 className="text-left font-extrabold text-2xl py-3 ">Disclaimer</h2>                
                <p className="text-gray-700 py-3 text-justify  text-lg">
                    All of the content contained on the <Link to="/" className="hover:text-underline">assistance.org</Link> is edited, checked, and verified for accuracy as much as it is possible to do so. However, we cannot guarantee either its accuracy or the safety of any external links it might contain. <Link to="/" className="hover:text-underline">assistance.org</Link>, as well as its owners, affiliates, and contributing authors can therefore not be held responsible for any problems or damage that occurs as a result of making use of material contained on our site.
                </p>
                <h2 className="text-left font-extrabold text-2xl py-3 ">Copyright</h2>                
                <p className="text-gray-700 py-3 text-justify  text-lg">
                    Any and all of the content presented on the <Link to="/" className="hover:text-underline">assistance.org</Link> website is, unless explicitly stated otherwise, subject to a copyright held by <Link to="/" className="hover:text-underline">assistance.org</Link>. It is permissible to link to content from this site as long as the original source is clearly stated, but the wholesale reproduction or partial modification of content is not permitted. Exceptions are granted only if you receive prior written consent from <Link to="/" className="hover:text-underline">assistance.org</Link>.
                </p>
                <h2 className="text-left font-extrabold text-2xl py-3 ">Contact</h2>                
                <p className="text-gray-700 py-3 text-justify  text-lg">
                    Should you have any further questions, concerns, or queries about these Terms and Conditions, or if you encounter difficulties while navigating and using the site, please contact <a href="mailto:contact@assistance.org">contact@assistance.org</a>.
                </p>
            </Modal>
            <a 
                href={`${process.env.REACT_APP_SERVER_URL}auth/google`}
                className="border rounded-sm bg-white shadow py-2 px-10 flex items-center justify-between cursor-pointer my-1 hover:border-gray-700">
                <img
                    alt="google logo"
                    className="h-8 w-8 object-contain mr-2"
                    src={googleLogo}
                />
                <p className="w-full text-md font-medium primary-color">Sign up with Google</p>
            </a>
            <a 
                href={`${process.env.REACT_APP_SERVER_URL}auth/facebook`}
                className="border rounded-sm bg-white shadow py-2 px-10 flex items-center justify-between cursor-pointer my-2 hover:border-gray-700">
                <img
                    alt="facebook logo"
                    className="h-8 w-8 object-contain mr-2"
                    src={facebookLogo}
                />
                <p className="w-full text-md font-medium primary-color">Sign up with Facebook</p>
            </a>
        </div>
        <Footer />
        </>
    )
}

export default Register
