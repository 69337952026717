import React from 'react'
import { getProfileImage } from '../../../utils/Helper'

function ChatBlocks(props) {
    return (
        <div className={`w-full py-3 flex flex-row items-center ${props.isSender?'justify-end':'justify-start'}`}>
            {
                !props.isSender && (
                    <img
                        alt="profile"
                        className="h-8 rounded-full mx-2"
                        src={props?.avatar ? props?.avatar?.indexOf('h')==='0'?props?.avatar:`${process.env.REACT_APP_SERVER_URL}${props?.avatar}` : getProfileImage(props?.username || 'Assistance')}
                    />
                )
            }
            <p className="primary-bg px-4 text-white text-left py-2 rounded-md text-md">
                {props.message}
            </p>
            {
                props.isSender && (
                    <img
                        alt="profile"
                        className="h-8 rounded-full mx-2"
                        src={props?.avatar ? props?.avatar?.indexOf('h')==='0'?props?.avatar:`${process.env.REACT_APP_SERVER_URL}${props?.avatar}` : getProfileImage(props?.username || 'Assistance')}
                    />
                )
            }
        </div>
    )
}

export default ChatBlocks
