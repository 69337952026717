import React, { useState, useEffect } from 'react'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'
import apiInstance from '../../api'
import { checkAvailability } from '../../utils/Helper'

function SearchBar({ data=null, placeholderText, type=null, trigger = t => t, size=null }) {
    let query = new URLSearchParams(useLocation().search);
    let history = useHistory();

    const [searchResult, setSearchResult] = useState([]);    

    const [search, setSearch] = useState('');  
    const [load, setLoad] = useState(false);      

    useEffect(() => {
       if(query) setSearch(query.get('q'));       
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
          
      useEffect(() => {
        async function searchAPI() {  
            setLoad(true);              
            apiInstance.get(`/homepage/search?q=${search?.trim()}`,)
            .then(response => {
                setLoad(false);
              if(response.data.status) {                                          
                setSearchResult([...response.data.blogs?.data,...response.data.orgs?.data]);
              } else {                    
                console.log(response.data?.data||response?.status);            
              }
            })
            .catch(e => {  
              setLoad(false);      
              console.log(e);          
            });          
      } 
        if (search && search!=="" && search!==null) {
            setLoad(true); 
            // debounce fx
            const timer = setTimeout(() =>{
                if (type === null) searchAPI();
            }, 2000);  
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[search])

    const handleChange = (e) => {                
        if(e.key === 'Enter') {
            if (search) trigger();
            if (search)  history.push({ pathname: "/search", search: `?q=${search?.trim()}`})
        }                   
    }

    const searchNow = () => {   
        if (search) trigger();     
        if (search) history.push({ pathname: "/search", search: `?q=${search?.trim()}`})
    }

    return (
        <div className={`${size==='small'?'w-1/2':'w-full'} flex flex-col items-center mb-4 lg:mb-0`}>
            <div className={`w-full flex items-center justify-between ${search?'rounded-t-lg': 'rounded-lg'} border-t border-l border-r border-b border-gray-400 bg-white shadow-sm`}>                
                    <input        
                        autoComplete={false}                   
                        value={search||''}
                        onChange={e => setSearch(e.target.value||'')}
                        onKeyPress={handleChange}
                        onKeyDown={handleChange}
                        className={`w-full p-3 ml-2 text-md primary-color border-none bg-none shadow-none focus:outline-none rounded-lg`}
                        placeholder={placeholderText}
                    />                                            
                <SearchOutlined    
                    onClick={searchNow}                 
                    className="p-2 mr-2 text-lg cursor-pointer" 
                />
            </div>            
            {
                type===null && !!search && searchResult.length ? (
                    <div className="w-full relative flex flex-col px-5 border-b border-l border-r shadow-sm border-gray-400 overflow-x-hidden overflow-y-auto categorySlide">
                        {
                            searchResult?.filter(item => checkAvailability(item?.active_from, item?.active_to) || []).map((result, idx) => (
                                <div key={idx} className="w-full border-b mb-1 flex flex-col items-start">                                                                            
                                    <Link
                                        to={`/article/${result?.category}`}    
                                        className="ml-2 text-xs text-left py-1"
                                    >{result?.category}                                        
                                    </Link>                                    
                                    <Link
                                        to={`/${result.url_key}`}
                                        className="p-2 text-md text-gray-700 text-left border-b cursor-pointer primary-color"
                                    >{result?.title}
                                    </Link>
                                </div>
                            ))
                        }                        
                    </div>
                ) : search && type===null && !load ?
                (
                    <div className="w-full border-b border-l border-r mb-1 flex flex-col items-center text-center border-gray-400">                                                                            
                        <p className="p-1 text-gray-700">No Results Found</p>
                    </div>
                ) : search && load && type===null && (
                    <div className="w-full border-b border-l border-r mb-1 flex flex-col items-center text-center border-gray-400">                                                                            
                        <p className="p-1 text-gray-700">Please Wait</p>
                    </div>
                )
            }
        </div>
    )
}

export default SearchBar
