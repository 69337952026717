import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route  
} from "react-router-dom"
import Index from './pages/Index'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import Contact from './pages/Contact'
import About from './pages/About'
import Category from './pages/Category'
import Search from './pages/Search'
import NotFound from './pages/NotFound'
import Feed from './pages/Feed'
import apiInstance from "./api"
import { useDataLayerValue } from './DataLayer'
import DevBanner from './components/DevBanner'        
import FallBack from './pages/FallBack'
import OurMission from './pages/OurMission'
import Terms from './pages/Terms'
import Register from './pages/Register'
import ForgetPassword from './pages/ForgetPassword'
import GuestRoute from './pages/GuestRoute'
import Profile from './pages/v2/Profile'
import GuestOrg from './pages/GuestOrg'
import FeedbackFloater from './pages/FeedbackFloater'
import OrganizationView from './pages/OrganizationView'
import SearchOrganization from './pages/SearchOrganization'
import SearchBlogs from './pages/SearchBlogs'
import RegisterFallback from './pages/RegisterFallback'
import EmailActivation from './pages/EmailActivation'
import CookiePolicy from './pages/CookiePolicy'
import CookieBanner from './components/User/CookieBanner'
import LandingPage from './pages/v2/LandingPage'
import HomePage from './pages/v2/HomePage'
import Events from './pages/v2/Events/'
import Community from './pages/v2/Community/'
import Create from './pages/v2/Events/Create'
import Detail from './pages/v2/Events/Detail'
import CreateBlog from './pages/v2/Blogs/CreateBlog'
import DetailBlog from './pages/v2/Blogs/DetailBlog'
import CreateCommunity from './pages/v2/Community/Create'
import DetailCommunity from './pages/v2/Community/Detail'
import FileShare from './pages/v2/FileShare'
import { addKeyToData } from './utils/Helper'

function App() {
  
  const [{ allKeywordsStatus, categoriesStatus, communityCategoryStatus, allCommunityPermissionsStatus, allMemberTypesStatus, allNotificationsStatus, allFeedTypesStatus, token }, dispatch] = useDataLayerValue();  
    
  useEffect(() => {    
    async function getAllCategories() {
        apiInstance.get("/category/secure")
        .then(response => {
          if(response.data.status) {
            // SHOOT DATA TO THE DATA LAYER         
            dispatch({
              type: 'SET_CATEGORIES',
              categories: response.data.data
            });
          } else {
            // window.location.replace('/fallback');
            console.log(response.data?.data||response?.status);            
          }
        })
        .catch(e => {
          console.log(e);          
        });
    }
    getAllCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[categoriesStatus]);

  useEffect(() => {    
    async function getAllCommunityCategories() {
        apiInstance.get("/communitycategory/secure")
        .then(response => {
          if(response.data.status) {
            // SHOOT DATA TO THE DATA LAYER         
            dispatch({
              type: 'SET_COMMUNITY_CATEGORIES',
              categories: response.data.data
            });
          } else {
            // window.location.replace('/fallback');
            console.log(response.data?.data||response?.status);            
          }
        })
        .catch(e => {
          console.log(e);          
        });
    }
    getAllCommunityCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[communityCategoryStatus]);

  useEffect(() => {    
    async function getAllMemberTypes() {            
        apiInstance.get("/membertype/secure")
        .then(response => {        
            if(response.data.status) {
            // DISPATCH TO DATA LAYER !!!
            dispatch({
                type: "SET_ALL_MEMBER_TYPES",
                memberTypes: response.data.data
            });

        } else  console.log(response.data?.data||response?.status); 
        }).catch(e => {
          console.log(e);    
        });
    }
    getAllMemberTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[allMemberTypesStatus]);

  useEffect(() => {
    async function getMyNotifications() {            
      apiInstance.get("/notification")
      .then(response => {        
          if(response.data.status) {
          // DISPATCH TO DATA LAYER !!!
          dispatch({
              type: "SET_NOTIFICATIONS",
              payload: response.data.data
          });

      } else  console.log(response.data?.data||response?.status); 
      }).catch(e => {
        console.log(e);    
      });
  }
  if (token) {
    getMyNotifications();
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[allNotificationsStatus]);

  useEffect(() => {
    async function getAllFeedTypes() {            
        apiInstance.get("/feedtype")
        .then(response => {        
            // DISPATCH TO DATA LAYER !!!
            dispatch({
                type: "SET_ALL_FEED_TYPES",
                feedTypes: response.data.data
            });
        }).catch(e => {
          console.log(e);
        });
    }

    getAllFeedTypes();
// eslint-disable-next-line react-hooks/exhaustive-deps
  },[allFeedTypesStatus]);

  useEffect(() => {        
    async function getAllKeywords() {
        apiInstance.get("/keyword")
        .then(response => {        
            if(response.data.status) {    
              // ADDING AN EXTRA KEY TO THE RESPONSE DATA FOR REACT UNIQUE PROP
              addKeyToData(response.data.data, "id");                 
            // DISPATCH TO DATA LAYER !!!
            dispatch({
                type: "SET_ALL_KEYWORDS",
                keywords: response.data.data
            });                

          }
        }).catch(e => {    
           console.log('Internal Server Error');
        });
      }

      getAllKeywords();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[allKeywordsStatus]);

  useEffect(() => {
    async function getAllCommunityPermissions() { // shoots all the present permissions from db to data layer
    apiInstance.get("/communityPermission")
    .then(response => {        
        if(response.data.status) {
        // ADDING AN EXTRA KEY TO THE RESPONSE DATA FOR REACT UNIQUE PROP
        addKeyToData(response.data.data, "id");
        // DISPATCH TO DATA LAYER !!!
        dispatch({
            type: "SET_ALL_COMMUNITY_PERMISSIONS",
            permissions: response.data.data
        });

        } 
    }).catch(e => {
       console.log(e);  
    });

    }

    getAllCommunityPermissions();
// eslint-disable-next-line react-hooks/exhaustive-deps
  },[allCommunityPermissionsStatus]); 

  return (
    <div>
        {
          process.env.REACT_APP_ENV === 'staging' ?
          <DevBanner title={"DEVELOPMENT MODE"} /> : null
        }
        <FeedbackFloater />
        <CookieBanner />
        <Router>                   
          <Switch>

            <Route path="/dashboard"> <Dashboard /> </Route>
            <Route path="/get-started"> <Register /> </Route>
            <Route path="/signin"> <Login /> </Route>
            <Route path="/admin"> <Login /> </Route>
            <Route path="/contact"> <Contact /> </Route>
            <Route path="/reset-password"> <ForgetPassword /> </Route> 
            <Route path="/about"> <About /> </Route>
            <Route path="/mission"> <OurMission /> </Route>
            <Route path="/terms"> <Terms /> </Route>   
            <Route path="/cookie-policy"> <CookiePolicy /> </Route>            
            <Route path="/article/:id"> <Category /> </Route> 
            <Route path="/organization/:id"> <OrganizationView /> </Route>           
            <Route path="/password/reset/:userId/:token"> <GuestRoute /> </Route> 
            <Route path="/emailaddress/verification/:userId/:token"> <EmailActivation /> </Route>                
            <Route path="/search"> <Search />  </Route>
            <Route path="/fallback" exact> <FallBack /> </Route>
            <Route path="/oops"> <NotFound /> </Route>
            <Route path="/blog" exact> <Index /> </Route>
            <Route path="/home"> <HomePage /> </Route>
            <Route path="/blog/create" exact> <CreateBlog /> </Route>
            <Route path="/b/:key" exact> <DetailBlog /> </Route>
            <Route path="/event/create"> <Create /> </Route>
            <Route path="/e/:key/invitation/:eventId/:roofToken/:inviteId/:check"> <Detail /> </Route>
            <Route path="/e/:key"> <Detail /> </Route>
            <Route path="/c/:key/invitation/:orgId/:token/:inviteId"> <DetailCommunity /> </Route>
            {/* <Route path="/invitation/accept/:eventId/:token/:inviteId/:check"> <Invitation /> </Route> */}
            <Route path="/event"> <Events /> </Route>
            <Route path="/community/create"> <CreateCommunity /> </Route>
            <Route path="/community"> <Community /> </Route>    
            <Route path="/:community_name/files/:file_name"> <FileShare /> </Route>
            <Route path="/:community_name/:key"> <Detail /> </Route>  
            <Route path="/@:username" exact> <Profile /> </Route>                  
            <Route path="/registration-complete"> <RegisterFallback /> </Route>
            <Route path="/search-organizations" exact> <SearchOrganization /> </Route>
            <Route path="/search-blogs" exact> <SearchBlogs /> </Route>
            <Route path="/submit-organization"> <GuestOrg/> </Route>
            <Route path="/b/:feedkey" exact> <Feed /> </Route>
            <Route path="/:key"> <DetailCommunity /> </Route> 
            <Route path="/" exact> <LandingPage /> </Route>
            <Route path="*"> <NotFound />  </Route>            

          </Switch>                          
        </Router>
    </div>  
  )

}

export default App;

