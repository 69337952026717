import React from 'react'
import { Row } from 'antd'
import CommunityCategory from './CommunityCategory';

function Community() {   

    return (
        <div className="w-full h-full flex flex-col items-center px-4">
            <h1
                className="w-full text-left text-lg font-semibold mb-4"
            >Manage Community Related Information               
            </h1>
            <Row className="w-full h-full">                
                <div className="w-2/5 h-full flex flex-col">
                    <h1
                        className="w-full text-left text-md font-semibold mb-4"
                    >CATEGORY
                    </h1>
                    <CommunityCategory />
                </div>   
                {/* <div className="w-3/5 h-full flex flex-col px-4 border-l border-gray-400">
                    <h1
                        className="w-full text-left text-md font-semibold mb-4"
                    >Feeds
                    </h1>
                    <RSSFeed />
                </div>    */}
            </Row>           
        </div>
    )
}

export default Community
    