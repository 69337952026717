/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import Footer from '../../components/User/v2/Footer'
import Header from '../../components/User/v2/Header'
import { Radio, Spin } from 'antd'
import MainSlider from '../../components/User/v2/MainSlider'
import { useDataLayerValue } from '../../DataLayer'
import {SendOutlined} from '@ant-design/icons';
import apiInstance from '../../api/'
import BriefItem from '../../components/User/v2/BriefItem'
import { getProfileImage } from '../../utils/Helper'
import CItem from './Community/CItem'
import AnnouncementItem from '../../components/User/v2/AnnouncementItem'
import ChatItem from '../../components/User/v2/ChatItem';
import ChatBlocks from '../../components/User/v2/ChatBlocks';

import socketIOClient from "socket.io-client";
import { Link } from 'react-router-dom'
import BlogItem from '../../components/User/v2/BlogItem'

// const socket = socketIOClient();

function HomePage() {

    const [{user, token}] = useDataLayerValue();
    const [selectedPanel, setSelectedPanel] = useState('blogs');
    const [events, setEvents] = useState([]);
    const [communities, setCommunities] = useState([]);
    const [announcements, setAnnouncements] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [load, setLoad] = useState(false);
    const [eventMode, setEventMode] = useState('upcoming');
    const [response, setResponse] = useState('');
    const [message, setMessage] = useState('');

    const getEngagedItems = () => {
        setLoad(true);
        apiInstance
        .get(`/person/engagedItems/${new Date().toISOString()}`)
        .then(response => {
            if (response.data) {
                setEvents(response.data.events);
                setCommunities(response.data.communities);
                setAnnouncements(response.data.announcements);
                setBlogs(response.data.blogs);
            }
        })
        .catch(e => {
            console.log(e.response);
        })
        .finally(() => setLoad(false));
    }

    useEffect(() => {
        if (token) {
            getEngagedItems();
        } else {
            window.location.replace('/');
        }
    }, []);

    // useEffect(() => {
    //     socket.on("message", message => {
    //     console.log(message);
    //       setResponse(prevState => [...prevState, message]);
    //     });
    // }, []);

    // const sendMessage = () => {
    //     socket.emit("message", `${message}`);
    //     setMessage("");
    // };

    return (
        <>
            <Header fixed={true} />
            <div className="w-full lg:w-11/12 mx-auto flex flex-row items-start justify-between py-10">
                
                <div className="w-full lg:w-1/3 flex flex-col items-center px-4">
                    <img
                        alt="profile"
                        src={user?.image ? user?.image?.indexOf('h')===0?user?.image:`${process.env.REACT_APP_SERVER_URL}${user?.image}`: getProfileImage(user?.username || 'Assistance')}
                        className="profile__avatar rounded-full mb-3"
                    />
                    <h1 className="w-full font-semibold text-black text-xl text-center py-1">
                        {user?.username}
                    </h1>
                    <p className="mb-2 w-full font-medium text-gray-600 text-center">
                        {moment().format('dddd, MMM, YYYY')}
                    </p>
                    <div className="w-full flex flex-col items-center py-3">
                        <h3 
                            onClick={() => setSelectedPanel('announcements')}
                            className={`text-left font-medium ${selectedPanel==='announcements'?'text-white primary-bg hover:text-white':'text-gray-600 hover:text-blue-500'} cursor-pointer text-lg px-3 rounded-sm py-2`}>
                            Announcements
                        </h3>
                        <h3 
                            onClick={() => setSelectedPanel('communties')}
                            className={`text-left font-medium ${selectedPanel==='communties'?'text-white primary-bg hover:text-white':'text-gray-600 hover:text-blue-500'} text-lg py-2 cursor-pointer px-3 rounded-sm`}>
                            My Communities
                        </h3>
                        <h3 
                            onClick={() => setSelectedPanel('events')}
                            className={`text-left font-medium ${selectedPanel==='events'?'text-white primary-bg hover:text-white':'text-gray-600 hover:text-blue-500'} text-lg py-2 cursor-pointer px-3 rounded-sm`}>
                            My Events
                        </h3>
                        <h3 
                            onClick={() => setSelectedPanel('blogs')}
                            className={`text-left font-medium ${selectedPanel==='blogs'?'text-white primary-bg':'text-gray-600'} text-lg py-2 cursor-pointer px-3 rounded-sm`}>
                            My Blogs
                        </h3>
                        <h3 
                            onClick={() => setSelectedPanel('messaging')}
                            className={`text-left font-medium ${selectedPanel==='messaging'?'text-white primary-bg':'text-gray-600'} text-lg py-2 cursor-pointer px-3 rounded-sm`}>
                            Inbox
                        </h3>
                    </div>
                </div>

                <div className="w-full flex flex-col items-start px-2">
                    {
                        load ? (
                            <div className="w-full flex flex-row items-center justify-center">
                                <Spin size="default"/>
                            </div>
                        ) : (
                            selectedPanel === 'announcements' ? (
                                <div className="w-full">
                                    {
                                        !announcements?.length && (
                                            <p className="py-4 text-center">
                                                No announcements available
                                            </p>
                                        )
                                    }
                                    <div className="w-full flex items-center justify-center">
                                        {
                                            announcements?.map((item, idx) => (
                                                <AnnouncementItem
                                                    {...item}
                                                    key={idx}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            ) : selectedPanel === 'communties' ? (
                                <div className="w-full">
                                    {
                                        !communities?.length && (
                                            <p className="py-4 text-center">
                                                No communties available
                                            </p>
                                        )
                                    }
                                <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-2">
                                    {
                                        communities?.map((community, key) => (
                                            <CItem key={key} isImage={true} {...community} />
                                        ))
                                    }
                                </div>
                                </div>
                            ) : selectedPanel === 'events' ? (
                                <div className="w-full">
                                    <div className="w-full flex flex-row items-start lg:items-end lg:justify-end py-2">
                                        <Radio.Group value={eventMode} onChange={e => setEventMode(e.target.value)}>
                                            <Radio.Button value="upcoming">Upcoming</Radio.Button>
                                            <Radio.Button value="past">Past Events</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                    {
                                        !(eventMode==='upcoming'?
                                        events?.filter(item => moment()?.isBetween(moment(item?.startDate), moment(item?.endDate))):
                                        events?.filter(item => moment(item?.endDate)?.isBefore(moment())))
                                        ?.length && (
                                            <p className="py-4 text-center">
                                                No events available
                                            </p>
                                        )
                                    }
                                    <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-2">
                                    {
                                        (eventMode==='upcoming'?
                                        events?.filter(item => moment()?.isBetween(moment(item?.startDate), moment(item?.endDate))):
                                        events?.filter(item => moment(item?.endDate)?.isBefore(moment())))
                                        ?.map((event, key) => (
                                            <BriefItem keyName={'e'} key={key} fixed={false} {...event} />
                                        ))
                                    }
                                    </div>
                                </div>
                            ) : selectedPanel === 'messaging' ? (
                                <div className="w-full flex flex-row items-start justify-between">
                                    <div className="w-6/12 inbox__container border-r bg-gray-100">
                                        <ChatItem 
                                            {...{avatar: null, username: 'Guide Us'}}
                                        />
                                        <ChatItem 
                                            {...{avatar: null, username: 'Guide Us'}}
                                        />
                                        <ChatItem 
                                            {...{avatar: null, username: 'Guide Us'}}
                                        />
                                        <ChatItem 
                                            {...{avatar: null, username: 'Guide Us'}}
                                        />
                                        <ChatItem 
                                            {...{avatar: null, username: 'Guide Us'}}
                                        />
                                    </div>
                                    <div className="bg-gray-100 w-full inbox__container p-2 flex flex-col items-start justify-between">
                                        <div className="w-full inbox__area bg-gray-100 flex flex-col items-start">
                                            <ChatItem 
                                                {...{avatar: null, username: 'Guide Us'}}
                                            />
                                            <div className="w-full flex flex-col items-start py-2">
                                                <ChatBlocks 
                                                  {...{avatar: null, username: 'Guide Us', message: 'Hello, World!', isSender: true}}   
                                                />
                                                <ChatBlocks 
                                                  {...{avatar: null, username: 'Guide Us', message: 'Hello!', isSender: false}}   
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full py-1 bg-white border flex flex-row items-center justify-between">
                                            <input value={message} onChange={(e) => setMessage(e.target.value)} type="text" placeholder="Enter Message" className="w-full outline-none bg-transparent text-black p-2"/>
                                            <button onClick={() => {}} className="primary-bg rounded-md text-white p-3 focus:outline-none">
                                                <SendOutlined style={{fontSize: 20}} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : selectedPanel === 'blogs' ? (
                                <div className="w-full flex flex-col items-start">
                                   {
                                       !blogs?.length && (
                                        <div className="w-full flex flex-col items-center py-4">
                                            <h2 className="w-full text-center text-black font-medium text-md py-1">
                                                You have engaged with no blogs
                                            </h2>
                                            <p className="w-full text-gray-600 text-center text-sm">
                                                No blogs available
                                            </p>
                                            <Link
                                                to="/blog/create"
                                                className="text-white primary-bg hover:text-white rounded-sm py-2 px-4 my-2"
                                            >
                                                Create your blog
                                            </Link>
                                        </div>
                                       )
                                   }
                                   <div className="w-full flex flex-col items-start">
                                   <Link
                                        to="/blog/create"
                                        className="text-white primary-bg hover:text-white rounded-sm py-2 px-4 my-2"
                                    >
                                        Create your blog
                                    </Link>
                                    {
                                        blogs?.map((item, idx) => (
                                            <BlogItem {...item} key={idx} handleBlogGet={() => getEngagedItems()} />
                                        ))
                                    }
                                   </div>
                                </div>
                            ) : null
                        )
                    }
                </div>

            </div>
            <Footer />
        </>
    )
}

export default HomePage
