import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Header from '../components/User/Header'
import Footer from '../components/User/Footer'

function Terms() {

    useState(() => {
        document.title = 'Terms and Conditions - Assistance';
    },[]);

    return (
        <>
            <Header />            
            <div className="terms px-8 lg:px-24 w-full h-full py-12 flex flex-col items-start">                
                <h1 className="w-full text-center font-extrabold text-3xl py-3 lg:px-12">Terms and Conditions</h1>   
                <h2 className="text-left font-extrabold text-2xl py-3 lg:px-12">Privacy</h2>                
                <p className="text-gray-700 py-3 text-justify lg:px-12 text-lg">
                    At certain points in the <Link className="hover:text-underline" to="/">assistance.org</Link> website navigation, you may be asked to share your email address or other personal identifying information with us. As provided in these Terms and Conditions, such information will never be distributed to a third party and it will never be publicly visible without your express written consent.

                    Your email address will only be used to send you the <Link className="hover:text-underline" to="/">assistance.org</Link> newsletter and/or to alert you to any information that you have specifically requested you be notified about.
                </p>
                <h2 className="text-left font-extrabold text-2xl py-3 lg:px-12">Use of the Site</h2>                
                <p className="text-gray-700 py-3 text-justify lg:px-12 text-lg">
                    The <Link to="/" className="hover:text-underline">assistance.org</Link> website hosts a blog and a public forum, both of which are equipped with commenting facilities. While we invite you to share your opinions and questions in this way, they must not be used to distribute spam messages, post commercial advertisements, or spread links to malicious or dangerous websites. We do retain the right to moderate any comment or written content submitted to the <Link to="/" className="hover:text-underline">assistance.org</Link> website and to remove any content we deem to have violated our policies.
                </p>
                <h2 className="text-left font-extrabold text-2xl py-3 lg:px-12">Disclaimer</h2>                
                <p className="text-gray-700 py-3 text-justify lg:px-12 text-lg">
                    All of the content contained on the <Link to="/" className="hover:text-underline">assistance.org</Link> is edited, checked, and verified for accuracy as much as it is possible to do so. However, we cannot guarantee either its accuracy or the safety of any external links it might contain. <Link to="/" className="hover:text-underline">assistance.org</Link>, as well as its owners, affiliates, and contributing authors can therefore not be held responsible for any problems or damage that occurs as a result of making use of material contained on our site.
                </p>
                <h2 className="text-left font-extrabold text-2xl py-3 lg:px-12">Copyright</h2>                
                <p className="text-gray-700 py-3 text-justify lg:px-12 text-lg">
                    Any and all of the content presented on the <Link to="/" className="hover:text-underline">assistance.org</Link> website is, unless explicitly stated otherwise, subject to a copyright held by <Link to="/" className="hover:text-underline">assistance.org</Link>. It is permissible to link to content from this site as long as the original source is clearly stated, but the wholesale reproduction or partial modification of content is not permitted. Exceptions are granted only if you receive prior written consent from <Link to="/" className="hover:text-underline">assistance.org</Link>.
                </p>
                <h2 className="text-left font-extrabold text-2xl py-3 lg:px-12">Contact</h2>                
                <p className="text-gray-700 py-3 text-justify lg:px-12 text-lg">
                    Should you have any further questions, concerns, or queries about these Terms and Conditions, or if you encounter difficulties while navigating and using the site, please contact <a href="mailto:contact@assistance.org">contact@assistance.org</a>.
                </p>
                <div className="w-full lg:px-12 py-2">
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Terms
