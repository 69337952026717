/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/style-prop-object */
import React from 'react'
import {SoundTwoTone} from '@ant-design/icons'
import draftToHtml from 'draftjs-to-html'
import moment from 'moment'
import { getProfileImage } from '../../../utils/Helper'
import { useDataLayerValue } from '../../../DataLayer'

function AnnouncementItem(props) {
    const [{token, user}] = useDataLayerValue();
    
    return (
        <div className="border-2 rounded-sm shadow-sm p-4">
            <div className="py-2 w-full flex flex-row items-start">
                <SoundTwoTone style={{ fontSize: 40 }} />
                <div className="w-fullflex flex-col items-start">
                    <h1 className="w-full text-left font-medium text-xl px-3">
                        {props.title}
                    </h1>
                    <p className="px-3 w-full text-left text-gray-700 py-2 text-md">
                        {props.description}
                    </p>
                </div>
            </div>
            {
                props.image && (
                    <img
                        src={`${process.env.REACT_APP_SERVER_URL}api/communityannouncement/${props?.image}?a= ${token}`}
                        alt="image"
                        className="my-2 object-cover" style={{ maxHeight: 200 }}
                    />
                )
            }
            <p
                className="w-full text-left text-black py-2 text-lg"
                dangerouslySetInnerHTML={{__html: draftToHtml(JSON.parse(props.content)) }}
            ></p>
            <div className="w-full flex flex-col items-start py-1 border-b">
                {
                    props.shared_members && (
                        props.shared_members?.indexOf(',')?
                        props.shared_members?.split(',')?.indexOf(user?.id?.toString()) !== -1 
                        : props.shared_members === user?.id?.toString()) && (
                            <>
                                <p className="w-full text-gray-700 text-left text-md">
                                    You have an action item
                                </p>
                                <p className="w-full text-black text-left text-lg">
                                    {props.actionDetail||''}
                                </p>
                            </>
                        )
                }
            </div>
            <div className="w-full flex flex-row items-start py-2">
                <img
                    src={props?.logo ? props?.logo?.indexOf('h')==='0'?props?.logo:`${process.env.REACT_APP_SERVER_URL}${props?.logo}`: getProfileImage(props?.name || 'Assistance')}
                    alt="logo"
                    className="object-cover h-12 w-12 rounded-full"
                />
                <div className="flex flex-col item-start">
                    <p
                        className="w-full text-left px-2 text-black text-md">
                        {props.name}
                    </p>
                    <p className="w-full text-left text-gray-7000 px-2 text-xs">
                        {moment(props.activeFrom).fromNow()}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AnnouncementItem
