/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import Header from '../../../components/User/v2/Header'
import Footer from '../../../components/User/v2/Footer'
import { useParams, useHistory } from 'react-router-dom'
import { FacebookFilled, LinkOutlined, WhatsAppOutlined, SearchOutlined, QuestionCircleOutlined, ShareAltOutlined, TeamOutlined, SettingOutlined, PhoneOutlined, MailOutlined, TwitterOutlined, LogoutOutlined, EditOutlined } from '@ant-design/icons'
import {message,Button, Table,Input,Radio,Space, Checkbox, Divider, Spin, Dropdown, Menu, Select, Switch, Popconfirm, Tag} from 'antd'
import draftToHtml from 'draftjs-to-html'
import { useDataLayerValue } from '../../../DataLayer'
import apiInstance from '../../../api'
import {buildPermissionName, getProfileImage, validateEmail, constructJSONMap} from '../../../utils/Helper'
import Modal from 'antd/lib/modal/Modal'
import logo from '../../../assets/img/icon.png'
import MainSlider from '../../../components/User/v2/MainSlider'
import defaultMaleImage from '../../../assets/img/default_user_male.jpg'
import Search from 'antd/lib/input/Search'
import moment from 'moment'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState } from 'draft-js'
import { convertFromRaw, convertToRaw } from 'draft-js'

import { Option } from 'antd/lib/mentions'
import Highlighter from 'react-highlight-words'
import download from 'js-file-download'

const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

const announcementContent = {"entityMap":{},"blocks":[]};

function DetailCommunity() {

    const [{user, allCommunityPermissions, allMemberTypes}] = useDataLayerValue();

    const history = useHistory();
    const { key, orgId, token, inviteId } = useParams();

    const [data, setData] = useState(null);
    const [loadingState, setLoadingState] = useState(false);
    const [activeTab, setActiveTab] = useState('info');
    const [descriptionContent, setDescriptionContent] = useState(null);
    const [invitationModal, setInvitationModal] = useState(false);
    const [invitationLoadingState, setInvitationLoadingState] = useState(false);
    const [tempUser, setTempUser] = useState(null);
    const [invitationLoad, setInvitationLoad] = useState(false);
    const [invitations, setInvitations] = useState([]);
    const [activeInvitationStatus, setActiveInvitationStatus] = useState('accept');
    const [invitationEmail, setInvitationEmail] = useState(''); 
    const [totalMembers, setTotalMembers] = useState(0);
    const [reportEmail, setReportEmail] = useState('');
    const [reportMessage, setReportMessage] = useState('');
    const [reportLoading, setReportLoading] = useState(false);
    const [reasonsList, setReasonsList] = useState([]);
    const [selectedReason, setSelectedReason] = useState(null);
    const [completed, setCompleted] = useState(false);
    const [invitationData, setInvitationData] = useState(null);
    const [acceptLoadingState, setAcceptLoadingState] = useState(false);
    const [eventsStatus, setEventStatus] = useState('upcoming');
    const [communityEvents, setCommunityEvents] = useState([]);
    const [members, setMembers] = useState([]);
    const [previewData, setPreviewData] = useState(null);
    const [search, setSearch] = useState(null);
    const [page, setPage] = useState(1);
    const [filePrivacy, setFilePrivacy] = useState('public');
    const [selectPrivacyMember, setSelectPrivacyMember] = useState([]);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [fileDescription, setFileDescription] = useState('');
    const [fileUploadModal, setFileUploadModal] = useState(false);
    const [fileUploadLoad, setFileUploadLoad] = useState(false);
    const [communityFiles, setCommunityFiles] = useState([]);
    const [downloadLoad, setDownloadLoad] = useState(false);
    const [removeLoad, setRemoveLoad] = useState(false);
    const [updateLoad, setUpdateLoad] = useState(false);
    const [exisitingPrivacyMembers, setExistingPrivacyMembers] = useState([]);
    const [currentSelectedFile, setCurrentSelectedFile] = useState(null);
    const [privacyMembersToAdd, setPrivacyMembersToAdd] = useState([]);
    const [privacyMembersToRemove, setPrivacyMembersToRemove] = useState([]);
    const [openSettings, setOpenSettings] = useState(false);
    const [ssLoad, setSSLoad] = useState(false);

    // announcements
    const [announcementModal, setAnnouncementModal] = useState(false);
    const [aTitle, setATitle] = useState('');
    const [aDescription, setADescription] = useState('');
    const [aImage, setAImage] = useState(null);
    const [aContent, setAContent] = useState(null);
    const [aContentState, setAContentState] = useState(EditorState.createEmpty());
    const [aIsActionItem, setAIsActionItem] = useState(false);
    const [aActiveFrom, setActiveFrom] = useState('');
    const [aActiveTo, setActiveTo] = useState('');
    const [aFiles, setAFiles] = useState(null);
    const [allAnnouncements, setAllAnnouncements] = useState([]);
    const [aLoad, setALoad] = useState(false);
    const [currentAnnoucement, setCurrentAnnouncement] = useState(null);
    const [bLoad, setBLoad] = useState(false);
    const [aPeopleSelect, setAPeopleSelect] = useState([]);
    const [actionDescription, setActionDescription] = useState('');
    const [aPeopleToAdd, setPeopleToAdd] = useState([]);
    const [aPeopleToRemove, setPeopleToRemove] = useState([]);
    const [existingAPeople, setExistingAPeople] = useState([]);
    const [activeSubTab, setActiveSubTab] = useState('members');
    const [allSubscriptions, setAllSubscriptions] = useState([]);
    const [sLoad, setSLoad] = useState(false);
    const [sModal, setSModal] = useState(false);
    const [sTitle, setSTitle] = useState('');
    const [sPrice, setSPrice] = useState('0');
    const [isSFree, setIsSFree] = useState(true);
    const [sValidity, setSValidity] = useState(null);
    const [currentSubscription, setCurrentSubscription] = useState(null);

    const [memberModal, setMemberModal] = useState(false);
    const [memberUserType, setMemberUserType] = useState(null);
    const [memberUsername, setMemberUsername] = useState('');
    const [memberEmail, setMemberEmail] = useState('');
    const [memberExpire, setMemberExpire] = useState('');
    const [memberRenewal, setMemberRenewal] = useState('');
    const [memberLoad, setMemberLoad] = useState(false);
    const [currentMember, setCurrentMember] = useState(null);
    const [memberAutoRenewal, setMemberAutoRenewal] = useState(false);
    const [memberSubscription, setMemberSubscription] = useState(null);
    const [memberUsernameAvailable, setMemberUsernameAvailable] = useState(null);
    const [memberEmailAvailable, setMemberEmailAvailable] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchInput, setSearchInput] = useState('');

    const [communityPermissions, setCommunityPermissions] = useState([]);
    const [permissionCategories, setPermissionCategories] = useState([]);
    const [assignedPermissions, setAssignedPermissions] = useState([]);
    const [checkedPermissions, setCheckedPermissions] = useState([]);  
    const [progress, setProgress] = useState(true);
    const [checkAll, setCheckAll] = useState([]);
    const [permissionsToAdd, setPermissionsToAdd] = useState([]);
    const [permissionsToRemove, setPermissionsToRemove] = useState([]);
    const [plans, setPlans] = useState([]);

    const allowedToCreateAnnouncement = aTitle && aContent && aActiveFrom && aActiveTo && (aIsActionItem ? actionDescription && aPeopleSelect?.length > 0 : true);
    // const allowedToModifyAnnouncement = aImage!==currentAnnoucement?.image&&aIsActionItem!==currentAnnoucement?.&&aDescription!==currentAnnoucement?.description&&aTitle!==currentAnnoucement?.title && aContent!==currentAnnoucement?.content && aActiveFrom!==currentAnnoucement?.activeFrom && aActiveTo!==currentAnnoucement?.activeTo;

    const handlePeopleSelect = (e) => {
        setPeopleToAdd(e.filter(k => !existingAPeople.includes(k)));
        setPeopleToRemove(existingAPeople.filter(sp => !e.includes(sp)));
        setAPeopleSelect(e);
    };

    const onEditorChange = (eState) => {
        const tempState = eState.getCurrentContent();
        setAContent(tempState);
        setAContentState(eState);
    };

    const handleFileUpload = () => {
        let filePayload = new FormData();
        filePayload.append('communityId', data?.org_id);
        if (fileToUpload) {
            filePayload.append('file', fileToUpload);
        }
        filePayload.append('description', fileDescription || '');
        filePayload.append('privacy', filePrivacy);
        if (selectPrivacyMember?.length) {
            filePayload.append('privacy_members', JSON.stringify(selectPrivacyMember));
        }
        setFileUploadLoad(true);
        apiInstance
        .post("/community-files", filePayload)
        .then(response => {
            message.success(response.data.message);
            setFileToUpload(null);
            setFilePrivacy('public');
            setSelectPrivacyMember([]);
            setFileUploadModal(false);
            getFilesByOrg();
        })
        .catch(e => {
            console.log(e);
            if (e.response.data) {
                message.error(e.response.data?.message || 'Internal Server Error');
            } else message.error('Internal Server Error');
        }).finally(() => setFileUploadLoad(false));
    };

    const downloadFile = (id) => {
        let payload = {
            id
        };
        window.open(`${process.env.REACT_APP_SERVER_URL}api/community-files/download/${id}`, '_blank');
    };

    const modifyFile = (name) => {
        setUpdateLoad(true);
        let payload = {
            id: currentSelectedFile?.id,
            membersToAdd: privacyMembersToAdd?.length ? JSON.stringify(privacyMembersToAdd) : null,
            membersToRemove: privacyMembersToRemove?.length ? JSON.stringify(privacyMembersToRemove) : null,
            description: fileDescription,
            privacy: filePrivacy,
            communityId: data?.org_id
        };
        apiInstance
        .put(`/community-files/modify`, payload)
        .then(response => {
            getFilesByOrg();
            setFileUploadModal(false);
            message.success(response.data.message);
        })
        .catch(e => {
            console.log(e);
            message.error(typeof e?.response.data.message === 'string' ? e?.response.data.message : 'Internal Server Error');
        })
        .finally(() => {
            setUpdateLoad(false);
            resetTempVars();
        })
    };

    const removeImage = () => {
      if (aImage) {
          apiInstance.put("/communityannouncement/removeImage", { communityId: data?.org_id, fileName: aImage })
          .then(response => {
            setAImage(null);
            setAFiles(null);
            message.success('Image Removed Successfully');
          })
          .catch(e => message.error('Internal Server Error'));
      }  
    };

    const resetTempVars = () => {
        setCurrentSelectedFile(null);
        setPrivacyMembersToAdd([]);
        setPrivacyMembersToRemove([]);
        setExistingPrivacyMembers([]);
    }

    const removeFile = (name) => {
        let payload = {
            id: communityFiles?.filter(f => f.file_name === name)[0]?.id || null,
            filePath: communityFiles?.filter(f => f.file_name === name)[0]?.file_path
        };
        setRemoveLoad(true);
        apiInstance.post('/community-files/remove', payload)
        .then(response => {
            message.success(response.data.message);
            getFilesByOrg();
        })
        .catch(e => {
            message.error(e.response?.data.message || 'Internal Server Error');
        }).finally(() => setRemoveLoad(false));
    }

    const getFilesByOrg = (id=null, member=false) => {
        setFileUploadLoad(true);
        apiInstance
        .get(`/community-files/${member?'secure/':''}${id || data?.org_id}`)
        .then(response => {
            setCommunityFiles(response.data.data);
        })
        .catch(e => {
            console.log(e.response);
        })
        .finally(() => {
            setFileUploadLoad(false);
        });
    };

    const handlePrivacyMemberChange = (e) => {
        setPrivacyMembersToAdd(e.filter(k => !exisitingPrivacyMembers.includes(k)));
        setPrivacyMembersToRemove(exisitingPrivacyMembers.filter(sp => !e.includes(sp)));
        setSelectPrivacyMember(e);
    }

    const onSearch = (value, key) => {
        if (key) setSearch(value);
        else setSearch(value);
    };

    const getLinkPreview = (url) => {
        apiInstance
        .get(`/homepage/link-preview?url=${url}`)
        .then(response => {
            setPreviewData(response.data);
        })
        .catch(e => {
            console.log(e);
        });
    }
    
    const isOwner = user?.id === data?.created_by;

    function getAllInvitationsByOrganization(id=null) {
        if (data?.org_id || id) {
            apiInstance
            .get(`/organization/invitation/${data?.org_id || id}`)
            .then(response => {
                setInvitations(response.data.data);
            })
            .catch(e => {
                console.log(e);
            });
        }
    }

    function getAllMembersByOrg(id=null, createdBy=null, page=1) {
        if (data?.org_id || id) {
            apiInstance
            .get(`/organization/members/${data?.org_id || id}?page=${createdBy===user?.id?-1:data?.created_by===user?.id?-1:page}`)
            .then(response => {
                setMembers(response.data.data);
                setTotalMembers(response.data.found - 1 || 0);
            })
            .catch(e => {
                console.log(e);
            });
        }
    }
    
    async function findOrgByKey(key) {
        setLoadingState(true);
        apiInstance
        .get(`/organization/key/${key}`)
        .then(response => {
            if (response.data.status) {
                document.title = `${response.data.data?.name} - Assistance Org`;
                setData(response.data.data);
                getPlansByCommunity(response.data.data.org_id);
                if (user?.id === response.data.data?.created_by) {
                    getAllInvitationsByOrganization(response.data.data.org_id);
                    getAllAnnouncements(response.data.data.org_id);
                }
                if (response.data.data.created_by === user?.id) {
                    // getFilesByOrg(response.data.data.org_id);
                } else {
                    // getFilesByOrg(response.data.data.org_id, true);
                }
                getAllMembersByOrg(response.data.data.org_id, response.data.data.created_by);
                getAllCommunityEvents(response.data.data.org_id);
                if (response.data.data?.description) {
                    setDescriptionContent(JSON.parse(response.data.data.description));
                }
                getLinkPreview(response.data.data.url);
            } else {
                message.warning(response.data?.message || 'something went wrong');
                history.push('/oops');
            }
        })
        .catch(e => {
            if(e.response?.status === 404) {
                history.push('/oops');
            }
            console.log(e);
        }).finally(() => {
            setLoadingState(false);
        });
    }

    async function checkForUser() {
        setTempUser(null);
        setInvitationLoadingState(true);
        apiInstance
        .get(`/organization/user/check?email=${invitationEmail}`)
        .then(response => {
            if (response.data.status) {
                setTempUser(response.data.data);
            }
        })
        .catch(e => {
            console.log(e);
        }).finally(() => {
            setInvitationLoadingState(false);
        });
    }

    // useEffect(() => {
    //     // debounce fx
    //     const timer = setTimeout(() =>{
    //         checkUserLogic();
    //     }, 2000);  
    //     return () => clearTimeout(timer);
    // }, [invitationEmail]);

    const checkUserLogic = () => {
        if (invitationEmail) {
            if (validateEmail(invitationEmail)) {
                checkForUser();
            }
        }
    };

    const copyURLToShare = (id) => {
        let temp = communityFiles.filter(r => r.id === id)[0];
        if (temp) {
            let url = `${process.env.REACT_APP_CLIENT_URI}/${data?.url_key}/files/${temp.file_name}`;
            /* Copy the text inside the text field */
            navigator.clipboard.writeText(url);
            message.success('Copied to clipboard');
        }
    }

    const acceptedInviteColumns = [
        {
            title: 'Email',
            key: 'email_address',
            dataIndex: 'email_address'
        },
        // {
        //     title: 'Registered User',
        //     key: 'isExistingUser',
        //     dataIndex: 'isExistingUser',
        //     render: record => <p>{record===1?'Yes':'No'}</p>
        // },
    ];

    const pendingInviteColumns = [
        {
            title: 'Email',
            key: 'email_address',
            dataIndex: 'email_address'
        },
        // {
        //     title: 'Registered User',
        //     key: 'isExistingUser',
        //     dataIndex: 'isExistingUser',
        //     render: record => <p>{record===1?'Yes':'No'}</p>
        // }
    ];

    const fileManagementColumns = [
        {
            title: 'File Name',
            key: 'file_name',
            dataIndex: 'file_name'
        },
        {
            title: 'Description',
            key: 'description',
            dataIndex: 'description',
            render: result => (
                <p>{result?result:'-'}</p>
            )
        },
        {
            title: 'File Type',
            key: 'file_type',
            dataIndex: 'file_type'
        },
        {
            title: 'Last Modified',
            key: 'modified_at',
            dataIndex: 'modified_at',
            render: record => (
                <p>{moment(record).format('DD/MM/YYYY')}</p>
            )
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'id',
            render: record => (
                <div className="flex flex-row items-center">
                    <ShareAltOutlined style={{marginRight: 4, marginLeft: 4}} onClick={() => copyURLToShare(record)} />
                    <Button
                        loading={downloadLoad}
                        onClick={() => downloadFile(record)}
                        size="small"
                        type="primary"
                        style={{marginRight: 4}}
                    >Download</Button>
                   {
                       !!isOwner && (
                        <>
                            <Button 
                                style={{marginRight: 4}}
                                onClick={() => {
                                    const tempData = communityFiles?.filter(r => r.id === record)[0];
                                    setCurrentSelectedFile(tempData);
                                    if (tempData) {
                                        setFileDescription(tempData?.description || '');
                                        setFilePrivacy(tempData?.isPublic===1?'public':'private');
                                        let tempMembers = tempData?.shared_members?.indexOf(',')!==-1?tempData?.shared_members?.split(','):[tempData?.shared_members];
                                        tempMembers = tempMembers?.map(tm => parseInt(tm));
                                        setExistingPrivacyMembers(tempMembers||[]);
                                        setSelectPrivacyMember(tempMembers||[]);
                                        setFileUploadModal(true);
                                    }
                                }}
                                size="small"
                                type="default"
                            >Modify</Button>
                            <Button
                                loading={removeLoad}
                                onClick={() => removeFile(record)}
                                size="small"
                                type="danger"
                            >Delete</Button>
                        </>
                       )
                   }
                </div>
            )
        }
    ];

    const annoucementColumns = [
        {
            title: 'Title',
            key: 'title',
            dataIndex: 'title'
        },
        {
            title: 'Status',
            key: 'id',
            dataIndex: 'id',
            render: (result) => (
                <Tag
                    color={allAnnouncements?.filter(a => a.id === result)[0]?.isBlocked===1?'red':moment().isBetween(allAnnouncements?.filter(a => a.id === result)[0]?.activeFrom, allAnnouncements?.filter(a => a.id === result)[0]?.activeTo)?'green':'red'}
                >{allAnnouncements?.filter(a => a.id === result)[0]?.isBlocked===1?'Blocked':moment().isBetween(allAnnouncements?.filter(a => a.id === result)[0]?.activeFrom, allAnnouncements?.filter(a => a.id === result)[0]?.activeTo)?'Active':'Expired'}</Tag>
            )
        },
        {
            title: 'Last Modified',
            key: 'modified_at',
            dataIndex: 'modified_at',
            render: record => (
                <p>{moment(record).format('DD/MM/YYYY')}</p>
            )
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'id',
            render: record => (
                <div className="flex flex-row items-center">
                   {
                       !!isOwner && (
                        <>
                            <Button 
                                onClick={async () => {
                                    const tempA = allAnnouncements?.filter(ai => ai?.id === record)[0];
                                    if (tempA) {
                                        setCurrentAnnouncement(tempA);
                                        setExistingAnnouncementData(tempA);
                                        if (tempA?.isActionItem) {
                                            const people = await apiInstance.get(`/communityannouncement/people/${record}`);
                                            if (people?.data?.status) {
                                                let tempMembers = people.data.data?.map(tm => parseInt(tm?.personId));
                                                setExistingAPeople(tempMembers||[]);
                                                setAPeopleSelect(tempMembers||[]);
                                            } else {
                                                setExistingAPeople([]);
                                                setAPeopleSelect([]);
                                            }
                                        }
                                        setAnnouncementModal(true);
                                    } else message.warn('Try Again Later');
                                }} 
                                style={{marginRight: 4}}
                                size="small"
                                type="default"
                            >Modify</Button>
                            <Popconfirm 
                                okButtonProps={{loading: aLoad}}
                                onConfirm={() => deleteAnnouncement(record)}
                                title="Are you sure？" icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                                <Button
                                    size="small"
                                    type="danger"
                                >Delete</Button>
                            </Popconfirm>
                        </>
                       )
                   }
                </div>
            )
        }
    ];

    const subscriptionColumns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name'
        },
        {
            title: 'Validity',
            key: 'validity',
            dataIndex: 'validity',
            render: record => (
                <p>{record==='month'?'1 Month':record==='year'?'1 Year':record==='quarter'?'3 Months':'Unlimited'}</p>
            )
        },
        {
            title: 'Price',
            key: 'amount',
            dataIndex: 'amount',
            render: record => (
                <p>{record===0?'Free':record}</p>
            )
        },
        {
            title: 'Last Modified',
            key: 'modified_at',
            dataIndex: 'modified_at',
            render: record => (
                <p>{moment(record).format('DD/MM/YYYY')}</p>
            )
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'id',
            render: record => (
                <div className="flex flex-row items-center">
                   {
                       !!isOwner && (
                        <>
                            <Button 
                                onClick={async () => {
                                    const tempA = allSubscriptions?.filter(ai => ai?.id === record)[0];
                                    if (tempA) {
                                        setCurrentSubscription(tempA);
                                        getPermissionsBySubscriptionId(tempA?.id);
                                        setSTitle(tempA?.name);
                                        setSPrice(tempA?.amount||0);
                                        setIsSFree(tempA?.isFree===1?true:false);
                                        setSValidity(tempA?.validity);
                                        setSModal(true);
                                    } else message.warn('Try Again Later');
                                }} 
                                style={{marginRight: 4}}
                                size="small"
                                type="default"
                            >Modify</Button>
                            {/* <Popconfirm 
                                okButtonProps={{loading: aLoad}}
                                onConfirm={() => deleteAnnouncement(record)}
                                title="Are you sure？" icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                                <Button
                                    size="small"
                                    type="danger"
                                >Delete</Button>
                            </Popconfirm> */}
                        </>
                       )
                   }
                </div>
            )
        }
    ];

    // ANT DESIGN METHODS
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                setSearchInput(node);
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            </Space>
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible && searchInput) {
            setTimeout(() => searchInput.select(), 100);
        }
        },
        render: text =>
        searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });

    // ANT DESIGN METHODS
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);        
    };

    const memberColumns = [
        // {
        //     title: 'Status',
        //     key: 'is_active',
        //     dataIndex: 'is_active',
        //     render: (result) => (
        //         <Tag
        //             color={result===1?'green':'red'}
        //         >{result===1?'Active':'Inactive'}</Tag>
        //     )
        // },
        {
            title: 'Email',
            key: 'email_address',
            dataIndex: 'email_address',
            ...getColumnSearchProps('email_address')
        },
        {
            title: 'Member Type',
            key: 'name',
            dataIndex: 'name',
            onFilter: (value, record) => record.name === value
            // render: record => (
            //     <p>{allMemberTypes?.filter(m => m?.id === record)[0]?.name}</p>
            // )
        },
        {
            title: 'Subscription',
            key: 'subscription_name',
            dataIndex: 'subscription_name',
            onFilter: (value, record) => record.subscription_name === value,
            render: value => (<p>{value?value:'none'}</p>)
        },
        {
            title: 'Status',
            key: 'membership_status',
            dataIndex: 'membership_status',
            onFilter: (value, record) => record.membership_status === value
        },
        {
            title: 'Expires Date',
            key: 'expires_on',
            dataIndex: 'expires_on',
            render: record => (
                <p>{moment(record).format('DD/MM/YYYY')}</p>
            )
        },
        {
            title: 'Action',
            key: 'id',
            dataIndex: 'id',
            render: record => (
                <div className="flex flex-row items-center">
                   {
                       !!isOwner && (
                        <>
                            <Button 
                                onClick={() => {
                                    const tempA = members?.filter(ai => ai?.id === record)[0];
                                    if (tempA) {
                                        setCurrentMember(tempA);
                                        setMemberUsername(tempA?.username);
                                        setMemberEmail(tempA?.email_address);
                                        setMemberSubscription(tempA?.subscription_id);
                                        setMemberUserType(tempA?.memberTypeId);
                                        setMemberRenewal(tempA?.renewalDate);
                                        setMemberExpire(tempA?.expires_on);
                                        setMemberAutoRenewal(tempA?.autoRenewal===1?true:false);
                                        setMemberModal(true);
                                    } else message.warn('Try Again Later');
                                }} 
                                style={{marginRight: 4}}
                                size="small"
                                type="default"
                            >Modify</Button>
                            {/* <Popconfirm 
                                okButtonProps={{loading: aLoad}}
                                onConfirm={() => deleteAnnouncement(record)}
                                title="Are you sure？" icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                                <Button
                                    size="small"
                                    type="danger"
                                >Delete</Button>
                            </Popconfirm> */}
                        </>
                       )
                   }
                </div>
            )
        }
    ];

    const setExistingAnnouncementData = (data) => {
        setATitle(data?.title || '');
        setADescription(data?.description || '');
        setActiveFrom(moment(data?.activeFrom).format('YYYY-MM-DDTHH:MM:SS') || '');
        setActiveTo(moment(data?.activeTo).format('YYYY-MM-DDTHH:MM:SS') || '');
        setAIsActionItem(data?.isActionItem===1?true:false);
        setAImage(data?.image || null);
        setAContentState(data?.content?EditorState.createWithContent(convertFromRaw(JSON.parse(data.content))):EditorState.createEmpty())
        setAContent(data?.content?convertFromRaw(JSON.parse(data?.content)):null);
    };

    const deleteAnnouncement = (id) => {
        setALoad(true);
        apiInstance
        .delete(`/communityannouncement/${id}`)
        .then(response => {
            message.success('Announcement Removed Successfully');
            getAllAnnouncements();
        })
        .catch(e => {
            message.error(e.response.data?.message || 'Internal Server Error');
        })
        .finally(() => setALoad(false));
    };

    const onReasonChange = (e) => {
        setSelectedReason(e.target.value);
    };

    useEffect(() => {
        if (key) findOrgByKey(key);
    }, [key]);

    useEffect(() => {
        async function getReasons() {
            apiInstance
            .get('/reason')
            .then(response => {
                if (response.data.status) {
                    setReasonsList(response.data.data?.filter(item => item?.entity === 'event'));
                }
            }).catch(e => console.log(e.response));
        }
        getReasons();
    }, []);

    function sendReportEmail() {
        let payload = {
            entityId: data?.id,
            email: reportEmail,
            message: reportMessage,
            url: window.location.href,
            user: user,
            userId: user?.id || null,
            reasonId: selectedReason,
            entity: 'event',
        };
        if (reportEmail && reportMessage && data?.id) {
            if (validateEmail(reportEmail)) {
                setReportLoading(true);
                apiInstance
                .post("/report", payload)
                .then(response => {
                    if (response.data?.status) {
                        setReportEmail('');
                        setReportMessage('');
                        message.success('Report Sent');
                    } else message.warn('Something went wrong');
                })
                .catch(e => {
                    console.log(e);
                })
                .finally(_ => setReportLoading(false));
            } else message.warn('Invalid email address');
        }
    }

    function createInvitation(payload) {
        setInvitationLoad(true);
        apiInstance
        .post('/organization/invitation', payload)
        .then(response => {
            if (response.data.status) {
                message.success(response.data.message);
                setInvitationModal(false);
                setInvitationEmail('');
                getAllInvitationsByEvent();
            } else message.warn(response.data?.message || 'Something went wrong')
        })
        .catch(e => {
            console.log(e);
        })
        .finally(_ => setInvitationLoad(false));
    }

    const createInvite = () => {
        let payload = {
            email: invitationEmail,
            orgId: data?.org_id,
            isExistingUser: tempUser?1:0,            
            key: data?.url_key,
            orgName: data?.name,
            orgLocation: data?.city,
            orgImage: data?.logo,
            orgType: data?.category_name
        }
        if (invitationEmail && data?.id) {
            createInvitation(payload);
        }
    }

    useEffect(() => {
        if (user) {
            setReportEmail(user?.email_address);
        }
    }, [user]);

    function findInvitationById() {
        if (inviteId) {
            setLoadingState(true);
            apiInstance
            .get(`/organization/invitation/id/${inviteId}`)
            .then(response => {
                if (response.data.status) {
                    setInvitationData(response.data?.data);
                    if (response.data?.data?.isAccepted === 1) setCompleted(true);
                } else message.warn(response.data?.message || 'Something went wrong');
            })
            .catch(e => console.log(e))
            .finally(() => setLoadingState(false));
        }
    }

    function acceptInvite() {
        if (inviteId && orgId && token) {
            let payload = {
                token: token,
                orgId: parseInt(orgId),
                inviteId: parseInt(inviteId),
            };
            setAcceptLoadingState(true);
            apiInstance
            .post('/organization/invitation/accept', payload)
            .then(response => {
                if (response.data.message !== 'Invitation Accepted') {
                    setCompleted(true);
                    setTimeout(() => window.location.replace(`/${key}`), 1000);
                } else {
                    window.location.replace(`/${key}`)
                }
                message.success(response.data?.message || 'Accepted');
            })
            .catch(e => console.log(e))
            .finally(_ => setAcceptLoadingState(false));
        }
    }

    useEffect(() => {
        if (orgId && token && inviteId) {
            if (user) {
                sessionStorage.removeItem('invitation_callback');
                findInvitationById();
            } else {
                sessionStorage.setItem('invitation_callback', window.location.href);
                history.push('/signin');
            }
        }
    }, [orgId, token, inviteId]);

    const MemberItem = (props) => (
        <div className="rounded-md bg-white shadow p-2 flex flex-col items-center">
            <img
                alt="profile"
                className="w-16 h-16 rounded-full"
                src={props?.avatar ? props?.avatar?.indexOf('h')==='0'?props?.avatar:`${process.env.REACT_APP_SERVER_URL}${props?.avatar}` : getProfileImage(props?.username || 'Assistance')}
            />
            <p className="text-black font-medium text-center text-md pt-2">
                {props?.username}
            </p>
            <p className="text-gray-700 text-xs text-center pt-1">
                {props.admin?'Administrator':'Member'}
            </p>
            <a rel="noopener noreferrer" target="_blank" href={`mailto:${props.email_address}`} className="text-lg"><MailOutlined /></a>
        </div> 
    )

    async function getAllCommunityEvents(id) {
        setLoadingState(true);
        apiInstance
        .get(`/event/c/${id}/secure`)
        .then(response => {
            if (response.data.status) {
                setCommunityEvents(response.data.data);
            } else message.warning(response.data?.data || 'something went wrong');
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoadingState(false);
        });
    }

    const EventPanel = (props) => (
        <div className="w-full flex flex-col items-start bg-gray-200 p-1">
            <div className="w-full flex flex-row items-center px-3 pt-3">
                <p 
                    className={`px-2 pb-2 mr-2 text-xs text-left ${eventsStatus === 'upcoming' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}                        
                    onClick={() => setEventStatus('upcoming')}>
                        Upcoming Events
                </p>
                <p 
                    className={`px-2 pb-2 mr-2 text-xs text-left ${eventsStatus === 'past' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}                        
                    onClick={() => setEventStatus('past')}>
                        Past Events
                </p>
                <Button
                    onClick={() => history.push({pathname: '/event/create', state: {c_data: data, c_members: members?.length}})}
                    type="primary"
                    className="px-2 ml-3 text-sm text-white primary-bg rounded-sm cursor-pointer"
                    style={{height: 30}}                    
                >Create Event
                </Button>
            </div>
            <div className="w-full">
                {
                    !(eventsStatus==='upcoming'?
                    communityEvents?.filter(item => moment()?.isBetween(moment(item?.startDate), moment(item?.endDate))):
                    communityEvents?.filter(item => moment(item?.endDate)?.isBefore(moment()))?.length && (
                        <p className="py-4 text-center">
                            No events available
                        </p>
                    ))
                }
                {
                    communityEvents?.length ? (
                        <MainSlider 
                            keyName={key}
                            showCreateButton={false}
                            title={null}
                            data={(eventsStatus==='upcoming'?
                            communityEvents?.filter(item => moment()?.isBetween(moment(item?.startDate), moment(item?.endDate))):
                            communityEvents?.filter(item => moment(item?.endDate)?.isBefore(moment())))}
                            // handleFetchEvent={(e) => handleFetchingEvent(e)}
                        />
                    ) : null
                }
            </div>
        </div>
    )

    const joinCommunity = () => {
        if (user) {
            let payload = {
                memberType: allMemberTypes?.filter(type => type.name === 'Member')[0]?.id || 2,
                orgId: data?.org_id,
            };
            if (data?.org_id) {
                apiInstance
                .post("/organization/member/join", payload)
                .then(response => {
                    message.success('Joined as a member');
                    getAllMembersByOrg(data?.org_id);
                })
                .catch(e => console.log(e))
                .finally(_ => setAcceptLoadingState(false));
            }
        } else {
            sessionStorage.setItem('join_callback', window.location.href);
            history.push('/signin');
        }
    };

    const removeMemberFromCommunity = () => {
        if (user) {
            let payload = {
                orgId: data?.org_id,
            };
            if (data?.id) {
                apiInstance
                .put("/organization/member/remove", payload)
                .then(response => {
                    message.success('Left Community');
                    getAllMembersByOrg(data?.org_id);
                })
                .catch(e => console.log(e))
                .finally(_ => setAcceptLoadingState(false));
            }
        } else {
            sessionStorage.setItem('join_callback', window.location.href);
            history.push('/signin');
        }
    };

    const LinkPreviewPanel = () => {
        return (
            <a 
                href={previewData?.url}
                className="w-full border cursor-pointer shadow p-3 rounded-md bg-white flex flex-row items-start">
                <img
                    alt="image"
                    src={previewData?.favicons[0] || previewData?.images[0]}
                    className="object-contain h-12"
                />
                <div className="px-3 flex flex-col items-start">
                    <a href={previewData?.url} className="w-full text-left mb-1 text-xs text-gray-800">
                        {previewData?.url?.split('//')[1]?.split('/')[0] || previewData?.url}
                    </a>
                    <h3 className="w-full text-left font-medium text-black text-lg">
                        {previewData?.title}
                    </h3>
                </div>
            </a>
        )
    }   

    const isMember = members?.filter(m => m?.user_id === user?.id)?.length !== 0;

    const onPrivacyChange = (e) => {
        setFilePrivacy(e.target.value);
    }

    const handleSubmit = (type='create') => {
        let payload = new FormData();
        payload.append('title', aTitle);
        payload.append('description', aDescription);
        payload.append('content', aContent ? JSON.stringify(convertToRaw(aContent)) : null);
        payload.append('image', aImage || null);
        payload.append('activeFrom', new Date(aActiveFrom).toISOString());
        payload.append('activeTo', new Date(aActiveTo).toISOString());
        payload.append('isActionItem', aIsActionItem);
        payload.append('communityId', data?.org_id);
        payload.append('communityName', data?.name);
        payload.append('communityKey', data?.url_key);
    
        payload.append('actionDetail', actionDescription);
        payload.append('shared_people', aPeopleSelect?.length?JSON.stringify(aPeopleSelect):null);
        payload.append('peopleToAdd', aPeopleToAdd?.length?JSON.stringify(aPeopleToAdd):null);
        payload.append('peopleToRemove', aPeopleToRemove?.length?JSON.stringify(aPeopleToRemove):null);

        if (aFiles) {
            payload.append('file', aFiles);
        }

        if (currentAnnoucement) {
            payload.append('id', currentAnnoucement?.id);
        }

        if (type === 'create') {
            if (aTitle && aContent && aActiveFrom && aActiveTo) {
                createAnnouncement(payload);
            } else {
                message.warn('Parameters missing');
            }
        } else {
            updateAnnouncement(payload);
        }
    };

    const resetAInputs = () => {
        setCurrentAnnouncement(null);
        setATitle('');
        setADescription('');
        setAContent(null);
        setAContentState(null);
        setActiveFrom('');
        setActiveTo('');
        setAFiles(null);
        setAImage(null);
        setAIsActionItem(false);
        setAPeopleSelect([]);
        setExistingAPeople([]);
        setPeopleToAdd([]);
        setPeopleToRemove([]);
    };

    const createAnnouncement = (payload) => {
        setALoad(true);
        apiInstance
        .post("/communityannouncement", payload)
        .then(response => {
            if(response.data.status) {
                setAnnouncementModal(false);
                resetAInputs();            
                getAllAnnouncements();                                 
                message.success(response.data.message);
            } else message.warning(response.data?.message || 'something went wrong');
        })
        .catch(e => {
            console.log(e);
            if(e.response || e.response?.data) {                
            if(typeof e.response.data.message === 'string') message.error(e.response.data.message);
                if(e.response.status === 401 && e.response.status === 403) {
                    sessionStorage.clear();
                    window.location.replace("/signin");
                }
            } else message.error("Internal Server Error");
        })
        .finally(() => setALoad(false));
    };

    const updateAnnouncement = (payload) => {
        setALoad(true);
        apiInstance
        .put("/communityannouncement/modify", payload)
        .then(response => {
            if(response.data.status) {
                setAnnouncementModal(false);
                resetAInputs();                                             
                message.success(response.data.message);
                getAllAnnouncements();
            } else message.warning(response.data?.message || 'something went wrong');
        })
        .catch(e => {
            console.log(e);
            if(e.response || e.response?.data) {                
            if(typeof e.response.data.message === 'string') message.error(e.response.data.message);
                if(e.response.status === 401 && e.response.status === 403) {
                    sessionStorage.clear();
                    window.location.replace("/signin");
                }
            } else message.error("Internal Server Error");
        })
        .finally(() => setALoad(false));
    };

    const getAllAnnouncements = (id) => {
        apiInstance
        .get(`/communityannouncement/${id || data?.org_id}`)
        .then(response => {
            setAllAnnouncements(response.data.data);
        })
        .catch(e => {
            console.log(e.response.data);
        });
    };

    const blockAnnouncement = (flag=true) => {
        let payload = {
            status: flag,
            communityId: data?.org_id,
            id: currentAnnoucement?.id,
            time: new Date().toISOString()
        };
        setBLoad(true);
        apiInstance
        .put("/communityannouncement/status", payload)
        .then(response => {
            getAllAnnouncements();
            setCurrentAnnouncement(null);
            resetAInputs();
            setAnnouncementModal(false);
            message.success(`Anonuncement ${payload.status?'Blocked':'Unblocked'} Successfully`);
        })  
        .catch(e => {
            message.error('Internal Server Error');
        })
        .finally(() => setBLoad(false));

    };

    const allowToUpload = filePrivacy && fileToUpload;

    const getAllSubscriptions = () => {
        setSLoad(true);
        apiInstance
        .get(`/subscription/${data?.org_id}`)
        .then(response => {
            if (response.data.data) {
                setAllSubscriptions(response.data.data);
            }
        })
        .catch(e => console.log(e))
        .finally(() => setSLoad(false));
    };

    const sSubmit = (type='create') => {
        if (type === 'create') {
            let payload = {
                name: sTitle,
                amount: sPrice,
                isFree: isSFree?1:0,
                validity: sValidity,
                communityId: data?.org_id,
                permissionsToAdd: JSON.stringify(permissionsToAdd),
                permissionsToRemove: JSON.stringify(permissionsToRemove),
            };
            console.log(payload);
            setSSLoad(true)
            apiInstance
            .post('/subscription', payload)
            .then(response => {
                message.success('Subscription Created');
                setSModal(false);
            })
            .catch(e => {
                message.error('Internal Server Error');
            })
            .finally(() => {
                getAllSubscriptions();
                setSSLoad(false);
                setSTitle('');
                setSPrice('0');
                setSValidity(null);
                setIsSFree(true);
            });
        } else if (type === 'update') {
            let payload = {
                id: currentSubscription?.id,
                name: sTitle,
                amount: sPrice,
                validity: sValidity,
                isFree: isSFree?1:0,
                permissionsToAdd: JSON.stringify(permissionsToAdd),
                permissionsToRemove: JSON.stringify(permissionsToRemove),
            };
            setSSLoad(true)
            apiInstance
            .put('/subscription', payload)
            .then(response => {
                setCurrentSubscription(null);
                message.success('Subscription Updated');
                setSModal(false);
            })
            .catch(e => {
                message.error('Internal Server Error');
            })
            .finally(() => {
                getAllSubscriptions();
                setSSLoad(false);
                setSValidity(null);
                setSTitle('');
                setSPrice('0');
                setIsSFree(true);
            });
        }
    };  

    const isUsernameOrEmailAvailable = async (payload, type) => {
        apiInstance
        .get(`/organization/member/username/${payload}`)
        .then(response => {
            if (response.data.status) {
                if (response.data.data) {
                    if (type === 'email') {
                        setMemberEmailAvailable('no');
                        setMemberUsername(response.data.data?.username);
                        setMemberEmail(response.data.data?.email_address);
                    } else {
                        setMemberUsernameAvailable('no');
                        setMemberUsername(response.data.data?.username);
                        setMemberEmail(response.data.data?.email_address);
                    }
                } else {
                    if (type === 'email') {
                        setMemberEmailAvailable('yes');
                    } else {
                        setMemberUsernameAvailable('yes');   
                    }
                }
            }
        })
        .catch(e => console.log(e.response));
    };

    useEffect(() => {
        if (memberUsername) {
            const timer = setTimeout(async () =>{
                await isUsernameOrEmailAvailable(memberUsername, 'username');
            }, 2000);  
            return () => clearTimeout(timer);
        } else setMemberUsernameAvailable(null);
    }, [memberUsername]);

    useEffect(() => {
        if (memberEmail) {
            const timer = setTimeout(async () =>{
                await isUsernameOrEmailAvailable(memberEmail, 'email');
            }, 2000);  
            return () => clearTimeout(timer);
        } else setMemberEmailAvailable(null);
    }, [memberEmail]);

    function getExpireDate(validity) {
        let expireDate = null;
        if (validity) {
            if (validity === 'month') {
                expireDate = moment().add(1, 'months').toISOString();
            } else if (validity === 'quarter') {
                expireDate = moment().add(3, 'months').toISOString();
            } else if (validity === 'year') {
                expireDate = moment().add(1, 'years').toISOString();
            } else {
                expireDate = moment().add(99, 'years').toISOString();
            }
        }
        return expireDate;
    }

    useEffect(() => {
        if (memberSubscription) {
            let subscriptionSelected = allSubscriptions?.filter(s => s?.id === memberSubscription)[0];
            if (subscriptionSelected) {
                let expireDate = getExpireDate(subscriptionSelected?.validity);
                setMemberExpire(expireDate);
                setMemberRenewal(expireDate);
            }
        }
    }, [memberSubscription]);

    function resetMemberInput() {
        setMemberAutoRenewal(false);
        setMemberEmail('');
        setMemberEmailAvailable(null);
        setMemberExpire(null);
        setMemberRenewal(null);
        setMemberUserType(null);
        setMemberSubscription(null);
        setMemberUsername('');
        setMemberUsernameAvailable(null);
    }

    const createOrUpdateMembership = (type='create') => {
        let payload = {
            username: memberUsername,
            email: memberEmail,
            memberTypeId: memberUserType,
            communityId: data?.org_id,
            subscriptionId: memberSubscription,
            expire: moment(memberExpire)?.toISOString(),
            renewalDate: moment(memberRenewal)?.toISOString(),
            autoRenewal: memberAutoRenewal ? 1 : 0,
            id: currentMember?.id || null,
            communityName: data?.name
        };

        if (type === 'create') {
            setMemberLoad(true);
            apiInstance
            .post('/organization/membership/create', payload)
            .then(response => {
                message.success('Member Created Successfully');
                resetMemberInput();
                getAllMembersByOrg();
                setMemberModal(false);
            })
            .catch(e => {
                console.log(e);
                message.error('Internal Server Error');
            })
            .finally(() => {
                setMemberLoad(false);
            });
        } else {
            apiInstance
            .put('/organization/membership/update', payload)
            .then(response => {
                message.success('Member Updated Successfully');
                resetMemberInput();
                getAllMembersByOrg();
                setMemberModal(false);
            })
            .catch(e => {
                console.log(e);
                message.error('Internal Server Error');
            });
        }

    };

    const handleExportMembershipData = async () => {
        let payload = {
            communityId: data?.org_id,
            owner: data?.created_by
        };
        apiInstance({
            url: '/organization/membership/export', //your url
            method: 'POST',
            data: payload,
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', Date.now()+'-membership_data.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    };

    /**
     * Used to build the category with their permissions using the
     * available permissions from the DATA LAYER VALUE
     */
     const buildPermissionCategories = (entirePermissions) => {                

        let result = []; // FINAL ARRAY TO BE RETURNED
        
        // BUILD CATEGORY ARRAY
        let permissionCategories = new Set();

        for(var entity of entirePermissions) {
            let entityName = entity.name.split(':')[0];
            permissionCategories.add(entityName);
        }        

        setPermissionCategories(permissionCategories);

        for(let category of permissionCategories) {
            let innerJSON = {};
            innerJSON.title = category.charAt(0).toUpperCase() + category.slice(1);
            innerJSON.permissions = entirePermissions.filter(permission => permission.name.split(':')[0] === category);
            result.push(innerJSON);
        }
               
        return result;

    }

    const getCommunityPermissions = () => {
        apiInstance
        .get("/communityPermission")
        .then(response => {
            setCommunityPermissions(buildPermissionCategories(response.data.data));
        })
        .catch(e => {
            message.error('Something went wrong');
        });
    };

    const getPermissionsBySubscriptionId = (id) => {
        apiInstance
        .get(`/communityPermission/subscription/${id}`)
        .then(response => {
            if(response.data?.data.length) {
                setAssignedPermissions(buildPlainOptions(response.data.data, "id"));
                setCheckedPermissions(buildCheckedOptions(response.data.data));
            } else {                                                                
                    let initial = [];
                    Array.from(permissionCategories).forEach((data, index) => {            
                        initial[index] = [];                                                                                   
                    });                                                      
                    setCheckedPermissions(initial)                                                                               
            }            
        })
        .catch(e => {
            message.error('Something went wrong');
        });
    };

    /**
     * Used to build the array out of json array 
     * in a separate nested array
     * @param [{}] dataArray 
     */
     const buildCheckedOptions = (dataArray) => {
        let result = [];                           

        if(dataArray.length) {
            for(let category of permissionCategories) {
                result.push(buildPlainOptions(dataArray.filter(p => p.permission.split(':')[0] === category), "permission"));
             }
        } else {
            // NO ASSIGNED PERMISSIONS            
            for(let i=0; i<permissionCategories.length; i++){
                result.push([]);
            }
        }

        setProgress(false);

        return result;
    }

    useEffect(() => {
        handlePermissionsChanges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[checkedPermissions]);

    const handlePermissionsChanges = () => {
        let existingPermissions = assignedPermissions;
        let correctedPermissions = [];
        let innerCorrectedArray = [];
        // eslint-disable-next-line
        checkedPermissions.map(permission => {
        //     if(permission.length) correctedPermissions.push(allPermissions.filter(cp => buildPermissionName(cp.name) === permission)[0].id);
            if(permission.length) {
                // eslint-disable-next-line
                permission.map(p => innerCorrectedArray.push(p));
            }            
        });  
        // eslint-disable-next-line
        innerCorrectedArray.map(ic => {
            correctedPermissions.push(allCommunityPermissions.filter(ap => buildPermissionName(ap?.name) === ic)[0].id);
        })        
        setPermissionsToAdd(correctedPermissions.filter(permission => !existingPermissions.includes(permission)));
        setPermissionsToRemove(existingPermissions.filter(permission => !correctedPermissions.includes(permission)));        
    };

    const buildPlainOptions = (dataArray, key) => {
        let result = [];
        if(dataArray.length) {
            // eslint-disable-next-line
            dataArray.map(item => {
                if(key === 'id') result.push(item[key]);
                else result.push(buildPermissionName(item[key]));
            });
        } 
        return result;
    };

    const onCheckAllChange = (e, permissions, index) => {        
        
        const newCheckPermissions = checkedPermissions.map((permission, idx) => {
            if(idx !== index) return permission;
            if(e.target.checked) {                
                return [
                    ...buildPlainOptions(permissions, "name")
                ]
            } else {
                return [];
            }
        });

        setCheckedPermissions(newCheckPermissions);        
        checkAll[index] = e.target.checked;
        setCheckAll(checkAll);             
    };

    const onCheckChange = (checkedList, index) => { 
            const newCheckPermissions = checkedPermissions.map((permission, idx) => {                
                if(idx !== index) return permission;
                return [
                    ...checkedList
                ]
            });                                    
            setCheckedPermissions(newCheckPermissions);        

        checkAll[index] = checkedList.length === communityPermissions[index].permissions.length;
        setCheckAll(checkAll);        
    };

    function resetPermissionBasedInputs() {
        setAssignedPermissions([]);  
        setCheckedPermissions([]); 
        setCheckAll([]);
    };

    function resetSModalInputs() {
        setCurrentSubscription(null);
        setSPrice('0');
        setSTitle('');
        setSValidity(null);
        setIsSFree(true);
    }

    const capitalizeFirstLetter = s => s.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');

    const buildPlans = (permissionsFromCommunity) => {
        /**
         * ['Read, Write, Update Blogs', 'Read Events']
         */
        permissionsFromCommunity.map(item => {
            if (item?.permissions) {
                let plan_details = [];
                let permissions = [];
                if (item.permissions?.indexOf(',') !== -1) {
                    // more an item
                    permissions = item.permissions?.split(',');
                } else {
                    // only one item
                    permissions = [item.permissions];
                }
                let categories = new Set(); // unique titles
                permissions.map(permission => {
                    categories.add(permission.split(':')[0]);
                });
                let tempDetails = [];
                Array.from(categories).map(category => {
                    let tempObj = {};
                    tempObj['title'] = category;
                    let tempPermissions = [];
                    permissions.map(permission => {
                        if (permission.split(':')[0] === category) {
                            // found category
                            tempPermissions.push(capitalizeFirstLetter(permission.split(':')[1]));
                        }
                    });
                    tempObj['permissions'] = tempPermissions;
                    tempDetails.push(tempObj);
                });
                tempDetails.map(detail => {
                    let tempString = `${detail.permissions.join(', ')} ${isVowel(detail.title)?'an':'a'} ${capitalizeFirstLetter(detail.title)}`;
                    plan_details.push(tempString);
                });
                item['plan_details'] = plan_details;
            }
        });
        return permissionsFromCommunity;
    };

    const isVowel = (str) => ['a','e','o','u','i']?.includes(str.split('')[0]);

    const getPlansByCommunity = (id) => {
        apiInstance
        .get(`/subscription/plans/${id}`)
        .then(response => {
            if (response.data.status) {
                setPlans(buildPlans(response.data.data));
            }
        })
        .catch(e => {
            console.log(e);
            message.error('Internal Server Error');
        });
    };

    const allowedToCreateMemeber = memberUserType && memberSubscription && memberUsername && memberEmail && memberExpire && memberRenewal;

    return (
        <>
            <Header fixed={false} />   
            {
                loadingState ? (
                    <div className="w-full main__preLoaderContainer flex items-center justify-center">
                        <img 
                            className="main__preLoader"
                            src={logo}
                            alt="preloader"
                        />
                    </div>
                ) : (
                    <div className="w-full flex flex-col items-start lg:px-0 px-4">  
                        {/* TOP HEADER SECTION */}
                        <div className="community__header bg-white w-8/12 mx-auto my-1 flex flex-col items-start">
                            <div className="w-full flex flex-row items-center">
                                {
                                    !!data?.logo && (
                                        <img 
                                            className="p-3 community__avatar rounded-full object-cover" 
                                            alt="banner"
                                            src={`${data?.logo ? process.env.REACT_APP_SERVER_URL+'/'+data?.logo : defaultMaleImage}`}
                                        />
                                    )
                                }
                                <div className={`${data?.logo && 'ml-4'} w-full flex flex-col items-start`}>
                                    <h1 className="w-full text-md lg:text-xl font-medium">{data?.name} <span className="px-3 py-1 text-white rounded-full bg-blue-500 text-sm mx-3">{data?.isPublic===1?'Public':'Private'}</span>
                                    {/* {isOwner && (<EditOutlined onClick={() => history.push(`/community/create?type=edit&id=${data?.org_id}`)}  style={{fontSize:'16px'}} />)} */}
                                    </h1>
                                   <div className="w-full flex flex-row items-center">
                                        <p onClick={() => setActiveTab('members')} className="cursor-pointer bg-gray-600 rounded-sm text-md px-2 py-1 text-white my-1">
                                            <TeamOutlined /> {totalMembers} Members
                                        </p>
                                   </div>
                                </div>
                                <div className="mt-2 py-1 flex flex-col items-end justify-end">
                                    {
                                        isOwner ? (
                                            <Button
                                                onClick={() => setInvitationModal(true)}
                                                type="primary"
                                                className="px-5 mr-3 text-md text-white primary-bg rounded-sm cursor-pointer"
                                                style={{height: 30}}                    
                                            >Invite
                                            </Button>
                                        ) : (
                                            !isMember && (
                                                (orgId && token && inviteId) ? (
                                                    <Button
                                                        size="large"
                                                        loading={acceptLoadingState}
                                                        onClick={() => acceptInvite()}
                                                        type="primary"
                                                        className={`px-5 mr-3 text-md text-white bg-green-400 rounded-sm cursor-pointer`}
                                                        // style={{height: 30}}                    
                                                    >Accept Invite
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        size="large"
                                                        loading={acceptLoadingState}
                                                        onClick={() => joinCommunity()}
                                                        type="primary"
                                                        className={`px-5 mr-3 text-md text-white ${isMember ? 'bg-red-400 border-red-400 hover:bg-red-500' : 'primary-bg'} rounded-sm cursor-pointer`}
                                                        // style={{height: 30}}                    
                                                    >Join the community
                                                    </Button>
                                                )
                                            )   
                                        )
                                    }
                                    <div className="flex flex-row items-center mt-2">
                                        <a rel="noopener noreferrer" target="_blank" href="#" className="text-lg mr-3"><MailOutlined /></a>
                                        <a rel="noopener noreferrer" target="_blank" href="#" className="text-lg mx-3"><PhoneOutlined /></a>                                                
                                        <a rel="noopener noreferrer" href="#" className="text-lg lg:mx-3"><LinkOutlined /></a>     
                                        {
                                            isOwner && (
                                                <a rel="noopener noreferrer" onClick={() => {
                                                    setOpenSettings(true);
                                                    setActiveTab('settings');
                                                }} className="text-lg lg:mx-3"><SettingOutlined /></a>
                                            )
                                        }
                                       {
                                           isMember && !isOwner && (
                                            <a rel="noopener noreferrer" href="#" onClick={() => removeMemberFromCommunity()} className="text-lg lg:mx-3"><LogoutOutlined /></a>   
                                           )
                                       }                                                                             
                                    </div>
                                </div>
                            </div>
                            {/* TAB SECTION STARTS HERE */}
                            <div className="w-full flex flex-row items-center border-b">
                                    {
                                        openSettings ? (
                                            <>
                                                <p 
                                                    className={`px-2 pb-2 mr-2 text-md text-left ${openSettings ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}                        
                                                    onClick={() => {
                                                        setOpenSettings(true);
                                                        setActiveTab('settings');
                                                    }}>
                                                    Settings
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <p 
                                                    className={`px-2 pb-2 mr-2 text-md text-left ${activeTab === 'info' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}                        
                                                    onClick={() => setActiveTab('info')}>
                                                    Info
                                                </p>
                                                <p 
                                                    className={`px-2 pb-2 mr-2 text-md text-left ${activeTab === 'events' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}                        
                                                    onClick={() => setActiveTab('events')}>
                                                    Events
                                                </p>  
                                                {
                                                    !!isOwner && (
                                                        <p 
                                                            className={`px-2 pb-2 mr-2 text-md text-left ${activeTab === 'invitations' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}                        
                                                            onClick={() => setActiveTab('invitations')}>
                                                            Invitations
                                                        </p>
                                                    )
                                                }
                                                {
                                                    !!isOwner && (
                                                        <p 
                                                            className={`px-2 pb-2 mr-2 text-md text-left ${activeTab === 'announcements' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}                        
                                                            onClick={() => setActiveTab('announcements')}>
                                                            Announcements
                                                        </p>
                                                    )
                                                }
                                                {
                                                    !!isOwner && (
                                                        <p 
                                                            className={`px-2 pb-2 mr-2 text-md text-left ${activeTab === 'members' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}                        
                                                            onClick={() => {
                                                                getAllSubscriptions();
                                                                getCommunityPermissions();
                                                                setActiveTab('members');
                                                            }}>
                                                            Membership
                                                        </p>  
                                                    )
                                                }
                                                {
                                                    !isOwner && (
                                                        <p 
                                                            className={`px-2 pb-2 mr-2 text-md text-left ${activeTab === 'members' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}                        
                                                            onClick={() => {
                                                                setActiveTab('members');
                                                            }}>
                                                            Members
                                                        </p> 
                                                    ) 
                                                }
                                                <p 
                                                    className={`px-2 pb-2 mr-2 text-md text-left ${activeTab === 'blogs' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}                        
                                                    onClick={() => setActiveTab('blogs')}>
                                                    Blogs
                                                </p>     
                                                {/* <p 
                                                    className={`px-2 pb-2 mr-2 text-md text-left ${activeTab === 'files' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}                        
                                                    onClick={() => setActiveTab('files')}>
                                                    Files
                                                </p>                                          */}
                                            </>
                                        )
                                    }
                                </div>
                            {/* TAB SECTION ENDS HERE */} 
                        </div>
                        {/* TOP HEADER SECTION */}
                        <div className="w-full lg:w-8/12 mx-auto my-4 bg-white p-2 lg:p-3 flex flex-col items-start">
                            {
                                activeTab === 'info' ? (
                                    <>
                                        <div className="relative w-full flex flex-row items-start justify-between">
                                           <div className="w-full flex flex-col item-start">
                                                {
                                                    descriptionContent ? 
                                                    (
                                                        <>
                                                            <div dangerouslySetInnerHTML={{__html: draftToHtml(descriptionContent)}}></div>
                                                        </>
                                                    ) : null

                                                }
                                                <div className="w-full flex flex-col items-start py-4">
                                                    <h3 className="w-full text-left font-medium py-2 text-lg">
                                                        Follow Us On
                                                    </h3>
                                                    <div className="py-1 flex items-center">
                                                        <a 
                                                            rel="noopener noreferrer" 
                                                            target="_blank" 
                                                            href={`https://www.facebook.com/sharer.php?u=${window.location.href}`} 
                                                            className="text-lg mr-2"><FacebookFilled /></a>
                                                        <a rel="noopener noreferrer" target="_blank" href={"http://twitter.com/share?&url="+window.location.href+"&hashtags=assistance,assistanceorg,helpingothersmatter"} className="text-lg mx-2"><TwitterOutlined /></a>                                                
                                                        <a rel="noopener noreferrer" href={"https://api.whatsapp.com://send?text=Hey there! look out here: "+window.location.href} data-action="share/whatsapp/share" className="text-lg lg:mx-2"><WhatsAppOutlined /></a>                              
                                                    </div>
                                                </div>
                                                
                                                {
                                                    previewData && (
                                                        <div className="w-full py-4">
                                                            <LinkPreviewPanel />
                                                        </div>
                                                    )
                                                }
                                           </div>
                                            <div className="sticky ml-4 w-1/2 bg-black rounded-md p-4 flex flex-col items-start">
                                                <h3 className="py-2 text-gray-400 text-left w-full uppercase text-xs">
                                                    Administrator
                                                </h3>
                                                <div className="py-4 flex flex-row items-center">
                                                    <img
                                                        src={data?.avatar || getProfileImage(data?.username || 'S')}
                                                        alt="avatar"
                                                        className="h-10 w-10 rounded-full border object-contain border-white"
                                                    />
                                                    <div class="flex flex-col item-start mb-2">
                                                        <p className="ml-3 text-white text-md font-medium text-left">
                                                            {data?.username}
                                                        </p>
                                                        <a rel="noopener noreferrer" target="_blank" href={`mailto:${data?.email_address}`} className="ml-3 text-lg text-white"><MailOutlined /></a>
                                                    </div>
                                                </div>
                                                <h3 className="py-2 text-gray-400 text-left w-full uppercase text-xs">
                                                    Category
                                                </h3>
                                                <p className="text-white mb-2 text-md font-medium text-left">
                                                    {data?.category_name}
                                                </p>
                                                <h3 className="py-2 text-gray-400 text-left w-full uppercase text-xs">
                                                    Location
                                                </h3>
                                                <p className="text-white mb-2 text-md font-medium text-left">
                                                    {data?.state}, {data?.country}
                                                </p>
                                                {/* <div className="w-full py-2">
                                                    <iframe
                                                        className="w-full"
                                                        title="embed map"
                                                        style={{ border: 0 }}
                                                        loading="lazy"
                                                        allowFullScreen={true}
                                                        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_EMBED_MAP_API}&q=${data?.fullAddress}, ${data?.city} ${data?.state} ${data?.country}`}>
                                                    </iframe> 
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="w-full py-3">
                                            {/* PLAN DETAILS PANEL STARTS */}
                                            <h1 className="w-full text-black text-md font-medium text-left py-2">Community Plan Details</h1>
                                            <div className="w-full flex flex-col items-start">
                                                {
                                                    plans?.map((item, idx) => (
                                                        <div key={idx} className="w-full flex flex-row items-start border rounded-sm p-2">
                                                            <div className="w-1/3 flex flex-col items-start">
                                                                <h3 className="font-medium text-sm text-left px-2 text-black">
                                                                    {item?.name}
                                                                </h3>
                                                                <p className="py-1 text-md text-left px-2 text-black">
                                                                    ${item?.amount}
                                                                </p>
                                                            </div>
                                                            <div className="px-1 w-full flex flex-col items-start">
                                                                {
                                                                    item?.plan_details?.map(details => (
                                                                        <p className="w-full text-md text-left text-black">
                                                                            {details}
                                                                        </p>
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            {/* PLAN DETAILS PANEL ENDS */}
                                        </div>
                                        <div className="w-full py-4">
                                            <EventPanel />
                                        </div>
                                    </>
                                ) : activeTab === 'invitations' ? (
                                    <div className="w-full">
                                        <div className="w-full lg:w-1/2 flex flex-row items-center">
                                            <Button
                                                onClick={() => setInvitationModal(true)}
                                                type="primary"
                                                className="px-5 mr-3 text-md text-white primary-bg rounded-sm cursor-pointer"
                                                style={{height: 30}}                    
                                            >Invite
                                            </Button>
                                            <Search
                                                onChange={text => setSearch(text.target.value)}
                                                value={search} 
                                                placeholder="Search by email or username" 
                                                onSearch={text => onSearch(text, false)} 
                                                className="rounded-md my-3" 
                                            /> 
                                        </div>

                                        {
                                            isOwner ? (
                                                <div className="w-full">
                                                    <div className="w-full flex flex-row items-center mt-5">
                                                        <p 
                                                            onClick={() => setActiveInvitationStatus('accept')}
                                                            className={`text-sm lg:text-md mx-6 cursor-pointer border-b border-black ${activeInvitationStatus==='accept'?'text-blue-500 border-blue-500':'text-black'}`}>Accepted ({invitations?.filter(invite => invite?.isAccepted===1)?.length || 0})</p>
                                                        <p 
                                                            onClick={() => setActiveInvitationStatus('pending')}
                                                            className={`text-sm lg:text-md cursor-pointer border-b border-black ${activeInvitationStatus==='pending'?'text-blue-500 border-blue-500':'text-black'}`}>Pending ({invitations?.filter(invite => invite?.isAccepted===0)?.length || 0})</p>
                                                    </div>
                                                    {
                                                        activeInvitationStatus==='pending' ? (
                                                            <div className="w-full flex flex-col items-start py-4"> 
                                                                    <Table
                                                                    className="w-full"
                                                                    columns={pendingInviteColumns}
                                                                    dataSource={(search?invitations?.filter(i => i?.email_address?.includes(search)):invitations)
                                                                                ?.filter(invite => invite?.isAccepted===0)}
                                                                />                                                        
                                                            </div>
                                                        ) : (
                                                            <div className="w-full flex flex-col items-start py-4"> 
                                                                <Table
                                                                    className="w-full"
                                                                    columns={acceptedInviteColumns}
                                                                    dataSource={(search?invitations?.filter(i => i?.email_address?.includes(search)):invitations)
                                                                            ?.filter(invite => invite?.isAccepted===1)}
                                                                />
                                                            </div>  
                                                        )
                                                    }
                                                    </div>
                                            ) : null
                                        }
                                    </div>
                                ) : activeTab === 'members' ? (
                                    <div className="w-full">
                                        {
                                            !!isOwner && (
                                                <div className="w-full flex flex-row items-center border-b">
                                                    <p 
                                                        className={`px-2 pb-2 mr-2 text-md text-left ${activeSubTab === 'members' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}                        
                                                        onClick={() => setActiveSubTab('members')}>
                                                        Members
                                                    </p>
                                                </div>
                                            )
                                        }
                                        {
                                            activeSubTab === 'members' ? (
                                                <>
                                                    <div className="w-full lg:w-1/2 flex flex-row items-center mt-2">
                                                        {
                                                            !!isOwner && (
                                                                <Button
                                                                    onClick={() => {
                                                                        setMemberModal(true);
                                                                    }}
                                                                    type="primary"
                                                                    className="px-5 mr-3 text-md text-white primary-bg rounded-sm cursor-pointer"
                                                                    style={{height: 30}}                    
                                                                >Create
                                                                </Button>
                                                            )
                                                        }
                                                        {
                                                            !!isOwner && (
                                                                <Button
                                                                    onClick={() => handleExportMembershipData(true)}
                                                                    type="dashed"
                                                                    className="px-5 ml-3 text-md primary-bg rounded-sm cursor-pointer"
                                                                    style={{height: 30}}                    
                                                                >Export to Excel
                                                                </Button>
                                                            )
                                                        }
                                                        {
                                                            !isOwner && (
                                                                <Search
                                                                    onChange={text => setSearch(text.target.value)}
                                                                    value={search} 
                                                                    placeholder="Search by email or username" 
                                                                    onSearch={text => onSearch(text, false)} 
                                                                    className="rounded-md my-3" 
                                                                /> 
                                                            )
                                                        }
                                                    </div>
                                                    
                                                    {
                                                        isOwner ? (
                                                             <div className="w-full">
                                                                <Table
                                                                    columns={memberColumns}
                                                                    dataSource={members?.filter(m => m?.personId !== data?.created_by)}
                                                                />
                                                             </div>
                                                        ) : (
                                                            <div className="w-full flex flex-col items-center bg-gray-200 pb-3">
                                                                {
                                                                    !(search ? members?.filter(m => m?.username?.toLowerCase()?.includes(search) || m?.email_address?.toLowerCase()?.includes(search)) : members)?.length && (
                                                                        <p className="w-full py-4 text-center">
                                                                            No Members
                                                                        </p>
                                                                    )
                                                                }
                                                                <div className="w-full grid grid-cols-6 gap-4 p-4">
                                                                    {
                                                                        (search ? members?.filter(m => m?.username?.toLowerCase()?.includes(search) || m?.email_address?.toLowerCase()?.includes(search)) : members)?.map((inv, idx) => (
                                                                            <MemberItem 
                                                                                admin={inv?.memberTypeId===1?true:false}
                                                                                key={idx}
                                                                                {...inv}
                                                                            />
                                                                        ))
                                                                    }  
                                                                </div>
                                                                {
                                                                    members?.length < totalMembers && (
                                                                        <Button
                                                                            onClick={() => {
                                                                                let oldPage = page;
                                                                                oldPage++;
                                                                                getAllMembersByOrg(null, oldPage);
                                                                            }}
                                                                            type="primary"
                                                                            className="px-5 mr-3 text-md text-white primary-bg rounded-sm cursor-pointer"
                                                                            style={{height: 30}}                    
                                                                        >Load More
                                                                        </Button>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    
                                                </>
                                            ) : null
                                        }
                                    </div>
                                ) : activeTab === 'report' ? (
                                    <>
                                        <div className="w-full flex flex-col items-start">
                                            <p className="mr-4 py-2 text-black uppercase font-medium text-left">Report this community</p>
                                            <p className="text-left text-gray-700 py-1">
                                                Please help Assistance Org investigate this community by providing information about why you've reporting it.
                                            </p>
                                        </div>
                                        <div className="w-full lg:w-1/2 flex flex-col flex-center items-start">
                                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                                <label className="text-md text-left w-full py-2" for="email_address">Email Address <span className="text-red-500">*</span></label>
                                                <Input
                                                    disabled={user}
                                                    value={reportEmail}
                                                    onChange={(e) => setReportEmail(e.target.value)}
                                                    type="email"
                                                    placeholder="Required for copyright reports"
                                                    id="email_address" 
                                                    className="w-full border border-gray-400 rounded-md p-1 outline-none"
                                                />
                                            </div>
                                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                                <label className="text-md text-left w-full py-2" for="message">Description <span className="text-red-500">*</span></label>
                                                <TextArea
                                                    onChange={text => setReportMessage(text.target.value)}
                                                    value={reportMessage}
                                                    id="message"
                                                    placeholder="Additional details are required for reported issuses"
                                                    className="w-full border border-gray-400 rounded-md p-1 outline-none"
                                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                                />
                                            </div>
                                            <div className="w-full flex flex-col items-start my-1 ml-1">
                                                <label className="text-md text-left w-full py-2" for="message">Reason for report <span className="text-red-500">*</span></label>
                                                <Radio.Group onChange={onReasonChange} value={selectedReason}>
                                                    <Space direction="vertical">
                                                        {
                                                            reasonsList
                                                            ?.map(item => (
                                                                <Radio value={item?.id}>{item?.name}</Radio>
                                                            ))
                                                        }
                                                    </Space>
                                                </Radio.Group>
                                            </div>
                                            <p className="w-full text-left py-2 text-xs text-gray-600">
                                                Before you send the report make sure its appropiate and meets our terms and conditions.  
                                            </p>
                                            <div className="w-full flex items-start">
                                                <Button
                                                    onClick={() => sendReportEmail()}
                                                    loading={reportLoading}
                                                    type="primary"
                                                    disabled={!(reportEmail && reportMessage)}
                                                    className="px-5 my-2 text-md text-white primary-bg rounded-md cursor-pointer"
                                                    style={{ height: 35 }}
                                                >Report this event</Button>
                                            </div>
                                        </div>
                                    </>
                                ) : activeTab === 'events' ? (
                                    <div className="w-full py-4">
                                        <EventPanel />
                                    </div>
                                ) : activeTab === 'blogs' ? (
                                    <div className="w-full py-4">
                                        <h1>Blogs</h1>
                                    </div>
                                ) : activeTab === 'files' ? (
                                    <div className="w-full">
                                       <div className="mb-2 w-full flex items-center justify-end">
                                            {
                                                !!isOwner && (
                                                    <Button
                                                        onClick={() => setFileUploadModal(true)}
                                                        type="primary"
                                                        className="px-5 mr-3 text-md text-white primary-bg rounded-sm cursor-pointer"
                                                        style={{height: 30}}                    
                                                    >Upload
                                                    </Button>
                                                )
                                            }
                                        </div>
                                        <Table
                                            bordered={true}
                                            columns={fileManagementColumns}
                                            dataSource={communityFiles}
                                        />
                                    </div>
                                ) : activeTab === 'settings' ? (
                                    <div className="w-full">
                                        <div className="w-full flex flex-col items-start">
                                            <h2 className="text-black font-semibold w-full text-left py-2 text-lg capitalize">
                                                Edit Community Details
                                            </h2>
                                            <div className="py-2 w-full flex flex-row items-center justify-start">
                                                <Button
                                                    onClick={() => {
                                                        setOpenSettings(false);
                                                        setActiveTab('info');
                                                    }}
                                                    style={{height: 38}}  
                                                    type="default"
                                                    className="px-5 text-base rounded-sm cursor-pointer outline-none focus:outline-none"
                                                >Cancel</Button>
                                                <Button
                                                    onClick={() => history.push(`/community/create?type=edit&id=${data?.org_id}`)}
                                                    style={{height: 38}}
                                                    type="primary"
                                                    className="px-5 text-base text-white primary-bg ml-3 rounded-sm cursor-pointer outline-none focus:outline-none"
                                                >Edit</Button>
                                            </div>
                                            <div class='w-full'>
                                                <h2 className="text-black font-semibold w-full text-left py-2 text-lg capitalize">
                                                    Subscriptions <Button
                                                        onClick={() => {
                                                            let initial = [];
                                                            Array.from(permissionCategories).forEach((data, index) => {            
                                                                initial[index] = [];                                                                                   
                                                            });     
                                                            console.log(initial);                                                 
                                                            setCheckedPermissions(initial);    
                                                            setSModal(true);
                                                        }}
                                                        type="primary"
                                                        className="px-5 mr-3 text-md text-white primary-bg rounded-sm cursor-pointer"
                                                        style={{height: 30}}                    
                                                    >Create
                                                    </Button>
                                                </h2>
                                                 <div className="w-full py-2">
                                                    <div className="w-full my-2">
                                                        <Table
                                                            loading={sLoad}
                                                            columns={subscriptionColumns}
                                                            dataSource={allSubscriptions}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : activeTab === 'announcements' ? (
                                    <div className="w-full">
                                        <div className="w-full lg:w-1/2 flex flex-row items-center">
                                            <Button
                                                onClick={() => setAnnouncementModal(true)}
                                                type="primary"
                                                className="px-5 mr-3 text-md text-white primary-bg rounded-sm cursor-pointer"
                                                style={{height: 30}}                    
                                            >Create
                                            </Button>
                                            <Search
                                                onChange={text => setSearch(text.target.value)}
                                                value={search} 
                                                placeholder="Search by title" 
                                                onSearch={text => onSearch(text, false)} 
                                                className="rounded-md my-3" 
                                            /> 
                                        </div>
                                        <div className="w-full">
                                            <Table
                                                columns={annoucementColumns}
                                                dataSource={allAnnouncements}
                                            />
                                        </div>
                                    </div>
                                ) : null
                            }      
                        </div>           
                    </div>
                )
            } 
            <Modal
                className="apps"
                closable={true}
                visible={invitationModal}
                onCancel={() => setInvitationModal(false)}
                title="Create Invitation"
                style={{top: 20}}
                destroyOnClose={true}
                footer={[<Button loading={invitationLoad} disabled={!invitationEmail} type="primary" onClick={() => createInvite()}>Send</Button>,
                         <Button onClick={() => setInvitationModal(false)}type="default">Cancel</Button>
                        ]}>
                <div className="w-full flex flex-col items-start">
                    <div className="w-full flex flex-col items-center my-1 ml-1">
                        <label className="text-md text-left w-full py-2" for="email_address">Email Address <span className="text-red-500">*</span></label>
                        <Input
                            value={invitationEmail}
                            onChange={(e) => setInvitationEmail(e.target.value)}
                            type="email" 
                            id="email_address" 
                            className="w-full border border-gray-400 rounded-md p-1 outline-none"
                        />
                        {/* {
                                invitationLoadingState ? (
                                    <div className="w-full flex items-start py-3">
                                        <Spin size="small" />
                                    </div>
                                ) : (
                                    tempUser && (
                                        <span className="w-full text-gray-600 py-1 text-sm text-left flex flex-row items-center">
                                            User found: {tempUser?.image && (<img src={tempUser?.image} className="rounded-full ml-2 mr-1 h-6 w-6 contain" />)} {tempUser?.username}
                                        </span>
                                    )
                                )
                        } */}
                    </div>
                </div>
            </Modal>
            <Modal
                className="apps"
                closable={false}
                visible={fileUploadModal}
                onCancel={() => setFileUploadModal(false)}
                title={currentSelectedFile?'Modify File':'New File'}
                style={{top: 20}}
                destroyOnClose={true}
                footer={[<Button 
                                loading={currentSelectedFile?updateLoad:fileUploadLoad} 
                                disabled={!currentSelectedFile?!allowToUpload:!((currentSelectedFile?.isPublic===1?filePrivacy!=='public':filePrivacy!=='private')||currentSelectedFile?.description!==fileDescription||privacyMembersToAdd?.length||privacyMembersToRemove?.length)} 
                                type="primary" 
                                onClick={() => currentSelectedFile?modifyFile():handleFileUpload()}
                            >{currentSelectedFile?'Save':'Upload'}</Button>,
                         <Button onClick={() => setFileUploadModal(false)}type="default">Cancel</Button>
                        ]}>
                <div className="w-full flex flex-col items-start">
                    <div className="w-full flex flex-col items-center mt-1 ml-1">
                        <label className="text-md text-left w-full py-2" for="new_file">
                            Upload File 
                        <span className="text-red-500">*</span>
                        </label>
                        <Input
                            disabled={currentSelectedFile}
                            onChange={(e) => setFileToUpload(e.target.files[0])}
                            type="file" 
                            id="new_file" 
                            className="w-full border border-gray-400 rounded-md p-1 outline-none"
                        />
                    </div>
                    <div className="w-full flex flex-col items-center my-1 ml-1">
                        <label className="text-md text-left w-full py-2" for="email_address">Short Description</label>
                        <Input
                            value={fileDescription}
                            onChange={e => setFileDescription(e.target.value)}
                            type="text" 
                            id="short_description" 
                            className="w-full border border-gray-400 rounded-md p-1 outline-none"
                        />
                    </div>
                    <div className="w-full flex flex-col items-start my-1 ml-1">
                        <label className="text-md text-left w-full py-2" for="privacy">File Privacy <span className="text-red-500">*</span></label>
                        <Radio.Group onChange={e => onPrivacyChange(e)} value={filePrivacy}>
                            <Radio value={'public'}>Public</Radio>
                            <Radio value={'private'}>Private</Radio>
                        </Radio.Group>
                        {
                            filePrivacy === 'private' && (
                                <Select                      
                                    allowClear
                                    className="w-full rounded-md event__select outline-none mt-3"
                                    mode="tags"  
                                    size="small"
                                    notFoundContent="No members available"                                              
                                    placeholder="Add members to share"
                                    value={selectPrivacyMember}
                                    onChange={handlePrivacyMemberChange}
                                >        
                                    {
                                        members?.map((m, key) => (
                                            <Option key={key} value={m?.personId}>{m?.username}</Option>     
                                        ))
                                    }
                                </Select> 
                            )
                        } 
                    </div>
                </div>
            </Modal>
            <Modal
                className="apps"
                closable={false}
                width={700}
                visible={announcementModal}
                onCancel={() => {}}
                title={currentAnnoucement?"Modify Announcement":"Create Announcement"}
                style={{top: 20}}
                destroyOnClose={true}
                footer={[<Button loading={aLoad} disabled={!allowedToCreateAnnouncement} type="primary" 
                                    onClick={() => handleSubmit(currentAnnoucement?'update':'create')}>{currentAnnoucement?'Save':'Submit'}</Button>,
                                    <Button loading={bLoad} onClick={() => blockAnnouncement(currentAnnoucement?.isBlocked===1?false:true)}type="dashed" danger>{currentAnnoucement?.isBlocked===1?'UnBlock':'Block'}</Button>
                                    ,<Button onClick={() => setAnnouncementModal(false)}type="default">Cancel</Button>
                        ]}>
                 <div className="w-full flex flex-col items-start">
                    <div className="w-full flex flex-col items-center mt-1">
                        <label className="text-md text-left w-full py-2" for="atitle">
                            Title <span className="text-red-500">*</span>
                        </label>
                        <Input
                            value={aTitle}
                            onChange={(e) => setATitle(e.target.value)}
                            type="text" 
                            id="atitle" 
                            className="w-full border border-gray-400 rounded-md p-1 outline-none"
                        />
                    </div>
                    <div className="w-full flex flex-col items-center mt-1">
                        <label className="text-md text-left w-full py-2" for="adescription">
                            Short Description
                        </label>
                        <Input
                            value={aDescription}
                            onChange={(e) => setADescription(e.target.value)}
                            type="text" 
                            id="adescription" 
                            className="w-full border border-gray-400 rounded-md p-1 outline-none"
                        />
                    </div>
                    <div className="w-full flex flex-col items-center mt-1">
                        <label className="text-md text-left w-full py-2" for="adescription">
                            Detailed Content <span className="text-red-500">*</span>
                        </label>
                        <Editor
                            editorState={aContentState}
                            toolbarClassName="w-full border rounded-md-tr rounded-md-tl"
                            wrapperClassName="w-full border rounded-md"
                            editorClassName="w-full border rounded-md-br rounded-md-bl"
                            onEditorStateChange={e => onEditorChange(e)}
                            toolbar={{
                                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                                inline: { inDropdown: false },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: false },
                                link: { inDropdown: true },
                            }}
                        />
                    </div>
                    <div className="w-full flex flex-row items-center py-1">
                        <div className="w-full flex flex-col items-center mr-1">
                            <label className="text-md text-left w-full py-2" for="aFrom">
                                Active From <span className="text-red-500">*</span>
                            </label>
                            <Input
                                disabled={currentAnnoucement}
                                value={aActiveFrom}
                                onChange={(e) => setActiveFrom(e.target.value)}
                                type="datetime-local" 
                                id="aFrom" 
                                className="w-full border border-gray-400 rounded-md p-1 outline-none"
                            />
                        </div>
                        <div className="w-full flex flex-col items-center ml-2">
                            <label className="text-md text-left w-full py-2" for="aTo">
                                Active To <span className="text-red-500">*</span>
                            </label>
                            <Input
                                disabled={currentAnnoucement}
                                value={aActiveTo}
                                onChange={(e) => setActiveTo(e.target.value)}
                                type="datetime-local" 
                                id="aTo" 
                                className="w-full border border-gray-400 rounded-md p-1 outline-none"
                            />
                        </div>
                    </div>
                    <div className="w-full flex flex-row items-center py-1">
                        <div className="w-full flex flex-col items-center mr-1">
                            <label className="text-md text-left w-full py-2" for="aImage">
                                Image
                                {
                                    aImage && (
                                        <span 
                                            onClick={() => removeImage()}
                                            className="underline cursor-pointer px-2 text-sm text-red-500">
                                            Remove Old Image
                                        </span>
                                    )
                                }
                            </label>
                            <Input
                                onChange={(e) => setAFiles(e.target.files[0])}
                                type="file" 
                                id="aImage" 
                                className="w-full border border-gray-400 rounded-md p-1 outline-none"
                            />
                        </div>
                        <div className="w-full flex flex-col items-start ml-2">
                            <label className="text-md text-left w-full py-2" for="aTo">
                                Has an Action Item ?
                            </label>
                            <Switch size="small" defaultChecked={aIsActionItem} value={aIsActionItem} onChange={e => setAIsActionItem(e)} />
                        </div>
                    </div>
                    {
                        aIsActionItem && (
                        <>
                    <div className="py-1 w-full flex flex-col items-start">
                            <label className="text-md text-left w-full py-2" for="adescription">
                                Action Detail Description
                                {
                                    aIsActionItem && (<span className="text-red-500">*</span>)
                                }
                            </label>
                            <Input
                                value={actionDescription}
                                onChange={(e) => setActionDescription(e.target.value)}
                                type="text" 
                                id="adescription" 
                                className="w-full border border-gray-400 rounded-md p-1 outline-none"
                            />
                    </div>
                    <div className="py-1 w-full flex flex-col items-start">
                            <label className="text-md text-left w-full py-2" for="adescription">
                               Add People for action item
                            </label>
                            <Select                        
                                allowClear
                                className="w-full rounded-md event__select outline-none"
                                mode="multiple"  
                                notFoundContent="No People Found"                                              
                                placeholder="Add People To Inform"
                                value={aPeopleSelect}
                                onChange={handlePeopleSelect}
                            >        
                                {
                                    members.length &&
                                    members.map(member => (
                                        <Option value={member.personId}>{member.username}</Option>
                                    ))                
                                }        
                            </Select> 
                    </div>
                    </>
                        )
                    }
                 </div>
            </Modal>
            <Modal
                className="apps"
                closable={false}
                width={700}
                visible={sModal}
                onCancel={() => {
                    resetSModalInputs();
                    setSModal(false);
                }}
                title={currentSubscription?"Modify Subscription":"Create Subscription"}
                style={{top: 20}}
                destroyOnClose={true}
                footer={[<Button loading={ssLoad} disabled={!sTitle} type="primary" 
                                    onClick={() => sSubmit(currentSubscription?'update':'create')}>{currentSubscription?'Save':'Submit'}</Button>
                                    ,<Button onClick={() => {
                                        resetSModalInputs();
                                        setSModal(false);
                                    }} type="default">Cancel</Button>
                        ]}>
                 <div className="w-full flex flex-col items-start">
                    <div className="w-full flex flex-col items-center mt-1">
                        <label className="text-md text-left w-full py-2" for="stitle">
                            Title <span className="text-red-500">*</span>
                        </label>
                        <Input
                            value={sTitle}
                            onChange={(e) => setSTitle(e.target.value)}
                            type="text" 
                            id="stitle" 
                            className="w-full border border-gray-400 rounded-md p-1 outline-none"
                        />
                    </div>
                    <div className="w-full flex flex-row items-center justify-between">
                        <div className="w-full flex flex-col items-center mt-1 mr-1">
                            <label className="text-md text-left w-full py-2" for="stitle">
                                Validity <span className="text-red-500">*</span>
                            </label>
                            <Select                      
                                className="w-full rounded-md outline-none"  
                                value={sValidity}
                                onChange={e => setSValidity(e)}
                            > 
                                <Option disabled value={null}>Select Validity</Option>
                                <Option value="month">A Month (1 mo)</Option>
                                <Option value="quarter">A Quarter (3 mo)</Option>
                                <Option value="year">A Year (12 mo)</Option>
                                <Option value="unlimited">Unlimited</Option>
                            </Select>
                        </div>
                        {
                            isSFree ? null : (
                                <div className="w-full flex flex-col items-center mt-1 ml-2">
                                    <label className="text-md text-left w-full py-2" for="sPrice">
                                        Price
                                    </label>
                                    <Input
                                        value={sPrice}
                                        onChange={(e) => setSPrice(e.target.value)}
                                        type="number" 
                                        id="sPrice" 
                                        className="w-full border border-gray-400 rounded-md p-1 outline-none"
                                    />
                                </div>
                            )
                        }
                    </div>
                    
                    <div className="w-full flex flex-col items-center mt-1">
                        <label className="text-md text-left w-full py-2" for="stitle">
                            Configure Permissions <span className="text-red-500">*</span>
                        </label>
                        {
                            communityPermissions?.map((ep, index) => (
                                <div className="w-full flex flex-col items-start text-left">
                                    <p className="w-full text-left py-1 text-md">{ep?.title}</p>
                                    <div className="w-full flex flex-col items-start border-b pb-2">
                                            <div className="site-checkbox-all-wrapper pb-1">
                                                <Checkbox                                                   
                                                    onChange={e => onCheckAllChange(e, ep.permissions, index)}
                                                    checked={checkedPermissions[index]?.length === communityPermissions[index]?.permissions.length}                                                                            
                                                >Select all                                         
                                                </Checkbox>
                                            </div>
                                            <CheckboxGroup                                    
                                                defaultValue={checkedPermissions[index]}   
                                                value={checkedPermissions[index]}                                 
                                                options={buildPlainOptions(ep.permissions, "name")}  
                                                onChange={e => onCheckChange(e, index)}                                                                      
                                            />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="w-full flex flex-col items-start ml-2">
                        <label className="text-md text-left w-full py-2" for="sTo">
                            Is Free ?
                        </label>
                        <Switch id="sTo" size="small" defaultChecked={isSFree} value={isSFree} onChange={e => setIsSFree(e)} />
                    </div>
                 </div>
            </Modal>
            <Modal
                className="apps"
                closable={false}
                width={700}
                visible={memberModal}
                onCancel={() => {
                    setMemberModal(false);
                }}
                title={currentMember?"Modify Member":"Create Member"}
                style={{top: 20}}
                destroyOnClose={true}
                footer={[<Button loading={memberLoad} disabled={!allowedToCreateMemeber} type="primary" 
                                    onClick={() => createOrUpdateMembership(currentMember?'update':'create')}>{currentMember?'Save':'Submit'}</Button>
                                    ,<Button onClick={() => setMemberModal(false)}type="default">Cancel</Button>
                        ]}>
                 <div className="w-full flex flex-row items-start">
                    <div className="w-full flex flex-col items-center mt-1 mr-1">
                        <label className="text-md text-left w-full py-2" for="mUsername">
                            Username <span className="text-red-500">*</span>
                        </label>
                        <Input
                            disabled={memberUsernameAvailable==='no'||currentMember?.username}
                            value={memberUsername}
                            onChange={(e) => setMemberUsername(e.target.value)}
                            type="text" 
                            id="mUsername" 
                            className="w-full border border-gray-400 rounded-md p-1 outline-none"
                        />
                        {
                            memberUsernameAvailable === 'yes' ? (
                                <p className="w-full text-green-500 text-sm py-1 font-medium text-left">New User</p>
                            ) : memberUsernameAvailable === 'no' ? (
                                <p className="w-full text-green-500 text-sm py-1 font-medium text-left">User Found</p>
                            ) : null
                        }
                    </div>
                    <div className="w-full flex flex-col items-center mt-1 ml-2">
                        <label className="text-md text-left w-full py-2" for="stitle">
                            Member Type <span className="text-red-500">*</span>
                        </label>
                        <Select                      
                            className="w-full rounded-md outline-none"  
                            value={memberUserType}
                            onChange={e => setMemberUserType(e)}
                        > 
                            <Option disabled value={null}>Select Member Type</Option>
                            {
                                allMemberTypes?.map((s, idx) => (
                                    <Option key={idx} value={s?.id}>{s?.name}</Option>
                                ))
                            }
                        </Select>
                    </div>
                 </div>
                 <div className="w-full flex flex-row items-start">
                    <div className="w-full flex flex-col items-center mt-1 mr-1">
                        <label className="text-md text-left w-full py-2" for="memberEmail">
                            Email Address <span className="text-red-500">*</span>
                        </label>
                        <Input
                            disabled={memberEmailAvailable==='no'||currentMember?.email_address}
                            value={memberEmail}
                            onChange={(e) => setMemberEmail(e.target.value)}
                            type="email" 
                            id="memberEmail" 
                            className="w-full border border-gray-400 rounded-md p-1 outline-none"
                        />
                        {
                            memberEmailAvailable === 'yes' ? (
                                <p className="w-full text-green-500 text-sm py-1 font-medium text-left">New User</p>
                            ) : memberEmailAvailable === 'no' ? (
                                <p className="w-full text-green-500 text-sm py-1 font-medium text-left">User Found</p>
                            ) : null
                        }
                    </div>
                    <div className="w-full flex flex-col items-center mt-1 ml-2">
                        <label className="text-md text-left w-full py-2" for="stitle">
                            Subscription <span className="text-red-500">*</span>
                        </label>
                        <Select                      
                            className="w-full rounded-md outline-none"  
                            value={memberSubscription}
                            onChange={e => setMemberSubscription(e)}
                        > 
                            <Option disabled value={null}>Select Subscription</Option>
                            {
                                allSubscriptions?.map((s, idx) => (
                                    <Option key={idx} value={s?.id}>{s?.name}</Option>
                                ))
                            }
                        </Select>
                    </div>
                 </div>

                 <div className="w-full flex flex-row items-start">
                    <div className="w-full flex flex-col items-center mt-1 mr-1">
                        <label className="text-md text-left w-full py-2" for="mExpire">
                            Expires On <span className="text-red-500">*</span>
                        </label>
                        <Input
                            disabled={true}
                            value={moment(memberExpire).format('YYYY-MM-DD')}
                            onChange={(e) => setMemberExpire(e.target.value)}
                            type="date" 
                            id="mExpire" 
                            className="w-full border border-gray-400 rounded-md p-1 outline-none"
                        />
                    </div>
                    <div className="w-full flex flex-col items-center mt-1 ml-2">
                        <label className="text-md text-left w-full py-2" for="mRenewal">
                            Renewal Date <span className="text-red-500">*</span>
                        </label>
                        <Input
                            disabled={true}
                            value={moment(memberRenewal).format('YYYY-MM-DD')}
                            onChange={(e) => setMemberRenewal(e.target.value)}
                            type="date" 
                            id="mRenewal" 
                            className="w-full border border-gray-400 rounded-md p-1 outline-none"
                        />
                    </div>
                 </div>
                 <div className="w-full flex flex-col items-start ml-2">
                    <label className="text-md text-left w-full py-2" for="mTo">
                        Auto Renewal
                    </label>
                    <Switch id="mTo" size="small" defaultChecked={memberAutoRenewal} value={memberAutoRenewal} onChange={e => setMemberAutoRenewal(e)} />
                </div>

            </Modal>
            <Footer />
        </>
    )
}

export default DetailCommunity
