import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Input } from 'antd'
import moment from 'moment'

function CreatePoster({ type }) {
    return (
        <div className="w-full flex flex-col lg:flex-row items-start justify-between rounded-lg primary-bg shadow-lg px-6 lg:px-10 py-4 mb-8">
            <div className="w-full lg:w-1/2 flex flex-row items-start">
                <img
                    alt="icon"
                    src="./calendar-white.png"
                    className="contain h-20 mr-8 hidden"
                />
            <div className="w-full flex flex-col items-start">
                    <h1 className="w-full text-2xl text-white font-bold text-left">
                        {type==='Communities'?'Create your own community and make your space':'Create and host your customized event now'}
                    </h1>
                    <h2 className="w-full text-left text-md text-gray-300 font-medium py-1">  
                        {type==='Communities'?'Manage your own community with events, tasks with your members':'Manage your own events for your special and important moments to gather people together'}
                    </h2>
                    {type==='Communities'? (
                        <Link
                            to="/community/create" 
                            className="focus:outline-none text-center w-56 whitespace-nowrap px-4 py-3 bg-white text-blue-500 shadow-md font-bold text-md rounded-md my-2">
                            Create Community
                        </Link>
                    ) : (
                        <Link
                            to="/event/create" 
                            className="focus:outline-none text-center w-56 whitespace-nowrap px-4 py-3 bg-white text-blue-500 shadow-md font-bold text-md rounded-md my-2">
                            Create an event
                        </Link>
                    )}
                </div>
            </div>
            {
                !type && (
                <div className="w-full lg:w-1/2 flex flex-col items-start border-t lg:border-t-0 lg:border-l border-gray-300 lg:my-0 my-2 px-4">
                    <h3 className="w-full text-left text-xl font-medium text-white">Find Events</h3>
                <div className="w-full flex flex-col lg:flex-row items-center justify-between">
                <div className="w-full flex flex-col items-center lg:mr-2">
                        <label className="text-md text-left text-white w-full py-1" for="start">Start Date</label>
                        <Input
                            // value={startDate}
                            // onChange={(e) => {
                            //     const tempDate = new Date(e.target.value)?.toISOString();
                            //     setStartDate(e.target.value);
                            // }} 
                            type="date" 
                            id="start" 
                            min={moment().format("YYYY-MM-DD")}
                            className="w-full border border-gray-400 rounded-sm p-1 outline-none"
                        />
                    </div>
                    <div className="w-full flex flex-col items-center my-1 lg:ml-1">
                        <label className="text-md text-left text-white w-full py-1" for="end">End Date</label>
                        <Input
                            // value={endDate}
                            // onChange={(e) => {
                            //     const tempDate = new Date(e.target.value)?.toISOString();
                            //     setEndDate(e.target.value);
                            // }} 
                            type="date" 
                            id="end" 
                            // min={startDate?moment(new Date(startDate)?.toISOString()).format("YYYY-MM-DD"):moment().format("YYYY-MM-DD")}
                            className="w-full border border-gray-400 rounded-sm p-1 outline-none"
                        />
                    </div>
                    <div className="w-full flex flex-col items-center my-1 lg:ml-2">
                            <label className="text-md text-left text-white w-full py-1" for="location">Location</label>
                            <Input
                                type="text" 
                                id="location" 
                                className="w-full border border-gray-400 rounded-sm p-1 outline-none"
                            />
                    </div>
               </div>
               <div className="w-full flex flex-col lg:flex-row items-end justify-between">
                    <div className="w-full flex flex-col items-center lg:mr-2 lg:mb-0 mb-3">
                        <label className="text-md text-left text-white w-full py-1" for="name">Event Name</label>
                        <Input
                            type="text" 
                            id="name" 
                            className="w-full border border-gray-400 rounded-sm p-1 outline-none"
                        />
                    </div>
                    <Button
                        className="focus:outline-none text-center w-56 h-10 whitespace-nowrap px-4 bg-transparent border-white border-2 text-white font-bold text-md rounded-md">
                        Apply Filter
                    </Button>
                </div>
            </div>
                )
            }
            
        </div>
    )
}

export default CreatePoster
