export const initialState = {
    notifications: [],
    currentSelectedCategory: null,
    selectedCommunityCategory: null,
    userSelectedCategories: [],
    searchTrigger: false,
    categories: [],    
    categoriesStatus: false,
    communityCategories: [],
    communityCategoryStatus: false,
    feeds: [],
    feedsStatus: false,
    selectedCategory: null,
    allRoles: [],
    allRolesStatus: false,
    allPermissions: [],
    allPermissionsStatus: false,
    allCommunityPermissions: [],
    allCommunityPermissionsStatus: false,
    allKeywords: [],
    allKeywordsStatus: false,
    allFeedTypes: [],
    allFeedTypesStatus: false,
    allTagTypes: [],
    allTagTypesStatus: false,
    allMemberTypes: [],
    allMemberTypesStatus: false,
    allAddressTypes: [],
    allReasons: [],
    allReasonsStatus: false,
    allTags: [],
    allTagsStatus: false,
    allAddressTypesStatus: false,
    showNotifications: false,
    allNotificationsStatus: false,
    user: sessionStorage.getItem('assistance_user') ? JSON.parse(sessionStorage.getItem('assistance_user')) || null : null,    
    token: sessionStorage.getItem('assistance_token') || null,    
    permissions: sessionStorage.getItem('assistance_permissions') || []    
}

export const getUnReadNotifications = (notifications) =>
        notifications?.filter(item => item.unread === 1)?.length || 0;

const reducer = (state, action) => {
    // console.log(action);
    switch(action.type) {  
        case 'REFRESH_NOTIFICATIONS':
            return {
                ...state,
                allNotificationsStatus: action.payload
            }
        case 'TOGGLE_NOTIFICATION':
            return {
                ...state,
                showNotifications: action.payload
            }
        case 'SET_NOTIFICATIONS': 
            return {
                ...state,
                notifications: action.payload
            }
        case 'READ_NOTIFICATION':
            let newNotifications = state.notifications;
            // READ LOGIC
            let id = action.payload;
            newNotifications.forEach((val, index) => {
                if (val.id === id) {
                    newNotifications[index] = {
                        ...val,
                        unread: 0
                    };
                }
            });
            return {
                ...state,
                notifications: newNotifications
            }
        case 'REMOVE_NOTIFICATION': 
            const filteredNotifications = state.notifications.filter(item => item.id !== action.payload);
            return {
                ...state,
                notifications: filteredNotifications
            }
        case 'SET_USER_CATEGORY':
            return {
                ...state,
                currentSelectedCategory: action.category
            }
        case 'SET_USER_CATEGORIES':
            return {
                ...state,
                userSelectedCategories: action.categories ? Array.from(action.categories) : []
            }
        case 'LOG_OUT':
            return {
                ...state,
                user: null,
                token: null,
                permissions: []
            }
        case 'SET_CATEGORIES':
            return {
                ...state,
                categories: action.categories
            }
        case 'SET_COMMUNITY_CATEGORIES':
                return {
                    ...state,
                    communityCategories: action.categories
                }            
        case 'SET_CATEGORIES_STATUS':
            return {
                ...state,
                categoriesStatus: action.status
            }
        case 'SET_COMMUNITY_CATEGORIES_STATUS':
                return {
                    ...state,
                    communityCategoryStatus: action.status
                }            
        case 'UPDATE_SELECTED_CATEGORY':            
            return { 
                ...state,
                selectedCategory: action.item 
            }
        case 'UPDATE_SELECTED_COMMUNITY_CATEGORY':
            return {
                ...state,
                selectedCommunityCategory: action.item
            }
        case 'UPDATE_USER':
            return {
                ...state,
                user: JSON.parse(sessionStorage.getItem('assistance_user'))
            }            
        case 'SET_ALL_ROLES': 
            return { 
                ...state,
                allRoles: [...action.roles]
            }            
        case 'SET_ALL_ROLES_STATUS':
            return {
                ...state,
                allRolesStatus: action.status
            }            
        case 'SET_ALL_PERMISSIONS': 
            return { 
                ...state,
                allPermissions: [...action.permissions]
            }            
        case 'SET_ALL_PERMISSIONS_STATUS':
            return {
                ...state,
                allPermissionsStatus: action.status
            }          
        case 'SET_ALL_COMMUNITY_PERMISSIONS': 
            return { 
                ...state,
                allCommunityPermissions: [...action.permissions]
            }            
        case 'SET_ALL_COMMUNITY_PERMISSIONS_STATUS':
            return {
                ...state,
                allCommunityPermissionsStatus: action.status
            }            
        case 'SET_ALL_KEYWORDS': 
            return { 
                ...state,
                allKeywords: [...action.keywords]
            }            
        case 'SET_ALL_KEYWORDS_STATUS':
            return {
                ...state,
                allKeywordsStatus: action.status
            }            
        case 'SET_ALL_TAGS': 
            return { 
                ...state,
                allTags: [...action.tags]
            }            
        case 'SET_ALL_TAGS_STATUS':
            return {
                ...state,
                allTagsStatus: action.status
            }            
        case 'SET_ALL_FEED_TYPES': 
            return { 
                ...state,
                allFeedTypes: [...action.feedTypes]
            }            
        case 'SET_ALL_FEED_TYPES_STATUS':
            return {
                ...state,
                allFeedTypesStatus: action.status
            } 
        case 'SET_ALL_TAG_TYPES': 
            return { 
                ...state,
                allTagTypes: [...action.tagTypes]
            }            
        case 'SET_ALL_TAG_TYPES_STATUS':
            return {
                ...state,
                allTagTypesStatus: action.status
            } 
        case 'SET_ALL_REASONS': 
            return { 
                ...state,
                allReasons: [...action.reasons]
            }            
        case 'SET_ALL_REASONS_STATUS':
            return {
                ...state,
                allReasonsStatus: action.status
            } 
        case 'SET_ALL_MEMBER_TYPES': 
            return { 
                ...state,
                allMemberTypes: [...action.memberTypes]
            }            
        case 'SET_ALL_MEMBER_TYPES_STATUS':
            return {
                ...state,
                allMemberTypesStatus: action.status
            } 
        case 'SET_ALL_ADDRESS_TYPES': 
            return { 
                ...state,
                allAddressTypes: [...action.addressTypes]
            }            
        case 'SET_ALL_ADDRESS_TYPES_STATUS':
            return {
                ...state,
                allAddressTypesStatus: action.status
            }            
        case 'SET_FEEDS': 
            return { 
                ...state,
                feeds: action.feeds
            }            
        case 'SET_FEEDS_STATUS':
            return {
                ...state,
                feedsStatus: action.status
            }            
        case 'SET_SEARCH_TRIGGER':
                return {
                    ...state,
                    searchTrigger: action.status
                }                
        default:
            return state;
    }
}

export default reducer;