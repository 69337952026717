/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import defaultMaleImage from '../../../assets/img/default_user_male.jpg'
import { FacebookFilled, LinkOutlined, WhatsAppOutlined, TeamOutlined, PhoneOutlined, MailOutlined, TwitterOutlined } from '@ant-design/icons'

function Banner(props) {
    return (
        <div className="w-full mt-8 mb-4">
            {
                props?.community ? (
                    <div className="community__header bg-white w-full my-1 flex flex-col items-start">
                            <div className="w-full flex flex-row items-center">
                                <img 
                                    className="p-3 community__avatar rounded-full object-cover" 
                                    alt="banner"
                                    src={`${props?.community?.c_data?.logo ? process.env.REACT_APP_SERVER_URL+'/'+props?.community?.c_data?.image : defaultMaleImage}`}
                                />
                                <div className="ml-4 w-full flex flex-col items-start">
                                    <h1 className="w-full text-md lg:text-xl font-medium">{props?.community?.c_data?.name}</h1>
                                   <div className="w-full flex flex-row items-center">
                                        <p className="bg-gray-600 rounded-sm text-md px-2 py-1 text-white my-1">
                                            <TeamOutlined /> {props?.community?.c_members} Members
                                        </p>
                                   </div>
                                </div>
                                <div className="py-1 flex flex-col items-center justify-center">
                                    <div className="flex flex-row items-center mt-2">
                                        <a rel="noopener noreferrer" target="_blank" href="#" className="text-lg mr-3"><MailOutlined /></a>
                                        <a rel="noopener noreferrer" target="_blank" href="#" className="text-lg mx-3"><PhoneOutlined /></a>                                                
                                        <a rel="noopener noreferrer" href="#" className="text-lg lg:mx-3"><LinkOutlined /></a>                                                                                           
                                    </div>
                                </div>
                            </div>
                        </div>
                ) : (
                    <img
                        src="https://images.unsplash.com/photo-1558008258-3256797b43f3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1189&q=80"
                        className="rounded-lg shadow-lg mb-8 w-full object-cover h-40"
                        alt="banner"
                    />
                )
            }
        </div>
    )
}

export default Banner
