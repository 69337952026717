/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Header from '../../components/User/v2/Header'
import {FileTwoTone} from '@ant-design/icons' 
import apiInstance from '../../api';
import { useParams } from 'react-router';
import { Button, Result } from 'antd';
import logo from '../../assets/img/icon.png'

function FileShare() {
    const [data, setData] = useState(null);

    const { community_name, file_name } = useParams();
    const [loading, setLoading] = useState(false);

    async function getDataSource() {
        setLoading(true);
        apiInstance
        .get(`/community-files/shareable/file/${file_name}/${community_name}`)
        .then(response => {
            if (response.data.data) {
                setData(response.data.data);
            }
        })  
        .catch(e => {
            // message.error(e.response.data.message || 'Internal Server Error');
        }).finally(() => setLoading(false));
    }

    useEffect(() => {
        if (community_name && file_name) {
            getDataSource();
        }
    }, [file_name, community_name]);

    return (
        <>
            <Header fixed={false} />
            {
                loading ? (
                    <div className="w-full main__preLoaderContainer flex items-center justify-center">
                        <img 
                            className="main__preLoader"
                            src={logo}
                            alt="preloader"
                        />
                    </div>
                ) : (
                    data ? (
                        <>
                        <div className="w-full flex flex-col items-center mt-6">
                            <h1 className="w-full text-center text-4xl font-medium text-black py-1">
                                {data?.community_title}
                            </h1>
                            <p className="w-6/12 mx-auto text-lg text-gray-600 text-center py-1">
                            A file has been shared to you.
                            </p>
                        </div>
                        <div className="border-4 border-gray-400 border-dashed bg-white p-2 w-full mx-auto lg:w-1/2 flex flex-col items-center justify-center my-10">
                            <div className="w-full flex flex-row items-center justify-center">
                                <FileTwoTone style={{fontSize: 25}} /> 
                                <h1 className="px-2 text-center text-black py-2 text-xl font-medium">
                                    {data?.file_name}
                                </h1>
                            </div>
                            <a 
                                href={`${process.env.REACT_APP_SERVER_URL}api/community-files/download/${data?.id}`}
                                className="outline-none hover:text-white focus:outline-none hover:bg-blue-400 bg-blue-500 text-lg text-white rounded-sm my-3 shadow py-3 px-4 font-medium">
                                Download Now
                            </a>
                        </div>
                        </>
                    ) : (
                        <Result
                            status="404"
                            title="Oops"
                            subTitle="Sorry, you are not authorized or file not found."
                            extra={<Button onClick={() => window.location.replace('/')} type="primary">Back Home</Button>}
                        />
                    )
                )
            }  
        </>
    )
}

export default FileShare
