/* eslint-disable */
import React,{ useEffect, useState } from 'react'
import { buildPermissionName } from '../../../utils/Helper'
import { Button, Table, Input, Space, Switch, Modal, Spin, Tabs } from 'antd'
import { PlusOutlined, SearchOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import moment from 'moment'
import CrudModal from './CrudModal'
import RPModal from './RPModal'
import { useDataLayerValue } from '../../../DataLayer'

const { TabPane } = Tabs;

function CrudUI({ title, utility=null, data=null, dataStatus=false, addModalTrigger, updateModalTrigger, load=false, setAddModalTrigger = am => am, setUpdateModalTrigger = um => um, createItem = ci => ci, updateItem = ut => ut, deleteItem = dt => dt, setCurrentItem = ct => ct, currentItem, changeStatus = cs => cs }) {

    const [{ allPermissions, allCommunityPermissions , allFeedTypes, allKeywords, allRoles, allReasons }] = useDataLayerValue();
    
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchInput, setSearchInput] = useState('');         
    // eslint-disable-next-line
    const [permissionCategories, setPermissionCategories] = useState([]);
    const [exisitingPermissions, setExisitingPermissions] = useState([]);

    const [rpModalTrigger, setRPModalTrigger] = useState(false);

    useEffect(() => {        
        if(!data) {
            switch(utility) {
                // eslint-disable-next-line
                case 'Permissions': data = allPermissions;break;                
                case 'Roles': data = allRoles;break;
                case 'Keywords': data = allKeywords;break;
                case 'Feed Types': data = allFeedTypes;break;
                case 'Reasons': data = allReasons;break;
                default: break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if(utility === 'Permissions') setExisitingPermissions(buildPermissionCategories(allPermissions));
        if(utility === 'Community Permissions') setExisitingPermissions(buildPermissionCategories(allCommunityPermissions));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[allPermissions, allCommunityPermissions]);

    /**
     * Used to build the category with their permissions using the
     * available permissions from the DATA LAYER VALUE
     */
    const buildPermissionCategories = (entirePermissions) => {                

        let result = []; // FINAL ARRAY TO BE RETURNED
        
        // BUILD CATEGORY ARRAY
        let permissionCategories = new Set();

        for(var entity of entirePermissions) {
            let entityName = entity.name.split(':')[0];
            permissionCategories.add(entityName);
        }        

        setPermissionCategories(permissionCategories);

        for(let category of permissionCategories) {
            let innerJSON = {};
            innerJSON.title = category.charAt(0).toUpperCase() + category.slice(1);
            innerJSON.permissions = entirePermissions.filter(permission => permission.name.split(':')[0] === category);
            result.push(innerJSON);
        }
               
        return result;

    }
    
    // ANT DESIGN METHODS
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                setSearchInput(node);
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            </Space>
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible && searchInput) {
            setTimeout(() => searchInput.select(), 100);
        }
        },
        render: text =>
        searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });

    // ANT DESIGN METHODS
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);        
    };

    // ANT DESIGN METHODS
    const handleReset = clearFilters => {
        clearFilters();        
        setSearchText('');
    };

    // Table Columns Defined
    const defaultColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'            
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',            
            ...getColumnSearchProps('name')
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (is_active, record) => (                
                <Switch     
                    loading={load}
                    onChange={e => changeStatus({status: e?1:0}, record.id)}                              
                    className="mx-2"
                    size="small"                              
                    defaultChecked={is_active}
                    checked={is_active}
                />
            )
        },
        {
            title: 'Last Updated',
            dataIndex: 'modified_at',
            key: 'modified_at',
            render: modified_at => {
                return moment(modified_at).format('lll');
            }
        },
        {
            title: 'Modified By',
            dataIndex: 'modified_name',
            key: 'id'        
        },
        {
            title: 'Action',
            key: 'action',            
            render: (text, record) => (
                <Space size="middle">                    
                    <p                            
                        className="primary-color cursor-pointer"                                                   
                        onClick={() => openUpdateModal(true, record)}                     
                    ><EditOutlined />
                    </p>
                    {/* <Popconfirm                             
                        onConfirm={() => deleteIt(record.id)}
                        title="Are you sure?"                                     
                        okText={"Yes"}                                        
                        cancelText={"No"}
                    ><a><DeleteOutlined /></a> 
                    </Popconfirm>                     */}
                    {
                        utility === 'Roles'
                        ? <p  
                            className="primary-color cursor-pointer"                                                   
                            onClick={() => openAssignModal(true, record)}                     
                        ><UserAddOutlined /></p>
                        : null
                    }                    
                </Space>
            )
        }
    ];            

    // Table Columns Defined PERMISSIONS
    const columnsForPermissions = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'        
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',            
            ...getColumnSearchProps('name'),
            render: name => buildPermissionName(name)      
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: description => (
                <p className="text-xs text-gray-800 text-left px-1"
                >{description||'None'}</p>
            )
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (is_active, record) => (                
                <Switch     
                    key={record.id}
                    loading={load}
                    onChange={e => changeStatus({status: e?1:0}, record.id)}                              
                    className="mx-2"
                    size="small"                              
                    defaultChecked={is_active}
                    checked={is_active}
                />
            )
        },
        {
            title: 'Last Updated',
            dataIndex: 'modified_at',
            key: 'modified_at',
            render: modified_at => {
                return moment(modified_at).format('lll');
            }
        },
        {
            title: 'Modified By',
            dataIndex: 'modified_name',
            key: 'id'        
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <p
                        className="primary-color cursor-pointer"                                                   
                        onClick={() => openUpdateModal(true, record)}                   
                    ><EditOutlined />
                    </p>
                    {/* <Popconfirm 
                        onConfirm={() => deleteIt(record.id)}                       
                        title="Are you sure?"                                     
                        okText={"Yes"}                                        
                        cancelText={"No"}
                    ><a><DeleteOutlined /></a> 
                    </Popconfirm>                                                      */}
                </Space>
            )
        }
    ];    
    
    // Table Columns Defined REASONS
    const columnsForReasons = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'            
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',            
            ...getColumnSearchProps('name')
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (is_active, record) => (                
                <Switch     
                    loading={load}
                    onChange={e => changeStatus({status: e?1:0}, record.id)}                              
                    className="mx-2"
                    size="small"                              
                    defaultChecked={is_active}
                    checked={is_active}
                />
            )
        },
        {
            title: 'Entity',
            dataIndex: 'entity',
            key: 'entity',            
            filters: [
                { text: 'event', value: 'event' },
                { text: 'community', value: 'community' },
                { text: 'feed', value: 'feed' },
                { text: 'all', value: 'all' } 
            ],
            onFilter: (value, record) => record.entity === value
        },
        {
            title: 'Last Updated',
            dataIndex: 'modified_at',
            key: 'modified_at',
            render: modified_at => {
                return moment(modified_at).format('lll');
            }
        },
        {
            title: 'Modified By',
            dataIndex: 'modified_name',
            key: 'id'        
        },
        {
            title: 'Action',
            key: 'action',            
            render: (text, record) => (
                <Space size="middle">                    
                    <p                            
                        className="primary-color cursor-pointer"                                                   
                        onClick={() => openUpdateModal(true, record)}                     
                    ><EditOutlined />
                    </p>
                    {/* <Popconfirm                             
                        onConfirm={() => deleteIt(record.id)}
                        title="Are you sure?"                                     
                        okText={"Yes"}                                        
                        cancelText={"No"}
                    ><a><DeleteOutlined /></a> 
                    </Popconfirm>                     */}
                    {
                        utility === 'Roles'
                        ? <p  
                            className="primary-color cursor-pointer"                                                   
                            onClick={() => openAssignModal(true, record)}                     
                        ><UserAddOutlined /></p>
                        : null
                    }                    
                </Space>
            )
        }
    ];

    /**
     * Triggered from action button of the CrudModal
     * @param {boolean} flag 
     */
    const actionFromModal = (flag=false, action, payloadData) => {
        // CALLING RESPECTIVE FUNCTIONS
        switch(action) {
            case 'create': createItem(payloadData);
                            break;
            case 'update': updateItem(payloadData.id, payloadData.data);
                            break;
            default: setUpdateModalTrigger(false);
                    setAddModalTrigger(false);
                    setCurrentItem(null);
                    setRPModalTrigger(false);
        }       
    }

    const openUpdateModal = (flag, data) => {
        setCurrentItem(data);
        setUpdateModalTrigger(flag);
    }           

    const deleteIt = (id) => {          
        const info = Modal.info({
            title: 'Please Wait...',
            content: <Spin size="small" />,
            okButtonProps: {
                className: 'hidden'
            }
        });
        let result = deleteItem(id);            
        info.destroy();
    }        

    const openAssignModal = (flag, data=null) => {
        setCurrentItem(data);
        setRPModalTrigger(flag);
    }

    return (
        <div className="w-full h-full flex flex-col items-center">
            <div className="w-full flex items-center mb-4">
                <h1 className="px-4 font-bold text-lg text-black"
                >{utility}  
                </h1>
                <Button  
                    onClick={() => setAddModalTrigger(true)}                
                    type="primary"                    
                ><PlusOutlined/> Create
                </Button>              
            </div>
            {
                utility !== 'Permissions' && utility !== 'Community Permissions' ?
                (
                    <Table
                        pagination={{
                            pageSize: 6
                        }}
                        loading={dataStatus}
                        className="w-full py-4"
                        columns={utility==='Permissions' || utility==='Community Permissions'?columnsForPermissions:utility==='Reasons'?columnsForReasons:defaultColumns}
                        dataSource={data.sort((a,b) => new Date(b.modified_at) - new Date(a.modified_at))}
                    />
                )
                : (
                    <Tabs className="w-full px-4">
                        {
                            exisitingPermissions.map((ep, index) => (
                                <TabPane tab={ep.title} key={index}>
                                    <Table
                                        pagination={{
                                            pageSize: 6
                                        }}
                                        loading={dataStatus}
                                        className="py-4"
                                        columns={columnsForPermissions}
                                        dataSource={ep.permissions.sort((a,b) => new Date(b.modified_at) - new Date(a.modified_at))}
                                    />
                                </TabPane>
                            ))
                        }
                    </Tabs>                    
                )
            }            
            <CrudModal 
                utility={utility}                  
                type="create"             
                modalTitle={`Create a ${title}`}
                okLabel="Create"
                processStatus={load}
                modalTrigger={addModalTrigger}
                callTrigger={actionFromModal}
            />
            <CrudModal
                utility={utility}
                data={currentItem}
                type="update"
                modalTitle={`Update a ${title}`}
                okLabel="Save"
                processStatus={load}
                modalTrigger={updateModalTrigger}
                callTrigger={actionFromModal}
            />
            <RPModal                
                selectedRole={currentItem}                
                modalTrigger={rpModalTrigger}
                callTrigger={actionFromModal}
            />
        </div>
    )
}

export default CrudUI
