import assistanceIcon from '../assets/img/icon.png'
import moment from 'moment'
import apiInstance from '../api'

var CryptoJS = require("crypto-js")

/**
 * Used to create a clear breadcrumb format
 * data using the given data and the target category
 * @param {*} data 
 * @param {*} targetCategory 
 */
export const constructBreadCrumb = (data, targetCategory) => {
    
    let result = []; // result category array from target to its top most root

    for (var category in data) {

        if(data[category].id === parseInt(targetCategory)) {
            result.push(data[category].title); // target category
            if (data[category].parent_id !== null) {

                // concats the old result array with the current returned category array
                result = [...result, ...constructBreadCrumb(data, data[category].parent_id)];

            }
        }
    }

    return result;
}

/**
     * Used to construct the required tree format
     * that is supported by the tree UI from the raw data
     * @param {data} data 
     * @param {null} parent 
*/
export const generateTreeData = (data, parent) => {

        let result = []; // result nested array

        for (var item in data) { // iterating through each category

             // add an extra key to the object for React unique props
            // eslint-disable-next-line
            if (data[item].parent_id == parent) { // partially checking the parent_id of item and current parent matches

                data[item].key = data[item].id;
                // sub item run
                // recursive call with the current parent item
                var children = generateTreeData(data, data[item].id);

                if(children.length) { // check for parent item with non empty sub item 
                    data[item].children = children;
                } else data[item].children = [];

                // parent item append
                result.push(data[item]);

            }

        }

        return result;
}

/**
 * Used to add a key to the given data object
 * and return the same object in order to acheive
 * react unique prop
 * @param {*} data
 * @param {string} key
 */
export const addKeyToData = (data, key) => {
    // eslint-disable-next-line
    data.map(item => {
        item.key = item[key];
    });    

}

/**
 * Used to construct the required format [{key:value, key:value}]
 * inorder to perform some mapping operations
 * @param {*} data 
 * @param {string} parentData 
 */
export const constructJSONMap = (data, parentData, dataKey, parentKey) => {
    let result = [];
    // eslint-disable-next-line
    data.map(item => {
        let tempJSON = {};
        tempJSON[dataKey] = parseInt(item);
        tempJSON[parentKey] = parseInt(parentData);
        result.push(tempJSON);
    });

    return result;
}

/**
 * Used to validate the input email address
 */
export const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

/**
     * Used to build the permission required Name
     * format for viewing
     * @param {string} string 
     */
export const buildPermissionName = (string) => {
        let result = "";
        let stringArray = string.split(':');
        let firstWrd = stringArray[1].charAt(0).toUpperCase() + stringArray[1].slice(1);
        let secondWrd = stringArray[0].charAt(0).toUpperCase() + stringArray[0].slice(1);
        result = `${firstWrd} ${secondWrd}`;
        return result;
}

/**
 * Used to generate the reversed permission Name
 * like present in the db
 * @param {string} string 
 */
export const reversePermissionName = (string) => {
    let result = "";
    let stringArray = string.split(' ');
    let firstWrd = stringArray[1].charAt(0).toLowerCase() + stringArray[1].slice(1);
    let secondWrd = stringArray[0].charAt(0).toLowerCase() + stringArray[0].slice(1);
    result = `${firstWrd}:${secondWrd}`;
    return result;
}

/**
 * Used to split the url into different components
 * that are required and used whenever needed
 * @param {url} url 
 */
export const breakDownURL = (url) => {
    var domain = "",
        shortUrl = "",
        page = "";
    
        // removing the protocols
        if(url.indexOf("https://") === 0) url = url.substr(8);
        if(url.indexOf("http://") === 0) url = url.substr(7); 
        
        // removing the subdomains
        if(url.indexOf("www.") === 0) url = url.substr(4);

        domain = url.split('/')[0].split('.')[0];
        shortUrl = url.split('/')[0];

        if(url.split('/').length > 1) {
            page = url.split('/')[1].split('.')[0];
        }

        return { domain, page, shortUrl };
}

/**
 * Used to validate the given url 
 * is valid
 * @param {*} url 
 */
export const validateURL = (url) => {
    var elm;
    if(url) {
        if(!elm){
            elm = document.createElement('input');
            elm.setAttribute('type', 'url');
          }
          elm.value = url;
          return elm.validity.valid;
    } else return false;
}

/**
 * Used to modify the seo open graph tags in index.html
 * that updates corresponding to the current context
 * @param {*} data 
 */
export const injectOGTags = (data) => {

    document.querySelector("meta[property='og:title']").setAttribute("content", data?.title || 'Assistance = Helping others matter');
    document.querySelector("meta[property='og:description']").setAttribute("content", data?.description || "Provide a safe and secure platform for local communities where people connect to help each other by sharing information or providing assistance");
    document.querySelector("meta[property='og:image']").setAttribute("content", data?.image || assistanceIcon);    
    
    document.querySelector("meta[name='title']").setAttribute("content", data?.title || 'Assistance = Helping others matter');
    document.querySelector("meta[name='description']").setAttribute("content", data?.description || "Provide a safe and secure platform for local communities where people connect to help each other by sharing information or providing assistance");
    document.querySelector("meta[name='image']").setAttribute("content", data?.image || assistanceIcon);    
    
}

/**
 * Used to check the availability of a particular feed
 * based on the given from and to dates with today dates and
 * returns true or false
 * @param {date} from 
 * @param {date} to 
 */
export const checkAvailability = (from, to) => {        
    if (moment(from).isValid() && moment(to).isValid()) {
        // go green
        if (moment(from).isBefore(new Date()) && moment(to).isAfter(new Date())) {
            return true;
        } else if(moment(from).isSame(new Date())) {
            return true;
        } else {
            return false;
        }
    } else if (moment(from).isValid() && !moment(to).isValid()) {
        // go yellow
        if (moment(from).isAfter(new Date())) {
            return false;
        } else if (moment(from).isBefore(new Date())) {
            return true;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

/**
 * Used to request access token from server using refresh token
 */
export const requestAccessToken = (refreshToken) => {
    return new Promise((resolve, reject) => {
        apiInstance.post("/auth/token", { token: refreshToken })
        .then(response => {
            const accessToken = response.data.token;
            resolve(accessToken);
        })
        .catch(err => reject(err.data));
    });
}

/**
 * Permission Global UI Hook
 * If Current logged in user is authorized with provided
 * permissions return boolean value based on it
 */
export const usePermissions = (existingPermissions, userPermissions) => {
    if (existingPermissions !== '') {
        // CHECK FOR STRING OR ARRAY
        if (typeof existingPermissions === 'string') {
            existingPermissions = [existingPermissions];
        }
        // PERMISSION CHECK
        if (existingPermissions.length > 0) {
            if (userPermissions &&
                userPermissions.length &&
                existingPermissions.some(permission => userPermissions.indexOf(permission) >= 0)) {
                    return true; // CLEAR TO GO
                } else return false;
        } else return false;
    } else return false;
}

export const authorizeCheck = (userPermissions, permission) => {
    if (permission !== '') {
        // CHECK FOR STRING OR ARRAY
        if (typeof permission === 'string') {
            permission = [permission];
        }
        // PERMISSION CHECK
        if (permission.length > 0) {
            if (userPermissions &&
                userPermissions.length &&
                permission.some(p => userPermissions.indexOf(p) >= 0)) {
                    return true; // CLEAR TO GO
                } else return false;
        } else return false;
    } else return false;
}

/**
 * Used to genereate 2d canvas with username first letter
 * for profile photo mockup
 */
export const getProfileImage = (name, size=150, color='#080E33') => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = canvas.height = size;

    context.fillStyle = color;
    context.fillRect(0, 0, size, size);

    context.textAlign = "center";  
    context.font = "Bold 55px Arial";    
    context.fillStyle = "white";
    context.textBaseline = 'middle';
    context.textAlign = "center";
    context.fillText(letters(name), size / 2, size / 2);

    return canvas.toDataURL();
}

// return required letters via getProfileImage Hook
const letters = name => {
    var words = name.split(" ");
  
    if (words.length === 1) return words[0].split("")[0].toUpperCase();
    else if (words.length > 1) {
      return (
        words[0].split("")[0] + words[words.length - 1].split("")[0]
      ).toUpperCase();
    } else return;
};

/**
 * Used to generate an encrypted form for the given string data
 * @param {*} payload 
 */
export const encryptData = (payload) => {
    let encryptedString = "";

    encryptedString = CryptoJS.AES.encrypt(payload, 'assistance-key-for-authorization-problem').toString();

    return encryptedString;
}

/**
 * Used to generate an decrypted form for the given encrypted string
 * @param {*} payload 
 */
 export const decryptData = (payload) => {
    let decryptedString = "";

    let bytes = CryptoJS.AES.decrypt(payload, 'assistance-key-for-authorization-problem');
    decryptedString = bytes.toString(CryptoJS.enc.Utf8);

    return decryptedString;
}

/**
 * Used to include any type of entity to the sitemap which is modified directly
 * to the html file and it works only for production
 * @param {*} title 
 * @param {*} url 
 * @param {*} type 
 */
export const injectToSitemap = (title, url, type) => {
    if (process.env.REACT_APP_ENV !== 'production') {
        if (title && type && url) {
            apiInstance
                .put(`/sitemap/modify?type=${type}&title=${title}&url=${url}`)
                .then(response =>{
                    if (response.data?.status) {
                        console.log('included to sitemap`');
                    }
                })
                .catch(e => console.log(e?.response));
        }
    }
}