/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../assets/img/logo.png'

function Footer() {
    return (
        <div className="w-full flex flex-col items-center justify-center px-10 lg:px-32 py-10 bg-white shadow-sm border-t border-gray-300">
            <div className="w-full flex flex-row items-start justify-between mb-10">
                <Link 
                    to="/"
                    className="hidden lg:flex items-center">
                    <img   
                        alt="logo"                 
                        className="h-10 object-contain mx-1"
                        src={logo}
                    />                
                </Link>
                <div className="lg:w-1/2 flex flex-col lg:flex-row items-start justify-between">
                    <div className="w-full flex flex-col items-start">
                        <a href="#" className="text-md text-gray-800 py-2 px-10">Community</a>
                        <a href="#" className="text-md text-gray-800 py-2 px-10">Members</a>
                        <a href="#" className="text-md text-gray-800 py-2 px-10">Tasks</a>
                        <a href="#" className="text-md text-gray-800 py-2 px-10">Events</a>
                        <a href="#" className="text-md text-gray-800 py-2 px-10">Blogs</a>
                    </div>
                    <div className="w-full flex flex-col items-start">
                        <a href="#" className="text-md text-gray-800 py-2 px-10">Getting Started</a>
                        <a href="#" className="text-md text-gray-800 py-2 px-10">Sitemap</a>
                        <a href="#" className="text-md text-gray-800 py-2 px-10">Articles</a>
                    </div>
                    <div className="w-full flex flex-col items-start">
                        <a href="#" className="text-md text-gray-800 py-2 px-10">About Us</a>
                        <a href="#" className="text-md text-gray-800 py-2 px-10">Our Mission</a>
                        <a href="#" className="text-md text-gray-800 py-2 px-10">Contact Us</a>
                        <a href="#" className="text-md text-gray-800 py-2 px-10">Terms and Conditions</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
