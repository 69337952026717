/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import Header from '../../../components/User/v2/Header'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import './styles.css'
import { Button, Input, message, Select } from 'antd'
import { useDataLayerValue } from '../../../DataLayer'
import Footer from '../../../components/User/v2/Footer'
import apiInstance from '../../../api'
import SuccessModal from './SuccessModal'
import { useHistory, useLocation } from 'react-router'
import {Modal} from 'antd'

const { Option } = Select;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const MenuBar = ({ editor }) => {
    if (!editor) {
      return null
    }
  
    return (
      <div style={{ boxSizing: 'border-box'}} className="mb-10">
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          bold
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          italic
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? 'is-active' : ''}
        >
          strike
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCode().run()}
          className={editor.isActive('code') ? 'is-active' : ''}
        >
          code
        </button>
        <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
          clear marks
        </button>
        <button onClick={() => editor.chain().focus().clearNodes().run()}>
          clear nodes
        </button>
        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={editor.isActive('paragraph') ? 'is-active' : ''}
        >
          paragraph
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
        >
          h1
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
        >
          h2
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
        >
          h3
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
        >
          h4
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
          className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
        >
          h5
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
          className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
        >
          h6
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          bullet list
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          ordered list
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={editor.isActive('codeBlock') ? 'is-active' : ''}
        >
          code block
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive('blockquote') ? 'is-active' : ''}
        >
          blockquote
        </button>
        <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
          horizontal rule
        </button>
        <button onClick={() => editor.chain().focus().setHardBreak().run()}>
          hard break
        </button>
        <button onClick={() => editor.chain().focus().undo().run()}>
          undo
        </button>
        <button onClick={() => editor.chain().focus().redo().run()}>
          redo
        </button>
      </div>
    )
};

function Create() {

    const history = useHistory();
    let query = useQuery();

    document.title = "Create your customized blog - Assistance Org";

    const [{ categories, allFeedTypes, allKeywords, allKeywordsStatus }, dispatch] = useDataLayerValue();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState(null);
    const [urlKey, setUrlKey] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [image, setImage] = useState(null);
    const [urlKeyError, setUrlKeyError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [keywords, setKeywords] = useState([]);
    const [keywordStatus, setKeywordStatus] = useState(false);
    const [keywordsSelect, setKeywordsSelect] = useState([]);
    const [keywordsToAdd, setKeywordsToAdd] = useState([]);
    const [keywordsToRemove, setKeywordsToRemove] = useState([]);
    const [imageErrorText, setImageErrorText] = useState(null);
    const [previewModal, setPreviewModal] = useState(false);
    const [removeLoadingState, setRemoveLoadingState] = useState(false);
    const [tempPreviewImage, setTempPreviewImage] = useState(null);


    const [data, setData] = useState(null);

    useEffect(() => {
      if (title) {
        let key = title.toLowerCase().replace(/ /g, '-');
        setUrlKey(key);
      } else {
        setUrlKey('');
      }
    },[title]);

    useEffect(() => {
      if (urlKey) {

          // debounce fx
          const timer = setTimeout(() =>{
              checkUrlKey();
          }, 2000);  
          return () => clearTimeout(timer);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[urlKey]);

    const editor = useEditor({
        extensions: [
          StarterKit,
        ],
        content: `
        <h2>
          Hi there,
        </h2>
        <p>
          this is a <em>basic</em> template of <strong>content</strong>. Sure, there are all kind of basic text styles you’d probably expect from a text editor. But wait until you see the lists:
        </p>
        <ul>
          <li>
            That’s a bullet list with one …
          </li>
          <li>
            … or two list items.
          </li>
        </ul>
        <p>
          You can start editing your content
        </p>
        <blockquote>
          - Snug Share
        </blockquote>`,
    });

    useEffect(() => {
      const actionType = query.get("type");
      const receivedId = query.get("id");
      if (actionType === 'edit') {
          findFeedById(receivedId);
      }
  }, []); 

    async function checkUrlKey() {
      apiInstance
      .get(`/feed/urlkey/check?key=${urlKey}&id=${data?.id || null}`)
      .then(response => {
        if(response.data.status) {                                         
          if(response.data.data) {
              setUrlKeyError(false);
          } else {
              setUrlKeyError(true);
          }
        } else {            
          // window.location.replace('/fallback');
          console.log(response.data?.data||response?.status);            
        }
      })
      .catch(e => {        
        console.log(e);
        if(e.response || e.response?.data) {                          
            message.error(e.response.data.message);
            
        } else {
          // history.push('/');
        }
      });  
    }

    const handleSubmit = (type = null) => {
      if (!editor?.isEmpty && title && description && !urlKeyError && category) {
        const payload = new FormData();
        payload.append('title', title);
        payload.append('description', description);
        payload.append('categoryId', category);
        payload.append('urlKey', urlKey);
        payload.append('file', imageFile);
        payload.append('content', JSON.stringify(editor.getHTML()));
        payload.append("keywordsToAdd", JSON.stringify(keywordsToAdd||[]));
        payload.append("keywordsToRemove", JSON.stringify(keywordsToRemove||[]));
        payload.append("keywordsToCreate", JSON.stringify([]));
        payload.append('feedTypeId', allFeedTypes?.filter(i => i?.name === 'Internal Blog')[0]?.id);
        payload.append('image', image);
        setLoading(true);
        if (type === 'edit') {
          payload.append('id', data?.id);
          apiInstance
          .put(`/feed/updateAll`, payload)
          .then(response => {
            if (response.data.status) {
              message.success('Blog Updated Successfully');
              resetInputs();
              history.push(`/b/${urlKey}`);
            } else message.error('Something Went Wrong');
          })
          .catch(e => {
            message.error('Internal Server Error');
          })
          .finally(() => setLoading(false));
        } else {
          apiInstance
          .post('/feed', payload)
          .then(response => {
            if (response.data.status) {
              message.success('Blog Created Successfully');
              setShowSuccessModal(true);
            } else message.error('Something Went Wrong');
          })
          .catch(e => {
            message.error('Internal Server Error');
          })
          .finally(() => setLoading(false));
        }
      } else message.warn('Parameters Missing');
    };

    const handlePreviewImage = (e) => {
      e.preventDefault(); 
      if (imageFile) {
          const imageForPreview = URL.createObjectURL(imageFile);
          setTempPreviewImage(imageForPreview);
          setPreviewModal(true);
      } else {
          if (image) {
              setPreviewModal(true);
              const imagePath = process.env.REACT_APP_SERVER_URL+image;
              setTempPreviewImage(imagePath);
          } else {
              alert('choose a image');
          }
      }
  };

    const handleKeywordChange = (data) => {         
      setKeywordsSelect(data);
      let temp = allKeywords.map(({name})=>name);               
      let newKeywords = data.filter(keyword => !temp.includes(keyword));
      if(newKeywords.length) {
          setKeywordStatus(true);
          // CREATE NEW KEYWORD            
          let keywordData = { name: newKeywords[newKeywords.length-1]};            
          apiInstance.post("/keyword" , keywordData).then(response => {
              if(response.data.status) {                    
                  let newKeywordId = [response.data.data.insertId.toString()];                
                  setKeywordsToAdd([...newKeywordId,...keywordsToAdd]);                                        
                  dispatch({
                      type: 'SET_ALL_KEYWORDS_STATUS',
                      status: !allKeywordsStatus
                  });
                  setKeywordStatus(false);
              } else message.warning(response.data?.data || 'Something went wrong');
              setKeywordStatus(false);
          })
          .catch(e => {
              console.log(e);
              setKeywordStatus(false);                     
              message.error("Internal Server Error");
          });
                          
      }
      
      let exisitingKeywords = getIdByName(keywords, false);
      let correctedKeywords = getIdByName(data, true);
              
      setKeywordsToAdd(correctedKeywords.filter(keyword => !exisitingKeywords.includes(keyword)));                
      setKeywordsToRemove(exisitingKeywords.filter(keyword => !correctedKeywords.includes(keyword)));             
    };

    const getIdByName = (data, type) => {
        let result = [];
        // eslint-disable-next-line
        allKeywords.map(({ name, id }) => {
            if(type) {
            // eslint-disable-next-line
            data.map(i => {
                if(i === name) result.push(id.toString());
                });
            } else {
                // eslint-disable-next-line
                data.map((i) => {
                    if(i.keywords === name) result.push(id.toString());
                    });
            }
        });
        return result;
    };

    async function getKeywordsForAFeed(id) {
      setKeywords([]);
      setKeywordStatus(true);
      apiInstance.get(`/feed/keyword/${id}`)
      .then(response => 
      {
          if(response.data.status) {
              setKeywords(response.data.data); 
              setKeywordsSelect(Array.from(response.data.data.map(k => k.keywords)));
              setKeywordStatus(false);                
          } else message.warning(response.data?.data || 'something went wrong');
      })
      .catch(e => {
        message.error("Internal Server Error");
      });
    }

    async function findFeedById(id) {
      setLoading(true);
      apiInstance
      .get(`/feed/key/${id}`)    
      .then(response => {
          setData(response.data.data);
          getKeywordsForAFeed(response.data.data.id);
          setExistingData(response.data.data);
      })
      .catch(e => {
          console.log(e);
          message.error("Blog Not Found");
      })
      .finally(() => setLoading(false));
    }

    function setExistingData(row) {
      if (row) {
        setTitle(row?.title);
        setDescription(row?.description);
        setCategory(row?.category_id);
        setUrlKey(row?.url_key);
        setImage(row?.image);
      }
    }

    useEffect(() => {
      if (editor && data?.content) {
        // editor set Content
        editor.commands.setContent(data?.content?.slice(1, -1));
      }
    }, [editor, data]);

    const resetInputs = () => {
      setTitle('');
      setDescription('');
      setCategory(null);
      setImageFile(null);
      setImage('');
      setKeywords([]);
    };

    const handleRemoveImage = (e) => {
      e.preventDefault();
      Modal.warning({
          title: 'Are you sure to remove the image?',
          content: null,
          okText: "Remove",
          className: "apps",
          okButtonProps: {load: removeLoadingState, type: "danger", className: "px-5 text-md text-white rounded-md cursor-pointer mx-3"},
          onOk() {
              removeImageFromServer();
          }
      });
    };

    async function removeImageFromServer() {
        if (query.get('id')) {
            setRemoveLoadingState(true);
            const imageRelatedPayload = {
                image_path: image
            };
            apiInstance
            .put(`/feed/image_remove/${data?.id}`, imageRelatedPayload)
            .then(response => {
                if (response.data.status) {
                    setImage(null);
                    message.success('Image removed successfully');
                } else message.warning(response.data?.data || 'something went wrong');
            })
            .catch(e => {
                console.log(e);
                if(e.response || e.response?.data) {                
                if(typeof e.response.data.message === 'string') message.error(e.response.data.message);
                    if(e.response.status === 401 && e.response.status === 403) {
                        sessionStorage.clear();
                        window.location.replace("/signin");
                    }
                } else message.error("Internal Server Error");
            })
            .finally(() => {
                setRemoveLoadingState(false);
            });
        }
    };

    return (
        <>
            <Header />
            <div className="w-6/12 my-4 mx-auto flex flex-col items-start">
                <h1 className="w-full text-left font-bold text-2xl text-black py-1">
                    {query.get("type")==='edit'?'Update':'Create'} your blog
                </h1>
                <div className="w-full flex flex-col items-center my-1">
                    <label className="text-base font-medium text-black text-left w-full mb-3" for="name">
                        Title <span className="text-red-500">*</span>
                    </label>
                    <Input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)} 
                        type="text" 
                        id="name" 
                        className="w-full border shadow-sm border-gray-400 rounded-md p-3 outline-none"
                    />
                </div>
                <div className="w-full flex flex-col items-center my-1">
                    <label className="text-base font-medium text-black text-left w-full mb-3" for="description">
                        Short Description <span className="text-red-500">*</span>
                    </label>
                    <Input
                        value={description}
                        onChange={(e) => setDescription(e.target.value)} 
                        type="text" 
                        id="description" 
                        className="w-full border shadow-sm border-gray-400 rounded-md p-3 outline-none"
                    />
                </div>
            </div>
            <div className="w-6/12 my-10 mx-auto flex flex-col items-start">
                <MenuBar editor={editor} />
                <EditorContent editor={editor} />
            </div>
            <div className="w-6/12 my-4 mx-auto flex flex-col items-start">
                <div className="w-full flex flex-row items-center">
                    <div className="w-full flex flex-col items-center my-1 mr-2">
                        <label className="text-base font-medium text-black text-left w-full py-2" for="category">Category
                        <span className="text-red-500"> *</span></label>
                        <select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            id="category"
                            className="w-full border border-gray-400 rounded-md p-3 outline-none"  
                        >
                            <option value="0" selected disabled>Select Category</option>
                            {
                                categories?.map((item, idx) => (
                                    <option key={idx} value={item?.id}>{item?.title}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="w-full flex flex-col items-center my-1 ml-1">
                        <label className="text-base font-medium text-black text-left w-full mb-3" for="image">
                            Cover Image
                            {imageFile&&(<span onClick={(e) => handlePreviewImage(e)} className="text-sm hover:underline mx-2 cursor-pointer text-blue-400">Preview</span>)} 
                            {imageErrorText&&(<span className="text-sm text-red-500 mx-2">{imageErrorText}</span>)} 
                            {image && query.get('type') === 'edit' && (<span onClick={(e) => handlePreviewImage(e)} className="text-sm hover:underline mx-2 cursor-pointer text-blue-400">Preview</span>)}
                            {image && query.get('type') === 'edit' &&(<span onClick={(e) => handleRemoveImage(e)} className="hover:underline text-sm text-red-500 mx-2 cursor-pointer">Remove Image</span>)}
                        </label>
                        <Input
                            onChange={e => setImageFile(e.target.files[0])}
                            type="file" 
                            id="image" 
                            className="w-full border shadow-sm border-gray-400 rounded-md p-3 outline-none"
                        />
                    </div>
                </div>

                <div className="w-full flex flex-row items-center">
                  <div className="w-full flex flex-col items-center my-1 mr-2">
                    <label className="text-base font-medium text-black text-left w-full mb-3" for="urlkey">
                        Slug <span className="text-red-500">*</span>
                    </label>
                    <Input
                        disabled={!urlKeyError}
                        value={urlKey}
                        onChange={(e) => setUrlKey(e.target.value)} 
                        type="text" 
                        id="urlkey" 
                        className="w-full border shadow-sm border-gray-400 rounded-md p-3 outline-none"
                    />
                    {
                      urlKeyError && (
                        <p className="w-full text-left py-1 text-sm text-red-500">
                            Slug not available
                        </p>
                      )
                    }
                </div>
                
                  <div className="w-full flex flex-col items-center my-1 ml-1">
                    <label className="text-base font-medium text-black text-left w-full mb-3" for="urlkey">
                        Keywords <span className="text-red-500">*</span>
                    </label>
                      <Select                        
                          allowClear
                          style={{width: '100%'}}
                          mode="tags"  
                          notFoundContent="No Keywords Create one"                                              
                          placeholder="Add Keywords"
                          value={keywordsSelect}
                          onChange={handleKeywordChange}
                          className="w-full rounded-md p-3 event__select outline-none"
                      >        
                          {
                              allKeywords.length &&
                              allKeywords.map(keyword => (
                                  <Option key={keyword.name}>{keyword.name}</Option>
                              ))                
                          }        
                      </Select> 
                </div>
                </div>

                <div className="w-full flex flex-row items-center">
                  <Button
                      loading={loading}
                      onClick={() => handleSubmit(query.get("type")==='edit'?'edit':null)}
                      disabled={urlKeyError || editor?.isEmpty || !title || !description || !category}
                      type="primary"
                      className="px-5 text-base text-white my-4 primary-bg rounded-sm cursor-pointer outline-none focus:outline-none"
                      style={{height: 40}}              
                  >{query.get("type")==='edit'?'Save':'Submit'}
                  </Button>
                  <Button
                    onClick={() => history.push('/home')}
                    type="secondary"
                    className="px-5 text-base my-4 rounded-sm cursor-pointer outline-none focus:outline-none"
                    style={{height: 40, marginLeft: 10}}              
                >Cancel
                </Button>
                </div>

            </div>
            <Footer />
            {
              showSuccessModal && (
                <SuccessModal
                  modalTitle="Your Blog is Created"
                  callTrigger={() => resetInputs()}
                  trigger={showSuccessModal}
                  urlKey={urlKey}
                />
              )
            }
            <Modal
              visible={previewModal}
              title="Preview Image"
              footer={null}
              className="apps"
              onCancel={() => setPreviewModal(false)}
              destroyOnClose={true}
              >
                  <div className="p-3">
                      <img
                      src={tempPreviewImage}
                      className="object-cover h-40 w-full"
                      alt="preview"
                      />
                  </div>
            </Modal>
        </>
    )
}

export default Create
