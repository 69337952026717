/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react'
import Header from '../../components/User/v2/Header'
import { Link } from 'react-router-dom'
import mainImage from '../../assets/img/main.png'
import Footer from '../../components/User/v2/Footer';
import calendar from '../../assets/img/calendar.png'
import people from '../../assets/img/people-circle-sharp.png'
import doumentIcon from '../../assets/img/document-text.png'
import mapIcon from '../../assets/img/mapIcon.svg'
import workIcon from '../../assets/img/workIcon.svg'
import joinIcon from '../../assets/img/joinIcon.svg'
import { Button, Input, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import MainSlider from '../../components/User/v2/MainSlider'
import apiInstance from '../../api';
import mockupImage from '../../assets/img/mockup.png';
import playStore from '../../assets/img/playstore.png';
import appStore from '../../assets/img/appstore.png';
import logo from '../../assets/img/icon.png';
import peopleIcon from '../../assets/img/people.png';
import eventIcon from '../../assets/img/event.png';
import heroImage from '../../assets/img/hero-1.jpg';
import heroImage1 from '../../assets/img/hero-2.jpg';
import how1 from '../../assets/img/how-1.jpg';
import how2 from '../../assets/img/how-2.jpg';
import how3 from '../../assets/img/how-3.jpg';
import screen1 from '../../assets/img/screen-1.png';
import screen10 from '../../assets/img/screen-1-0.png';

function LandingPage() {

    // useEffect(() => document.title = 'Assistance.org - Helping Others Matters', []);

    const [events, setEvents] = useState([]);
    const [communities, setCommunities] = useState([]);

    async function getAllEvents() {
        apiInstance
        .get('/event/secure')
        .then(response => {
            if (response.data.status) {
                setEvents(response.data.data);
            } else message.warning(response.data?.data || 'something went wrong');
        }).catch(e => {
            console.log(e);
        });
    }

    async function getAllCommunities() {
        apiInstance
        .get('/organization/secure')
        .then(response => {
            if (response.data.status) {
                setCommunities(response.data.data);
            } else message.warning(response.data?.data || 'something went wrong');
        }).catch(e => {
            console.log(e);
        });
    }

    useEffect(() => { 
        getAllEvents();
        getAllCommunities();
    },[]);

    return (
        <div>
            <Header />
            <div className="w-full bg-white" style={{minHeight: 600}}>
                <div className="w-full lg:w-9/12 mx-auto flex flex-col lg:flex-row items-center justify-between">
                    <div className="flex flex-col items-center lg:items-start py-20">
                        <h1 style={{lineHeight: 1.2}} className="text-3xl lg:text-6xl text-black font-medium text-center lg:text-left">
                            One place. <br/>
                            For everyone.
                        </h1>
                        <p className="py-2 w-full lg:w-8/12 text-xl mt-4 text-gray-800 text-center lg:text-left">
                        Join a group to meet people, make friends, find support, grow a business, and explore your interests. Thousands of events are happening every day, both online and in person!
                        </p>
                        <a 
                            href="/signin"
                            className="outline-none focus:outline-none hover:bg-gray-200 primary-bg text-lg text-white rounded-sm my-3 shadow py-3 px-4 font-medium">
                            Try Assistance
                        </a>
                    </div>
                    <div className="w-9/12 py-8">
                        <img
                            className="hero__image"
                            src={heroImage}
                            alt="hero_image"
                        />
                    </div>
                    {/* <div className="w-9/12 grid grid-cols-2 gap-2">
                        <a 
                            href="/community"
                            className="cursor-pointer home__card w-full flex flex-col items-start rounded-md bg-black py-5 px-4">
                            <img
                                src={peopleIcon}
                                className="object-contain h-20 mb-2"
                                alt="icon"
                            />
                            <h2 className="w-full font-bold whitespace-nowrap text-lg text-white text-black text-left py-2">
                                Create Community
                            </h2>
                            <p className="text-left text-white text-sm py-2">
                                A group to manage things
                            </p>
                        </a>
                        <a 
                            href="/event"
                            className="cursor-pointer home__card w-full flex flex-col items-start rounded-md bg-black py-5 px-4">
                            <img
                                src={eventIcon}
                                className="object-contain h-20 mb-2"
                                alt="icon"
                            />
                            <h2 className="w-full font-bold text-lg text-white text-black text-left py-2">
                                Host Event
                            </h2>
                            <p className="w-full text-left text-white text-sm py-2">
                                A time to experience
                            </p>
                        </a>
                    </div> */}
                </div>
            </div>
            <div className="w-full lg:w-9/12 mx-auto py-10 bg-white flex flex-col items-center">
                <h1 className="w-full text-center text-5xl font-medium text-black py-2">
                    How Assistance works
                </h1>
                <p className="w-6/12 mx-auto text-lg text-gray-600 text-center py-1">
                    Meet new people who share your interests through online and in-person events. It’s free to create an account.
                </p>
                <a 
                    href="/signin"
                    className="outline-none hover:text-white focus:outline-none hover:bg-blue-400 bg-blue-500 text-lg text-white rounded-sm my-3 shadow py-3 px-4 font-medium">
                    Join Assistance
                </a>
                <div className="w-full lg:w-10/12 mx-auto flex flex-col items-center py-20">
                    <div className="w-full flex flex-row items-start my-8">
                        <img
                            src={how1}
                            alt="how image"
                            className="hero__2"
                        />
                        <div className="w-1/2 px-10 flex flex-col items-start">
                            <h5 className="text-left w-full text-black font-medium text-3xl py-2">
                                Find a community
                            </h5>
                            <p className="w-full mx-auto text-lg text-gray-800 text-left py-1">
                                Do what you love, meet others who love it, find your community. The rest is history!
                            </p>
                            <a 
                                href="/community"
                                className="outline-none hover:text-white focus:outline-none hover:bg-blue-400 bg-blue-500 text-lg text-white rounded-sm my-3 shadow py-3 px-4 font-medium">
                                Explore More
                            </a>
                        </div>
                    </div>
                    <div className="w-full flex flex-row-reverse items-start my-8">
                        <img
                            src={how2}
                            alt="how image"
                            className="hero__2"
                        />
                        <div className="w-1/2 px-10 flex flex-col items-start">
                            <h5 className="text-left w-full text-black font-medium text-3xl py-2">
                                Hosting an Event
                            </h5>
                            <p className="w-full mx-auto text-lg text-gray-800 text-left py-1">
                                Do what you love, meet others who love it, find your community. The rest is history!
                            </p>
                        </div>
                    </div>
                    <div className="w-full flex flex-row items-start my-8">
                        <img
                            src={how3}
                            alt="how image"
                            className="hero__2"
                        />
                        <div className="w-1/2 px-10 flex flex-col items-start">
                            <h5 className="text-left w-full text-black font-medium text-3xl py-2">
                                Manage Your Community
                            </h5>
                            <p className="w-full mx-auto text-lg text-gray-800 text-left py-1">
                                Do what you love, meet others who love it, find your community. The rest is history!
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="w-full grid grid-cols-3 gap-8">
                    <div className="p-4 flex flex-col items-center justify-center">
                        <img
                            src={mapIcon}
                            className="object-contain h-40 mb-8"
                            alt="icon"
                        />
                        <h5 className="text-center w-full text-black font-medium text-xl py-2">
                            Find a community
                        </h5>
                        <p className="w-full mx-auto text-md text-gray-600 text-center py-1">
                            Do what you love, meet others who love it, find your community. The rest is history!
                        </p>
                    </div>
                    <div className="p-4 flex flex-col items-center justify-center">
                        <img
                            src={joinIcon}
                            className="object-contain h-40 mb-8"
                            alt="icon"
                        />
                        <h5 className="text-center w-full text-black font-medium text-xl py-2">
                            Host Event
                        </h5>
                        <p className="w-full mx-auto text-md text-gray-600 text-center py-1">
                            Do what you love, meet others who love it, find your community. The rest is history!
                        </p>
                    </div>
                    <div className="p-4 flex flex-col items-center justify-center">
                        <img
                            src={workIcon}
                            className="object-contain h-40 mb-8"
                            alt="icon"
                        />
                        <h5 className="text-center w-full text-black font-medium text-xl py-2">
                            Manage Your Community
                        </h5>
                        <p className="w-full mx-auto text-md text-gray-600 text-center py-1">
                            Do what you love, meet others who love it, find your community. The rest is history!
                        </p>
                    </div>
                </div> */}
            </div>
            <div className="w-full lg:w-9/12 mx-auto py-10 bg-white">
                {
                    events?.length && (
                        <MainSlider 
                            showCreateButton={false}
                            title="Upcoming Events"
                            data={events}
                        />
                    )
                }
            </div>
            
            <div className="w-full lg:w-9/12 mx-auto py-10 bg-white flex flex-col items-center">
                <h1 className="w-full text-center text-5xl font-medium text-black py-2">
                    Communities at your finger tip
                </h1>
                <p className="w-6/12 mx-auto text-lg text-gray-600 text-center py-1">
                    Meet new people who share your interests through online and in-person events. It’s free to create an account.
                </p>
                <a 
                    href="/signin"
                    className="outline-none hover:text-white focus:outline-none hover:bg-blue-400 bg-blue-500 text-lg text-white rounded-sm my-3 shadow py-3 px-4 font-medium">
                    Start your community
                </a>
                <div className="w-full flex flex-row items-center justify-center py-4">
                    <img
                        src={screen1}
                        className="screen_hero"
                        alt="screen"
                    />
                </div>
            </div>
            <div className="w-full lg:w-9/12 mx-auto py-4 bg-white">
                {
                    communities?.length && (
                        <MainSlider 
                            type="community"
                            showCreateButton={false}
                            title="Popular Communities"
                            data={communities}  
                            isImage={false}                          
                        />
                    )
                }
            </div>
            <div className="w-full bg-white" style={{minHeight: 500}}>
                <div className="w-full lg:w-9/12 mx-auto flex flex-row items-center">
                    <img
                        alt="mockup"
                        src={mockupImage}
                        className="object-contain mockupImage"
                    />
                    <div className="px-8 flex flex-col items-start">
                        <h1 className="w-full text-left text-4xl font-medium text-black py-2">
                            Get Snug Share now in mobiles
                        </h1>
                        <p className="w-full mx-auto text-lg text-gray-600 text-left py-1">
                            Meet new people who share your interests through online and in-person events. It’s free to create an account.
                        </p>
                        <div className="flex flex-row items-center py-2">
                        {/* <div className="w-24 h-24 rounded-lg border-2 shadow-sm px-2 flex items-center justify-center bg-white">
                            <img
                                alt="logo"
                                src={logo}
                                className="object-contain h-20 rounded-lg"
                            />
                        </div> */}
                        <div className="w-full flex flex-row items-center py-2">
                            <img
                                alt="logo"
                                src={playStore}
                                className="object-contain h-16"
                            />
                            <img
                                alt="logo"
                                src={appStore}
                                className="object-contain ml-3 h-16"
                            />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default LandingPage
