/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useState, useEffect } from 'react'
import Banner from '../../../components/User/v2/Banner'
import Footer from '../../../components/User/v2/Footer'
import Header from '../../../components/User/v2/Header'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Input, message, Button, Modal, Breadcrumb, Select } from 'antd'
import moment from 'moment'
import apiInstance from '../../../api/'
import {useDataLayerValue} from '../../../DataLayer'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState } from 'draft-js'
import { convertFromRaw, convertToRaw } from 'draft-js'
import {injectToSitemap} from '../../../utils/Helper'

const {Option} = Select

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const descriptionContent = {"entityMap":{},"blocks":[]};

function CreateCommunity() {
    let query = useQuery();

    document.title = "Your own customized community - Assistance Org";

    const [{user, allAddressTypes, token, communityCategories}] = useDataLayerValue();

    useEffect(() => {
        if(!token) {
            // window.location.replace("/signin");
        }
     
    
    }, [token]);

    useEffect(() => {
        getDumpLocationData();
        getDumpTimeZoneData();
    }, []);

    const history = useHistory();

    const [descriptionState, setDescriptionState] = useState(EditorState.createEmpty());
    const [descriptionContent, setDescriptionContent] = useState(null);
    const [urlKeyError, setUrlKeyError] = useState(false);
    const [dumpCountries, setDumpCountries] = useState([]);
    const [dumpStates, setDumpStates] = useState([]);


    const [id, setId] = useState(null);
    const [files, setFiles] = useState(null);
    const [previewModal, setPreviewModal] = useState(false);
    const [tempPreviewImage, setTempPreviewImage] = useState(null);
    const [image, setImage] = useState(null);
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [urlKey, setUrlKey] = useState('');
    const [load, setLoad] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [contactName, setContactName] = useState('');
    const [communityType, setCommunityType] = useState('');
    const [agree, setAgree] = useState(false);
    const [fullAddress, setFullAddress] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [isOwned, setIsOwned] = useState(false);
    const [selectedType, setSelectedType] = useState('type');
    const [imageErrorText, setImageErrorText] = useState(null);
    const [otherCategory, setOtherCategory] = useState(null);
    const [errorText, setErrorText] = useState(null);
    const [data, setData] = useState(null);
    const [loadingState, setLoadingState] = useState(false);
    const [dumpTimeZones, setDumpTimeZones] = useState([]);
    const [selectedTimeZone ,setSelectedTimeZone] = useState(null);
    const [visibility, setVisibility] = useState(null);

    const [steps, setSteps] = useState(1);

    useEffect(() => {
        if (title) {
            const _urlKey = title.toLowerCase().replace(/ /g, '-');
            setUrlKey(_urlKey);
        } else {
            setUrlKey('');
        }
    }, [title]);

    async function findOrgById(key) {
        setLoadingState(true);
        apiInstance
        .get(`/organization/${key}`)
        .then(response => {
            if (response.data.status) {
                document.title = `${response.data.data?.name} - Assistance Org`;
                setExistingData(response.data.data);
                setData(response.data.data?.id);
                // getAllInvitationsByOrganization(response.data.data.org_id);
                if (response.data.data?.description) {
                    setDescriptionContent(JSON.parse(response.data.data.description));
                }
            } else {
                message.warning(response.data?.message || 'something went wrong');
                history.push('/oops');
            }
        })
        .catch(e => {
            if(e.response?.status === 404) {
                history.push('/oops');
            }
            console.log(e);
        }).finally(() => {
            setLoadingState(false);
        });
    }

    async function getDumpTimeZoneData() {
        apiInstance
        .get('/shared/timezone')
        .then(response => {
            if (response.data) {
                setDumpTimeZones(response.data);
            }
        })
        .catch(e => console.log(e.response));
    }
    
    useEffect(() => {
        const actionType = query.get("type");
        const receivedId = query.get("id");
        if (actionType === 'edit') {
            findOrgById(receivedId);
        }
    }, []); 

    async function getDumpLocationData() {
        apiInstance
        .get('/shared/country')
        .then(response => {
            if (response.data) {
                setDumpCountries(response.data);
                const tempStates = response.data[0]?.states;        
                setDumpStates(tempStates);
            }
        })
        .catch(e => console.log(e.response));
    }

    const onEditorChange = (eState, type) => {
        const tempState = eState.getCurrentContent();
        if (type === 'description') {
            setDescriptionContent(tempState);
            setDescriptionState(eState);
        } 
        // else if (type === 'terms') {
        //     setTermsContent(tempState);
        //     setTermsState(eState);
        // } else if (type === 'faq') {
        //     setFaqsContent(tempState);
        //     setFaqsState(eState);
        // }
    };

    const handleCountryChange = (e) => {
        let sCountry = e.target.value;
        setSelectedCountry(sCountry);
        const tempStates = dumpCountries?.filter(e => e.country === sCountry)[0]?.states;        
        setDumpStates(tempStates);
    }

    const onSubmit = (type='create') => {
        const communityPayload = new FormData();
        communityPayload.append("name", title);
        communityPayload.append("description", descriptionContent ? JSON.stringify(convertToRaw(descriptionContent)) : null);
        communityPayload.append("url", url);
        communityPayload.append("url_key", urlKey);
        communityPayload.append("email", email);
        communityPayload.append("phone", phone);
        communityPayload.append("contact_name", contactName);
        communityPayload.append("orgType", selectedType);
        communityPayload.append("owned", isOwned);
        communityPayload.append("userip", null);
        communityPayload.append("userid", user?.id);
        communityPayload.append("username", user?.username);
        communityPayload.append("time_zone", selectedTimeZone);
        communityPayload.append("other_category", otherCategory);
        communityPayload.append("line1", fullAddress);
        communityPayload.append("city", city);
        communityPayload.append("states", selectedState);
        communityPayload.append("country", selectedCountry);
        communityPayload.append("isPublic", visibility);
        communityPayload.append("zip", zip);  
        
        if (files) {
            communityPayload.append("file", files);
        }

        let oldStep = steps;
        if (oldStep === 3) {
            if (!fullAddress || !selectedCountry || !selectedState || !city || !zip) {
                setErrorText(true);
            } else {
                if (agree) {
                if (type === 'create') {
                    setLoad(true);
                    apiInstance
                    .post("/organization/", communityPayload)
                    .then(response => {
                        setLoad(false);                    
                        message.success('Organization Created');
                        history.push(`/${urlKey}`);
                    }).catch(e => {
                        setLoad(false);
                        console.log(e);
                        if(e.response || e.response?.data) {                                    
                            if(e.response.status === 401) {
                                alert('Your Session is expired!');
                                sessionStorage.clear();                            
                                window.location.replace("/signin");                                     
                            } else {
                                if(typeof e.response?.data?.message === 'string') {
                                    message.error(e.response.data.message);                                      
                                } else {
                                    message.warn("Something went wrong");
                                }                        
                            }
                        } else message.error("Internal Server Error");  
                    });
                } else {
                    updateACommunity(communityPayload);
                } 
            } else {
                if(type==='edit') {
                    updateACommunity(communityPayload);
                } else {
                    alert('Agree to Terms and Conditions');
                }
            }
            }
        }
        
    }

    function updateACommunity(payload) {
        setLoad(true);
        apiInstance
        .put(`/organization/${data}`, payload)
        .then(response => {
            setLoad(false);                    
            message.success('Organization Updated');
            history.push(`/${urlKey}`);
        }).catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);                                      
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error");  
        });
    }

    function setExistingData(data) {
        if (data) {
            setData(data?.id || null);
            setTitle(data?.name || '');
            setUrlKey(data?.url_key || '');
            setSelectedType(data?.orgtype || null);
            setDescriptionState(data?.description?EditorState.createWithContent(convertFromRaw(JSON.parse(data.description))):EditorState.createEmpty());
            setDescriptionContent(data?.description?convertFromRaw(JSON.parse(data?.description)):null);
            setOtherCategory(data?.other_category || '');
            setImage(data?.logo || null);
            setEmail(data?.email || '');
            setUrl(data?.url || '');
            setPhone(data?.phone || '');
            setContactName(data?.contact_name || '');
            setFullAddress(data?.line_1 || '');
            setSelectedCountry(data?.country || '');
            setSelectedState(data?.state || '');
            setCity(data?.city || '');
            setZip(data?.zipcode || '');
            setSelectedTimeZone(data?.time_zone || null);
            setVisibility(data?.isPublic);
        }
    }

    const allowMeToCreate = visibility !== null && title && url && urlKey && email && selectedType && fullAddress && zip && city && imageErrorText===null && urlKeyError===false && selectedTimeZone;

    const isValidImageSize = (imageFile) => {
        const MAX_IMG_SIZE = 1000000; //1MB max file(s) size
        if (imageFile.size > MAX_IMG_SIZE) {
            return false;
        } else return true;
    };

    const isValidImageFormat = (imageFile) => {
        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
            return false;
        } else return true;
    };

    const imageOnChange = (e) => {
        const imageFile = e.target.files[0];
        if (imageFile) {
            if (isValidImageFormat(imageFile)) {
                setImageErrorText(null);
                if (isValidImageSize(imageFile)) {
                    setImageErrorText(null);
                    setFiles(imageFile);
                } else {
                    // wrong size
                    setImageErrorText('(Maximum 1MB size is allowed)');
                }
            } else {
                // wrong format
                setImageErrorText('(Only JPEG, PNG, JPG is accepted)');
            }
        }
    };

    const handlePreviewImage = (e) => {
        e.preventDefault(); 
        if (files) {
            const imageForPreview = URL.createObjectURL(files);
            setTempPreviewImage(imageForPreview);
            setPreviewModal(true);
        } else {
            if (image) {
                setPreviewModal(true);
                const imagePath = process.env.REACT_APP_SERVER_URL+image;
                setTempPreviewImage(imagePath);
            } else {
                alert('choose a image');
            }
        }
    };

    async function checkUrlKey() {
        apiInstance
        .get(`/orgnization/urlkey/check?key=${urlKey}&id=${id || null}`)
        .then(response => {
          if(response.data.status) {                                         
            if(response.data.data) {
                setUrlKeyError(false);
            } else {
                setUrlKeyError(true);
            }
          } else {            
            // window.location.replace('/fallback');
            console.log(response.data?.data||response?.status);            
          }
        })
        .catch(e => {        
          console.log(e);
          if(e.response || e.response?.data) {                          
              // message.error(e.response.data.message);
              if(e.response.status === 401) {
                  sessionStorage.clear();
                  window.location.replace("/admin");
              }
          } else {
            // history.push('/');
          }
        });  
    }

    useEffect(() => {
        if (urlKey) {

            // debounce fx
            const timer = setTimeout(() =>{
                checkUrlKey();
            }, 2000);  
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[urlKey]);

    const stepHeadings = {
        1: 'General Information',
        2: 'Other Information',
        3: 'Location Information'
    }

    const getStepHeading = (index) => {
        return stepHeadings[index];
    };

    const handleNextStep = () => {
        let oldStep = steps;
        if (oldStep === 1) {
            if(!title || !urlKey || !selectedType || !descriptionContent) {
                setErrorText(true);
            } else {
                if (oldStep <= 3) {
                    oldStep++;
                    setSteps(oldStep);
                }       
            }
        } else if (oldStep === 2) {
            if (!email || !url || !contactName) {
                setErrorText(true);
            } else {
                if (oldStep <= 3) {
                    oldStep++;
                    setSteps(oldStep);
                }
            }
        }
    };

    const handlePreviousStep = () => {
        let oldStep = steps;
        if (oldStep > 1) {
            oldStep--;
            setSteps(oldStep);
        }
    };

    return (
        <>
            <Header />
            {
                loadingState ? (
                    <div className="w-full main__preLoaderContainer flex items-center justify-center">
                        <img 
                            className="main__preLoader"
                            src="./logo192.png"
                            alt="preloader"
                        />
                    </div>
                ) : (

                <div className="w-8/12 mx-auto flex flex-col items-start mb-10 py-10">

                    <div className="w-full mb-6">
                            <Breadcrumb.Item      
                                href={`/home`}
                                key="home"
                                separator={'>'}
                            >Home
                            </Breadcrumb.Item>
                            <Breadcrumb.Item      
                                href={`/community`}
                                key="community"
                                separator={'>'}
                            >Community
                            </Breadcrumb.Item>
                            <Breadcrumb.Item      
                                href={`/community/create`}
                                key="community-create"
                                separator={''}
                            >{query.get('type') === 'edit' ? 'Edit' : 'Create'} Community
                            </Breadcrumb.Item>
                    </div>

                        <div className="border shadow w-full flex flex-col items-start rounded-md">
                            <div className="font-medium text-base w-full bg-blue-500 text-white text-center p-2 rounded-t-md">
                                {getStepHeading(steps)}
                            </div>
                            
                            {/* STEP 01 */}
                            {
                                steps === 1 && (
                                    <div 
                                        className="p-4 w-full flex flex-col items-start">
                                        <div className="w-full flex flex-col items-center my-1">
                                            <label className="text-base font-medium text-black text-left w-full mb-3" for="name">
                                                Your community name <span className="text-red-500">*</span>
                                            </label>
                                            <Input
                                                onFocus={() => setErrorText(null)}
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)} 
                                                type="text" 
                                                id="name" 
                                                className="w-full border shadow-sm border-gray-400 rounded-md p-3 outline-none"
                                            />
                                            {
                                                !title && errorText && (
                                                    <p className="w-full text-left py-1 text-sm text-red-500">
                                                        Provide the community name
                                                    </p>
                                                )
                                            }
                                        </div>
            
                                        <div className="w-full flex flex-row items-center my-1">
                                            <div 
                                                className="w-full flex flex-col items-center my-1 mr-2">
                                                <label className="text-base font-medium text-black text-left w-full mb-3" for="urlkey">
                                                    Customized url slug <span className="text-red-500">*</span>
                                                </label>
                                                <Input
                                                    onFocus={() => setErrorText(null)}
                                                    value={urlKey}
                                                    onChange={(e) => setUrlKey(e.target.value)} 
                                                    type="text" 
                                                    id="urlkey" 
                                                    className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                                />
                                                {
                                                    urlKeyError && (
                                                        <p className="w-full text-sm text-red-400 font-medium">
                                                            Url is not available
                                                        </p>
                                                    )
                                                }
                                                {
                                                    !urlKey && errorText && (
                                                        <p className="w-full text-left py-1 text-sm text-red-500">
                                                            Provide the url slug
                                                        </p>
                                                    ) 
                                                }
                                            </div>
                                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                                <label className="text-base font-medium text-black text-left w-full py-2" for="type">Community Type <span className="text-red-500">*</span></label>
                                                <select
                                                    onFocus={() => setErrorText(null)}
                                                    className="w-full shadow-sm border border-gray-400 rounded-md p-3 outline-none"  
                                                    value={selectedType}
                                                    onChange={(e) => setSelectedType(e.target.value)}
                                                >        
                                                    <option value="type" disabled>Select Category</option>
                                                    {
                                                        communityCategories?.map(cc => (
                                                            <option value={cc.id}>{cc.title}</option>
                                                        ))
                                                    }
                                                </select> 
                                                {
                                                    selectedType === 'type' && errorText && (
                                                        <p className="w-full text-left py-1 text-sm text-red-500">
                                                            Select the community category
                                                        </p> 
                                                    )
                                                }
                                            </div>
                                        </div>
                                        
                                        {
                                            selectedType==="2" && (
                                            <div 
                                                className="w-full flex flex-col items-center my-1 mr-2">
                                                <label className="text-base font-medium text-black text-left w-full py-2" for="othertype">Other Community Type</label>
                                                <Input 
                                                    onFocus={() => setErrorText(null)}
                                                    id="othertype"
                                                    value={otherCategory}
                                                    onChange={(e) => setOtherCategory(e.target.value)} 
                                                    type="text" className="w-full shadow-sm border border-gray-400 rounded-md p-3 outline-none"/>
                                                {
                                                    !otherCategory && errorText && (
                                                        <p className="w-full text-left py-1 text-sm text-red-500">
                                                            Provide the community category
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        )}
            
                                        <div className="w-full flex flex-col items-center my-1">
                                            <label className="text-base font-medium text-black text-left w-full mb-3" for="description">
                                                Detailed description <span className="text-red-500">*</span>
                                            </label>
                                            <Editor
                                                onFocus={() => setErrorText(null)}
                                                editorState={descriptionState}
                                                toolbarClassName="w-full border rounded-md-tr rounded-md-tl"
                                                wrapperClassName="w-full border rounded-md"
                                                editorClassName="w-full border rounded-md-br rounded-md-bl"
                                                onEditorStateChange={e => onEditorChange(e, 'description')}
                                                toolbar={{
                                                    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                                                    inline: { inDropdown: false },
                                                    list: { inDropdown: true },
                                                    textAlign: { inDropdown: false },
                                                    link: { inDropdown: true },
                                                }}
                                            />
                                            {
                                                !descriptionContent && errorText && (
                                                    <p className="w-full text-left py-1 text-sm text-red-500">
                                                        Provide the community description
                                                    </p>
                                                )
                                            }
                                        </div>
                                    </div>       
                                )
                            }

                            {/* STEP 02 */}
                            {
                                steps === 2 && (
                                    <div 
                                        className="p-4 w-full flex flex-col items-start">
                                        
                                        <div className="w-full flex flex-row items-center my-1">
                                            <div className="w-full flex flex-col items-center my-1 mr-2">
                                                <label className="text-base font-medium text-black text-left w-full mb-3" for="image">Logo 
                                                    {files&&(<span onClick={(e) => handlePreviewImage(e)} className="text-sm hover:underline mx-2 cursor-pointer text-blue-400">Preview</span>)} 
                                                    {imageErrorText&&(<span className="text-sm text-red-500 mx-2">{imageErrorText}</span>)} 
                                                    {/* {image && query.get('type') === 'edit' && (<span onClick={(e) => handlePreviewImage(e)} className="text-sm hover:underline mx-2 cursor-pointer text-blue-400">Preview</span>)}
                                                    {image && query.get('type') === 'edit' &&(<span onClick={(e) => handleRemoveImage(e)} className="hover:underline text-sm text-red-500 mx-2 cursor-pointer">Remove Image</span>)} */}
                                                </label>
                                                <Input 
                                                    onChange={(e) => imageOnChange(e)} 
                                                    type="file" id="image" className="w-full border border-gray-400 rounded-md p-2 outline-none"/>
                                            </div>
                                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                                <label className="text-base font-medium text-black text-left w-full mb-3" for="name">
                                                    Email address <span className="text-red-500">*</span>
                                                </label>
                                                <Input
                                                    onFocus={() => setErrorText(null)}
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)} 
                                                    type="email" 
                                                    id="email" 
                                                    className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                                />
                                                {
                                                    errorText && !email && (
                                                        <p className="w-full text-left py-1 text-sm text-red-500">
                                                            Provide the community email
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        </div>
            
                                        <div className="w-full flex flex-col items-center my-1">
                                            <div className="w-full flex flex-col items-center my-1 mr-2">
                                                <label className="text-base font-medium text-black text-left w-full mb-3" for="url">
                                                    Your community website <span className="text-red-500">*</span>
                                                </label>
                                                <Input
                                                    onFocus={() => setErrorText(null)}
                                                    value={url}
                                                    onChange={(e) => setUrl(e.target.value)} 
                                                    type="url" 
                                                    id="url" 
                                                    className="w-full border shadow-sm border-gray-400 rounded-md p-3 outline-none"
                                                />
                                                {
                                                    !url && errorText && (
                                                        <p className="w-full text-left py-1 text-sm text-red-500">
                                                            Provide the community website link
                                                        </p>
                                                    )
                                                }
                                            </div>
                                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                                <label className="text-base font-medium text-black text-left w-full py-2" for="privacy">Visibility
                                                <span className="text-red-500">*</span></label>
                                                <select
                                                    onFocus={() => setErrorText(null)}
                                                    value={visibility}
                                                    onChange={(e) => setVisibility(e.target.value)}
                                                    id="privacy"
                                                    className="w-full border border-gray-400 rounded-md p-3 outline-none"  
                                                >
                                                    <option value={null} selected disabled>Select Visibility</option>
                                                    <option value={1}>Public</option>
                                                    <option value={0}>Private</option>
                                                </select>
                                                {
                                                    errorText && !visibility && (
                                                        <p className="w-full text-left py-1 text-sm text-red-500">
                                                            Select Visibility
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        </div>
            
                                        <div className="w-full flex flex-row items-center my-1">
                                            <div className="w-full flex flex-col items-center my-1 mr-2">
                                                <label className="text-base font-medium text-black text-left w-full mb-3" for="name">Phone Number</label>
                                                <Input
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)} 
                                                    type="text" 
                                                    id="name" 
                                                    className="w-full border border-gray-400 rounded-md p-3 outline-none shadow-sm"
                                                />
                                            </div>
                                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                                <label className="text-base font-medium text-black text-left w-full mb-3" for="name">Contact Name <span className="text-red-500">*</span></label>
                                                <Input
                                                    onFocus={() => setErrorText(null)}
                                                    value={contactName}
                                                    onChange={(e) => setContactName(e.target.value)} 
                                                    type="text" 
                                                    id="name" 
                                                    className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                                />
                                                {
                                                    !contactName && errorText && (
                                                        <p className="w-full text-left py-1 text-sm text-red-500">
                                                            Provide the community contact name
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        </div>
            
                                    </div>
                                )
                            }
                            
                            {/* STEP 03 */}
                            {
                                steps === 3 && (
                                    <div
                                        className="p-4 w-full flex flex-col items-start">

                                        <div className="w-full flex flex-row items-center my-1">
                                            <div className="w-full flex flex-col items-center my-1 mr-2">
                                                <label className="text-base font-medium text-black text-left w-full py-2" for="line1">Full Address
                                                <span className="text-red-500">*</span></label>
                                                <Input 
                                                    onFocus={() => setErrorText(null)}
                                                    id="line1"
                                                    value={fullAddress}
                                                    onChange={(e) => setFullAddress(e.target.value)} 
                                                    type="text" className="w-full border border-gray-400 rounded-md p-3 outline-none"/>
                                                    {
                                                        errorText && !fullAddress && (
                                                            <p className="w-full text-left py-1 text-sm text-red-500">
                                                                Provide the full address
                                                            </p>
                                                        )
                                                    }
                                            </div>
                                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                                <label className="text-base font-medium text-black text-left w-full py-2" for="country">Country
                                                <span className="text-red-500">*</span></label>
                                                <select
                                                    onFocus={() => setErrorText(null)}
                                                    value={selectedCountry}
                                                    onChange={(e) => handleCountryChange(e)}
                                                    id="country"
                                                    className="w-full border border-gray-400 rounded-md p-3 outline-none"  
                                                >
                                                    <option value={null} selected disabled>Select Country</option>
                                                    {
                                                        dumpCountries?.map((dc, index) => (
                                                            <option value={dc.country}>{dc.country}</option>
                                                        ))
                                                    }
                                                </select>
                                                {
                                                    errorText && !selectedCountry && (
                                                        <p className="w-full text-left py-1 text-sm text-red-500">
                                                            Provide the country
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div className="w-full flex flex-row items-center my-1">
                                            <div className="w-full flex flex-col items-center my-1 mr-2">
                                                <label className="text-base font-medium text-black text-left w-full py-2" for="states">State
                                                <span className="text-red-500">*</span></label>
                                                {
                                                    dumpStates?.length ? (
                                                        <select 
                                                            onFocus={() => setErrorText(null)}
                                                            value={selectedState}
                                                            onChange={e => setSelectedState(e.target.value)}
                                                            id="states"
                                                            className="w-full border border-gray-400 rounded-md p-3 outline-none"  
                                                        >
                                                            <option value={null} selected disabled>Select State</option>
                                                            {
                                                                dumpStates?.map((ds, index) => (
                                                                    <option value={ds}>{ds}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    ) : (
                                                        <Input 
                                                            onFocus={() => setErrorText(null)}
                                                            id="state"
                                                            value={selectedState}
                                                            onChange={(e) => setSelectedState(e.target.value)} 
                                                            type="text" className="w-full border border-gray-400 rounded-md p-3 outline-none"/>
                                                    )
                                                }
                                                {
                                                    errorText && !selectedState && (
                                                        <p className="w-full text-left py-1 text-sm text-red-500">
                                                            Provide the state
                                                        </p>
                                                    )
                                                }
                                            </div>
                                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                                <label className="text-base font-medium text-black text-left w-full py-2" for="city">City
                                                <span className="text-red-500">*</span></label>
                                                <Input 
                                                    onFocus={() => setErrorText(null)}
                                                    id="city"
                                                    value={city}
                                                    onChange={(e) => setCity(e.target.value)} 
                                                    type="text" className="w-full border border-gray-400 rounded-md p-3 outline-none"/>
                                                {
                                                    errorText && !city && (
                                                        <p className="w-full text-left py-1 text-sm text-red-500">
                                                            Provide the city
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div className="w-full flex flex-row items-center my-1">
                                            <div className="w-full flex flex-row items-start justify-start my-1 mr-2">
                                                <div className="w-full flex flex-col items-center my-1 ml-1">
                                                    <label className="text-base font-medium text-black text-left w-full py-2" for="zip">Postal Code
                                                    <span className="text-red-500">*</span></label>
                                                    <Input 
                                                        onFocus={() => setErrorText(null)}
                                                        id="zip"
                                                        value={zip}
                                                        onChange={(e) => setZip(e.target.value)} 
                                                        type="text" className="w-full border border-gray-400 rounded-md p-3 outline-none"/>
                                                    {
                                                        errorText && !zip && (
                                                            <p className="w-full text-left py-1 text-sm text-red-500">
                                                                Provide the postal code
                                                            </p>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                                <label className="text-base font-medium text-black text-left w-full py-2" for="timezone">Time Zone
                                                <span className="text-red-500"> *</span></label>
                                                <select
                                                    onFocus={() => setErrorText(null)}
                                                    onChange={(e) => setSelectedTimeZone(e.target.value)}
                                                    id="visibility"
                                                    className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                                    value={dumpTimeZones?.filter(i => i?.abbr===selectedTimeZone)[0]?.text}  
                                                >
                                                    <option value="" selected disabled>Select Time Zone</option>
                                                {
                                                    dumpTimeZones?.map(times => (
                                                        <option value={times?.abbr}>{times?.text}</option>
                                                    ))
                                                }
                                                </select>
                                            </div>
                                        </div>

                                        {
                                            query.get('type') !== 'edit' && (
                                            <>
                                            <div className="w-full flex flex-row items-center my-3">
                                                    <input
                                                        defaultChecked={false}
                                                        onChange={e => setAgree(e.target.checked)}
                                                        style={{height: 18, width: 18}} 
                                                        type="checkbox" 
                                                        className="border border-gray-400 rounded-md p-3 outline-none" 
                                                    />
                                                    <p className="w-full text-md mx-3 text-left text-gray-700">
                                                        By creating the community you agree to Assistance Org’s <a target="_blank" href="#">Terms and Conditions</a>.
                                                    </p>
                                                </div>
                                                <div className="w-full flex flex-row items-center my-3">
                                                <input
                                                    defaultChecked={false}
                                                    onChange={e => setIsOwned(e.target.checked)}
                                                    style={{height: 18, width: 18}} 
                                                    type="checkbox" 
                                                    className="border border-gray-400 rounded-md p-3 outline-none" 
                                                />
                                                <p className="w-full text-md mx-3 text-left text-gray-700">
                                                    Do you own the community ?
                                                </p>
                                            </div>
                                            </>
                                            )
                                    }    
                                        
                                    </div>
                                )
                            }
                            
                            <div 
                                className="w-full flex flex-row items-end justify-end my-2 p-4">
                                {
                                    steps > 1 && (
                                        <button
                                            onClick={() => handlePreviousStep()}
                                            className="px-5 text-base text-white bg-gray-500 ml-3 rounded-sm cursor-pointer mr-3 outline-none focus:outline-none"
                                            style={{height: 40}}              
                                        >Previous
                                        </button>
                                    )
                                }
                                {/* <button
                                    className="px-5 text-base text-white bg-yellow-500 rounded-sm cursor-pointer outline-none focus:outline-none"
                                    style={{height: 40}}              
                                >Save as Draft
                                </button> */}
                                {
                                    steps !== 3 && (
                                        <button
                                            onClick={() => handleNextStep()}
                                            className="px-5 text-base text-white primary-bg ml-3 rounded-sm cursor-pointer outline-none focus:outline-none"
                                            style={{height: 40}}              
                                        >Next
                                        </button>
                                    )
                                }
                                {
                                    steps === 3 && (
                                        <Button
                                            type="primary"
                                            loading={load}
                                            disabled={!allowMeToCreate && query.get('type') !== 'edit'}
                                            onClick={() => query.get('type') === 'edit' ? onSubmit('edit') : onSubmit('create')}
                                            className="px-5 text-base text-white primary-bg ml-3 rounded-sm cursor-pointer outline-none focus:outline-none"
                                            style={{height: 40}}              
                                        >Submit
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                </div>
                )
            }
                <Modal
                    visible={previewModal}
                    title="Preview Image"
                    footer={null}
                    className="apps"
                    onCancel={() => setPreviewModal(false)}
                    destroyOnClose={true}
                >
                    <div className="p-3">
                        <img
                            src={tempPreviewImage}
                            className="object-cover h-40 w-full"
                            alt="preview"
                        />
                    </div>
                </Modal>

            <Footer />
            
        </>
    )
}

export default CreateCommunity
