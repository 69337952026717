import React,{ useEffect, useState } from 'react'
import { Input, message, Modal } from 'antd'
import '../stylesheets/Login.css'
import { Link, useLocation } from 'react-router-dom'
import apiInstance from '../api'
import { validateEmail } from '../utils/Helper'
import googleLogo from '../assets/img/google-icon.svg'
import facebookLogo from '../assets/img/facebook-icon.svg'
import * as jwt from 'jsonwebtoken'
import Footer from '../components/User/v2/Footer'
import Header from '../components/User/v2/Header'
import { useDataLayerValue } from '../DataLayer'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Login() {    
    let query = useQuery();

    const [{user}] = useDataLayerValue();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [load, setLoad] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Log In');  
    const [termsModal, setTermsModal] = useState(false);
    
    useEffect(() => {
        document.title = "Log in to Assistance | Assistance";   

        if (query.get("code") === '404') message.warning("Account not found");

        if (query.get("code") === '401') message.warning("Account belongs to default assistance account");

        if (query.get("code") === '301') message.warning("Account is deactivated, Contact Support");

        // SOCIAL AUTH CALLBACK        
        if(query.get('token') && query.get('auth') === 'social') {

            let tokenDecoded = jwt.decode(query.get('token'));

            const _token = query.get('token') // TOKEN FROM QUERY
            const _user = tokenDecoded.user; // USER DATA FROM TOKEN
            const _permissions = tokenDecoded.permissions; // PERMISSION DATA FROM TOKEN

            sessionStorage.setItem('assistance_token', _token);
            sessionStorage.setItem('assistance_user', JSON.stringify(_user));
            sessionStorage.setItem('assistance_permissions', _permissions);

            setUsername('');
            setPassword('');                                
                        
            if(_user?.role === 'Super User') {
                window.location.replace("/dashboard");
            } else {
                // going back where you left before
                let fallbackRoute = sessionStorage.getItem('assistance_current_route_helper');
                let invitationRoute = sessionStorage.getItem('invitation_callback');
                if (invitationRoute) {
                    window.location.replace(invitationRoute);
                } else if (fallbackRoute) {
                    window.location.replace(fallbackRoute);
                } else {
                    window.location.replace('/home');
                }
                // window.location.replace("/");
                // history.push("/");
            }    
        }                

        if (user) {
            window.location.replace('/home');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logIn = (e) => {
        e.preventDefault();
        if(username && password) {            
        
            if(username.indexOf('@')>=0) {
                if(validateEmail(username)) {
                    signInWithEmailOrUsername(username, password);
                } else message.warning('Invalid Email Address');
            } else  signInWithEmailOrUsername(username, password);
            
        } else message.warning('User name or Password missing');
    }

    const signInWithEmailOrUsername = (username, password) => {
            let userLoginInfo = { // PAYLOAD FOR LOGGING IN AS USER
                username,
                password
            };

            // START REQUESTING API
            setLoad(true);
            setButtonLabel('Logging In');

            apiInstance.post("/auth/login", userLoginInfo)
            .then(response => {   
                
                if(response.data.status) {
                    // CLEAR TO GO !!!
                    const _token = response.data.token; // TOKEN FROM AUTHENTICATION API                    
                    const _user = response.data.payload.user; // USER DATA FROM API
                    const _permissions = response.data.payload.permissions; // PERMISSION DATA FROM API

                    if (parseInt(_user?.is_active) === 1) {
                        sessionStorage.setItem('assistance_token', _token);                    
                        sessionStorage.setItem('assistance_user', JSON.stringify(_user));
                        sessionStorage.setItem('assistance_permissions', _permissions);

                        setUsername('');
                        setPassword('');                                
                                    
                        if(_user?.role === 'Super User') {
                            window.location.replace("/dashboard");
                        } else {
                            // going back where you left before
                            let fallbackRoute = sessionStorage.getItem('assistance_current_route_helper');
                            let invitationRoute = sessionStorage.getItem('invitation_callback');
                            let joinedRoute = sessionStorage.getItem('join_callback');
                            if (invitationRoute) {
                                window.location.replace(invitationRoute);
                            } else if (joinedRoute) {
                                window.location.replace(joinedRoute);
                            } else if (fallbackRoute) {
                                if (fallbackRoute !== '/signin') {
                                    window.location.replace(fallbackRoute);
                                } else {
                                    window.location.replace('/home');
                                }
                            } else {
                                window.location.replace('/home');
                            }
                        }    
                    } else {                    
                        setPassword('');  
                        message.warning("Your Account is not activated");                                                                                  
                    }
                }                                                           
                setLoad(false);
                setButtonLabel('Log In');                 
            }).catch(e => {                
                console.log(e.response);
                if(e.response || e.response?.data) {                                    
                    if(e.response.status === 404 || e.response.status === 300 || e.response.status === 401) {
                        message.warning(e.response.statusText);
                    } else if (typeof e.response.data?.message === 'string') {
                        message.error(e.response.data?.message|| 'Something went wrong');
                    } else {
                        message.error('Something went wrong');
                    }
                } else message.error("Internal Server Error");
                setLoad(false);
                setButtonLabel('Log In');
                setPassword('');                                
            });
    }

    return (
        <>
        <Header />
        <div    
            className="w-full max-h-full lg:h-screen flex flex-col items-center py-12 bg-white lg:px-12"
        >
            <div 
                className="lg:w-1/3 md:w-1/2 bg-white rounded-lg px-2 py-4
                            flex flex-col items-center"
            >
                <h1 className="text-lg text-black py-2 font-medium">
                    Log in to Assistance
                </h1>
                <form className="login">
                    <Input 
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        type="text" 
                        placeholder="Username or Email address"
                        className="p-2 my-2 rounded-sm text-black text-md"
                    />
                    <Input.Password 
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Password"
                        className="p-2 my-2 rounded-sm text-black text-md"
                    />                 
                    <Link
                        to="/reset-password"
                        className="w-full text-left px-2 py-3 text-md hover:text-blue-500 hover:underline"
                    >Forget Password?</Link>                                      
                    <button                            
                        disabled={load}                                      
                        type="submit"              
                        onClick={logIn}
                        className="w-full text-white font-medium rounded-sm text-lg block primary-btn text-center
                                   p-2 my-2 cursor-pointer mb-4 focus:outline-none"
                    >{buttonLabel}                   
                    </button>
                    <p className="py-2 text-gray-600 text-center text-md"
                    >By continuing, you agree to Assistance's <span onClick={() => setTermsModal(true)} className="underline cursor-pointer">Terms and Conditions</span>.          
                    </p>
                </form>                                    
                <Link
                    to="/get-started"
                    className="w-full text-center py-1 text-md
                        font-medium primary-color"
                >No account? <strong>Sign Up</strong></Link>                
            </div>
            <Modal
                width={800}    
                onCancel={() => setTermsModal(false)}            
                title="Terms and Conditions"
                visible={termsModal}
                footer={null}
            >                
                <h1 className="text-left font-extrabold text-xl py-3">Privacy</h1>                
                <p className="text-gray-700 py-3 text-justify text-lg">
                    At certain points in the <Link className="hover:text-underline" to="/">assistance.org</Link> website navigation, you may be asked to share your email address or other personal identifying information with us. As provided in these Terms and Conditions, such information will never be distributed to a third party and it will never be publicly visible without your express written consent.

                    Your email address will only be used to send you the <Link className="hover:text-underline" to="/">assistance.org</Link> newsletter and/or to alert you to any information that you have specifically requested you be notified about.
                </p>
                <h2 className="text-left font-extrabold text-xl py-3 ">Use of the Site</h2>                
                <p className="text-gray-700 py-3 text-justify  text-lg">
                    The <Link to="/" className="hover:text-underline">assistance.org</Link> website hosts a blog and a public forum, both of which are equipped with commenting facilities. While we invite you to share your opinions and questions in this way, they must not be used to distribute spam messages, post commercial advertisements, or spread links to malicious or dangerous websites. We do retain the right to moderate any comment or written content submitted to the <Link to="/" className="hover:text-underline">assistance.org</Link> website and to remove any content we deem to have violated our policies.
                </p>
                <h2 className="text-left font-extrabold text-2xl py-3 ">Disclaimer</h2>                
                <p className="text-gray-700 py-3 text-justify  text-lg">
                    All of the content contained on the <Link to="/" className="hover:text-underline">assistance.org</Link> is edited, checked, and verified for accuracy as much as it is possible to do so. However, we cannot guarantee either its accuracy or the safety of any external links it might contain. <Link to="/" className="hover:text-underline">assistance.org</Link>, as well as its owners, affiliates, and contributing authors can therefore not be held responsible for any problems or damage that occurs as a result of making use of material contained on our site.
                </p>
                <h2 className="text-left font-extrabold text-2xl py-3 ">Copyright</h2>                
                <p className="text-gray-700 py-3 text-justify  text-lg">
                    Any and all of the content presented on the <Link to="/" className="hover:text-underline">assistance.org</Link> website is, unless explicitly stated otherwise, subject to a copyright held by <Link to="/" className="hover:text-underline">assistance.org</Link>. It is permissible to link to content from this site as long as the original source is clearly stated, but the wholesale reproduction or partial modification of content is not permitted. Exceptions are granted only if you receive prior written consent from <Link to="/" className="hover:text-underline">assistance.org</Link>.
                </p>
                <h2 className="text-left font-extrabold text-2xl py-3 ">Contact</h2>                
                <p className="text-gray-700 py-3 text-justify  text-lg">
                    Should you have any further questions, concerns, or queries about these Terms and Conditions, or if you encounter difficulties while navigating and using the site, please contact <a href="mailto:contact@assistance.org">contact@assistance.org</a>.
                </p>
            </Modal>
            <hr></hr>
            <a 
                href={`${process.env.REACT_APP_SERVER_URL}auth/google`}
                className="border rounded-sm bg-white shadow py-2 px-10 flex items-center justify-between cursor-pointer my-1 hover:border-gray-700">
                <img
                    alt="google logo"
                    className="h-8 w-8 object-contain mr-2"
                    src={googleLogo}
                />
                <p className="w-full text-md font-medium primary-color">Sign in with Google</p>
            </a>
            <a 
                href={`${process.env.REACT_APP_SERVER_URL}auth/facebook`}
                className="border rounded-sm bg-white shadow py-2 px-10 flex items-center justify-between cursor-pointer my-2 hover:border-gray-700">
                <img
                    alt="facebook logo"
                    className="h-8 w-8 object-contain mr-2"
                    src={facebookLogo}
                />
                <p className="w-full text-md font-medium primary-color">Sign in with Facebook</p>
            </a>     
        </div>
        <Footer />  
        </>
    )
}

export default Login
