/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import moment from 'moment'
import defaultImage from '../../../assets/img/event-1.png'
import { CalendarFilled, PushpinFilled, SmileFilled } from '@ant-design/icons'

function BriefItem(props) {
    return (
        <div className={`border brief__item rounded-md flex flex-col ${props.fixed?'':'bg-white'} items-start shadow`}>
            {
                props.fixed && (
                    <img
                        onClick={() => window.location.replace(`/${props.keyName?props.keyName:'e'}/${props.url_key}`)}
                        className="w-full rounded-t-md cursor-pointer" 
                        src={props?.image?`${process.env.REACT_APP_SERVER_URL}${props?.image}`:defaultImage} alt="Five developers at work."
                    />
                )
            }
            <div className="w-full flex flex-col items-start p-3 pb-2 border-b">
                <a href={`/${props.keyName?props.keyName:'e'}/${props.url_key}`} className="breif__title overflow-hidden overflow-ellipsis font-medium text-lg text-blue-500 py-1 text-left">
                    {props.title}
                </a>
                <div className="w-full flex items-center">
                    <CalendarFilled />
                    {
                        moment(props?.startDate)?.isSame(moment(props?.endDate)) ? (
                            <p className="ml-2 overflow-hidden overflow-ellipsis text-sm text-black py-1">{`${moment(props?.startDate).format('lll')}`}</p>
                        ) : (
                            <p className="ml-2 breif__title overflow-hidden overflow-ellipsis text-sm text-black py-1">{`${moment(props?.startDate).format('lll')} - ${moment(props?.endDate).format('lll')}`}</p>
                        )
                    }
                    {/* <p className="ml-2 overflow-hidden overflow-ellipsis text-sm text-black py-1">{`${moment(props?.startDate).format('lll')} - ${moment(props?.endDate).format('lll')}`}</p> */}
                </div>
                <div className="flex flex-row items-center">
                    <PushpinFilled />
                    <p className="ml-2 text-sm text-black py-1 text-left">{props?.locationDetail}</p>
                </div>
                <div className="flex flex-row items-center">
                    <SmileFilled />
                    <p className="ml-2 text-sm text-black py-1 text-left">Hosted by {props?.username}</p>
                </div>
            </div>
            <a href="#" className="w-full text-blue-500 text-md text-left py-2 px-3 flex flex-row items-center">
                {props?.type}
            </a>
        </div>
    )
}

export default BriefItem
