import React from 'react'
import { getProfileImage } from '../../../utils/Helper'

function ChatItem(props) {
    return (
        <div className="w-full border shadow py-4 flex flex-row items-center bg-white cursor-pointer hover:bg-gray-100">
            <img
                alt="profile"
                className="h-10 rounded-full mx-2"
                src={props?.avatar ? props?.avatar?.indexOf('h')==='0'?props?.avatar:`${process.env.REACT_APP_SERVER_URL}${props?.avatar}` : getProfileImage(props?.username || 'Assistance')}
            />
            <h4 className="w-full text-base text-black font-medium text-left px-1">
                {props.username}
            </h4>
        </div>
    )
}

export default ChatItem
