/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import apiInstance from '../../api'
import { Table, message, Space, Button, Input } from 'antd'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'

function Report() {

    const [reports, setReports] = useState([]);
    const [load, setLoad] = useState(false);
    const [spin, setSpin] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        setSpin(true);
        apiInstance
            .get("/report")
                .then(response => {
                    setSpin(false);
                    if(response.data.status) {
                        setReports(response.data.data);
                    } else message.warning('Something went wrong');
                })
                .catch(e => {
                    setSpin(false);
                    if(e.response || e.response.data) {                
                        message.error(e.response.data.message);
                        if(e.response.status === 401) {
                            sessionStorage.clear();
                            window.location.replace("/admin");
                        }
                    } else message.error("Internal Server Error");
                });
                
    },[]);

    // ANT DESIGN METHODS
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                setSearchInput(node);
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            </Space>
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible && searchInput) {
            setTimeout(() => searchInput.select(), 100);
        }
        },
        render: text =>
        searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });

    // ANT DESIGN METHODS
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);        
    };

    // ANT DESIGN METHODS
    const handleReset = clearFilters => {
        clearFilters();        
        setSearchText('');
    };

     // Table Columns Defined
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'            
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',            
        },        
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            ...getColumnSearchProps('username')            
        },
        {
            title: 'From Email',
            dataIndex: 'fromEmail',
            key: 'fromEmail',
            ...getColumnSearchProps('fromEmail')
                       
        },
        {
            title: 'Entity',
            dataIndex: 'entity',
            key: 'entity',  
            filters: [
                { text: 'event', value: 'event' },
                { text: 'community', value: 'community' },
                { text: 'feed', value: 'feed' },
                { text: 'all', value: 'all' } 
            ],
            onFilter: (value, record) => record.entity === value
        },
        {
            title: 'Entity Id',
            dataIndex: 'entityId',
            key: 'entityId'            
        },
        {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            render: url => {
                return <a href={url} target="_blank">Open Link</a>
            }            
        },
        {
            title: 'Reported at',
            dataIndex: 'created_at',
            key: 'created_at',
            render: created_at => {
                return moment(created_at).format('lll');
            }
        },
        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: (text, record) => (
        //         <p     
        //             className="cursor-pointer primary-color"
        //             // onClick={() => openUpdateModal(true, record)}                                    
        //         ><EyeTwoTone /> View Detail
        //         </p>            
        //     )
        // }    
    ];


    return (
        <div className="w-full h-full flex flex-col items-center px-4">
            <h1
                className="w-full text-left text-lg font-semibold mb-4"
            >Reports
            </h1> 
            <div className="w-full py-2 m-2">
                <Table      
                    pagination={{
                        pageSize: 6
                    }}            
                    expandable={{
                        expandedRowRender: record => <p style={{ margin: 0 }}>{record.message}</p>,
                        rowExpandable: record => record.name !== 'Not Expandable',
                    }}   
                    rowKey="id"
                    loading={load}
                    className="w-full py-2"
                    dataSource={reports}
                    columns={columns}
                />
            </div>           
        </div>
    )
}

export default Report
