/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useState, useEffect } from 'react'
import Banner from '../../../components/User/v2/Banner'
import Footer from '../../../components/User/v2/Footer'
import Header from '../../../components/User/v2/Header'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Input, message, Button, Modal, Breadcrumb, Select, Radio } from 'antd'
import moment from 'moment'
import apiInstance from '../../../api/'
import {useDataLayerValue} from '../../../DataLayer'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState } from 'draft-js'
import { convertFromRaw, convertToRaw } from 'draft-js'

const {Option} = Select

const descriptionContent = {"entityMap":{},"blocks":[]};
const termsContent = {"entityMap":{},"blocks":[]};
const faqsContent = {"entityMap":{},"blocks":[]};


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Create() {
    document.title = "Host your customized event - Assistance Org";

    const [{token}] = useDataLayerValue();

    const location = useLocation();

    const stepsHeading = {
        1: 'General Information',
        2: 'Event Information',
        3: 'Contact Information',
        4: 'Other Information'
    };

    useEffect(() => {
        if (location.state) {
            setCommunity(location.state?.c_data?.org_id);
            setVisibility('community');
        }
    }, []);

    useEffect(() => {
        if(!token) {
            window.location.replace("/signin");
        }
     }, [token]);

    const history = useHistory();
    let query = useQuery();

    const [id, setId] = useState(null);
    const [title, setTitle] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [urlKey, setUrlKey] = useState('');
    const [url, setUrl] = useState('');
    const [image, setImage] = useState(null);
    const [eventType, setEventType] = useState('');
    const [locationDetail, setLocationDetail] = useState('');
    const [conducted, setConducted] = useState('1');
    const [community, setCommunity] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [publishedDate, setPublishedDate] = useState('');
    const [visibility, setVisibility] = useState('');
    const [agreement, setAgreement] = useState(false);
    const [load, setLoad] = useState(false);
    const [imageErrorText, setImageErrorText] = useState(null);
    const [files, setFiles] = useState(null);
    const [previewModal, setPreviewModal] = useState(false);
    const [tempPreviewImage, setTempPreviewImage] = useState(null);
    const [loadingState, setLoadingState] = useState(false);
    const [removeLoadingState, setRemoveLoadingState] = useState(false);
    const [urlKeyError, setUrlKeyError] = useState(false);
    const [askCount, setAskCount] = useState(false);
    const [tagTypes, setTagTypes] = useState([]);
    const [tagSelect, setTagSelect] = useState([]);
    const [tags, setTags] = useState([]);
    const [tagsToAdd, setTagsToAdd] = useState([]);
    const [tagsToRemove, setTagsToRemove] = useState([]);
    const [eventTags, setEventTags] = useState([]);
    const [steps, setSteps] = useState(1);
    const [errorText, setErrorText] = useState(null);
    const [dumpTimeZones, setDumpTimeZones] = useState([]);

    const [descriptionState, setDescriptionState] = useState(EditorState.createEmpty());
    const [descriptionContent, setDescriptionContent] = useState(null);
    const [termsState, setTermsState] = useState(EditorState.createEmpty());
    const [termsContent, setTermsContent] = useState(null);
    const [faqsState, setFaqsState] = useState(EditorState.createEmpty());
    const [faqsContent, setFaqsContent] = useState(null);
    const [selectedTimeZone ,setSelectedTimeZone] = useState(null);
    const [isUnlimited, setIsUnlimited] = useState(null);
    const [spots, setSpots] = useState(0);

    async function getAllTagTypes() {            
        apiInstance.get("/tagtype/secure")
        .then(response => {        
            if(response.data.status) {
            // ADDING AN EXTRA KEY TO THE RESPONSE DATA FOR REACT UNIQUE PROP
           setTagTypes(response.data.data);
        } else message.warning(response.data?.data || 'something went wrong');
        }).catch(e => {
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error");   
        });
    };

    async function getAllTags() {            
        apiInstance.get("/tag")
        .then(response => {        
            if(response.data.status) {
            // ADDING AN EXTRA KEY TO THE RESPONSE DATA FOR REACT UNIQUE PROP
           setTags(response.data.data);
        } else message.warning(response.data?.data || 'something went wrong');
        }).catch(e => {
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error");   
        });
    };

    async function getEventTags() {
        apiInstance.get(`/tag/event/${id}`)
        .then(response => {        
            if(response.data.status) {
                setEventTags(response.data.data);
                if (response.data.data?.length) {
                    setTagSelect(response.data.data?.map(t => t.name));
                }
            } else message.warning(response.data?.data || 'something went wrong');
        }).catch(e => {
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error");   
        });
    }

    useEffect(() => {
        getAllTagTypes();
        getAllTags();
        getDumpTimeZoneData();
    }, []);

    useEffect(() => {
        if (id) {
            getEventTags();
        }
    }, [id]);

    async function getDumpTimeZoneData() {
        apiInstance
        .get('/shared/timezone')
        .then(response => {
            if (response.data) {
                setDumpTimeZones(response.data);
            }
        })
        .catch(e => console.log(e.response));
    }

    const onEditorChange = (eState, type) => {
        const tempState = eState.getCurrentContent();
        if (type === 'description') {
            setDescriptionContent(tempState);
            setDescriptionState(eState);
        } else if (type === 'terms') {
            setTermsContent(tempState);
            setTermsState(eState);
        } else if (type === 'faq') {
            setFaqsContent(tempState);
            setFaqsState(eState);
        }
    };

    useEffect(() => {
        const actionType = query.get("type");
        const receivedId = query.get("id");
        if (actionType === 'edit') {
            findEventById(receivedId);
        }
    }, []); 

    function setExistingData(data) {
        if (data) {
            setId(data?.id || null);
            setTitle(data?.title||'');
            setDescriptionState(data?.description?EditorState.createWithContent(convertFromRaw(JSON.parse(data.description))):EditorState.createEmpty());
            setDescriptionContent(data?.description?convertFromRaw(JSON.parse(data?.description)):null);
            setTermsState(data?.termsAndConditions?EditorState.createWithContent(convertFromRaw(JSON.parse(data?.termsAndConditions))):EditorState.createEmpty());
            setTermsContent(data?.termsAndConditions?convertFromRaw(JSON.parse(data?.termsAndConditions)):null);
            setFaqsState(data?.faqs?EditorState.createWithContent(convertFromRaw(JSON.parse(data?.faqs))):EditorState.createEmpty());
            setFaqsContent(data?.faqs?convertFromRaw(JSON.parse(data?.faqs)):null);
            setUrlKey(data?.url_key ||'');
            setUrl(data?.url || '');
            setImage(data?.image || null);
            setEventType(data?.type || '');
            setLocationDetail(data?.locationDetail || '');
            setConducted(data?.isOnline?.toString() || '1');
            setCommunity(data?.community || null);
            setStartDate(moment(data?.startDate).format('YYYY-MM-DDTHH:MM:SS') || '');
            setEndDate(moment(data?.endDate).format('YYYY-MM-DDTHH:MM:SS') || '');
            setPublishedDate(moment(data?.publishedDate).format('YYYY-MM-DDTHH:MM:SS') || '');
            setVisibility(data?.isPublic?.toString()==='1'?'public':'private' || '');
            setAskCount(data?.isPeopleCountReq);
        } else message.warn("No data available");
    };

    async function findEventById(id) {
        apiInstance
        .get(`/event/${id}`)
        .then(response => {
            if (response.status) {
                setExistingData(response.data.data);
            } else message.warning(response.data?.message || 'something went wrong');
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoadingState(false);
        });
    };

    const handleSelectedChange = (key, e) => {
        if (key === 'conducted') {
            setConducted(e.target.value);
        }
        if (key === 'community') {
            setVisibility(e.target.value);
        }
        if (key === 'visibility') {
            setVisibility(e.target.value);
        }
    };

    useEffect(() => {
        if (title) {
            const _urlKey = title.toLowerCase().replace(/ /g, '-');
            setUrlKey(_urlKey);
        } else {
            setUrlKey('');
        }
    }, [title]);

    const isValidImageSize = (imageFile) => {
        const MAX_IMG_SIZE = 1000000; //1MB max file(s) size
        if (imageFile.size > MAX_IMG_SIZE) {
            return false;
        } else return true;
    };

    const isValidImageFormat = (imageFile) => {
        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
            return false;
        } else return true;
    };

    const imageOnChange = (e) => {
        const imageFile = e.target.files[0];
        if (imageFile) {
            if (isValidImageFormat(imageFile)) {
                setImageErrorText(null);
                if (isValidImageSize(imageFile)) {
                    setImageErrorText(null);
                    setFiles(imageFile);
                } else {
                    // wrong size
                    setImageErrorText('(Maximum 1MB size is allowed)');
                }
            } else {
                // wrong format
                setImageErrorText('(Only JPEG, PNG, JPG is accepted)');
            }
        }
    };

    const handlePreviewImage = (e) => {
        e.preventDefault(); 
        if (files) {
            const imageForPreview = URL.createObjectURL(files);
            setTempPreviewImage(imageForPreview);
            setPreviewModal(true);
        } else {
            if (image) {
                setPreviewModal(true);
                const imagePath = process.env.REACT_APP_SERVER_URL+image;
                setTempPreviewImage(imagePath);
            } else {
                alert('choose a image');
            }
        }
    };

    const createAnEvent = (payload) => {
        setLoad(true);
        apiInstance
        .post('/event', payload)
        .then(response => {
            setLoad(false);
            if(response.data.status) {                                             
                message.success(response.data?.message || response.data?.data || 'Event Created');
                if (community) {
                    history.push(`/${location.state.c_data?.url_key}`);
                } else {
                    history.push(`/e/${urlKey}`);
                }
            } else message.warning(response.data?.data || 'something went wrong');
        }).catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                
            if(typeof e.response.data.message === 'string') message.error(e.response.data.message);
                if(e.response.status === 401 && e.response.status === 403) {
                    sessionStorage.clear();
                    window.location.replace("/signin");
                }
            } else message.error("Internal Server Error");
        });
                    
    };

    const updateAnEvent = (payload) => {
        setLoad(true);
        const eventId = query.get('id');
        apiInstance
        .put(`/event/${eventId}`, payload)
        .then(response => {
            setLoad(false);
            if(response.data.status) {                                             
                message.success(response.data.message);
                if (community) {
                    history.push(`/c/${location.state.c_key}`);
                } else {
                    history.push('/event');
                }
            } else message.warning(response.data?.data || 'something went wrong');
        }).catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                
            if(typeof e.response.data.message === 'string') message.error(e.response.data.message);
                if(e.response.status === 401 && e.response.status === 403) {
                    sessionStorage.clear();
                    window.location.replace("/signin");
                }
            } else message.error("Internal Server Error");
        });
                    
    };

    const onSubmit = (type='create') => {
        let payload = new FormData();
        payload.append("communityName", location.state?.c_data?.name);
        payload.append("title", title);
        payload.append("short_description", descriptionContent ? JSON.stringify(convertToRaw(descriptionContent)) : null);
        payload.append("terms", termsContent ? JSON.stringify(convertToRaw(termsContent)) : null);
        payload.append("faqs", faqsContent ? JSON.stringify(convertToRaw(faqsContent)) : null);
        payload.append("image", image || null);
        payload.append("url_key", urlKey);
        payload.append("url", url);
        payload.append("type", eventType);
        payload.append("location_detail", locationDetail || null);
        payload.append("community_id", community || null);
        payload.append("start_date", new Date(startDate)?.toISOString());
        payload.append("end_date", new Date(endDate)?.toISOString());
        payload.append("published_date", new Date(publishedDate)?.toISOString());
        payload.append("time_zone", selectedTimeZone);
        payload.append("is_public", visibility==='public'?'1':'0');
        payload.append("is_online", conducted);
        payload.append("isPeopleCountReq", isUnlimited);
        payload.append("spots", spots);
        payload.append("visibility_type", visibility);
        payload.append("tagsToAdd", JSON.stringify(tagsToAdd||[]));
        payload.append("tagsToRemove", JSON.stringify(tagsToRemove||[]));

        if (files) {
            payload.append("file", files);
        }
        if (title && eventType && startDate && endDate && publishedDate) {
           if (conducted === '1') {
                if (url) {
                    if (steps === 4) {
                        if (!publishedDate || !visibility) {
                            setErrorText(true);
                        } else {
                            if (type === 'create') {
                                createAnEvent(payload);
                                // console.log(Object.fromEntries(payload));
                            } else {
                                updateAnEvent(payload);
                            }
                        }
                    }
                } else {
                    message.warn('Event URL is required');
                }
           } else {
               if (locationDetail) {
                if (steps === 4) {
                    if (!publishedDate || !visibility) {
                        setErrorText(true);
                    } else {
                        if (type === 'create') {
                            createAnEvent(payload);
                            // console.log(Object.fromEntries(payload));
                        } else {
                            updateAnEvent(payload);
                        }
                    }
                }
               } else {
                   message.warn('Location Detail is required');
               }
           }
        } else {
            message.warn('Please enter all information');
        }
    };

    const handleRemoveImage = (e) => {
        e.preventDefault();
        Modal.warning({
            title: 'Are you sure to remove the image?',
            content: null,
            okText: "Remove",
            className: "apps",
            okButtonProps: {load: removeLoadingState, type: "danger", className: "px-5 text-md text-white rounded-md cursor-pointer mx-3"},
            onOk() {
                removeImageFromServer();
            }
        });
    };

    async function removeImageFromServer() {
        if (query.get('id')) {
            setRemoveLoadingState(true);
            const eventId = query.get('id');
            const imageRelatedPayload = {
                image_path: image
            };
            apiInstance
            .put(`/event/image_remove/${eventId}`, imageRelatedPayload)
            .then(response => {
                if (response.data.status) {
                    setImage(null);
                    message.success('Image removed successfully');
                } else message.warning(response.data?.data || 'something went wrong');
            })
            .catch(e => {
                console.log(e);
                if(e.response || e.response?.data) {                
                if(typeof e.response.data.message === 'string') message.error(e.response.data.message);
                    if(e.response.status === 401 && e.response.status === 403) {
                        sessionStorage.clear();
                        window.location.replace("/signin");
                    }
                } else message.error("Internal Server Error");
            })
            .finally(() => {
                setRemoveLoadingState(false);
            });
        }
    };

    async function deleteAnEvent() {
        setRemoveLoadingState(true);
        const eventId = query.get('id');
        if (eventId) {
            apiInstance
            .delete(`/event/${eventId}`)
            .then(response => {
                setRemoveLoadingState(false);
                if(response.data.status) {                                             
                    message.success(response.data.data);
                    history.push('/event');
                } else message.warning(response.data?.data || 'something went wrong');
            }).catch(e => {
                setRemoveLoadingState(false);
                console.log(e);
                if(e.response || e.response?.data) {                
                if(typeof e.response.data.message === 'string') message.error(e.response.data.message);
                    if(e.response.status === 401 && e.response.status === 403) {
                        sessionStorage.clear();
                        window.location.replace("/signin");
                    }
                } else message.error("Internal Server Error");
            });
        }
    };

    const handleDelete = () => {
        Modal.warning({
            title: 'Are you sure to delete the event?',
            content: null,
            okText: "Delete",
            className: "apps",
            okButtonProps: {load: removeLoadingState, type: "danger", className: "px-5 text-md text-white rounded-md cursor-pointer mx-3"},
            onOk() {
                deleteAnEvent();
            }
        });
    };

    async function checkUrlKey() {
        apiInstance
        .get(`/event/urlkey/check?key=${urlKey}&id=${id || null}`)
        .then(response => {
          if(response.data.status) {                                         
            if(response.data.data) {
                setUrlKeyError(false);
            } else {
                setUrlKeyError(true);
            }
          } else {            
            // window.location.replace('/fallback');
            console.log(response.data?.data||response?.status);            
          }
        })
        .catch(e => {        
          console.log(e);
          if(e.response || e.response?.data) {                          
              // message.error(e.response.data.message);
              if(e.response.status === 401) {
                  sessionStorage.clear();
                  window.location.replace("/admin");
              }
          } else {
            // history.push('/');
          }
        });  
    }

    useEffect(() => {
        if (urlKey) {

            // debounce fx
            const timer = setTimeout(() =>{
                checkUrlKey();
            }, 2000);  
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[urlKey]);

    const getIdByName = (data, type) => {
        let result = [];
        // eslint-disable-next-line
        tags.map(({ name, id }) => {
           if(type) {
            // eslint-disable-next-line
            data.map(i => {
                if(i === name) result.push(id.toString());
               });
            } else {
                // eslint-disable-next-line
                data.map((i) => {
                    if(i.name === name) result.push(id.toString());
                   });
            }
        });
        return result;
    }

    const handleTagsChange = (data) => {         
        setTagSelect(data);
        let temp = tags.map(({name})=>name);               
        let newTags = data.filter(keyword => !temp.includes(keyword));
        if(newTags.length) {
            // CREATE NEW KEYWORD            
                let tagData = { name: newTags[newTags.length-1], tagTypeId: tagTypes[1]?.id};            
                apiInstance
                .post("/tag" , tagData).then(response => {
                    if(response.data.status) {                    
                        let newTagId = [response.data.data.insertId.toString()];                
                        setTagsToAdd([...newTagId,...tagsToAdd]); 
                        getAllTags();                                       
                    } else message.warning(response.data?.data || 'Something went wrong');
                })
                .catch(e => {
                    console.log(e);
                    if(e.response || e.response?.data) {                
                        message.error(e.response.data.message);
                        if(e.response.status === 401) {
                            sessionStorage.clear();
                            window.location.replace("/signin");
                        }
                    } else message.error("Internal Server Error");
                });
                            
        }
        
        let exisitingTags = getIdByName(eventTags, false);
        let correctedTags = getIdByName(data, true);
                
        setTagsToAdd(correctedTags.filter(tag => !exisitingTags.includes(tag)));                
        setTagsToRemove(exisitingTags.filter(tag => !correctedTags.includes(tag)));             
    };

    const handleNextStep = () => {
        let oldStep = steps;
        if (steps === 1) {
            if (!title || !urlKey || !eventType || !startDate || !endDate) {
                setErrorText(true);
            } else {
                if (oldStep <= 3) {
                    oldStep++;
                    setSteps(oldStep);
                }
            }
        } else if (steps === 2) {
            if (!descriptionContent) {
                setErrorText(true);
            } else {
                if (oldStep <= 3) {
                    oldStep++;
                    setSteps(oldStep);
                }
            }
        } else if (steps === 3) {
            if (!locationDetail) {
                setErrorText(true);
            } else {
                if (oldStep <= 3) {
                    oldStep++;
                    setSteps(oldStep);
                }
            }
        }
    }

    const getStepHeading = (index) => {
        return stepsHeading[index];
    };
    
    const handlePreviousStep = () => {
        let oldStep = steps;
        if (oldStep > 1) {
            oldStep--;
            setSteps(oldStep);
        }
    };

    const allowMeToCreate = isUnlimited!==null && isUnlimited===false?spots>0?true:false:isUnlimited && title && startDate && endDate && publishedDate && eventType && agreement && imageErrorText===null && urlKeyError===false && visibility && selectedTimeZone;

    useEffect(() => {
        if (isUnlimited===true) {
            setSpots(0);
        }
    }, [isUnlimited]);

    return (
        <>
            <Header />
            {
                loadingState ? (
                    <div className="w-full main__preLoaderContainer flex items-center justify-center">
                        <img 
                            className="main__preLoader"
                            src="./logo192.png"
                            alt="preloader"
                        />
                    </div>
                ) : (

               <div className="w-8/12 mx-auto flex flex-col items-start mb-10 py-10">
    
                   <div className="w-full mb-6">
                        {
                            location?.state?.c_data ? (
                                <>
                                    <Breadcrumb.Item      
                                        href={`/c/${location.state?.c_data?.url_key}`}
                                        key="community_key"
                                        separator={'>'}
                                    >{location.state?.c_data?.name}
                                    </Breadcrumb.Item>
                                </>
                            ) : (
                                <Breadcrumb.Item      
                                    href={`/event`}
                                    key="event"
                                    separator={'>'}
                                >Event
                                </Breadcrumb.Item>
                            )
                        }
                        <Breadcrumb.Item      
                            href={`/event/create`}
                            key="event-create"
                            separator={''}
                        >{query.get('type') === 'edit' ? 'Edit' : 'Create'} Event
                        </Breadcrumb.Item>
                   </div>

                   <div className="border shadow w-full flex flex-col items-start rounded-md">
                        
                        <div className="font-medium text-base w-full bg-blue-500 text-white text-center p-2 rounded-t-md">
                            {getStepHeading(steps)}
                        </div>

                        {/* STEP 01 */}
                        {
                            steps === 1 && (
                                <div 
                                    className="p-4 w-full flex flex-col items-start">
                                        
                                        <div className="w-full flex flex-col items-center my-1">
                                            <label className="text-base font-medium text-black text-left w-full mb-3" for="name">
                                                Name of the event <span className="text-red-500">*</span>
                                            </label>
                                            <Input
                                                onFocus={() => setErrorText(null)}
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)} 
                                                type="text" 
                                                id="name" 
                                                className="w-full border shadow-sm border-gray-400 rounded-md p-3 outline-none"
                                            />
                                            {
                                                !title && errorText && (
                                                    <p className="w-full text-left py-1 text-sm text-red-500">
                                                        Provide the event name
                                                    </p>
                                                )
                                            }
                                        </div>

                                        <div className="w-full flex flex-row items-center my-1">
                                            <div 
                                                className="w-full flex flex-col items-center my-1 mr-2">
                                                <label className="text-base font-medium text-black text-left w-full mb-3" for="urlkey">
                                                    Customized url slug <span className="text-red-500">*</span>
                                                </label>
                                                <Input
                                                    onFocus={() => setErrorText(null)}
                                                    value={urlKey}
                                                    onChange={(e) => setUrlKey(e.target.value)} 
                                                    type="text" 
                                                    id="urlkey" 
                                                    className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                                />
                                                {
                                                    urlKeyError && (
                                                        <p className="w-full text-sm text-red-400 font-medium">
                                                            Url is not available
                                                        </p>
                                                    )
                                                }
                                                {
                                                    !urlKey && errorText && (
                                                        <p className="w-full text-left py-1 text-sm text-red-500">
                                                            Provide the url slug
                                                        </p>
                                                    ) 
                                                }
                                            </div>
                                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                                <label className="text-base font-medium text-black text-left w-full mb-3" for="name">
                                                    Type of the event <span className="text-red-500">*</span>
                                                </label>
                                                <Input
                                                    onFocus={() => setErrorText(null)}
                                                    value={eventType}
                                                    onChange={(e) => setEventType(e.target.value)} 
                                                    type="text" 
                                                    id="eventType" 
                                                    className="w-full border shadow-sm border-gray-400 rounded-md p-3 outline-none"
                                                />
                                                {
                                                    !title && errorText && (
                                                        <p className="w-full text-left py-1 text-sm text-red-500">
                                                            Provide the event type
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        
                                        <div className="w-full flex flex-row items-center my-1">
                                            <div className="w-full flex flex-col items-center my-1 mr-2">
                                                <label className="text-base font-medium text-black text-left w-full mb-3" for="startDate">
                                                    Start of the event <span className="text-red-500">*</span>
                                                </label>
                                                <Input
                                                    onFocus={() => setErrorText(null)}
                                                    value={startDate}
                                                    onChange={(e) => {
                                                        const tempDate = new Date(e.target.value)?.toISOString();
                                                        setStartDate(e.target.value);
                                                    }} 
                                                    type="datetime-local"
                                                    id="startDate" 
                                                    className="w-full border shadow-sm border-gray-400 rounded-md p-3 outline-none"
                                                />
                                                {
                                                    !startDate && errorText && (
                                                        <p className="w-full text-left py-1 text-sm text-red-500">
                                                            Provide the event start date
                                                        </p>
                                                    )
                                                }
                                            </div> 
                                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                                <label className="text-base font-medium text-black text-left w-full mb-3" for="endDate">
                                                    End of the event <span className="text-red-500">*</span>
                                                </label>
                                                <Input
                                                    onFocus={() => setErrorText(null)}
                                                    value={endDate}
                                                    onChange={(e) => {
                                                        const tempDate = new Date(e.target.value)?.toISOString();
                                                        setEndDate(e.target.value);
                                                    }} 
                                                    type="datetime-local" 
                                                    id="endDate" 
                                                    className="w-full border shadow-sm border-gray-400 rounded-md p-3 outline-none"
                                                />
                                                {
                                                    !startDate && errorText && (
                                                        <p className="w-full text-left py-1 text-sm text-red-500">
                                                            Provide the event end date
                                                        </p>
                                                    )
                                                }
                                            </div> 
                                        </div>
                                                      
                                </div>
                            )
                        }

                        {/* STEP 02 */}
                        {
                            steps === 2 && (
                                <div 
                                    className="p-4 w-full flex flex-col items-start">
                                
                                    <div className="w-full flex flex-col items-center my-1">
                                        <label className="text-base font-medium text-black text-left w-full mb-3" for="description">
                                            Detailed description <span className="text-red-500">*</span>
                                        </label>
                                        <Editor
                                            onFocus={() => setErrorText(null)}
                                            editorState={descriptionState}
                                            toolbarClassName="w-full border rounded-md-tr rounded-md-tl"
                                            wrapperClassName="w-full border rounded-md"
                                            editorClassName="w-full border rounded-md-br rounded-md-bl"
                                            onEditorStateChange={e => onEditorChange(e, 'description')}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                                                inline: { inDropdown: false },
                                                list: { inDropdown: true },
                                                textAlign: { inDropdown: false },
                                                link: { inDropdown: true },
                                            }}
                                        />
                                        {
                                            !descriptionContent && errorText && (
                                                <p className="w-full text-left py-1 text-sm text-red-500">
                                                    Provide the event description
                                                </p>
                                            )
                                        }
                                    </div>
                                
                                    <div className="w-full flex flex-col items-center my-1">
                                        <label className="text-base font-medium text-black text-left w-full mb-3" for="terms">
                                            Event Terms and Conditions
                                        </label>
                                        <Editor
                                            onFocus={() => setErrorText(null)}
                                            editorState={termsState}
                                            toolbarClassName="w-full border rounded-md-tr rounded-md-tl"
                                            wrapperClassName="w-full border rounded-md"
                                            editorClassName="w-full border rounded-md-br rounded-md-bl"
                                            onEditorStateChange={e => onEditorChange(e, 'terms')}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                                                inline: { inDropdown: false },
                                                list: { inDropdown: true },
                                                textAlign: { inDropdown: false },
                                                link: { inDropdown: true },
                                            }}
                                        />
                                    </div>

                                    <div className="w-full flex flex-col items-center my-1">
                                        <label className="text-base font-medium text-black text-left w-full mb-3" for="faq">
                                            Frequently Asked Questions
                                        </label>
                                        <Editor
                                            onFocus={() => setErrorText(null)}
                                            editorState={faqsState}
                                            toolbarClassName="w-full border rounded-md-tr rounded-md-tl"
                                            wrapperClassName="w-full border rounded-md"
                                            editorClassName="w-full border rounded-md-br rounded-md-bl"
                                            onEditorStateChange={e => onEditorChange(e, 'faq')}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                                                inline: { inDropdown: false },
                                                list: { inDropdown: true },
                                                textAlign: { inDropdown: false },
                                                link: { inDropdown: true },
                                            }}
                                        />
                                    </div>

                                </div>
                            )
                        }

                        {/* STEP 03 */}
                        {
                            steps === 3 && (
                                <div 
                                    className="p-4 w-full flex flex-col items-start">
                                
                                    <div className="w-full flex flex-row items-center my-1">
                                        <div className="w-full flex flex-col items-center my-1 mr-2">
                                            <label className="text-base font-medium text-black text-left w-full py-2" for="isOnline">Event Conducted
                                            <span className="text-red-500"> *</span></label>
                                            <select
                                                onFocus={() => setErrorText(null)}
                                                value={conducted}
                                                onChange={(e) => handleSelectedChange('conducted', e)}
                                                id="country"
                                                className="w-full border border-gray-400 rounded-md p-3 outline-none"  
                                            >
                                                <option value="1">Online</option>
                                                <option value="0">Offline</option>
                                            </select>
                                        </div>
                                        <div className="w-full flex flex-col items-center my-1 ml-1">
                                            <label className="text-base font-medium text-black text-left w-full mb-3" for="url">
                                                Website URL
                                            </label>
                                            <Input
                                                onFocus={() => setErrorText(null)}
                                                value={url}
                                                onChange={(e) => setUrl(e.target.value)} 
                                                type="text" 
                                                id="url" 
                                                className="w-full border shadow-sm border-gray-400 rounded-md p-3 outline-none"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-col items-center my-1">
                                        <label className="text-base font-medium text-black text-left w-full mb-3" for="location">
                                            Location of the event <span className="text-red-500">*</span>
                                        </label>
                                        <Input
                                            onFocus={() => setErrorText(null)}
                                            value={locationDetail}
                                            onChange={(e) => setLocationDetail(e.target.value)} 
                                            type="text" 
                                            id="location" 
                                            className="w-full border shadow-sm border-gray-400 rounded-md p-3 outline-none"
                                        />
                                        {
                                            !locationDetail && errorText && (
                                                <p className="w-full text-left py-1 text-sm text-red-500">
                                                    Provide the event location
                                                </p>
                                            )
                                        }
                                    </div>

                                </div>
                            )
                        }

                        {/* STEP 04 */}
                        {
                            steps === 4 && (

                                <div 
                                    className="p-4 w-full flex flex-col items-start">
                                        
                                    <div className="w-full flex flex-row items-center my-1">
                                        <div className="w-full flex flex-col items-center my-1 mr-2">
                                            <label className="text-base font-medium text-black text-left w-full mb-3" for="publishDate">
                                                When to publish <span className="text-red-500">*</span>
                                            </label>
                                            <Input
                                                onFocus={() => setErrorText(null)}
                                                value={publishedDate}
                                                onChange={(e) => {
                                                    const tempDate = new Date(e.target.value)?.toISOString();
                                                    setPublishedDate(e.target.value);
                                                }} 
                                                type="datetime-local"
                                                id="publishDate" 
                                                className="w-full border shadow-sm border-gray-400 rounded-md p-3 outline-none"
                                            />
                                            {
                                                !startDate && errorText && (
                                                    <p className="w-full text-left py-1 text-sm text-red-500">
                                                        Provide the event publish date
                                                    </p>
                                                )
                                            }
                                        </div> 
                                        <div className="w-full flex flex-col items-center my-1 ml-1">
                                            <label className="text-base font-medium text-black text-left w-full py-2" for="timezone">Time Zone
                                            <span className="text-red-500"> *</span></label>
                                            <select
                                                onFocus={() => setErrorText(null)}
                                                onChange={(e) => setSelectedTimeZone(e.target.value)}
                                                id="visibility"
                                                className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                                value={dumpTimeZones?.filter(i => i?.abbr===selectedTimeZone)[0]?.text}  
                                            >
                                                <option value="" selected disabled>Select Time Zone</option>
                                            {
                                                dumpTimeZones?.map(times => (
                                                    <option value={times?.abbr}>{times?.text}</option>
                                                ))
                                            }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="w-full flex flex-col items-center my-1 ml-1">
                                            <label className="text-base font-medium text-black text-left w-full py-2" for="artist">Tags</label>
                                            <Select           
                                                onFocus={() => setErrorText(null)}             
                                                allowClear
                                                className="w-full rounded-md event__select outline-none"
                                                mode="tags"  
                                                notFoundContent="No Tags Create one"                                              
                                                placeholder="Add tags for your event"
                                                value={tagSelect}
                                                onChange={handleTagsChange}
                                            >        
                                                {
                                                    tags.length &&
                                                    tags.map(ttype => (
                                                        <Option key={ttype.name}>{ttype.name}</Option>
                                                    ))                
                                                }        
                                            </Select>  
                                        </div>
                                    
                                    <div className="w-full flex flex-row items-center my-1">
                                        <div className="w-full flex flex-col items-center my-1 mr-2">
                                            <label className="text-base font-medium text-black text-left w-full py-2" for="image">Event Image 
                                                {files&&(<span onClick={(e) => handlePreviewImage(e)} className="text-sm hover:underline mx-2 cursor-pointer text-blue-400">Preview</span>)} 
                                                {imageErrorText&&(<span className="text-sm text-red-500 mx-2">{imageErrorText}</span>)} 
                                                {image && query.get('type') === 'edit' && (<span onClick={(e) => handlePreviewImage(e)} className="text-sm hover:underline mx-2 cursor-pointer text-blue-400">Preview</span>)}
                                                {image && query.get('type') === 'edit' &&(<span onClick={(e) => handleRemoveImage(e)} className="hover:underline text-sm text-red-500 mx-2 cursor-pointer">Remove Image</span>)}
                                            </label>
                                            <Input onChange={(e) => imageOnChange(e)} type="file" id="image" className="w-full border border-gray-400 rounded-md p-2 outline-none"/>
                                        </div>  
                                        <div className="w-full flex flex-col items-center my-1 ml-1">
                                            <label className="text-base font-medium text-black text-left w-full py-2" for="visibility">Privacy
                                            <span className="text-red-500"> *</span></label>
                                            <select
                                                onFocus={() => setErrorText(null)}
                                                value={visibility}
                                                onChange={(e) => handleSelectedChange('visibility', e)}
                                                id="visibility"
                                                className="w-full border border-gray-400 rounded-md p-3 outline-none"  
                                            >
                                                <option value="" selected disabled>Select Visibility</option>
                                                <option value="private">Private</option>
                                                <option value="public">Public</option>
                                                <option disabled={!community} value="community">Community Event</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="w-full flex flex-col items-start my-1 mb-2">
                                        <label className="text-base font-medium text-black text-left w-full py-2">How many seats do you contain? <span className="text-red-500"> *</span></label>
                                        <Radio.Group className="flex flex-row" onChange={e => setIsUnlimited(e.target.value)} value={isUnlimited}>
                                            <Radio value={true}>Unlimited</Radio>
                                            <Radio value={false}>Limited</Radio>
                                        </Radio.Group>
                                        {
                                            isUnlimited===false && (
                                                <>
                                                    <label className="text-base font-medium text-black text-left w-full py-2" for="spots">Mention the number of spots available <span className="text-red-500"> *</span></label>
                                                    <Input
                                                        min="1"
                                                        onFocus={() => setErrorText(null)}
                                                        value={spots}
                                                        onChange={e => setSpots(e.target.value)}
                                                        type="number"
                                                        id="spots" 
                                                        className="w-1/4 border shadow-sm border-gray-400 rounded-md p-3 outline-none"
                                                    />
                                                </>
                                            )
                                        }
                                    </div>

                                    {
                                        query.get('type') !== 'edit' && (
                                            <div className="w-full flex flex-row items-center my-2">
                                                <input
                                                    defaultChecked={false}
                                                    onChange={e => setAgreement(e.target.checked)}
                                                    style={{height: 18, width: 18}} 
                                                    type="checkbox"
                                                    id="agree" 
                                                    className="border border-gray-400 rounded-md p-3 outline-none" 
                                                />
                                                <label for="agree" className="w-full text-md mx-3 text-left text-gray-700">
                                                    By creating the event you agree to Assistance Org’s <a target="_blank" href="#">Terms and Conditions</a>.
                                                </label>
                                            </div>
                                        )
                                    }
                                
                                </div>
                            )
                        }

                        <div 
                            className="w-full flex flex-row items-end justify-end my-2 p-4">
                            {
                                steps > 1 && (
                                    <button
                                        onClick={() => handlePreviousStep()}
                                        className="px-5 text-base text-white bg-gray-500 ml-3 rounded-sm cursor-pointer mr-3 outline-none focus:outline-none"
                                        style={{height: 40}}              
                                    >Previous
                                    </button>
                                )
                            }
                            {
                                steps !== 4 && (
                                    <button
                                        onClick={() => handleNextStep()}
                                        className="px-5 text-base text-white primary-bg ml-3 rounded-sm cursor-pointer outline-none focus:outline-none"
                                        style={{height: 40}}              
                                    >Next
                                    </button>
                                )
                            }
                            {
                                steps === 4 && (
                                    <Button
                                        loading={load}
                                        type="primary"
                                        disabled={!allowMeToCreate && query.get('type') !== 'edit'}
                                         onClick={() => onSubmit(query.get('type')==='edit'?'edit':'create')}
                                        className="px-5 text-base text-white primary-bg ml-3 rounded-sm cursor-pointer outline-none focus:outline-none"
                                        style={{height: 40}}              
                                    >Submit
                                    </Button>
                                )
                            }
                        </div>

                   </div>
                   
                   {/* <div className="w-full flex flex-col items-center rounded-md border bg-white p-4 shadow mb-10">

                        <div className="w-full flex flex-col items-center my-1">
                            <label className="text-lg text-left w-full py-2" for="name">Name of the event <span className="text-red-500">*</span></label>
                            <Input
                                value={title}
                                onChange={(e) => setTitle(e.target.value)} 
                                type="text" 
                                id="name" 
                                className="w-full border border-gray-400 rounded-md p-3 outline-none"
                            />
                        </div>

                        <div className="w-full flex flex-col items-center my-1">
                            <label className="text-lg text-left w-full py-2" for="urlkey">Event URL <span className="text-red-500">*</span></label>
                            <div className="w-full flex flex-row items-center">
                                <p className="text-left px-2 text-lg text-black">
                                    {`${window.location.protocol}//${window.location.host}/e/`}
                                </p>
                                <Input
                                    value={urlKey}
                                    onChange={(e) => setUrlKey(e.target.value)} 
                                    type="text" 
                                    id="urlkey" 
                                    className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                />
                            </div>
                            {
                                urlKeyError && (
                                    <p className="w-full text-sm text-red-400 font-medium">
                                        Url is not available
                                    </p>
                                )
                            }
                        </div>

                        <div className="w-full flex flex-col items-center my-1">
                            <label className="text-lg text-left w-full py-2" for="description">Event Information <span className="text-red-500">*</span></label>
                            <Input
                                value={shortDescription}
                                onChange={(e) => setShortDescription(e.target.value)} 
                                type="text" 
                                id="description" 
                                className="w-full border border-gray-400 rounded-md p-3 outline-none"
                            />
                            <Editor
                                editorState={descriptionState}
                                toolbarClassName="w-full border rounded-md-tr rounded-md-tl"
                                wrapperClassName="w-full border rounded-md"
                                editorClassName="w-full border rounded-md-br rounded-md-bl"
                                onEditorStateChange={e => onEditorChange(e, 'description')}
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                                    inline: { inDropdown: false },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: false },
                                    link: { inDropdown: true },
                                }}
                            />
                        </div>

                        <div className="w-full flex flex-col items-center my-1">
                            <label className="text-lg text-left w-full py-2" for="terms">Terms and Conditions</label>
                            <Editor
                                editorState={termsState}
                                toolbarClassName="w-full border rounded-md-tr rounded-md-tl"
                                wrapperClassName="w-full border rounded-md"
                                editorClassName="w-full border rounded-md-br rounded-md-bl"
                                onEditorStateChange={e => onEditorChange(e, 'terms')}
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                                    inline: { inDropdown: false },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: false },
                                    link: { inDropdown: true },
                                }}
                            />
                        </div>

                        <div className="w-full flex flex-col items-center my-1">
                            <label className="text-lg text-left w-full py-2" for="description">FAQs</label>
                            <Editor
                                editorState={faqsState} 
                                toolbarClassName="w-full border rounded-md-tr rounded-md-tl"
                                wrapperClassName="w-full border rounded-md"
                                editorClassName="w-full border rounded-md-br rounded-md-bl"
                                onEditorStateChange={e => onEditorChange(e, 'faq')}
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                                    inline: { inDropdown: false },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: false },
                                    link: { inDropdown: true },
                                }}
                            />
                        </div>

                        <div className="w-full flex flex-col items-center my-1">
                            <label className="text-lg text-left w-full py-2" for="url">Website URL<span className={`text-red-500 ${conducted==='0'&&'hidden'}`}>*</span></label>
                            <Input
                                value={url}
                                onChange={(e) => setUrl(e.target.value)} 
                                type="url" 
                                id="url" 
                                className="w-full border border-gray-400 rounded-md p-3 outline-none"
                            />
                        </div>

                        <div className="w-full flex flex-row items-center my-1">
                            <div className="w-full flex flex-col items-center my-1 mr-2">
                                <label className="text-lg text-left w-full py-2" for="mode">Event Conducted <span className="text-red-500">*</span></label>
                                <select
                                    defaultValue={conducted}
                                    onChange={e => handleSelectedChange('conducted', e)} 
                                    id="mode" 
                                    className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                >
                                    <option value="1">Online</option>
                                    <option value="0">Offline</option>
                                </select>
                            </div>
                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                <label className="text-lg text-left w-full py-2" for="eventype">Event Type <span className="text-red-500">*</span></label>
                                <Input
                                    value={eventType}
                                    onChange={(e) => setEventType(e.target.value)} 
                                    type="text" 
                                    id="eventype" 
                                    className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-col items-center my-1">
                            <label className="text-lg text-left w-full py-2" for="location">Location Detail <span className={`text-red-500`}>*</span></label>
                            <Input
                                value={locationDetail}
                                onChange={(e) => setLocationDetail(e.target.value)} 
                                type="text" 
                                id="location"
                                placeholder="Short Address" 
                                className="w-full border border-gray-400 rounded-md p-3 outline-none"
                            />
                        </div>
                        <div className="w-full flex flex-row items-center my-1">
                            <div className="w-full flex flex-col items-center my-1 mr-2">
                                <label className="text-lg text-left w-full py-2" for="image">Image 
                                    {files&&(<span onClick={(e) => handlePreviewImage(e)} className="text-sm hover:underline mx-2 cursor-pointer text-blue-400">Preview</span>)} 
                                    {imageErrorText&&(<span className="text-sm text-red-500 mx-2">{imageErrorText}</span>)} 
                                    {image && query.get('type') === 'edit' && (<span onClick={(e) => handlePreviewImage(e)} className="text-sm hover:underline mx-2 cursor-pointer text-blue-400">Preview</span>)}
                                    {image && query.get('type') === 'edit' &&(<span onClick={(e) => handleRemoveImage(e)} className="hover:underline text-sm text-red-500 mx-2 cursor-pointer">Remove Image</span>)}
                                </label>
                                <Input onChange={(e) => imageOnChange(e)} type="file" id="image" className="w-full border border-gray-400 rounded-md p-2 outline-none"/>
                            </div>
                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                <label className="text-lg text-left w-full py-2" for="artist">Event Tags</label>
                                <Select                        
                                    allowClear
                                    className="w-full rounded-md event__select outline-none"
                                    mode="tags"  
                                    notFoundContent="No Tags Create one"                                              
                                    placeholder="Add tags for your event"
                                    value={tagSelect}
                                    onChange={handleTagsChange}
                                >        
                                    {
                                        tags.length &&
                                        tags.map(ttype => (
                                            <Option key={ttype.name}>{ttype.name}</Option>
                                        ))                
                                    }        
                                </Select>  
                            </div>
                        </div>

                        <div className="w-full flex flex-row items-center my-1">
                            <div className="w-full flex flex-col items-center my-1 mr-2">
                                <label className="text-lg text-left w-full py-2" for="start">Start Date <span className="text-red-500">*</span></label>
                                <Input
                                    value={startDate}
                                    onChange={(e) => {
                                        const tempDate = new Date(e.target.value)?.toISOString();
                                        setStartDate(e.target.value);
                                    }} 
                                    type="datetime-local" 
                                    id="start" 
                                    min={moment().format("YYYY-MM-DD")}
                                    className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                />
                            </div>
                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                <label className="text-lg text-left w-full py-2" for="end">End Date <span className="text-red-500">*</span></label>
                                <Input
                                    value={endDate}
                                    onChange={(e) => {
                                        const tempDate = new Date(e.target.value)?.toISOString();
                                        setEndDate(e.target.value);
                                    }} 
                                    type="datetime-local" 
                                    id="end" 
                                    min={startDate?moment(new Date(startDate)?.toISOString()).format("YYYY-MM-DD"):moment().format("YYYY-MM-DD")}
                                    className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                />
                            </div>
                        </div>

                        <div className="w-full flex flex-row items-center my-1">
                            <div className="w-full flex flex-col items-center my-1 mr-2">
                                <label className="text-lg text-left w-full py-2" for="publishdate">When to publish <span className="text-red-500">*</span></label>
                                <Input
                                    value={publishedDate}
                                    onChange={(e) => {
                                        const tempDate = new Date(e.target.value)?.toISOString();
                                        setPublishedDate(e.target.value);
                                    }} 
                                    type="datetime-local" 
                                    id="publishdate"
                                    min={moment().format("YYYY-MM-DD")} 
                                    max={startDate?moment(new Date(startDate)?.toISOString()).format("YYYY-MM-DD"):moment().format("YYYY-MM-DD")}
                                    className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                />
                            </div>
                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                <label className="text-lg text-left w-full py-2" for="visible">Visibility <span className="text-red-500">*</span></label>
                                <select 
                                    disabled={community}
                                    defaultValue={visibility}
                                    value={visibility}
                                    onChange={(e) => handleSelectedChange('visibility', e)}
                                    id="visible" 
                                    className="w-full border border-gray-400 rounded-md p-3 outline-none">
                                    <option value="" selected disabled>Select Visibility</option>
                                    <option value="private">Private</option>
                                    <option value="public">Public</option>
                                    <option value="community">Community Event</option>
                                </select>
                            </div>
                        </div>

                        <div className="w-full flex flex-row items-center my-1 ml-1">
                            <input checked={askCount} onChange={e => setAskCount(e.target.checked)} type="checkbox" />
                            <label className="mx-2 text-md text-left w-full py-2" for="people_count">Do you want to ask how many are going to attend in the invitation ?</label>
                        </div>

                        {
                            query.get('type') !== 'edit' && (
                                <div className="w-full flex flex-row items-center my-3">
                                    <input
                                        defaultChecked={false}
                                        onChange={e => setAgreement(e.target.checked)}
                                        style={{height: 18, width: 18}} 
                                        type="checkbox" 
                                        className="border border-gray-400 rounded-md p-3 outline-none" 
                                    />
                                    <p className="w-full text-md mx-3 text-left text-gray-700">
                                        By creating the community you agree to Assistance Org’s <a target="_blank" href="#">Terms and Conditions</a>.
                                    </p>
                                </div>
                            )
                        }

                        <div className="w-full flex flex-row items-center my-2">
                            <Button
                                loading={load}
                                onClick={() => onSubmit(query.get('type')==='edit'?'edit':'create')}
                                disabled={!allowMeToCreate && query.get('type') !== 'edit'}
                                type="primary"
                                className="px-5 text-lg text-white primary-bg rounded-md cursor-pointer"
                                style={{height: 45}}                    
                            >{query.get("type") === 'edit' ? 'Save' : 'Create'}
                            </Button>
                            {
                                query.get('type') === 'edit' && (
                                    <Button
                                        onClick={() => handleDelete()}
                                        loading={removeLoadingState}
                                        type="danger"
                                        className="px-5 text-lg text-white primary-bg rounded-md cursor-pointer mx-3"
                                        style={{height: 45}}                    
                                    >Delete
                                    </Button>
                                )
                            }
                            <Link
                                type="default"
                                to="/event"
                                className="mx-3 px-5 py-2 text-lg border-gray-600 border text-gray-600 rounded-md cursor-pointer"                    
                            >Cancel                    
                            </Link>
                        </div>

                   </div> */}

               </div>
                )}
               <Modal
                visible={previewModal}
                title="Preview Image"
                footer={null}
                className="apps"
                onCancel={() => setPreviewModal(false)}
                destroyOnClose={true}
               >
                   <div className="p-3">
                       <img
                        src={tempPreviewImage}
                        className="object-cover h-40 w-full"
                        alt="preview"
                       />
                   </div>
               </Modal>
    
            <Footer />
        </>
    )
}

export default Create