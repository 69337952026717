/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import defaultImage from '../../../assets/img/event-1.png'
import { PushpinFilled, SmileFilled } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'

function CItem(props) {

    const history = useHistory();
    
    return (
        <div className="border rounded-md p-3 ml-3 flex flex-row items-start shadow c__item">
            {
                !props?.isImage ? null : (
                    <img
                        onClick={() => history.push(`/${props.url_key}`)}
                        className="w-20 h-20 object-cover rounded-lg cursor-pointer" 
                        src={props?.logo?`${process.env.REACT_APP_SERVER_URL}${props?.logo}`:defaultImage} alt="Five developers at work."
                    />
                )
            }
            <div className="w-full flex flex-col items-start ml-2 pb-2">
                <Link to={`/${props.url_key}`} className="cursor-pointer hover:underline whitespace-nowrap breif__title overflow-hidden overflow-ellipsis font-medium text-lg text-blue-500 text-left">
                    {props.name}
                </Link>                
                <div className="flex flex-row items-center">
                    <PushpinFilled />
                    <p className="ml-2 text-sm text-black py-1 text-left">{props?.state}, {props?.country}</p>
                </div>
                <div className="flex flex-row items-center">
                    <SmileFilled />
                    <p className="ml-2 text-sm text-black py-1 text-left">{props?.members} Members</p>
                </div>
            </div>
            {/* <a href="#" className="w-full text-blue-500 text-md text-left py-2 px-3 flex flex-row items-center">
                {props?.org_type}
            </a> */}
        </div>
    )
}

export default CItem
