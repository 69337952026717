/* eslint-disable jsx-a11y/anchor-is-valid */
import { message, Popconfirm } from 'antd';
import moment from 'moment'
import React from 'react'
import apiInstance from '../../../api';
import { getProfileImage } from '../../../utils/Helper'
import {useDataLayerValue} from '../../../DataLayer'
import { DeleteOutlined } from '@ant-design/icons'
import draftToHtml from 'draftjs-to-html';

function DiscussionItem(props) {

    const [{user}] = useDataLayerValue();

    const handleDelete = () => {
        apiInstance
        .delete(`/discussion/${props.id}`)
        .then(response => {
            props.handleGetDiscussions();
            message.success('Content Removed');
        })
        .catch(e => {
            message.error('Internal Server Error');
        });
    };

    return (
        <div className="border w-full my-2 flex flex-row items-start justify-between bg-white rounded-sm shadow p-2">
            <div className="w-1/3 flex flex-row items-start">
            <img
                src={props?.avatar ? props?.avatar?.indexOf('h')==='0'?props?.avatar:`${process.env.REACT_APP_SERVER_URL}${props?.avatar}`: getProfileImage(props?.username || 'Assistance')}
                // src="https://bestprofilepictures.com/wp-content/uploads/2021/04/Cool-Profile-Picture-For-Discord.jpg"
                alt="avatar"
                className="h-24 w-24 rounded-md border object-cover"
            />
            <div className="px-3 flex flex-col items-start">
                <h3 className="text-md font-medium text-black text-left w-full">
                    {props.username}
                </h3>
                <p className="text-sm text-gray-600 text-left w-full py-1">
                    {props.isOwner?'Organizer':'Attendee'}
                </p>
                <p className="text-xs text-gray-600 text-left w-full">
                    {moment(props.modified_at).fromNow()}
                </p>
            </div>
            </div>
            <div className="w-full text-left px-4 flex flex-col item-start">
                <div dangerouslySetInnerHTML={{__html: draftToHtml(JSON.parse(props.content))}}>
                </div>
            </div>
            {
                user?.id === props?.userId && (
                    <Popconfirm
                        className="mx-2"                             
                        onConfirm={() => handleDelete()}
                        title="Are you sure?"                                     
                        okText={"Yes"}                                        
                        cancelText={"No"}
                    ><a><DeleteOutlined /></a> 
                    </Popconfirm>                    
                    // <p onClick={() => handleDelete()} className="text-red-500 text-sm px-2 underline">Delete</p>
                )
            }
        </div>
    )
}

export default DiscussionItem
