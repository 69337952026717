/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react'
import apiInstance from '../../../api';
import Header from '../../../components/User/v2/Header'
import { useParams, Link, useHistory } from 'react-router-dom'
import { message, Spin, Modal, Popconfirm, Comment, Tag, Input, Tooltip } from 'antd'
import { FacebookFilled, WhatsAppOutlined, TwitterOutlined } from '@ant-design/icons'
import '../Blogs/styles.css';
import Footer from '../../../components/User/v2/Footer';
import { getProfileImage } from '../../../utils/Helper';
import moment from 'moment';
import { useDataLayerValue } from '../../../DataLayer';
import ReplyComment from '../../../components/User/ReplyComment'

function Detail() {

    const { key } = useParams();
    const [loading, setLoading] = useState(false);
    const [feedData, setfeedData] = useState(null);
    const [{user, token}] = useDataLayerValue();
    const commentLimit = 500;
    const history = useHistory();

    //comments
    const [feedComments, setFeedComments] = useState([]);
    const [userComment, setUserComment] = useState('');            
    const [commentLoad, setCommentLoad] = useState(false);    
    const [commentReplyLoad, setCommentReplyLoad] = useState(false); 
    const [commentInputModal, setCommentInputModal] = useState(false);
    const [currentLevel1Key, setCurrentLevel1Key] = useState(null);
    const [level1Reply, setLevel1Reply] = useState('');    
    const [level2RepliesModal, setLevel2RepliesModal] = useState(false);
    const [level2Replies, setLevel2Replies] = useState([]);

    async function findBlogByKey() {
        setLoading(true);
        apiInstance
        .get(`/feed/key/${key}`)    
        .then(response => {
            setfeedData(response.data.data);
            getFeedComments(response.data.data.id);
        })
        .catch(e => {
            console.log(e);
            message.error("Blog Not Found");
        })
        .finally(() => setLoading(false));
    }

    const openCommentInputModal = (data) => {
        setCurrentLevel1Key(data);
        setCommentInputModal(true);
    }

    const handleOkInputModal = () => {
        if (level1Reply) {
            addCommentReply(currentLevel1Key?.id, 2, level1Reply);
        } else {
            message.warn('Reply cannot be empty');
        }
    }

    const handleCancelInputModal = () => {
        setLevel1Reply('');
        setCurrentLevel1Key(null);
        setCommentInputModal(false);
    }
    
    async function getFeedComments(id) {        
        apiInstance.get(`/feedcomments/${feedData?.id || id}`)
                .then(comments => {
                    comments.data.data.reverse();                     
                    setFeedComments(constructFeedComments(comments.data.data, null))
                }).catch(e => {                    
                    if (e.response.status === 404) {
                        setFeedComments([]);                        
                    }
                });
    }

    async function getCommentReplies(parentId, level=1, allComments=null)  {
        apiInstance.get(`/feedcomments/child/${parentId}/feed/${feedData?.id}`)
            .then(comments => {                
                comments.data.data.reverse();                                
                if(allComments) {
                    addCommentReplies(parentId, comments.data.data, allComments);
                }                
            }).catch(e => console.log(e));
    }

    const handleRepliesView = (parentId, level=1, comments) => {
        getCommentReplies(parentId, level=1, comments);
    }

    const deleteMyCommentById = (comment, level, data) => {        
        if (comment?.id) {
            apiInstance.delete(`/feedcomments/${comment.id}?level=${level}&parent=${comment?.parent_id}`)
                    .then(response => {
                        message.success(response.data?.data || 'Comment Deleted');
                        setLevel2RepliesModal(false);
                        getFeedComments();
                    }).catch(e => {
                        console.log(e);
                        if(e.response || e.response?.data) {                                    
                            if(e.response.status === 401) {                                
                                    sessionStorage.clear();
                                    message.error(typeof e.response.data.message === 'string' ? e?.response?.data?.message : 'Something went wrong');
                                    window.location.reload();
                                }                                                
                            }                        
                    });
        } else message.warning("Invalid Operation");
    }

    const softDeleteCommentById = (status, id) => {
        if (id) {
            apiInstance.put(`/feedcomments/status/${id}`, { status: status })
                    .then(response => {
                        message.success(response.data?.data || 'Comment Modified');
                        setLevel2RepliesModal(false);
                        getFeedComments();
                    }).catch(e => {
                        console.log(e);
                        if(e.response || e.response?.data) {                                    
                            if(e.response.status === 401) {                                
                                    sessionStorage.clear();
                                    message.error(typeof e.response.data.message === 'string' ? e?.response?.data?.message : 'Something went wrong');
                                    window.location.reload();
                                }                                                
                            }                        
                    });
        } else message.warning("Invalid Operation");
    }

    const handleRepliesViewModal = (id) => {        
        async function getLevel2Replies(parentId, level=2)  {
            setLevel2Replies([]);
            apiInstance.get(`/feedcomments/child/${parentId}/feed/${feedData?.id}`)
                .then(comments => {                
                    comments.data.data.reverse();                                
                    setLevel2Replies(constructFeedComments(comments.data.data));
                    setLevel2RepliesModal(true);
                }).catch(e => console.log(e));
        }
        getLevel2Replies(id);
    }

    const constructFeedComments = (data) => {                
        let result = [];
        for (let key of data) {
            if (key?.is_active === 1) {                
                let tempData = {};
                tempData['parentId'] = key.parent_id;
                tempData['key'] = key.id;
                tempData['level'] = key?.level;
                tempData['id'] = key?.id;  
                tempData['replies'] = key?.replies;
                let children = [];                                   
                if (key.replies > 0 && key.level === 0) {                    
                    let childrenTag = <Tag key={key.id+'tag'} onClick={() => handleRepliesView(key?.id, 1, data)} color="grey">View {key?.replies||'0'} {key.replies>1?'replies':'reply'}</Tag>;
                    children.push(childrenTag);
                    tempData['replyChildren'] = children;
                } else if (key.replies > 0 && key.level === 1) {                    
                    let childrenTag = <Tag key={key.id+'tag'} onClick={() => handleRepliesViewModal(key?.id)} color="grey">View {key?.replies||'0'} {key.replies>1?'replies':'reply'}</Tag>;
                    children.push(childrenTag);
                    tempData['replyChildren'] = children;
                } else tempData['replyChildren'] = null;  
                if (!tempData['children']) {
                    tempData['children'] = key.children;
                }
                if (user?.id === key?.created_by || user?.role === 'Super User') {
                    tempData['actions'] = [
                        !!user && (key.level===0) && (
                            <span onClick={() => addTempCommentInsertionArea(key, data, key.level) } key="comment-list-reply-to-0">Reply</span>
                        ),
                        !!user && (key.level===1) && (
                            <span onClick={() => openCommentInputModal(key) } key="comment-list-reply-to-0">Reply</span>
                        ),
                        (user?.id === key.created_by) &&                 
                            (
                            <Popconfirm
                                onConfirm={() => deleteMyCommentById(key, key.level, feedComments)}
                                title="Are you sure?"                
                                okText={"Yes"}                                        
                                cancelText={"No"}
                            ><span >delete</span>             
                            </Popconfirm>
                            ),
                        (user?.role === 'Super User') &&                
                            (
                            <Popconfirm
                                onCancel={() => softDeleteCommentById(false, key.id)}
                                onConfirm={() => softDeleteCommentById(true ,key.id)}
                                title="Modify Status"                
                                okText={"Activate"}                                        
                                cancelText={"Block"}
                            ><span >modify</span>             
                            </Popconfirm> 
                            )                   
                    ];
                } else if (key.level===0 || key.level===1){
                    tempData['actions'] = [
                        !!user && (key.level===0) && (
                            <span onClick={() => addTempCommentInsertionArea(key, data, key.level) } key="comment-list-reply-to-0">Reply</span>
                        ),
                        !!user && (key.level===1) && (
                            <span onClick={() => openCommentInputModal(key) } key="comment-list-reply-to-0">Reply</span>
                        )];
                } else {
                    tempData['actions'] = null;
                }
                tempData['author']  = <p className="cursor-pointer" onClick={() => parseInt(key?.is_person_active)===1?history.push(`/@${key?.username}`):''}>{user?.role==='Super User'?parseInt(key?.is_person_active)===1 ? key?.username || 'Assistance User' : `${key?.username} (Deactivated User)`:parseInt(key?.is_person_active)===1 ? key?.username || 'Assistance User' : 'Deactivated User'}</p>;
                tempData['avatar'] = parseInt(key?.is_person_active)===1 ? key?.image || getProfileImage(key?.username||'Assistance') : getProfileImage('Assistance');
                tempData['content'] = (
                    <p>{key?.content||''}</p>
                )
                tempData['datetime'] = (
                    <Tooltip title={moment(key?.modified_at).format('YYYY-MM-DD HH:mm:ss')}>
                    <span>{moment(key?.modified_at).fromNow()}</span>
                    </Tooltip>
                );                                
                result.push(tempData);
            } else {
                if (key?.created_by === user?.id || user?.role === 'Super User') {
                    let tempData = {};
                    tempData['key'] = key.id;
                    tempData['level'] = key?.level;
                    tempData['id'] = key?.id;
                    tempData['replies'] = key?.replies;
                    let children = [];                   
                    if (key.replies > 0 && key.level === 0) {
                        let childrenTag = <Tag key={key.id+'tag'} onClick={() => handleRepliesView(key?.id, 1, data)} color="grey">View {key?.replies||'0'} {key?.replies>1?'replies':'reply'}</Tag>;
                        children.push(childrenTag);
                        tempData['replyChildren'] = children;
                    } else tempData['replyChildren'] = null;
                    if (key.replies > 0 && key.level === 1) {                    
                        let childrenTag = <Tag key={key.id+'tag'} onClick={() => handleRepliesViewModal(key?.id)} color="grey">View {key?.replies||'0'} {key?.replies>1?'replies':'reply'}</Tag>;
                        children.push(childrenTag);
                        tempData['replyChildren'] = children;
                    } else tempData['replyChildren'] = null; 
                    if (user?.id === key?.created_by || user?.role === 'Super User') {
                        tempData['actions'] = [
                            // <span onClick={() => addTempCommentInsertionArea(key, data) } key="comment-list-reply-to-0">Reply</span>,
                            (user?.id === key.created_by) &&                
                               (
                                <Popconfirm
                                    onConfirm={() => deleteMyCommentById(key, key.level, feedComments)}
                                    title="Are you sure?"                
                                    okText={"Yes"}                                        
                                    cancelText={"No"}
                                ><span >delete</span>             
                                </Popconfirm>
                               ),
                            (user?.role === 'Super User') &&                
                               (
                                <Popconfirm
                                    onCancel={() => softDeleteCommentById(false, key.id)}
                                    onConfirm={() => softDeleteCommentById(true ,key.id)}
                                    title="Modify Status"                
                                    okText={"Activate"}                                        
                                    cancelText={"Block"}
                                ><span >modify</span>             
                                </Popconfirm>
                               )                    
                        ];
                    }            
                    tempData['author']  = <p className="cursor-pointer" onClick={() => history.push(`/@${key?.username}`)}>{parseInt(key?.is_person_active)===1 ? key?.username || 'Assistance User' : `${key?.username || ''} (Deactivated User)`}</p>;
                    tempData['avatar'] = key?.image || getProfileImage(key?.username||'Assistance');
                    tempData['content'] = (
                        <p><Tag color="red">Blocked By Admin</Tag> {key?.content||''}</p>
                    );
                    tempData['datetime'] = (
                        <Tooltip title={moment(key?.modified_at).format('YYYY-MM-DD HH:mm:ss')}>
                        <span>{moment(key?.modified_at).fromNow()}</span>
                        </Tooltip>
                    );
                    // tempData.children = null;
                    result.push(tempData);
                }                
            }            
        }
        return result;
    }

    const addComment = (parentId=null, level=0) => {        
    
        if (userComment?.trim()) {
        if (feedData) {
            let payload = {
                content: userComment.trim(),
                feedId: feedData?.id,
                parentId: parentId,
                level: level                 
            }
            // console.log(payload);
            setCommentLoad(true);
            apiInstance.post("/feedcomments", payload)
                .then(response => {
                    setCommentLoad(false);
                    message.success(response.data.data);
                    setUserComment('');
                    getFeedComments();
                }).catch(e => {
                    setCommentLoad(false);
                    console.log(e);
                    if(e.response || e.response?.data) {                                    
                        if(e.response.status === 401) {                    
                            message.warning('Session Expired');
                            sessionStorage.clear();                        
                            window.location.replace("/signin");                                                                 
                        } else message.warn(typeof e.response?.data?.message === 'string' ? e.response.data.message : 'Something went wrong');
                    } else message.error("Internal Server Error"); 
                });
        } else message.warning('No feed available');
        } else message.warning('Comment is empty');
    }

    const addCommentReply = (parentId, level, comment) => {
        if (comment?.trim()) {
            if (feedData) {
                let payload = {
                    content: comment.trim(),
                    feedId: feedData?.id,
                    parentId: parentId,
                    level: level                 
                }
                setCommentReplyLoad(true);
                apiInstance.post("/feedcomments", payload)
                    .then(response => {
                        setLevel1Reply('');
                        setCommentReplyLoad(false);
                        setCommentInputModal(false);
                        message.success(response.data.data);                                                
                        getFeedComments();
                    }).catch(e => {
                        setLevel1Reply('');
                        setCommentReplyLoad(false); 
                        setCommentInputModal(false);                       
                        console.log(e);
                        if(e.response || e.response?.data) {                                    
                            if(e.response.status === 401) {                    
                                message.warning('Session Expired');
                                sessionStorage.clear();                        
                                window.location.replace("/signin");                                                                 
                            } else message.warn(typeof e.response?.data?.message === 'string' ? e.response.data.message : 'Something went wrong');
                        } else message.error("Internal Server Error"); 
                    });
            } else message.warning('No blog available');
        } else {
            message.warning('No Reply Comment Found!');
        }
    }
    
    const FeedComment = ({ children, item }) => (
        <Comment    
            key={item.id}
            actions={item?.actions||null}
            author={item.author}
            avatar={item.avatar}
            content={item.content}
            datetime={item.datetime}
        >  
        {/* {
            children?.map(level1Item => (
                <Comment        
                    key={level1Item.id}
                    actions={level1Item.actions}
                    author={level1Item.author}
                    avatar={level1Item.avatar}
                    content={level1Item.content}
                    datetime={level1Item.datetime}                                                                
                ></Comment>
        } */}
        {/* {
                children?.map(level1Item => (
                    <Comment        
                        key={level1Item.id}
                        actions={level1Item.actions}
                        author={level1Item.author}
                        avatar={level1Item.avatar}
                        content={level1Item.content}
                        datetime={level1Item.datetime}                                                                
                    >
                        {
                            level1Item?.children.map(level2Item => (
                                <Comment        
                                    key={level2Item.id}
                                    actions={level2Item.actions}
                                    author={level2Item.author}
                                    avatar={level2Item.avatar}
                                    content={level2Item.content}
                                    datetime={level2Item.datetime}                                                                
                                >
                                    {
                                        level2Item?.children.map(level3Item => (
                                            <Comment        
                                                key={level3Item.id}
                                                actions={level3Item.actions}
                                                author={level3Item.author}
                                                avatar={level3Item.avatar}
                                                content={level3Item.content}
                                                datetime={level3Item.datetime}                                                                
                                            ></Comment>
                                        ))
                                    }           
                                </Comment>
                            ))
                        }                       
                    </Comment>
                ))
            }     */}
            {
                children
            }                         
        </Comment>
    )         

    const addTempCommentInsertionArea = (comment=null, data, level) => {                                           
        let comments = constructFeedComments(data);                      
            let cIdx = data.findIndex(c => c.id === comment.id);              
            if (comment && cIdx > -1) {                          
                    if (comments[cIdx]?.children) {                                  
                        (comments[cIdx].children).push(<Comment key={comment.id+data.length} avatar={user?.image} content={<ReplyComment level={level+1} parentId={comment.id} addComment={addCommentReply} load={commentReplyLoad} />}></Comment>);                                                
                    } else {
                        comments[cIdx].children=[];            
                        (comments[cIdx].children).push(<Comment key={comment.id+data.length} avatar={user?.image} content={<ReplyComment level={level+1} parentId={comment.id} addComment={addCommentReply} load={commentReplyLoad} />}></Comment>);                                                
                    }                                    
                setFeedComments(comments);
            }                
    }

    const addCommentReplies = (commentId, data, allComments) => {                         
        let cIdx = allComments.findIndex(c => c.id === commentId);
        allComments = constructFeedComments(allComments);        
        if (commentId && cIdx !== -1) {            
            allComments[cIdx].children = [];         
            data = constructFeedComments(data);
            // eslint-disable-next-line
            data.map(comment => {            
                allComments[cIdx].children.push(<FeedComment key={comment.id} children={comment.replyChildren} item={comment} />)
            })                       
            setFeedComments(allComments);                        
        }
    }

    const handleLevel2Cancel = () => {
        setLevel1Reply('');
        setLevel2RepliesModal(false);
        setLevel2Replies([]);
    }

    useEffect(() => {
        findBlogByKey();
    }, []);

    return (
        <>
            <Header />
            {
                loading ? (
                    <div className="w-full flex flex-col items-center justify-center min-h-screen">
                        <Spin  
                            size="default"
                        />
                    </div>
                ) : (
                    <div className="lg:w-7/12 mx-auto w-full flex flex-col items-start py-10">
                        <h1 className="w-full text-left font-bold text-black py-3 text-4xl">
                            {feedData?.title}
                        </h1>
                        <p className="w-full text-gray-700 text-left py-2 text-lg">
                            {feedData?.description}
                        </p>
                        <div className="w-full py-4 flex lg:flex-row flex-col items-start lg:items-center justify-between">   
                            <div className="hidden lg:flex items-center">
                                <img
                                    alt="profile"
                                    className="h-8 rounded-full mx-2"
                                    src={feedData?.avatar ? feedData?.avatar?.indexOf('h')==='0'?feedData?.avatar:`${process.env.REACT_APP_SERVER_URL}${feedData?.avatar}` : getProfileImage(feedData?.modified_name || 'Assistance')}
                                />
                                <p className="cursor-pointer text-md font-medium text-black" onClick={e => e.preventDefault()}>
                                    {feedData?.modified_name||'dev'}
                                </p>
                                <p className="text-left text-gray-700 px-3 text-sm">
                                    {moment(feedData?.modified_at).format('ll')}
                                </p>
                            </div>                                                 
                            <div className="flex flex-row items-center">
                                <p className="ml-3 text-md font-medium">Share: </p>
                                <div className="mr-3 lg:mx-3 py-1 mb-2 flex items-center">
                                    <a rel="noopener noreferrer" target="_blank" href={`https://www.facebook.com/sharer.php?u=${window.location.href}`} className="text-lg mr-2"><FacebookFilled /></a>
                                    <a rel="noopener noreferrer" target="_blank" href={"http://twitter.com/share?&url="+window.location.href+"&hashtags=assistance,assistanceorg,helpingothersmatter"} className="text-lg mx-2"><TwitterOutlined /></a>                                                
                                    <a rel="noopener noreferrer" href={"https://api.whatsapp.com://send?text=Hey there! look out here: "+window.location.href} data-action="share/whatsapp/share" className="text-lg lg:mx-2"><WhatsAppOutlined /></a>                              
                                </div>   
                            </div>                                  
                        </div>
                        {
                            feedData?.image && (
                                <div className="lg:w-7/12 mx-auto w-full my-4">
                                    <img
                                        className="w-full object-contain"
                                        alt="cover image"
                                        src={process.env.REACT_APP_SERVER_URL+feedData?.image}
                                    />
                                </div>
                            )
                        }
                        <div className="ProseMirror my-4" style={{border: 'none'}} dangerouslySetInnerHTML={{__html: feedData?.content?.slice(1,-1)}}></div>
                        <br/>
                        <div className="w-full flex flex-col items-start my-4">
                            <div className="flex items-start">
                                <p className="text-gray-800 py-2 text-lg font-medium">Comments</p>
                                {
                                    !token && (
                                        <Link   
                                        to="/signin"                                             
                                        className="mx-4 text-white rounded-lg text-md block primary-bg text-center
                                            px-4 py-2 cursor-pointer focus:outline-none hover:text-gray-400"
                                        >Sign In to comment
                                        </Link>
                                    )
                                }
                            </div>
                            {
                                !!token && (
                                    <Comment
                                        avatar={user?.image || getProfileImage(user?.username || 'Assistance')}
                                        content={<>
                                            <div className="w-full flex flex-row items-start">
                                                <textarea
                                                    maxLength={commentLimit}
                                                    value={userComment}
                                                    onChange={e => setUserComment(e.target.value)}
                                                    rows="2" 
                                                    style={{ resize: "none" ,overflow: "auto" }}                                               
                                                    placeholder="Add your comment here"
                                                    className="w-full border p-2 border-gray-500 rounded-lg mr-2 focus:outline-none"
                                                ></textarea>
                                                <button       
                                                    onClick={() => addComment()}                                                                                                                                                                     
                                                    className="text-white rounded-lg text-md block primary-bg text-center
                                                            px-4 py-2 cursor-pointer mb-4 focus:outline-none"
                                                >{commentLoad?'Wait':'Add'}                
                                                </button>
                                            </div>
                                            <p className="text-gray-600 py-1 text-right text-md mb-2">{`${commentLimit - userComment.length} characters left`}</p>                                                                              
                                            </>}
                                    />
                                )
                            }                                        
                            {
                                !!feedComments.length ? (
                                    feedComments.map(comment => (
                                        <FeedComment
                                            key={comment.id}
                                            children={comment.children||comment.replyChildren}
                                            item={comment}
                                        />
                                    ))
                                ) : <p className="text-md text-gray-700 text-center py-3">No Comments</p>
                            }
                            <Modal
                                style={{ top: 20 }}
                                title="Add Your Reply"
                                visible={commentInputModal}
                                okText={"Post"}                                            
                                onOk={() => handleOkInputModal()}
                                onCancel={() => handleCancelInputModal()}
                            >
                                <Input 
                                    value={level1Reply}
                                    onChange={e => setLevel1Reply(e.target.value)}
                                    required                        
                                    type="text" 
                                    placeholder="Your Reply"
                                    className="w-full p-2 my-2 rounded-lg text-black text-md"
                                />      
                            </Modal>  
                            <Modal
                                style={{ top: 20 }}
                                width={600}
                                title="Comment Replies"
                                visible={level2RepliesModal}
                                footer={null}
                                onCancel={() => handleLevel2Cancel()}                                            
                            >
                                {
                                    level2Replies.length ? 
                                    (
                                        level2Replies.map(comment => (
                                            <FeedComment
                                                key={comment.id}
                                                children={comment.children||comment.replyChildren}
                                                item={comment}
                                            />
                                        ))
                                    ) : ( <div className="w=full flex items-center justify-center"><Spin loading={true} size="small" /></div>)
                                }
                            </Modal>                                 
                        </div>
                    </div>
                )
            }
            <Footer />
        </>
    )
}

export default Detail
