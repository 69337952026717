/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../../assets/img/logo.png'
import calendar from '../../../assets/img/calendar.png'
import chatIcon from '../../../assets/img/chatbubbles-sharp.png'
import doumentIcon from '../../../assets/img/document-text.png'

import { HomeFilled, AppstoreFilled, BellFilled, InboxOutlined, CloseCircleFilled } from '@ant-design/icons'
import { Modal } from 'antd';
import { getProfileImage } from '../../../utils/Helper'
import { Menu, Dropdown } from 'antd'
// import blog from '../../../assets/img/blog.png';
import { useDataLayerValue } from '../../../DataLayer';
import { DownOutlined } from '@ant-design/icons'
import SearchBar from '../SearchBar'
import NotificationItem from './NotificationItem'
import { getUnReadNotifications } from '../../../reducer'
import apiInstance from '../../../api'

function Header({fixed=true}) {
    const [appModal, setAppModal] = useState(false);

    const [{ token, user, showNotifications, notifications }, dispatch] = useDataLayerValue();

    let location = useLocation();    

    const logOut = async () => {             
        // sessionStorage.clear();
        apiInstance.post("/auth/logout")
        .then(response => {
            sessionStorage.clear();
            dispatch({
                type: 'LOG_OUT'
            });                
            window.location.reload();
        })
        .catch(e => console.log(e));
    }    

    useEffect(() => {
        if (location?.pathname !== '/reset/password/' || location?.pathname !== '/reset-password' || location?.pathname !== '/signin' || location?.pathname !== '/get-started' || location?.pathname !== '/admin') {
            sessionStorage.setItem('assistance_current_route_helper', location.pathname||'/');   
        }
    },[location]);

    const mainMenu = (
        <Menu>
          {
              user?.role !== 'User' && (
                    <Menu.Item className="px-8">
                    <Link to="/dashboard">
                        Home
                    </Link>
                </Menu.Item> 
              )
          }       
          <Menu.Item className="px-8">          
            <Link 
                to={`/@${user?.username.replace(' ','-')}`}                                
                className="cursor-pointer"
            >Profile
            </Link>
          </Menu.Item>          
          <Menu.Item className="px-8">          
            <p                 
                onClick={logOut}
                className="cursor-pointer"
            >Log Out
            </p>
          </Menu.Item>          
        </Menu>
    );    

    const handleLogOut = async () => {
        await apiInstance.post("/auth/logout");
    };

    return (
        <div className={`w-full border-b border-gray-300 bg-white ${fixed?'sticky top-0 z-20':''}`}>
        <div className={`lg:w-10/12 mx-auto w-full flex lg:flex-row flex-col items-center justify-between px-10 lg:px-14 py-3 bg-white shadow-sm`}>
            <Link 
                to="/"
                className="hidden lg:flex items-center">
                <img   
                    alt="logo"                 
                    className="h-10 object-contain mx-1"
                    src={logo}
                />                
            </Link>
            <div className="w-full flex flex-row items-center justify-end">
                <div className="w-7/12 mx-8 flex flex-row items-center">
                    {/* <Link to="/home" className="h-full flex flex-col items-center justify-between mx-6">
                         <HomeFilled style={{ fontSize: 24, cursor: 'pointer', color: '#808080'}} />
                         
                    </Link> */}
                    <Link to={token?"/home":"/"} className="text-black hover:text-gray-600 text-base mx-6">
                        Home
                    </Link>
                    <p className="cursor-pointer hover:text-gray-600 text-black text-base mx-6" onClick={() => setAppModal(true)}>
                       Explore
                    </p>
                    {/* <div className="flex flex-col items-start mx-6" onClick={() => setAppModal(true)}>
                        <AppstoreFilled style={{ fontSize: 24, cursor: 'pointer', color: '#808080'}} />
                    </div> */}
                    {
                        !!token && (
                            <div className="flex flex-col items-start mx-4" onClick={() => setAppModal(true)}>
                                <InboxOutlined style={{ fontSize: 24, cursor: 'pointer', color: '#808080'}} />
                            </div>
                        )
                    }
                    {
                        !!token && (
                            <div className="flex flex-col items-start mx-6 relative">
                                {
                                    getUnReadNotifications(notifications) > 0 && (
                                        <span className="notification__bubble bg-blue-500 shadow-md text-white font-medium">
                                        {getUnReadNotifications(notifications)}</span>
                                    )
                                }
                                <BellFilled onClick={() => {
                                    dispatch({
                                        type: 'TOGGLE_NOTIFICATION',
                                        payload: !showNotifications
                                    });
                                }} style={{ fontSize: 24, cursor: 'pointer', color: '#808080'}} />
                                {
                                    showNotifications && (
                                        <>
                                        <div class="arrow-up"></div>                                         
                                        <div 
                                            className="shadow-md rounded-sm bg-gray-100 border-2 notification__container">                                                       
                                                    <div className="w-full flex flex-col items-center">
                                                        <div className="w-full bg-white border-b pb-2 px-3 py-2 flex items-center justify-between">
                                                            <h2 className="font-medium text-base text-gray-700 text-left w-full">
                                                                Notifications
                                                            </h2>
                                                            <CloseCircleFilled 
                                                                onClick={() => dispatch({type: 'TOGGLE_NOTIFICATION', payload: false})}
                                                                style={{ color: 'grey', fontSize: 18, cursor: 'pointer', opacity: 0.7 }} 
                                                            />
                                                        </div>
                                                        {
                                                            notifications?.length > 0 ? (
                                                                <div className="w-full h-full flex flex-col items-start">
                                                                    {
                                                                        notifications?.map((item, idx) => (
                                                                            <NotificationItem
                                                                                {...item}
                                                                                key={idx}
                                                                            />
                                                                        ))
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div className="w-full flex flex-col items-center justify-center py-2">
                                                                    <p className="w-full text-center text-md text-gray-700">
                                                                        No Notifications Available
                                                                    </p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>                                                                                            
                                        </div>
                                        </>
                                    )
                                }
                            </div>
                        )
                    }
                    <div className="w-full">
                        <SearchBar placeholderText="Search For" />
                    </div>
                </div>
                <div className="lg:py-0 py-3 flex flex-row items-center">
                {
                    !token && (
                        <>
                            <Link
                                to="/signin"
                                className="mx-2 px-5 py-2 border-bg text-white primary-btn rounded-sm cursor-pointer"                    
                            >Sign In                    
                            </Link>
                        </>
                    )
                }
                </div>
                {
                    !!token && (
                        <div className="hidden lg:flex items-center">
                            <img
                                alt="profile"
                                className="h-8 rounded-full mx-2"
                                src={user?.image ? user?.image?.indexOf('h')===0?user?.image:`${process.env.REACT_APP_SERVER_URL}${user?.image}` : getProfileImage(user?.username || 'Assistance')}
                            />
                            <Dropdown overlay={mainMenu}>
                                <p className="ant-dropdown-link cursor-pointer" onClick={e => e.preventDefault()}>
                                    {user?.username||'dev'} <DownOutlined />
                                </p>
                            </Dropdown>          
                        </div>
                    )
                }
            </div>
        </div>
        <Modal
            footer={null} 
            visible={appModal} 
            onCancel={() => setAppModal(false)}
            destroyOnClose={true}
            className="apps"
        >
            <div className="w-full grid grid-cols-2 lg:grid-cols-3 gap-8 p-6">
                <Link
                    to="/event" 
                    className="flex flex-col items-center cursor-pointer hover:bg-gray-100 rounded-lg">
                    <img
                        alt="icon"
                        src={calendar}
                        className="contain h-12 mb-3 mt-3"
                    />
                    <p className="font-medium text-base primary-color py-2">Events</p>
                </Link>
                <Link
                    to="/community" 
                    className="flex flex-col items-center cursor-pointer hover:bg-gray-100 rounded-lg">
                    <img
                        alt="icon"
                        src={chatIcon}
                        className="contain h-12 mb-3 mt-3"
                    />
                    <p className="font-medium text-base primary-color py-2">Communities</p>
                </Link>
                {/* <div className="flex flex-col items-center cursor-pointer hover:bg-gray-100 rounded-lg">
                    <img
                        alt="icon"
                        src={people}
                        className="contain h-12 mb-3 mt-3"
                    />
                    <p className="font-medium text-base primary-color py-2">Members</p>
                </div> */}
                {/* <div className="flex flex-col items-center cursor-pointer hover:bg-gray-100 rounded-lg">
                    <img
                        alt="icon"
                        src={alertIcon}
                        className="contain h-12 mb-3 mt-3"
                    />
                    <p className="font-medium text-base primary-color py-2">Announcement</p>
                </div> */}
                <Link
                    to="/blog" 
                    className="flex flex-col items-center cursor-pointer hover:bg-gray-100 rounded-lg">
                    <img
                        alt="icon"
                        src={doumentIcon}
                        className="contain h-12 mb-3 mt-3"
                    />
                    <p className="font-medium text-base primary-color py-2">Blog</p>
                </Link>
                {/* <div className="flex flex-col items-center cursor-pointer hover:bg-gray-100 rounded-lg">
                    <img
                        alt="icon"
                        src={doumentIcon}
                        className="contain h-12 mb-3 mt-3"
                    />
                    <p className="font-medium text-base primary-color py-2">ToDo List</p>
                </div> */}
            </div>
        </Modal>
        </div>
    )
}

export default Header
