import React,{ useState } from 'react'
import CrudUI from './CrudUI'
import apiInstance from '../../../api'
import { useDataLayerValue } from '../../../DataLayer'
import { message } from 'antd'

function Reason() {

    const [{ allReasons, allReasonsStatus}, dispatch] = useDataLayerValue();

    const [load, setLoad] = useState(false);
    const [addModalTrigger, setAddModalTrigger] = useState(false);
    const [updateModalTrigger, setUpdateModalTrigger] = useState(false);
    const [currentItem, setCurrentItem] = useState(null); 

    /**
     * Used to create a reason using the 
     * standard API
     * @param {*} data 
     */
     const createAReason = (data) => {        
        setLoad(true);
        apiInstance.post("/reason", data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);
                setAddModalTrigger(false);
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW REASON ADDED
                dispatch({
                    type: 'SET_ALL_REASONS_STATUS',
                    status: !allReasonsStatus
                });
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);          
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);        
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error");  
        });        
    };

    /**
     * Used to update a reason using the
     * standard API
     * @param {id} id
     * @param {*} data 
     */
     const updateAReason = (id, data) => {        
        setLoad(true);
        apiInstance.put(`/reason/${id}`, data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);
                setUpdateModalTrigger(false);
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW FEED TYPES ADDED
                dispatch({
                    type: 'SET_ALL_REASONS_STATUS',
                    status: !allReasonsStatus
                });
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);            
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);                    
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error"); 
        });        
    };

    /**
     * Used to change the current status of
     * a reason based on provided id
     * @param {*} id 
     */
     const changeStatusOfAReason = (data, id) => {    
        setLoad(true);
        apiInstance.put(`/reason/status/${id}`, data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);                
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW FEED TYPES ADDED
                dispatch({
                    type: 'SET_ALL_REASONS_STATUS',
                    status: !allReasonsStatus
                });
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);          
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);                      
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error"); 
        });        
    }

    return (
        <div className="w-full">
            <CrudUI
                title="Reason"
                utility="Reasons"
                data={allReasons}
                addModalTrigger={addModalTrigger}
                updateModalTrigger={updateModalTrigger} 
                load={load}                 
                setAddModalTrigger={setAddModalTrigger}
                setUpdateModalTrigger={setUpdateModalTrigger}
                createItem={createAReason}
                updateItem={updateAReason}
                // deleteItem={deleteAFeedType}
                changeStatus={changeStatusOfAReason}
                currentItem={currentItem}
                setCurrentItem={setCurrentItem}
            />
        </div>
    )
}

export default Reason
