import React,{ useState } from 'react'
import CrudUI from './CrudUI'
import apiInstance from '../../../api'
import { useDataLayerValue } from '../../../DataLayer'
import { message } from 'antd'

function Tags() {

    const [{ allTags, allTagsStatus}, dispatch] = useDataLayerValue();

    const [load, setLoad] = useState(false);
    const [addModalTrigger, setAddModalTrigger] = useState(false);
    const [updateModalTrigger, setUpdateModalTrigger] = useState(false);
    const [currentItem, setCurrentItem] = useState(null); 

    /**
     * Used to create a tag using the 
     * standard API
     * @param {*} data 
     */
     const createATag = (data) => {        
        setLoad(true);
        apiInstance.post("/tag", data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data || 'Tag Created');
                setAddModalTrigger(false);
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW TAGS ADDED
                dispatch({
                    type: 'SET_ALL_TAGS_STATUS',
                    status: !allTagsStatus
                });
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);          
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);                      
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error"); 
        });        
    }

    /**
     * Used to update a tag using the
     * standard API
     * @param {id} id
     * @param {*} data 
     */
    const updateATag = (id, data) => {
        setLoad(true);
        apiInstance.put(`/tag/${id}`, data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);
                setUpdateModalTrigger(false);
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW TAGS ADDED
                dispatch({
                    type: 'SET_ALL_TAGS_STATUS',
                    status: !allTagsStatus                    
                });
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);            
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);                       
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error"); 
        });        
    }

    /**
     * Used to soft delete a tag using the
     * standard API
     * @param {*} id 
     */
    const deleteATag = (id) => {        
        setLoad(true);
        apiInstance.put(`/tag/delete/${id}`)
        .then(response => {
            if(response.data.status) {
                message.success(response.data.data);
                setLoad(false);                
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW TAGS ADDED
                dispatch({
                    type: 'SET_ALL_TAGS_STATUS',
                    status: !allTagsStatus                    
                });            
                return true;    
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);
        })
        .catch(e => {            
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);                      
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error");  
            return false;
        });        
    }

    /**
     * Used to change the current status of
     * a tag based on provided id
     * @param {*} id 
     */
    const changeStatusOfATag = (data, id) => {        
        setLoad(true);
        apiInstance.put(`/tag/status/${id}`, data)
        .then(response => {
            if(response.data.status) {
                setLoad(false);
                message.success(response.data.data);                
                setCurrentItem(null);
                // UPDATE THE DATA LAYER WITH NEW TAGS ADDED
                dispatch({
                    type: 'SET_ALL_TAGS_STATUS',
                    status: !allTagsStatus                    
                });
            } else message.warning(response.data?.data || 'something went wrong');
            setLoad(false);          
        })
        .catch(e => {
            setLoad(false);
            console.log(e);
            if(e.response || e.response?.data) {                                    
                if(e.response.status === 401) {
                    alert('Your Session is expired!');
                    sessionStorage.clear();                            
                    window.location.replace("/signin");                                     
                } else {
                    if(typeof e.response?.data?.message === 'string') {
                        message.error(e.response.data.message);                         
                    } else {
                        message.warn("Something went wrong");
                    }                        
                }
            } else message.error("Internal Server Error");  
        });        
    }

    return (
        <div className="w-full">
            <CrudUI
                title="Tags"
                utility="Tags"
                data={allTags}
                addModalTrigger={addModalTrigger}
                updateModalTrigger={updateModalTrigger} 
                load={load}                 
                setAddModalTrigger={setAddModalTrigger}
                setUpdateModalTrigger={setUpdateModalTrigger}
                createItem={createATag}
                updateItem={updateATag}
                deleteItem={deleteATag}
                changeStatus={changeStatusOfATag}
                currentItem={currentItem}
                setCurrentItem={setCurrentItem}
            />
        </div>
    )
}

export default Tags
