import React from 'react'

function DevBanner({ title }) {
    return (
        <div className="w-full bg-red-500 text-white text-md font-bold text-center p-2">
            {title}
        </div>
    )
}

export default DevBanner
