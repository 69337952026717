/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'
import '../../../stylesheets/MainSlider.css'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import BriefItem from './BriefItem'
import CItem from '../../../pages/v2/Community/CItem'

function MainSlider({ title, showCreateButton=true, data=[], handleFetchEvent = hfe => hfe, type, isImage=true, keyName=null }) {

    const [listScrollLength, setListScrollLength] = useState(false);

    const listRef = useRef();

    const scrollLeft = () => {
        if (listRef.current) {
            listRef.current.scrollBy({
                top: 0,
                left: 470,
                behavior: 'smooth',
            });
    
        }
    }

    const scrollRight = () => {
        if (listRef.current) {
            listRef.current.scrollBy({
                top: 0,
                left: -470,
                behavior: 'smooth',
            });
        }
    }

    function toggleLeftButton() {
        if (listRef.current.scrollLeft === 0) {
            setListScrollLength(false);
        } else {
            setListScrollLength(true);
        }
    }

    const scrollEvent = (e) => {
        const target = e.target;
        if (target.scrollLeft === 0) {
            console.log('one')
            setListScrollLength(false);
        } else {
            if (!listScrollLength) {
                console.log('two')
                setListScrollLength(true);
            }
        }
    }

    return (
        <div className="w-full flex flex-col my-2">

            <div className="w-full flex flex-row items-center justify-between">
                    {
                        title && (
                            <h2 className="text-xl w-full text-left font-semibold">{title}</h2>
                        )
                    }
                    {
                        showCreateButton && (
                            <Link
                                to="/signin"
                                className="px-5 py-2 text-white primary-bg rounded-md cursor-pointer"            
                            >Create                   
                            </Link>
                        )
                    }
                </div>

            <div className="w-full flex flex-row">
                
                {
                    listScrollLength && (
                        <div 
                            className="slider__left flex items-center justify-center">
                            <span onClick={() => scrollRight()} className="cursor-pointer rounded-full bg-white text-black text-lg w-12 h-12 flex items-center justify-center font-bold  p-3 border shadow">
                                <ArrowLeftOutlined />
                            </span>
                        </div>
                    )
                }

                <div onScroll={(e) => scrollEvent(e)}className="py-4 slider__parent" ref={listRef}>

                    {
                        data.length ? (
                        data?.map((item, idx) => (
                            // <header 
                            //     onClick={() => handleFetchEvent(item)}
                            //     key={idx} 
                            //     className={`cursor-pointer slider__item rounded-md ${idx===0?'mr-4':'mx-4'}`}>
                            //     <img
                            //         className={`${item?.image?'slider__img':'slider__imgDefault'}`} 
                            //         src={item?.image?`${process.env.REACT_APP_SERVER_URL}/${item?.image}`:"./event-1.png"} alt="Five developers at work."/>
                            //     <section>
                            //         <h1 className="text-xl font-medium">{item?.title}</h1>
                            //         {/* <p className="text-md text-gray-300 py-1 text-left">Hosted by {item?.username}</p> */}
                            //         <p className="text-sm text-gray-300 uppercase pt-1">{`${moment(item?.startDate).format('lll')} - ${moment(item?.endDate).format('lll')}`}</p>
                            //     </section>
                            // </header>
                            type==='community'?(<CItem isImage={isImage} {...item}/>):(<BriefItem keyName={keyName} fixed={title} {...item} />)
                        )) ) : null
                    }
                
                </div>

                <div 
                    className={`slider__right flex items-center justify-center ${data?.length > 2 ?'':'lg:hidden'}`}>
                    <span onClick={() => scrollLeft()} className="rounded-full cursor-pointer bg-white text-black text-lg w-12 h-12 flex items-center justify-center font-bold  p-3 border shadow">
                        <ArrowRightOutlined />
                    </span>
                </div>
            </div>
                    
            {/* {
                title && (
                    <Link to="/" className="w-full text-right text-blue-400 font-medium text-md">
                        View All
                    </Link>
                )
            } */}

        </div>
    )
}

export default MainSlider
