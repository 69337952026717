/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import Footer from '../../../components/User/v2/Footer'
import Header from '../../../components/User/v2/Header'
import { useParams, useHistory, Link } from 'react-router-dom'
import logo from '../../../assets/img/icon.png'
import apiInstance from '../../../api'
import { message, Button, Modal, Input, Spin, Radio, Space, Table } from 'antd'
import moment from 'moment'
import draftToHtml from 'draftjs-to-html'
import { FacebookFilled, EditOutlined, WhatsAppOutlined, CopyOutlined, PlayCircleFilled, TwitterOutlined, CheckCircleFilled, MailOutlined, InfoCircleFilled, QuestionCircleFilled, LinkOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import {getProfileImage, validateEmail} from '../../../utils/Helper'
import { useDataLayerValue } from '../../../DataLayer'
import VideoPlayer from 'react-simple-video-player'
import DiscussionItem from '../../../components/User/v2/DiscussionItem'
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { convertToRaw, EditorState } from 'draft-js'

const { Search, TextArea } = Input;

const discussionContent = {"entityMap":{},"blocks":[]};

function Detail() {

    const { key, eventId, roofToken, check, inviteId } = useParams();

    const history = useHistory();
    
    const [{user, token}] = useDataLayerValue();

    const getLinkPreview = (url) => {
        apiInstance
        .get(`/homepage/link-preview?url=${url}`)
        .then(response => {
            setPreviewData(response.data);
        })
        .catch(e => {
            console.log(e);
        });
    }
    const [previewData, setPreviewData] = useState(null);
    const [data, setData] = useState(null);
    const [loadingState, setLoadingState] = useState(false);
    const [descriptionContent, setDescriptionContent] = useState(null);
    const [termsContent, setTermsContent] = useState(null);
    const [faqsContent, setFaqsContent] = useState(null);
    const [activeTab, setActiveTab] = useState('info');
    const [invitationModal, setInvitationModal] = useState(false);
    const [activeInvitationStatus, setActiveInvitationStatus] = useState('accept');
    const [invitationEmail, setInvitationEmail] = useState('');
    const [pcCheck, setPcCheck] = useState(false);
    const [invitationLoadingState, setInvitationLoadingState] = useState(false);
    const [tempUser, setTempUser] = useState(null);
    const [invitationLoad, setInvitationLoad] = useState(false);
    const [invitations, setInvitations] = useState([]);
    
    const [reportEmail, setReportEmail] = useState('');
    const [reportMessage, setReportMessage] = useState('');
    const [reportLoading, setReportLoading] = useState(false);

    const [searchOne, setSearchOne] = useState('');
    const [searchTwo, setSearchTwo] = useState('');
    const [reasonsList, setReasonsList] = useState([]);
    const [selectedReason, setSelectedReason] = useState(null);

    const [acceptLoadingState, setAcceptLoadingState] = useState(false);
    const [peopleCount, setPeopleCount] = useState(0);
    const [completed, setCompleted] = useState(false);
    const [invitationData, setInvitationData] = useState(null);
    const [memoryModal, setMemoryModal] = useState(false);
    const [memorySelect, setMemorySelect] = useState(null);
    const [memoryImage, setMemoryImage] = useState(null);
    const [memoryCaption, setMemoryCaption] = useState('');
    const [memoryLoad, setMemoryLoad] = useState(false);
    const [memoryItemsLoad, setMemoryItemsLoad] = useState(false);
    const [memories, setMemories] = useState([]);
    const [memoryFiles, setMemoryFiles] = useState([]);
    const [memoryVideos, setMemoryVideos] = useState([]);
    const [currentMemoryIndex, setCurrentMemoryIndex] = useState(0);
    const [memoryPreviewModal, setMemoryPreviewModal] = useState(false);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [currentMemoryMap ,setCurrentMemoryMap] = useState(null); 
    const [eventAttendModal ,setEventAttendModal] = useState(false);
    const [attendEmail, setAttendEmail] = useState('');

    const [dContent, setDContent] = useState('');
    const [dState, setDState] = useState(EditorState.createEmpty());
    const [dLoad, setDLoad] = useState(false);
    const [discussions, setDiscussions] = useState([]);

    const onEditorChange = (eState) => {
        const tempState = eState.getCurrentContent();
        setDContent(tempState);
        setDState(eState);
    };

    const memoryNext = () => {
        if (currentMemoryMap === 'image') {
            let oldStep = currentMemoryIndex;
            oldStep++;
            if (oldStep < memoryFiles?.length) {
                // allowed
                setCurrentMemoryIndex(oldStep);
            }
        } else {
            let oldStep = currentVideoIndex;
            oldStep++;
            if (oldStep < memoryVideos?.length) {
                // allowed
                setCurrentVideoIndex(oldStep);
            }
        }
    };

    const memoryBack = () => {
        if (currentMemoryMap==='image') {
            let oldStep = currentMemoryIndex;
            oldStep--;
            if (currentMemoryIndex >= 0) {
                // allowed
                setCurrentMemoryIndex(oldStep);
            }
        } else {
            let oldStep = currentVideoIndex;
            oldStep--;
            if (currentMemoryIndex >= 0) {
                // allowed
                setCurrentVideoIndex(oldStep);
            }
        }
    };

    const getFilesFromMemories = (data) => {
        let result = [];
        data?.map(item => {
            if (item?.memory_type === 'image') {
                let temp = {
                    id: item.id,
                    file_path: item.file_path,
                    url: `${process.env.REACT_APP_SERVER_URL}api/eventmemory/${item.file_path}?a= ${token}`
                }
                result.push(temp);
            }
        });
        return result;
    }

    const getFilesFromMemoriesVideos = (data) => {
        let result = [];
        data?.map(item => {
            if (item?.memory_type === 'video') {
                let temp = {
                    id: item.id,
                    file_path: item.file_path,
                    url: `${process.env.REACT_APP_SERVER_URL}api/eventmemory/video/${item.file_path}?a= ${token}` 
                };
                result.push(temp);
            }
        });
        return result;
    }

    const onReasonChange = (e) => {
        setSelectedReason(e.target.value);
    };

    async function getAllMemories(id=null) {
        setMemoryItemsLoad(true);
        apiInstance
        .get(`/eventmemory/${data?.id || id}`)
        .then(response => {
            setMemories(response.data.data);
            setMemoryFiles(getFilesFromMemories(response.data.data));
            setMemoryVideos(getFilesFromMemoriesVideos(response.data.data));
        })  
        .catch(e => {
            message.error(e.response.data?.message || "Internal Server Error");
        })
        .finally(() => setMemoryItemsLoad(false));
    }

    useEffect(() => {
        async function getReasons() {
            apiInstance
            .get('/reason')
            .then(response => {
                if (response.data.status) {
                    setReasonsList(response.data.data?.filter(item => item?.entity === 'event'));
                }
            }).catch(e => console.log(e.response));
        }
        getReasons();
    }, []);

    const onSearch = (value, key) => {
        if (key) setSearchOne(value);
        else setSearchTwo(value);
    };

    useEffect(() => {
        if (key) findEventByKey(key);
    }, [key]);

    const checkUserLogic = () => {
        if (invitationEmail) {
            if (validateEmail(invitationEmail)) {
                checkForUser();
            }
        }
    };

    // useEffect(() => {
    //     // debounce fx
    //     const timer = setTimeout(() =>{
    //         checkUserLogic();
    //     }, 2000);  
    //     return () => clearTimeout(timer);
    // }, [invitationEmail]);


    async function checkForUser() {
        setTempUser(null);
        setInvitationLoadingState(true);
        apiInstance
        .get(`/event/user/check?email=${invitationEmail}`)
        .then(response => {
            if (response.data.status) {
                setTempUser(response.data.data);
            }
        })
        .catch(e => {
            console.log(e);
        }).finally(() => {
            setInvitationLoadingState(false);
        });
    }

    async function findEventByKey(key) {
        setLoadingState(true);
        apiInstance
        .get(`/event/key/${key}`)
        .then(response => {
            if (response.data.status) {
                document.title = `${response.data.data?.title} - Assistance Org`;
                setData(response.data.data);
                getLinkPreview(response.data.data.url);
                getAllInvitationsByEvent(response.data.data.id);
                if (token) {
                    getAllMemories(response.data.data.id);
                    getDiscussionItems(response.data.data.id);
                }
                if (response.data.data?.description) {
                    setDescriptionContent(JSON.parse(response.data.data.description));
                }
                if (response.data.data?.termsAndConditions) {
                    setTermsContent(JSON.parse(response.data.data.termsAndConditions));
                }
                if (response.data.data?.faqs) {
                    setFaqsContent(JSON.parse(response.data.data.faqs));
                }
            } else {
                message.warning(response.data?.message || 'something went wrong');
                history.push('/oops');
            }
        })
        .catch(e => {
            if(e.response?.status === 404) {
                history.push('/oops');
            } 
            console.log(e);
        }).finally(() => {
            setLoadingState(false);
        });
    }

    function createInvitation(payload) {
        setInvitationLoad(true);
        apiInstance
        .post('/event/invitation', payload)
        .then(response => {
            if (response.data.status) {
                message.success(response.data.message);
                setInvitationModal(false);
                setInvitationEmail('');
                getAllInvitationsByEvent();
            } else message.warn(response.data?.message || 'Something went wrong')
        })
        .catch(e => {
            console.log(e);
        })
        .finally(_ => setInvitationLoad(false));
    }

    const createInvite = () => {
        let payload = {
            email: invitationEmail,
            eventId: data?.id,
            isExistingUser: tempUser?1:0,
            pcCheck: data?.isPeopleCountReq,
            key: data?.url_key,
            eventName: data?.title,
            eventLocation: data?.locationDetail,
            eventImage: data?.image,
            eventType: data?.type,
            start: data?.startDate,
            end: data?.endDate
        }
        if ((invitationEmail) && data?.id) {
            createInvitation(payload);
        }
    }

    function getAllInvitationsByEvent(id=null) {
        if (data?.id || id) {
            apiInstance
            .get(`/event/invitation/${data?.id || id}`)
            .then(response => {
                setInvitations(response.data.data);
            })
            .catch(e => {
                console.log(e);
            });
        }
    }

    function sendReportEmail() {
        let payload = {
            entityId: data?.id,
            email: reportEmail,
            message: reportMessage,
            url: window.location.href,
            user: user,
            userId: user?.id || null,
            reasonId: selectedReason,
            entity: 'event',
        };
        if (reportEmail && reportMessage && data?.id) {
            if (validateEmail(reportEmail)) {
                setReportLoading(true);
                apiInstance
                .post("/report", payload)
                .then(response => {
                    if (response.data?.status) {
                        setReportEmail('');
                        setReportMessage('');
                        message.success('Report Sent');
                    } else message.warn('Something went wrong');
                })
                .catch(e => {
                    console.log(e);
                })
                .finally(_ => setReportLoading(false));
            } else message.warn('Invalid email address');
        }
    }

    useEffect(() => {
        if (user) {
            setReportEmail(user?.email_address);
        }
    }, [user]);

    function findInvitationById() {
        if (inviteId) {
            setLoadingState(true);
            apiInstance
            .get(`/event/invitation/id/${inviteId}`)
            .then(response => {
                if (response.data.status) {
                    setInvitationData(response.data?.data);
                    if (response.data?.data?.isAccepted === 1) {
                        console.log(response.data.data);
                        setCompleted(true);
                    }
                } else message.warn(response.data?.message || 'Something went wrong');
            })
            .catch(e => console.log(e))
            .finally(() => setLoadingState(false));
        }
    }

    function acceptInvite(flag=false) {
        if (token) {
            let payload = {
                isAttend: flag,
                email: attendEmail || user?.email_address,
                roofToken: roofToken,
                eventId: parseInt(eventId) || data?.id,
                inviteId: parseInt(inviteId),
                peopleCount: check?peopleCount:data?.isPeopleCountReq===1?peopleCount:null,
                eventName: data?.title || null,
                userId: user?.id || null,
                eventKey: key || data?.url_key,
                userName: user?.username || null,
                owner: data?.created_by,
                isPeopleCountReq: data?.isPeopleCountReq
            };
            setAcceptLoadingState(true);
            apiInstance
            .post('/event/invitation/accept', payload)
            .then(response => {
                if (response.data.message === 'Joined Event') {
                    setCompleted(true);
                    findEventByKey(payload.eventKey);
                    setAttendEmail('');
                    setPeopleCount(0);
                    setEventAttendModal(false);
                }
                setCompleted(true);
                setTimeout(() => window.location.replace(`/e/${key}`), 1000);
                message.success(response.data?.message || 'Joined');
                
            })
            .catch(e => console.log(e))
            .finally(_ => setAcceptLoadingState(false));
        } else {
            if (token) {
                sessionStorage.removeItem('invitation_callback');
            } else {
                sessionStorage.setItem('invitation_callback', window.location.href);
                history.push('/signin');
            }
        }
    }

    useEffect(() => {
        if (eventId && roofToken && inviteId) {
            findInvitationById();
        }
    }, [eventId, roofToken, check, inviteId]);

    const acceptedInviteColumns = [
        {
            title: 'Email',
            key: 'email_address',
            dataIndex: 'email_address'
        },
        // {
        //     title: 'Registered User',
        //     key: 'isExistingUser',
        //     dataIndex: 'isExistingUser',
        //     render: record => <p>{record===1?'Yes':'No'}</p>
        // },
        {
            title: 'People Attending',
            key: 'peopleCount',
            dataIndex: 'peopleCount',
            render: record => <p>{record?record:null}</p>
        },
    ];

    const pendingInviteColumns = [
        {
            title: 'Email',
            key: 'email_address',
            dataIndex: 'email_address'
        },
        // {
        //     title: 'Registered User',
        //     key: 'isExistingUser',
        //     dataIndex: 'isExistingUser',
        //     render: record => <p>{record===1?'Yes':'No'}</p>
        // }
    ];

    const isOwner = user?.id === data?.created_by;

    const LinkPreviewPanel = () => {
        return (
            <a 
                href={previewData?.url}
                className="w-full border cursor-pointer shadow p-3 rounded-md bg-white flex flex-row items-start">
                <img
                    alt="image"
                    src={previewData?.favicons[0] || previewData?.images[0]}
                    className="object-contain h-12"
                />
                <div className="px-3 flex flex-col items-start">
                    <a href={previewData?.url} className="w-full text-left mb-1 text-xs text-gray-800">
                        {previewData?.url?.split('//')[1]?.split('/')[0] || previewData?.url}
                    </a>
                    <h3 className="w-full text-left font-medium text-black text-lg">
                        {previewData?.title}
                    </h3>
                </div>
            </a>
        )
    }   

    const addMemoryImage = () => {
        setMemoryLoad(true);
        let imageUploadPayload = new FormData();
        imageUploadPayload.append("file", memoryImage);
        imageUploadPayload.append("caption", memoryCaption);
        imageUploadPayload.append("eventId", data?.id);
        imageUploadPayload.append("memoryType", memorySelect);
        imageUploadPayload.append("eventName", data?.title);
        imageUploadPayload.append("eventKey", data?.url_key);
        apiInstance
        .post("/eventmemory/new", imageUploadPayload)
        .then(response => {
            message.success('Memory Created Sucessfully');
            setMemoryImage(null);
            setMemoryCaption('');
            setMemoryModal(false);
            getAllMemories();
        })
        .catch(e => {
            message.error(e.response?.data?.message || 'Internal Server Error');
        })
        .finally(() => setMemoryLoad(false));
    }

    const removeMemory = () => {
        setMemoryLoad(true);
        let memoryId = currentMemoryMap === 'image' ? memoryFiles[currentMemoryIndex]?.id : memoryVideos[currentVideoIndex]?.id;
        let fileName = currentMemoryMap === 'image' ? memoryFiles[currentMemoryIndex]?.file_path : memoryVideos[currentVideoIndex]?.file_path;
        apiInstance
        .delete(`/eventmemory/${memoryId}/${fileName}`)
        .then(response => {
            setCurrentMemoryIndex(0);
            setCurrentVideoIndex(0);
            setMemoryPreviewModal(false);
            message.success('Memory Removed Sucessfully');
            getAllMemories();
        })
        .catch(e => {
            console.log(e);
            message.error(e.response.data?.message || "Internal Server Error");
        })
        .finally(() => setMemoryLoad(false));
    };

    const MemoryItem = (props) => (
        props?.memory_type === 'image' ? (
            <div
                onClick={() => {
                    setCurrentMemoryMap('image');
                    setCurrentMemoryIndex(props.masterId);
                    setMemoryPreviewModal(true);
                }} 
                className="memory__item">
                <img
                    src={`${process.env.REACT_APP_SERVER_URL}api/eventmemory/${props.file_path}?a= ${token}`}
                    alt="memory image"
                />
                <div className="memory__overlay">
                    <p className="memory__caption">{props.caption}</p>
                </div>
            </div>
        ) : (
            <div
                onClick={() => {
                    setCurrentMemoryMap('video');
                    setCurrentVideoIndex(props.masterId);
                    setMemoryPreviewModal(true);
                }} 
                className="memory__item">
                <img
                    src={`${process.env.REACT_APP_SERVER_URL}/images/event/event_memory_default_thumnail.png`}
                    alt="memory image"
                />
                <div className="memory__overlay">
                    <span><PlayCircleFilled style={{color: 'white'}} /></span>
                    <p className="memory__caption">{props.caption}</p>
                </div>
            </div>
        )
    );

    const submitDiscussionContent = () => {
        let payload = {
            eventId: data?.id,
            content: dContent ? JSON.stringify(convertToRaw(dContent)) : null
        };

        if (payload.eventId) {
            setDLoad(true);
            apiInstance
            .post("/discussion", payload)
            .then(response => {
                setDContent('');
                setDState(EditorState.createEmpty());
                getDiscussionItems();
                message.success('Submitted your content');
            })
            .catch(e => {
                message.error('Internal Server Error');
            })
            .finally(() => setDLoad(false));
        }

    };

    async function getDiscussionItems(id=null) {
        apiInstance
        .get(`/discussion/${id||data?.id}`)
        .then(response => {
            if (response.data.status) {
                setDiscussions(response.data.data);
            }
        })
        .catch(e => message.error('Internal Server Error'));
    }

    return (
        <>
            <Header fixed={false} />

            {
                loadingState ? (
                    <div className="w-full main__preLoaderContainer flex items-center justify-center">
                        <img 
                            className="main__preLoader"
                            src={logo}
                            alt="preloader"
                        />
                    </div>
                ) : (
                    <div className="w-full lg:px-0 px-4">
                        {/* <div className="lg:w-8/12 sm:h-32 mx-auto rounded-md shadow-md mt-3 mb-2 event__banner">
                            <img 
                                className="w-full" 
                                alt="banner"
                                src={`${process.env.REACT_APP_SERVER_URL}/${data?.image}`}
                            />
                        </div> */}
                        <h1 className="w-8/12 mx-auto mt-2 text-lg lg:text-2xl font-medium">{data?.title} 
                            <span className="px-3 py-1 text-white rounded-full bg-blue-500 text-sm mx-3">{data?.isPublic===1?'Public':'Private'}</span>
                            {isOwner && moment(data?.startDate)?.isSameOrBefore(moment()) && (<EditOutlined onClick={() => history.push(`/event/create?type=edit&id=${data?.id}`)}  style={{fontSize:'16px'}} />)} </h1>
                        {/* <a href="#" className="w-8/12 text-blue-500 mx-auto text-left py-1 flex flex-row items-center">
                            {data?.type}
                        </a> */}
                        {
                            data?.tags?.split(',')?.length && (
                                <div className="w-8/12 mx-auto py-1 flex flex-row items-center">
                                    {
                                        data?.tags?.split(',')?.map((tag, idx) => (
                                            <span key={idx} className={`bg-gray-600 ${idx===0?'':'ml-2'} text-white rounded-sm text-xs font-medium px-1`}>
                                                #{tag}    
                                            </span>
                                        ))
                                    }
                                </div>
                            )
                        }
                        <div className="lg:w-8/12 mx-auto flex flex-row items-center justify-between">
                            {
                                moment(data?.endDate).isSameOrAfter(moment()) ? (
                                    <div className="flex flex-row items-center py-2">
                                        {
                                            isOwner ? (
                                                <Button
                                                    onClick={() => setInvitationModal(true)}
                                                    type="primary"
                                                    className="px-3 text-sm text-white primary-bg rounded-sm cursor-pointer"
                                                    style={{height: 30}}                    
                                                >Invite
                                                </Button>
                                            ) : (
                                                <div className="flex flex-col items-start">
                                                    {
                                                        data?.isPeopleCountReq===1 && data?.spots === 0 ? (
                                                            <p className="w-full text-left text-md pt-1 text-gray-600">
                                                                The event is full
                                                            </p>
                                                        ) : (
                                                        invitations?.filter(i => i?.email_address === user?.email_address)?.length ? (
                                                            !(roofToken && eventId && inviteId) && (
                                                                <>
                                                                    <p className="w-full text-left text-md pt-1 text-gray-600">
                                                                        Joined the Event
                                                                    </p>
                                                                </>
                                                            )
                                                        ) : (
                                                            !(roofToken && eventId && inviteId) && (
                                                                <>
                                                                    <Button
                                                                        onClick={() => {
                                                                            if (token) {
                                                                                sessionStorage.removeItem('invitation_callback');
                                                                                setEventAttendModal(true);
                                                                            } else {
                                                                                sessionStorage.setItem('invitation_callback', window.location.href);
                                                                                history.push('/signin');
                                                                            }
                                                                    }}
                                                                        type="primary"
                                                                        className="px-3 text-sm text-white primary-bg rounded-sm cursor-pointer"
                                                                        style={{height: 30}}                    
                                                                    >Attend Event
                                                                    </Button>
                                                                    {
                                                                        data?.isPeopleCountReq === 1 ? (
                                                                            <p className="w-full text-left text-md pt-1 text-gray-600">
                                                                                {data?.spots || 0} spots left
                                                                            </p>
                                                                        ) : (
                                                                            <p className="w-full text-left text-md pt-1 text-gray-600">
                                                                                Unlimited Spots
                                                                            </p> 
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        )
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            (roofToken && eventId && inviteId) && (
                                                completed ? (
                                                    <>
                                                        <p className="flex items-center">
                                                            <CheckCircleFilled style={{color: 'green', marginRight: '3px' }} />
                                                            You have joined this event
                                                        </p>
                                                    </>
                                                ) : (
                                                    <>
                                                    {
                                                        token ? (
                                                            invitations?.filter(i => i?.email_address === user?.email_address)?.length && invitations?.filter(i => i?.email_address === user?.email_address)[0]?.isAccepted===1? (
                                                                <>
                                                                    <p className="flex items-center">
                                                                        <CheckCircleFilled style={{color: 'green', marginRight: '3px' }} />
                                                                        You have joined this event
                                                                    </p>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Button
                                                                        onClick={() => acceptInvite()}
                                                                        load={acceptLoadingState}
                                                                        type="primary"
                                                                        className="ml-2 px-3 text-md text-white primary-bg rounded-md cursor-pointer"
                                                                        style={{height: 35}}
                                                                        disabled={data?.isPeopleCountReq===1 ? !(peopleCount>0) : false}                   
                                                                    >Accept Invitation
                                                                    </Button>
                                                                    {
                                                                        data?.isPeopleCountReq===1 && (
                                                                            <div className="w-1/2 flex flex-row items-center my-1 ml-1 pt-3 pb-3">
                                                                                    <label className="text-md text-left w-full py-2" for="poeple_count">Number of spots needed <span className="text-red-500">*</span></label>
                                                                                    <Input
                                                                                        value={peopleCount}
                                                                                        onChange={(e) => setPeopleCount(e.target.value)}
                                                                                        type="number" 
                                                                                        id="poeple_count" 
                                                                                        className="w-1/3 border border-gray-400 rounded-md p-1 outline-none"
                                                                                    />
                                                                            </div>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                            
                                                        ) : (
                                                            <Button
                                                                onClick={() => acceptInvite()}
                                                                load={acceptLoadingState}
                                                                type="primary"
                                                                className="ml-2 px-3 text-md text-white primary-bg rounded-md cursor-pointer"
                                                                style={{height: 35}}                  
                                                            >Accept Invitation
                                                            </Button>
                                                        )
                                                    }
                                                    </>
                                                )
                                            )
                                        }
                                        </div>
                                ) : (
                                    <p className="w-full text-left text-md pt-1 text-gray-600">
                                        Event Ended
                                    </p>
                                )
                            }
                            <div className="flex flex-row items-center">
                                <a rel="noopener noreferrer" target="_blank" href={data?.url || '#'} className="text-lg mr-2"><LinkOutlined /></a>
                                <a rel="noopener noreferrer" target="_blank" href={`https://www.facebook.com/sharer.php?u=${window.location.href}`} className="text-lg mx-2"><FacebookFilled /></a>
                                <a rel="noopener noreferrer" target="_blank" href={"http://twitter.com/share?&url="+window.location.href+"&hashtags=assistance,assistanceorg,helpingothersmatter"} className="text-lg mx-2"><TwitterOutlined /></a>                                                
                                <a rel="noopener noreferrer" href={"https://api.whatsapp.com://send?text=Hey there! look out here: "+window.location.href} data-action="share/whatsapp/share" className="text-lg lg:mx-2"><WhatsAppOutlined /></a>                              
                                <a rel="noopener noreferrer" onClick={() => { navigator.clipboard.writeText(window.location.href); message.success('Copied to clipboard')}} className="text-lg lg:mx-2"><CopyOutlined /></a>  
                            </div>                            
                        </div>
                        {/* TAB SECTION STARTS HERE */}
                        <div className="w-full lg:w-8/12 mx-auto flex flex-row bg-white pt-4 items-center border-b community__header">
                            <p 
                                onClick={() => setActiveTab('info')}
                                className={`px-2 pb-2 mr-2 text-md text-left ${activeTab === 'info' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}>
                                About
                            </p>
                            <p 
                                onClick={() => setActiveTab('invitations')}
                                className={`px-2 pb-2 mr-2 text-md text-left ${activeTab === 'invitations' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}>
                                {isOwner ? 'Invitations' : 'Attendees'}
                            </p>
                            
                            {
                                isOwner ? (
                                    <p 
                                        onClick={() => setActiveTab('memories')}
                                        className={`px-2 pb-2 mr-2 text-md text-left ${activeTab === 'memories' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}>
                                        Memories
                                    </p>
                                ) : (moment(data?.endDate)?.isBefore(moment())) ? (
                                    <p 
                                        onClick={() => setActiveTab('memories')}
                                        className={`px-2 pb-2 mr-2 text-md text-left ${activeTab === 'memories' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}>
                                        Memories
                                    </p>
                                ) : null
                            }
                            
                            <p 
                                onClick={() => setActiveTab('discussion')}
                                className={`px-2 pb-2 mr-2 text-md text-left ${activeTab === 'discussion' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}>
                                Discussion
                            </p>
                            <p 
                                onClick={() => setActiveTab('report')}
                                className={`px-2 pb-2 mr-2 text-md text-left ${activeTab === 'report' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}>
                                Report
                            </p>
                        </div>
                        {/* TAB SECTION ENDS HERE */}
                        {/* TAB PANEL STARTS HERE */}
                        <div className="w-full lg:w-8/12 mx-auto my-4 bg-white p-2 lg:p-3 flex flex-col items-start">
                            <div className="w-full">
                            {
                                activeTab === 'info' ? (

                                    <>
                                    <div className="relative w-full flex flex-row items-start justify-between">

                                        <div className="w-full flex flex-col item-start">

                                        <div className="w-full mb-4">
                                            <img 
                                                className="w-full h-full cover" 
                                                alt="banner"
                                                src={`${process.env.REACT_APP_SERVER_URL}/${data?.image}`}
                                            />
                                        </div>

                                        {
                                            descriptionContent ? 
                                            (
                                                <>
                                                    <div dangerouslySetInnerHTML={{__html: draftToHtml(descriptionContent)}}></div>
                                                </>
                                            ) : null
                                        }
                                        {
                                            termsContent ? (
                                                <>
                                                    <p className="w-full py-2 text-black uppercase font-medium text-left">TERMS AND CONDITIONS</p>
                                                    <div dangerouslySetInnerHTML={{__html: draftToHtml(termsContent)}}></div>
                                                </>
                                            ) : null
                                        }
                                        {
                                            faqsContent ? (
                                                <>
                                                    <p className="w-full py-2 text-black uppercase font-medium text-left">FAQs</p>
                                                    <div dangerouslySetInnerHTML={{__html: draftToHtml(faqsContent)}}></div>
                                                </>
                                            ) : null
                                        }

                                        {
                                            previewData && (
                                                <div className="w-full py-4">
                                                    <LinkPreviewPanel />
                                                </div>
                                            )
                                        }

                                        </div>

                                        <div className="absolute top-0 sticky ml-4 w-1/2 bg-black rounded-md p-4 flex flex-col items-start">
                                            <h3 className="py-2 text-gray-400 text-left w-full uppercase text-xs">
                                                Organizer
                                            </h3>
                                            <div className="py-4 flex flex-row items-center">
                                                {
                                                    data?.communityId ? (
                                                        <>
                                                            <img
                                                                src={data?.community_avatar ? data?.community_avatar?.indexOf('h')==='0'?data?.community_avatar:`${process.env.REACT_APP_SERVER_URL}${data?.community_avatar}`: getProfileImage(data?.community_title || 'Assistance')}
                                                                alt="avatar"
                                                                className="h-10 w-10 rounded-full border object-contain border-white"
                                                            />
                                                            <Link
                                                                to={`/${data?.community_key}`}
                                                                className="ml-3 hover:underline text-white mb-2 text-md font-medium text-left">
                                                                {data?.community_title}
                                                            </Link>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img
                                                                src={data?.avatar ? data?.avatar?.indexOf('h')==='0'?data?.avatar:`${process.env.REACT_APP_SERVER_URL}${data?.avatar}`: getProfileImage(data?.username || 'Assistance')}
                                                                alt="avatar"
                                                                className="h-10 w-10 rounded-full border object-contain border-white"
                                                            />
                                                            <p className="ml-3 text-white mb-2 text-md font-medium text-left">
                                                                {data?.username}
                                                            </p>
                                                        </>
                                                    )
                                                }
                                            </div>
                                            <h3 className="py-2 text-gray-400 text-left w-full uppercase text-xs">
                                                duration
                                            </h3>
                                            {
                                                    moment(data?.startDate).isSame(data?.endDate) ? (
                                                        <p className="text-white mb-1 text-md font-medium text-left">
                                                            {`${moment(data?.startDate).format('dddd, MMMM D, YYYY')} (${moment(data?.startDate).format('HH:MM A')} - ${moment(data?.endDate).format('HH:MM A')}) ${data?.time_zone || ''}`}
                                                        </p>    
                                                    ) : (
                                                        <p className="text-white mb-1 text-md font-medium text-left">
                                                            {`${moment(data?.startDate).format('MMM D, YYYY')} ${moment(data?.startDate).format('HH:MM A')} - ${moment(data?.endDate).format('MMM D, YYYY')} ${moment(data?.endDate).format('HH:MM A')} ${data?.time_zone}`}
                                                        </p>
                                                        )
                                            }
                                            {
                                                moment(data?.endDate, 'YYYY/MM/DD HH:mm').diff(moment(data?.startDate, 'YYYY/MM/DD HH:mm'), 'days', true)?.toPrecision(1) > 0 && (
                                                    <p className="text-gray-400 text-left w-full text-xs">{`${moment(data?.endDate, 'YYYY/MM/DD HH:mm').diff(moment(data?.startDate, 'YYYY/MM/DD HH:mm'), 'days', true)?.toPrecision(1)} day`}</p>
                                                 )
                                            }
                                            {
                                                moment(data?.startDate).isSame(data?.endDate) ? (
                                                    <p className="pb-1 text-gray-400 text-left w-full text-xs">
                                                        {/* {`(${moment(data?.startDate).format('HH:MM A')}) ${data?.time_zone || ''}`} */}
                                                    </p>
                                                ) : (
                                                    moment(data?.endDate, 'YYYY/MM/DD HH:mm').diff(moment(data?.startDate, 'YYYY/MM/DD HH:mm'), 'hours', true) > 24 ? (
                                                        <p className="pb-1 text-gray-400 text-left w-full text-xs">
                                                            {/* {`(${moment(data?.startDate).format('HH:MM A')} - ${moment(data?.endDate).format('HH:MM A')}) ${data?.time_zone}`} <br/>{`${moment(data?.endDate, 'YYYY/MM/DD HH:mm').diff(moment(data?.startDate, 'YYYY/MM/DD HH:mm'), 'days', true)?.toPrecision(1)} day`} */}
                                                        </p>
                                                    ) : (
                                                        <p className="pb-1 text-gray-400 text-left w-full text-xs">
                                                            {/* {`(${moment(data?.startDate).format('HH:MM A')} - ${moment(data?.endDate).format('HH:MM A')}) ${data?.time_zone}`} */}
                                                        </p>
                                                    )
                                                )
                                            }
                                            <h3 className="py-2 text-gray-400 text-left w-full uppercase text-xs">
                                                Category
                                            </h3>
                                            <p className="text-white mb-2 text-md font-medium text-left">
                                                {data?.type}
                                            </p>
                                            <h3 className="py-2 text-gray-400 text-left w-full uppercase text-xs">
                                                Location
                                            </h3>
                                            <p className="text-white mb-2 text-md font-medium text-left">
                                                {data?.locationDetail}
                                            </p>
                                        </div>

                                    </div>
                                    {/* <p className="w-full text-left text-gray-800 text-md mb-3">
                                        <strong>Location: </strong> {data?.locationDetail}
                                    </p>
                                    <p className="w-full text-left text-gray-800 text-md mb-3">
                                        <strong>Duration: </strong> {`${moment(data?.startDate).format('lll')}`} - {`${moment(data?.endDate).format('lll')}`}
                                    </p> */}
                                    
                                    
                                    <div className="w-full py-3">
                                        <div className="w-full h-full gmap">
                                            <iframe
                                                className="w-full"
                                                title="embed map"
                                                style={{ border: 0 }}
                                                loading="lazy"
                                                allowFullScreen={true}
                                                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_EMBED_MAP_API}&q=${data?.locationDetail}`}>
                                            </iframe>       
                                        </div>
                                    </div>
                                    </>

                                ) : activeTab === 'invitations' ? (
                                    <div className="w-full">
                                        {
                                            isOwner ? (
                                                <div className="w-full">
                                                    <div className="w-full flex flex-row items-center mt-5">
                                                        <p 
                                                            onClick={() => setActiveInvitationStatus('accept')}
                                                            className={`text-sm lg:text-md mx-6 cursor-pointer border-b border-black ${activeInvitationStatus==='accept'?'text-blue-500 border-blue-500':'text-black'}`}>Accepted ({invitations?.filter(invite => invite?.isAccepted===1)?.length || 0})</p>
                                                        <p 
                                                            onClick={() => setActiveInvitationStatus('pending')}
                                                            className={`text-sm lg:text-md cursor-pointer border-b border-black ${activeInvitationStatus==='pending'?'text-blue-500 border-blue-500':'text-black'}`}>Pending ({invitations?.filter(invite => invite?.isAccepted===0)?.length || 0})</p>
                                                    </div>
                                                    {
                                                        activeInvitationStatus==='pending' ? (
                                                            <div className="w-full flex flex-col items-start py-4"> 
                                                                 <Table
                                                                    className="w-full"
                                                                    columns={pendingInviteColumns}
                                                                    dataSource={invitations?.filter(invite => invite?.isAccepted===0)}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="w-full flex flex-col items-start py-4"> 
                                                                {
                                                                    data?.isPeopleCountReq === 1 && (
                                                                        <p className="w-full text-black font-medium text-right py-1">
                                                                            Total Head Count {invitations?.reduce((peopleCount, item) => item.peopleCount + peopleCount, 0)}
                                                                        </p>
                                                                    )
                                                                }
                                                                <Table
                                                                    className="w-full"
                                                                    columns={acceptedInviteColumns}
                                                                    dataSource={invitations?.filter(invite => invite?.isAccepted===1)}
                                                                />
                                                            </div>  
                                                        )
                                                    }
                                                    </div>
                                            ) : (
                                                <div className="w-full flex flex-col items-start py-4"> 
                                                    {
                                                        invitations
                                                        ?.filter(invite => invite?.isAccepted===1)?.length ? (
                                                            <Search
                                                                onChange={text => setSearchTwo(text.target.value)}
                                                                value={searchTwo} 
                                                                placeholder="Search by email" 
                                                                onSearch={text => onSearch(text, false)} 
                                                                className="rounded-md my-3" 
                                                            /> 
                                                        ) : null
                                                    }                                                           
                                                    {
                                                        (searchTwo ? invitations?.filter(_i => _i?.email_address?.toLowerCase().includes(searchTwo)) : invitations)
                                                        ?.filter(invite => invite?.isAccepted===1)
                                                        ?.map((i, idx) => (
                                                            <div
                                                                key={idx}
                                                                className="w-full border-b py-2 flex-row items-center justify-between">
                                                                <p className="w-full text-sm lg:text-md py-1 text-black text-left flex flex-col lg:flex-row items-start lg:items-center justify-between">
                                                                    <div className="flex flex-row items-center">
                                                                        <MailOutlined className="mr-2" />  {i?.email_address}
                                                                    </div>
                                                                </p>
                                                            </div>
                                                        ))
                                                    }
                                                    {
                                                        invitations?.filter(invite => invite?.isAccepted===1)?.length===0 && (
                                                            <p className="w-full text-gray-600 text-md text-left py-2">No {isOwner?'Invitations':'Attendees'} Available</p>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : 
                                activeTab === 'report' ? (
                                    <>
                                        <div className="w-full flex flex-col items-start">
                                            <p className="mr-4 py-2 text-black uppercase font-medium text-left">Report this event</p>
                                            <p className="text-left text-gray-700 py-1">
                                                Please help Assistance Org investigate this event by providing information about why you've reporting it.
                                            </p>
                                        </div>
                                        <div className="w-full lg:w-1/2 flex flex-col flex-center items-start">
                                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                                <label className="text-md text-left w-full py-2" for="email_address">Email Address <span className="text-red-500">*</span></label>
                                                <Input
                                                    disabled={user}
                                                    value={reportEmail}
                                                    onChange={(e) => setReportEmail(e.target.value)}
                                                    type="email"
                                                    placeholder="Required for copyright reports"
                                                    id="email_address" 
                                                    className="w-full border border-gray-400 rounded-md p-1 outline-none"
                                                />
                                            </div>
                                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                                <label className="text-md text-left w-full py-2" for="message">Description <span className="text-red-500">*</span></label>
                                                <TextArea
                                                    onChange={text => setReportMessage(text.target.value)}
                                                    value={reportMessage}
                                                    id="message"
                                                    placeholder="Additional details are required for reported issuses"
                                                    className="w-full border border-gray-400 rounded-md p-1 outline-none"
                                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                                />
                                            </div>
                                            <div className="w-full flex flex-col items-start my-1 ml-1">
                                                <label className="text-md text-left w-full py-2" for="message">Reason for report <span className="text-red-500">*</span></label>
                                                <Radio.Group onChange={onReasonChange} value={selectedReason}>
                                                    <Space direction="vertical">
                                                        {
                                                            reasonsList
                                                            ?.map(item => (
                                                                <Radio value={item?.id}>{item?.name}</Radio>
                                                            ))
                                                        }
                                                    </Space>
                                                </Radio.Group>
                                            </div>
                                            <p className="w-full text-left py-2 text-xs text-gray-600">
                                               Before you send the report make sure its appropiate and meets our terms and conditions.  
                                            </p>
                                            <div className="w-full flex items-start">
                                                <Button
                                                    onClick={() => sendReportEmail()}
                                                    loading={reportLoading}
                                                    type="primary"
                                                    disabled={!(reportEmail && reportMessage)}
                                                    className="px-5 my-2 text-md text-white primary-bg rounded-md cursor-pointer"
                                                    style={{ height: 35 }}
                                                >Report this event</Button>
                                            </div>
                                        </div>
                                    </>
                                ) : activeTab === 'memories' ? (
                                    <>
                                        <div className="w-full flex flex-col items-start">
                                            <div className="w-full flex flex-row items-center mb-3">
                                                {
                                                    isOwner && (
                                                        <Button
                                                            onClick={() => setMemoryModal(true)}
                                                            type="primary"
                                                            className="ml-2 px-3 text-md text-white primary-bg rounded-sm cursor-pointer"
                                                            style={{height: 35}}
                                                            disabled={!isOwner}                   
                                                        >Create
                                                        </Button>
                                                    )
                                                }
                                                <h2 className="w-full text-lg font-medium text-black px-3">
                                                    Event Memories
                                                </h2>
                                            </div>
                                                {
                                                    data?.isPublic === 1 ? (
                                                        !token ? (
                                                            <div className="w-full flex items-center justify-center py-2">
                                                                <Link
                                                                    to="/signin"
                                                                    className="px-3 text-md text-white primary-bg hover:text-white rounded-sm cursor-pointer py-3"                 
                                                                >Sign In to view memories
                                                                </Link>
                                                            </div>  
                                                        ) : null
                                                    ) : null
                                                }
                                            <div className={`w-full ${memories?.length > 0 ?'grid grid-cols-3 py-3 gap-0':''}`}>
                                                {
                                                    data?.isPublic === 1 ? (
                                                        token ? (
                                                            memoryItemsLoad ? (
                                                                <div className="w-full flex items-center justify-center py-2">
                                                                    <Spin size="default" />
                                                                </div>
                                                            ) : (
                                                                !memories?.length ? (
                                                                    <p className="w-full text-center text-lg text-black py-2">
                                                                        No Memories Available
                                                                    </p>
                                                                ) : (   
                                                                    memories?.map((item, ke) => (
                                                                        <MemoryItem 
                                                                            masterId={item?.memory_type==='image'?memoryFiles?.findIndex(e => e.id === item.id):memoryVideos?.findIndex(e => e.id === item.id)}
                                                                            {...item}
                                                                        />
                                                                    ))
                                                                )
                                                            )
                                                        ) : null
                                                    ) : (
                                                        memoryItemsLoad ? (
                                                            <div className="w-full flex items-center justify-center py-2">
                                                                <Spin size="default" />
                                                            </div>
                                                        ) : (
                                                            !memories?.length ? (
                                                                <p className="w-full text-center text-lg text-black py-2">
                                                                    No Memories Available
                                                                </p>
                                                            ) : (   
                                                                memories?.map((item, ke) => (
                                                                    <MemoryItem 
                                                                        masterId={item?.memory_type==='image'?memoryFiles?.findIndex(e => e.id === item.id):memoryVideos?.findIndex(e => e.id === item.id)}
                                                                        {...item}
                                                                    />
                                                                ))
                                                            )
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </>
                                ) : activeTab === 'discussion' ? (
                                    <>
                                        <h2
                                            className="w-full text-left text-2xl text-black font-medium"
                                        >Let's talk about the event</h2>
                                        <div className="relative w-full h-full flex flex-col items-start py-3" style={{minHeight: 200, maxHeight: 600}}>
                                            <div className="w-full p-2 rounded-sm my-2 flex flex-row items-start justify-between">
                                                <Editor
                                                    editorState={dState}
                                                    toolbarClassName="w-full border rounded-md-tr rounded-md-tl"
                                                    wrapperClassName="w-full border rounded-md"
                                                    editorClassName="w-full border rounded-md-br rounded-md-bl"
                                                    onEditorStateChange={e => onEditorChange(e)}
                                                    toolbar={{
                                                        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                                                        inline: { inDropdown: false },
                                                        list: { inDropdown: true },
                                                        textAlign: { inDropdown: false },
                                                        link: { inDropdown: true },
                                                    }}
                                                />
                                                <Button
                                                    disabled={!dState.getCurrentContent().hasText()}
                                                    loading={dLoad}
                                                    onClick={() => submitDiscussionContent()}
                                                    type="primary"
                                                    size="large"
                                                    className="mx-3 primary__btn rounded-sm text-white"
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                            <div className="w-full flex flex-col items-start overflow-y-auto">
                                                {
                                                    discussions?.map((item, key) => (
                                                        <DiscussionItem
                                                            {...item}
                                                            isOwner={item?.userId === data?.created_by}
                                                            key={key}
                                                            handleGetDiscussions={() => getDiscussionItems()}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </>
                                ) : null
                            }
                        </div>
                        {/* TAB PANEL ENDS HERE */}
                        </div>
                        </div>
                )
            }
            <Modal
                className="apps"
                closable={true}
                visible={invitationModal}
                onCancel={() => setInvitationModal(false)}
                title="Create Invitation"
                style={{top: 20}}
                destroyOnClose={true}
                footer={[<Button loading={invitationLoad} disabled={!invitationEmail} type="primary" onClick={() => createInvite()}>Send</Button>,
                         <Button onClick={() => setInvitationModal(false)}type="default">Cancel</Button>
                        ]}>
                <div className="w-full flex flex-col items-start">
                    <div className="w-full flex flex-col items-center my-1 ml-1">
                        <label className="text-md text-left w-full py-2" for="email_address">Email Address <span className="text-red-500">*</span></label>
                        {/* <textarea 
                            onChange={text => setInvitationEmail(text.target.value)}
                            value={invitationEmail}
                            rows="3" 
                            style={{ resize: 'none' }} 
                            className="w-full border border-gray-400 rounded-md p-1 outline-none" 
                            placeholder="Email addresses seperated by comma">
                        </textarea> */}
                        {/* {invitationEmail?.indexOf(',')>0?invitationEmail?.split(',')?.length?null:<span className="text-red-500 py-1">multiple email address should be separated by comma</span>:null} */}
                        <Input
                            value={invitationEmail}
                            onChange={(e) => setInvitationEmail(e.target.value)}
                            type="email" 
                            id="email_address" 
                            className="w-full border border-gray-400 rounded-md p-1 outline-none"
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                className="apps"
                closable={true}
                visible={eventAttendModal}
                onCancel={() => {
                    setAttendEmail('');
                    setPeopleCount(0);
                    setEventAttendModal(false);
                }}
                title="Attend Event"
                style={{top: 20}}
                destroyOnClose={true}
                footer={[<Button loading={acceptLoadingState} disabled={!((attendEmail||user?.email_address)&&data?.isPeopleCountReq===1?peopleCount>0?true:false:true)} type="primary" onClick={() => acceptInvite(true)}>Submit</Button>,
                         <Button onClick={() => {
                            setAttendEmail('');
                            setPeopleCount(0);
                            setEventAttendModal(false);
                         }}type="default">Close</Button>
                        ]}>
                <div className="w-full flex flex-col items-start">
                    <div className="w-full flex flex-col items-center my-1 ml-1">
                        <label className="text-md text-left w-full py-2" for="email_address">Email Address <span className="text-red-500">*</span></label>
                        <Input
                            disabled={user?.email_address}
                            value={attendEmail || user?.email_address}
                            onChange={(e) => setAttendEmail(e.target.value)}
                            type="email" 
                            id="email_address" 
                            className="w-full border border-gray-400 rounded-md p-1 outline-none"
                        />
                    </div>
                    {
                        data?.isPeopleCountReq===1 ? (
                            <div className="w-full flex flex-col items-start my-1 ml-1">
                                <label className="text-md text-left w-full py-2" for="spots">Number of spots needed <span className="text-red-500"> *</span></label>
                                <Input
                                    min="1"
                                    max={data?.spots}
                                    value={peopleCount}
                                    onChange={e => setPeopleCount(e.target.value)}
                                    type="number"
                                    id="spots" 
                                    className="w-1/4 border shadow-sm border-gray-400 rounded-md p-1 outline-none"
                                />
                            </div>
                        ) : null
                    }
                </div>
            </Modal>
            <Modal
                className="apps"
                closable={true}
                visible={memoryPreviewModal}
                onCancel={() => {
                    setCurrentMemoryMap(null);
                    setMemoryPreviewModal(false);
                }}
                title={null}
                width={700}
                style={{top: 20}}
                destroyOnClose={true}
                footer={[
                        <Button  
                            disabled={!((currentMemoryMap==='image'?currentMemoryIndex:currentVideoIndex) > 0)} 
                            type="primary" 
                            onClick={() => memoryBack()}
                        >Previous</Button>,
                        <Button  
                            disabled={!((currentMemoryMap==='image'?currentMemoryIndex:currentVideoIndex) < (currentMemoryMap==='image'?memoryFiles?.length-1:memoryVideos?.length-1))} 
                            type="primary" 
                            onClick={() => memoryNext()}
                        >Next</Button>,
                         <Button onClick={() => {
                            setCurrentMemoryMap(null); 
                            setMemoryPreviewModal(false)}}type="default">Close</Button>,
                            isOwner && (
                                <Button loading={memoryLoad} onClick={() => removeMemory()}type="danger">Delete</Button>
                            )
                        ]}>
                <div className="w-full flex items-center h-full p-0 m-0">
                    {
                        currentMemoryMap === 'image' ? (
                            <img
                                src={memoryFiles[currentMemoryIndex]?.url}
                                alt="memory image"
                                className="w-full h-full object-contain"
                            />       
                        ) : (
                            memoryVideos[currentVideoIndex] && (
                               <VideoPlayer
                                url={memoryVideos[currentVideoIndex]?.url}
                               />
                            )
                        )
                    }
                </div>
            </Modal>
            <Modal
                className="apps"
                closable={true}
                visible={memoryModal}
                onCancel={() => setMemoryModal(false)}
                title="Create a Memory"
                style={{top: 20}}
                destroyOnClose={true}
                footer={[<Button loading={memoryLoad} disabled={!(memoryImage&&memoryCaption)} type="primary" onClick={() => addMemoryImage()}>Submit</Button>,
                         <Button onClick={() => setMemoryModal(false)}type="default">Cancel</Button>
                        ]}>
                <div className="w-full flex flex-col items-start">
                    {
                        memorySelect ? (
                            <div className="w-full flex flex-col items-start">
                                <div className="w-full flex flex-col items-center my-1 ml-1">
                                    <label className="text-md text-left w-full py-2" for="fileAttachment">Image <span className="text-red-500">*</span></label>
                                    <Input
                                        onChange={(e) => setMemoryImage(e.target.files[0])}
                                        type="file" 
                                        id="fileAttachment"
                                        className="w-full border border-gray-400 rounded-md p-1 outline-none"
                                    />
                                </div>
                                <div className="w-full flex flex-col items-center my-1 ml-1">
                                    <label className="text-md text-left w-full py-2" for="caption">Caption <span className="text-red-500">*</span></label>
                                    <Input
                                        value={memoryCaption}
                                        onChange={(e) => setMemoryCaption(e.target.value)}
                                        type="text" 
                                        id="caption"
                                        className="w-full border border-gray-400 rounded-md p-1 outline-none"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="w-full flex flex-row items-center justify-evenly">
                                <div className="w-1/2 flex items-center justify-center">
                                    <p 
                                        onClick={() => setMemorySelect('image')}
                                        className="font-medium text-black text-center w-full text-lg py-4 bg-gray-100 cursor-pointer border-r-2 hover:bg-gray-200">
                                        Image
                                    </p>
                                </div>
                                <div className="w-1/2 flex items-center justify-center">
                                    <p 
                                        onClick={() => setMemorySelect('video')}
                                        className="font-medium text-black text-center w-full text-lg py-4 bg-gray-100 cursor-pointer border-l-2 hover:bg-gray-200">
                                        Video
                                    </p>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Modal>
            
            <Footer />
        </>
    )
}

export default Detail