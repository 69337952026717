import React,{ useState } from 'react'
import {Button, message, Modal} from 'antd'
import {useHistory} from 'react-router-dom'
import { FacebookFilled, WhatsAppOutlined, TwitterOutlined, CopyOutlined } from '@ant-design/icons'

function SuccessModal({type, modalTitle, okLabel, trigger, callTrigger = ct => ct, urlKey}) {

    const [isCopied, setIsCopied] = useState(false); 
    const history = useHistory();

    const handleCancel = () => {
        callTrigger(false);
        history.push(`/b/${urlKey}`);
    };

    const handleCopy = async (url) => {
        message.success('Copied to Clipboard');
        if ("clipboard" in navigator) {
            setIsCopied(true);
            return await navigator.clipboard.writeText(url);
        } else {
            setIsCopied(true);
            return document.execCommand("copy", true, url);
        }
    };


    return (
        <Modal                          
            onCancel={handleCancel}
            maskClosable={false}                      
            width={600}           
            className="apps"   
            style={{ top: 20 }}
            destroyOnClose={true}
            title={null}
            onOk={() => {
                callTrigger(false);
                history.push(`/b/${urlKey}`);
            }}
            visible={trigger}                      
            footer={[
                <Button
                    type="dashed"
                    className="px-5 flex items-center text-sm text-black primary-bg rounded-md cursor-pointer outline-none focus:outline-none"            
                >
                Continue</Button>
            ]}
        >
            <div className="w-full flex flex-col items-start">
                <h2 className="w-full text-left font-medium text-xl text-black py-1">
                    Share your blog to your friends!
                </h2>
                <input readOnly className="border w-full text-sm my-2 p-2 text-gray-700 rounded-sm outline-none" disabled value={'http://'+process.env.REACT_APP_CLIENT_URI+'/b/'+urlKey} />
                <div className="w-full flex flex-row items-center justify-evenly">
                    <Button
                        onClick={() => window.open(`https://api.whatsapp.com://send?text=Hey there! look out here: ${'http://'+process.env.REACT_APP_CLIENT_URI+'/b/'+urlKey}`)}
                        type="primary"
                        className="px-5 flex items-center text-base text-white my-4 primary-bg rounded-md cursor-pointer outline-none focus:outline-none"
                        style={{height: 40}}              
                    >
                        <WhatsAppOutlined /> Whatsapp</Button>
                    <Button
                        onClick={() => window.open(`https://www.facebook.com/sharer.php?u=${'http://'+process.env.REACT_APP_CLIENT_URI+'/b/'+urlKey}`)}
                        type="primary"
                        className="px-5 text-base flex items-center text-white my-4 primary-bg rounded-md cursor-pointer outline-none focus:outline-none"
                        style={{height: 40}}              
                    >
                        <FacebookFilled /> Facebook</Button>
                    <Button
                        onClick={() => window.open(`http://twitter.com/share?&url=${'http://'+process.env.REACT_APP_CLIENT_URI+'/b/'+urlKey}`)}
                        type="primary"
                        className="px-5 text-base text-white my-4 flex items-center primary-bg rounded-md cursor-pointer outline-none focus:outline-none"
                        style={{height: 40}}              
                    >
                        <TwitterOutlined /> Twitter</Button>
                    <Button
                        onClick={() => handleCopy('http://'+process.env.REACT_APP_CLIENT_URI+'/b/'+urlKey)}
                        type="primary"
                        className="px-5 text-base text-white my-4 primary-bg flex items-center rounded-md cursor-pointer outline-none focus:outline-none"
                        style={{height: 40}}              
                    >
                        <CopyOutlined /> {isCopied?'Copied':'Copy'}</Button>
                </div>
            </div>
        </Modal>
    )
}

export default SuccessModal
