/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React,{ useState, useEffect } from 'react'
import Header from '../../../components/User/v2/Header'
import MainSlider from '../../../components/User/v2/MainSlider'
import apiInstance from '../../../api/'
import { message, Select } from 'antd'
import { Link } from 'react-router-dom'

const {Option} = Select;

function Community() {

    document.title = "Events - Assistance Org"   

    const [communities, setCommunities] = useState([]);
    const [loadingState, setLoadingState] = useState(true);

    async function getAllCommunities() {
        setLoadingState(true);
        apiInstance
        .get('/organization')
        .then(response => {
            if (response.data.status) {
                setCommunities(response.data.data);
            } else message.warning(response.data?.data || 'something went wrong');
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoadingState(false);
        });
    }

    useEffect(() => { 
        getAllCommunities();
    },[]);

    return (
        <>
            <Header />
            <div className="lg:px-0 px-4 w-full lg:w-10/12 mx-auto flex flex-col items-center py-10">

                {
                    loadingState ? (
                        <div className="w-full main__preLoaderContainer flex items-center justify-center">
                            <img 
                                className="main__preLoader"
                                src="./logo192.png"
                                alt="preloader"
                            />
                        </div>
                    ) : (
                        <div className="lg:px-0 px-4 w-full lg:w-10/12 mx-auto flex flex-col items-center">
                            <div className="w-full flex flex-row items-center mb-4">
                            <Link
                                to="/community/create" 
                                className="focus:outline-none text-center w-56 whitespace-nowrap px-4 py-2 primary-bg text-white hover:text-white shadow-md font-bold text-md rounded-md my-2 mr-3">
                                Create Community
                            </Link>
                                <Select            
                                    showSearch={true}  
                                    allowClear={true}     
                                    className="w-1/4 rounded-md outline-none"                                               
                                    placeholder="Search Places"
                                >        
                                    <Option>Hello</Option>        
                                </Select>  
                            </div>                            
                            <MainSlider 
                                type="community"
                                showCreateButton={false}
                                title="Public Communities"
                                data={communities}                            
                            />
                                                    

                        </div>
                    )
                }             
                                
            </div>
        </>
    )
}

export default Community
