/* eslint-disable no-unused-vars */
import moment from 'moment'
import React from 'react'
import apiInstance from '../../../api'
import { useDataLayerValue } from '../../../DataLayer'
import { getProfileImage } from '../../../utils/Helper'
import {CloseCircleOutlined} from '@ant-design/icons';

function NotificationItem(props) {

    const [state, dispatch] = useDataLayerValue();

    const markAsRead = () => {
        if (props?.id) {
            apiInstance
            .put("/notification/read", { id: props.id })
            .then(response => {})
            .catch(e => {
                console.log(e.response.data);
            })
            .finally(() => {
                dispatch({
                    type: 'READ_NOTIFICATION',
                    payload: props.id
                });
            });
        }
    };

    const removeNotification = () => {
        if (props?.id) {
            apiInstance
            .delete(`/notification/${props.id}`)
            .then(response => {})
            .catch(e => {
                console.log(e.response.data);
            })
            .finally(() => {
                dispatch({
                    type: 'REMOVE_NOTIFICATION',
                    payload: props.id
                });
            });
        }
    };

    return (
        <div className={`${props?.unread===0?'bg-transparent':'bg-white'} w-full flex flex-row items-center justify-between border-b px-2 py-3`}>
            <div className="flex flex-row items-center">
                {props?.unread===0?null:<span className="bg-blue-500 p-1 rounded-full mx-1"></span>}
                <div className="flex flex-row items-start">
                    <img
                        alt="profile"
                        className="border border-blue-500 h-10 rounded-full mx-2"
                        src={props?.target_avatar ? props?.target_avatar?.indexOf('h')==='0'?props?.target_avatar:`${process.env.REACT_APP_SERVER_URL}${props?.target_avatar}` : getProfileImage(props.username || props.message?.replace('<strong>','') || 'Assistance')}
                    />
                    <div className="w-full flex flex-col items-start">
                        <a 
                            href={props.redirect_url}
                            onClick={() => markAsRead()}
                            className="w-full text-left hover:text-gray-800 text-black text-sm"
                            dangerouslySetInnerHTML={{__html: props.message}}
                        ></a>
                        <div className="w-full flex flex-row items-center mt-1">
                            <p
                                title={new Date(props.created_at).toUTCString()}
                                className="text-xs text-gray-600 text-left mb-1"
                            >{moment(props.created_at).fromNow()}</p> 
                            {
                                props.unread===0 ? null : (
                                    <>
                                        <span className="px-2">•</span>
                                        <p
                                            onClick={() => markAsRead()}
                                            className="underline cursor-pointer hover:text-black text-xs text-gray-600 text-left mb-1"
                                        >Mark as read</p>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <CloseCircleOutlined 
                onClick={() => removeNotification()}
                title="Remove"
                style={{marginLeft: 4, color: 'grey', fontSize: 17, opacity: 0.7, cursor: 'pointer'}} 
            />
        </div>
    )
}

export default NotificationItem
