/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Header from '../../components/User/v2/Header'
import { useParams, Link, useHistory } from 'react-router-dom'
import CItem from './Community/CItem'
import { Button, Input, message, Spin, Modal, Radio } from 'antd'
import { ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { useDataLayerValue } from '../../DataLayer'
import apiInstance from '../../api/'
import BriefItem from '../../components/User/v2/BriefItem'
import "../../App.css"
import { getProfileImage } from '../../utils/Helper'
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css"
import BlogItem from '../../components/User/v2/BlogItem'

const { confirm } = Modal;

const localizer = momentLocalizer(moment); // or globalizeLocalizer

function Profile() {

    const history = useHistory();

    const [{user, token}, dispatch] = useDataLayerValue();

    const [activeTab, setActiveTab] = useState('events');
    const [events, setEvents] = useState([]);
    const [communities, setCommunities] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [username, setUsername] = useState('');
    const [load, setLoad] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [passwordLoad, setPasswordLoad] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [closeLoad, setCloseLoad] = useState(false);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [eventView, setEventView] = useState('list');
    const [usernameLoad, setUsernameLoad] = useState(false);
    const [isUsernameExists, setIsUsernameExists] = useState(false);

    const transformIntoCalendarEventModel = (data) => {
        let result = [];
        if (data) {
            for(let item of data) {
                let tempItem = {};
                tempItem['start'] = moment(item?.startDate).format('YYYY-MM-DD')?.toString();
                tempItem['end'] = moment(item?.endDate).format('YYYY-MM-DD')?.toString();
                tempItem['title'] = item?.title;
                tempItem['key'] = item.url_key;
                result.push(tempItem);
            }
        }
        return result;
    }

    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (token) {
            setUsername(user?.username);
            getEngagedItems();
        }
    }, []);

    useEffect(() => {
        const timer = setTimeout(() =>{
            if (username) usernameExists();
        }, 2000);  
        return () => clearTimeout(timer);
    }, [username]);

    async function usernameExists() {
        setUsernameLoad(false);
        apiInstance
        .get(`/person/username/exists/${username||user?.username}`)
        .then(response => {
            if (response.data.data) {
                // already exists
                setIsUsernameExists(false);
            } else {
                // do not exists
                setIsUsernameExists(true);
            }
        })
        .catch(e => {
            console.log(e.response);
        })
        .finally(() => {
            setUsernameLoad(false);
        });
    }

    const getEngagedItems = () => {
        setLoad(true);
        apiInstance
        .get(`/person/engagedItems/${new Date().toISOString()}`)
        .then(response => {
            if (response.data) {
                setEvents(response.data.events);
                setCalendarEvents(transformIntoCalendarEventModel(response.data.events));
                setCommunities(response.data.communities);
                setBlogs(response.data.blogs);
            }
        })
        .catch(e => {
            console.log(e.response);
        })
        .finally(() => setLoad(false));
    }

    const handleFileChange = (e) => {
        setSelectedImage(e.target.files[0]);
    }

    useEffect(() => {
        if (selectedImage) {
            setImageURL(URL.createObjectURL(selectedImage));
        }
    }, [selectedImage]);

    const allowedToSave = selectedImage || username?username!==user?.username:false;

    const updateProfileData = () => {
        setLoad(true);
        let payload = new FormData();
        payload.append("username", username);
        if (selectedImage) {
            payload.append('file', selectedImage);
        }
        apiInstance
        .put('/person/profileData', payload)
        .then(response => {
            let oldData = sessionStorage.getItem('assistance-user');
            if (oldData) {
                oldData = JSON.parse(oldData);
            }
            if (response.data.url) {
                oldData = {
                    ...oldData,
                    image: response.data.url
                }
            } else {
                oldData = {
                    ...oldData,
                    username: username
                }
            }
           
            sessionStorage.setItem('assistance-user', JSON.stringify(oldData));
            dispatch({
                type: 'UPDATE_USER'
            });
            message.success(response.data.message || 'Profile Updated');
        })  
        .catch(e => {
            message.warning(e.response.data.message || 'Internal Server Error');
        }).finally(() => setLoad(false));
    }

    /**
     * Used to change the password based on the given
     * details with validation of new passsword
     * @param {oldPassword, newPassword, confirmPassword} data 
     */
     const handlePasswordChange = () => {
        if(password === confirmPassword) {
            // positive
            setPasswordLoad(true);
            let passwordPaylod = {
                password: oldPassword,                
                new_password: password
            };            
            apiInstance.put(`/person/password/secure/`, passwordPaylod)
            .then(response => {
                if(response.data.status) {
                    message.success(response.data.data);
                } else message.warning(response.data?.data|| 'Something went wrong');
                setPasswordLoad(false);
            }).catch(e => {
                if(e.response || e.response.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/admin");
                    }
                } else message.error("Internal Server Error");
            });
    
            setPassword('');
            setConfirmPassword('');
            setOldPassword('');
            
        } else message.warning('Password donot match');
    }

    function deactivateConfirmation() {
        confirm({
          okButtonProps: {
              loading: closeLoad
          },
          title: 'Do you want to deactivate your account?',
          icon: <ExclamationCircleOutlined />,
          content: 'Once it is deactivated, it cannot be used anymore',
          onOk() {
            setCloseLoad(true);

            apiInstance.put(`/person/delete`)
            .then(response => {
                if(response.data.status) {                 
                    sessionStorage.clear();   
                    setCloseLoad(false);
                    window.location.replace("/signin");
                    message.success('Account Deactivated Successfully');
                } else message.warning(response.data?.data|| 'Something went wrong');
            }).catch(e => {
                if(e.response || e.response.data) {                
                    message.error(e.response.data.message);
                    if(e.response.status === 401) {
                        sessionStorage.clear();
                        window.location.replace("/signin");
                    }
                } else message.error("Internal Server Error");
            });
    

          },
          onCancel() {
            message.warning('Deactivation cancelled');
          },
        });
    }

    return (
        <>
            <Header />
            <div className="w-full bg-white">
                <div className="w-full lg:w-11/12 mx-auto flex flex-col lg:flex-row items-start p-2 lg:p-8">
                        <div className="lg:w-1/3 w-full flex flex-row lg:flex-col items-start lg:items-center justify-center p-2 lg:p-4">
                        <img
                            alt="profile"
                            src={imageURL ? imageURL : user?.image ? user?.image?.indexOf('h')==='0'?user?.image:imageURL?imageURL:`${process.env.REACT_APP_SERVER_URL}${user?.image}`:  getProfileImage(user?.username || 'Assistance')}
                            className="profile__avatar rounded-full"
                        />
                        <div className="w-full flex flex-col items-center px-4">
                            <h2
                                className="py-2 text-center text-xl font-medium primary-color">
                                {user?.username || 'Unknown'}
                            </h2>
                            {
                                !!token ? 
                                (
                                    <a                     
                                        onClick={() => setIsEdit(true)}                                       
                                        className="text-blue-500 rounded-sm text-md block hover:border-blue-500 border-2 bg-white text-center
                                                px-4 py-2 cursor-pointer mb-2 focus:outline-none"
                                    >Edit Profile
                                    </a>                                
                                ) : (
                                    <Link                        
                                        to="/signin"
                                        className="text-white rounded-sm text-md block primary-bg text-center
                                                px-4 py-2 cursor-pointer mb-2 focus:outline-none"
                                    >Sign in to view email
                                    </Link>
                                )
                            }
                            <p
                                className="py-1 text-center text-md primary-color"
                            >Joined on Feb 22
                            </p>                    
                            </div> 
                        </div>
                        {
                            isEdit ? (
                                <div className="w-full flex flex-col items-start">
                                    <div className="w-full py-2 flex flex-col items-start">
                                        <h2 className="text-black font-semibold w-full text-left py-2 text-lg capitalize">
                                            General Information
                                        </h2>
                                        <div className="w-full flex flex-row items-center my-1">
                                            <div 
                                                className="w-full flex flex-col items-center my-1 mr-2">
                                                <label className="text-base font-medium text-black text-left w-full mb-3" for="urlkey">
                                                    Username <span className="text-red-500">*</span>
                                                </label>
                                                <Input
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    autoComplete={false}
                                                    value={username}
                                                    required
                                                    type="text" 
                                                    id="username" 
                                                    className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                                />
                                                {username!==user?.username?usernameLoad?<small>Please Wait...</small>:(<p className={`w-full text-left py-1 ${!isUsernameExists?'text-green-400':'text-red-500'}`}><small>{isUsernameExists?'Username not available':'Username available'}</small></p>):null}
                                            </div>
                                            <div className="w-full flex flex-col items-center my-1 ml-1">
                                                <label className="text-base font-medium text-black text-left w-full mb-3" for="urlkey">
                                                    Email Address <span className="text-red-500">*</span>
                                                </label>
                                                <Input
                                                    disabled={true}
                                                    autoComplete={false}
                                                    value={user?.email_address}
                                                    required
                                                    type="email" 
                                                    id="email" 
                                                    className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                                />
                                            </div>
                                        </div> 
                                        <div 
                                            className="w-1/2 flex flex-col items-center my-1 mr-2">
                                            <label className="text-base font-medium text-black text-left w-full mb-3" for="urlkey">
                                                Profile Photo
                                            </label>
                                            <Input
                                                accept="image/*"
                                                onChange={(e) => handleFileChange(e)}
                                                required
                                                type="file" 
                                                id="photo" 
                                                className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                            />
                                        </div>
                                        <div className="py-2 w-full flex flex-row items-end justify-end">
                                            <Button
                                                onClick={() => {
                                                    setUsername(username || user?.username);
                                                    setIsEdit(false);
                                                }}
                                                style={{height: 38}}  
                                                type="default"
                                                className="px-5 text-base rounded-sm cursor-pointer outline-none focus:outline-none"
                                            >Cancel</Button>
                                            <Button
                                                onClick={() => updateProfileData()}
                                                loading={load}
                                                disabled={!allowedToSave}
                                                style={{height: 38}}
                                                type="primary"
                                                className="px-5 text-base text-white primary-bg ml-3 rounded-sm cursor-pointer outline-none focus:outline-none"
                                            >Save Changes</Button>
                                        </div>
                                        <h2 className="text-black font-semibold w-full text-left py-2 text-lg capitalize">
                                           Change Password
                                        </h2>
                                        {
                                            user?.account_type !== 'default' ? (
                                                <div className="w-full">
                                                    <p
                                                        className="py-1 w-full text-left text-md mb-2 text-gray-600"
                                                    > You cannot update or modify password for a social account type
                                                    </p>
                                                </div>
                                            ) : (
                                                <>
                                                <div className="w-full flex flex-col items-start my-1">
                                                    <div 
                                                        className="w-1/2 flex flex-col items-center my-1 mr-2">
                                                        <label className="text-base font-medium text-black text-left w-full mb-3" for="urlkey">
                                                            Old Password <span className="text-red-500">*</span>
                                                        </label>
                                                        <Input
                                                            autoFocus={false}
                                                            value={oldPassword}
                                                            onChange={e => setOldPassword(e.target.value)}
                                                            autoComplete={false}
                                                            type="password" 
                                                            className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                                        />
                                                    </div>
                                                    <div className="w-full flex flex-row items-center my-1">
                                                        <div className="w-full flex flex-col items-center my-1 mr-2">
                                                            <label className="text-base font-medium text-black text-left w-full mb-3" for="urlkey">
                                                                New Password <span className="text-red-500">*</span>
                                                            </label>
                                                            <Input
                                                                value={password}
                                                                onChange={e => setPassword(e.target.value)}
                                                                autoComplete={false}
                                                                type="password" 
                                                                className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                                            />
                                                        </div>
                                                        <div className="w-full flex flex-col items-center my-1 ml-1">
                                                            <label className="text-base font-medium text-black text-left w-full mb-3" for="urlkey">
                                                                Confirm Password <span className="text-red-500">*</span>
                                                            </label>
                                                            <Input
                                                                value={confirmPassword}
                                                                onChange={e => setConfirmPassword(e.target.value)}
                                                                autoComplete={false}
                                                                type="password"
                                                                className="w-full border border-gray-400 rounded-md p-3 outline-none"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="py-2 w-full flex flex-row items-end justify-end">
                                                    <Button
                                                        loading={passwordLoad}
                                                        onClick={() => handlePasswordChange()}
                                                        disabled={!(oldPassword&&password&&confirmPassword)}
                                                        style={{height: 38}}
                                                        type="primary"
                                                        className="px-5 text-base text-white primary-bg ml-3 rounded-sm cursor-pointer outline-none focus:outline-none"
                                                    >Save</Button>
                                                </div>
                                                </>
                                            )
                                        }
                                        <h2 className="text-black font-semibold w-full text-left py-2 text-lg capitalize">
                                           Other Settings
                                        </h2>
                                        <div className="w-full flex flex-row items-center my-1">
                                            <div 
                                                className="w-full flex flex-col items-start my-1 mr-2">
                                                <label className="text-base font-medium text-black text-left w-full mb-3" for="urlkey">
                                                    Account Type
                                                </label>
                                                <h2 className={`capitalize ${user?.account_type==='google'?'bg-green-500':'bg-blue-500'} text-white rounded-sm px-2 py-1 text-lg font-semibold text-left`}>
                                                    {user?.account_type}
                                                </h2>
                                            </div>
                                            <div 
                                                className="w-full flex flex-col items-start my-1 ml-1">
                                                <label className="text-base font-medium text-black text-left w-full mb-3" for="urlkey">
                                                    Want to deactivate your account ?
                                                </label>
                                                <Button
                                                    onClick={() => deactivateConfirmation()}
                                                    style={{height: 38}}
                                                    type="danger"
                                                    className="px-5 mb-2 text-base text-white rounded-sm cursor-pointer outline-none focus:outline-none"
                                                >Deactivate Account</Button>
                                                <span><small><i>Contact: contact@assistance.org For further queries</i></small></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full flex flex-col items-start">
                                {/* TAB SECTION STARTS HERE */}
                                <div className="w-full flex flex-row bg-white pt-4 items-center border-b community__header">
                                    <p 
                                        onClick={() => setActiveTab('events')}
                                        className={`px-2 pb-2 mr-6 text-md text-left ${activeTab === 'events' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}>
                                        My Events {!!events?.length && (<span className={`mx-1 ${activeTab === 'events' ?'primary-bg text-white':'text-white bg-gray-500'} rounded-lg px-2 py-1 text-xs`}>{events.length}</span>)}
                                    </p>
                                    <p 
                                        onClick={() => setActiveTab('communities')}
                                        className={`px-2 pb-2 mr-6 text-md text-left ${activeTab === 'communities' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}>
                                        My Communities {!!communities?.length && (<span className={`mx-1 ${activeTab === 'communities' ?'primary-bg text-white':'text-white bg-gray-500'} rounded-lg px-2 py-1 text-xs`}>{communities.length}</span>)}
                                    </p>
                                    <p 
                                        onClick={() => setActiveTab('blogs')}
                                        className={`px-2 pb-2 mr-6 text-md text-left ${activeTab === 'blogs' ?'text-black border-b-2 border-blue-500':'text-gray-600'} cursor-pointer`}>
                                        My Blogs {!!blogs?.length && (<span className={`mx-1 ${activeTab === 'blogs' ?'primary-bg text-white':'text-white bg-gray-500'} rounded-lg px-2 py-1 text-xs`}>{blogs.length}</span>)}
                                    </p>
                                </div>
                                {/* TAB SECTION ENDS HERE */}
                                {/* TAB PANEL STARTS HERE */}
                                <div className="w-full my-1 lg:my-4 bg-white p-2 flex flex-col items-start">
                                    {
                                        load ? (
                                            <div className="w-full flex flex-row items-center justify-center">
                                                <Spin size="default"/>
                                            </div>
                                        ) : (
                                            <div className="w-full">
                                            {
                                                activeTab === 'events' ? (
                                                    <div className="w-full">
                                                        <div className="w-full flex flex-row items-start lg:items-end lg:justify-end py-2">
                                                            <Radio.Group value={eventView} onChange={e => setEventView(e.target.value)}>
                                                                <Radio.Button value="list">List View</Radio.Button>
                                                                <Radio.Button value="calendar">Calendar View</Radio.Button>
                                                            </Radio.Group>
                                                        </div>
                                                        {
                                                            eventView === 'calendar' ? (
                                                                <Calendar
                                                                    popup
                                                                    localizer={localizer}
                                                                    events={calendarEvents}
                                                                    startAccessor="start"
                                                                    endAccessor="end"
                                                                    style={{height: 600}}
                                                                    onSelectEvent={(e, f) => history.push(`/e/${e.key}`)}
                                                                />
                                                            ) : (
                                                                <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-2">
                                                                    {
                                                                        events?.map((event, key) => (
                                                                            <BriefItem keyName={key} key={key} fixed={false} {...event} />
                                                                        ))
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            !events?.length && (
                                                                <div className="w-full flex flex-col items-center py-4">
                                                                    <h2 className="w-full text-center text-black font-medium text-md py-1">
                                                                        You have engaged with no events
                                                                    </h2>
                                                                    <p className="w-full text-gray-600 text-center text-sm">
                                                                        No events available
                                                                    </p>
                                                                    <Link
                                                                        to="/event"
                                                                        className="text-white primary-bg hover:text-white rounded-sm py-2 px-4 my-2"
                                                                    >
                                                                        Host Your Event
                                                                    </Link>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                ): 
                                                activeTab === 'communities' ? (
                                                    <div className="w-full">
                                                        <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4">
                                                            {
                                                                communities?.map((community, key) => (
                                                                    <CItem key={key} isImage={true} {...community} />
                                                                ))
                                                            }
                                                        </div>
                                                    {
                                                        !communities?.length && (
                                                            <div className="w-full flex flex-col items-center py-4">
                                                                <h2 className="w-full text-center text-black font-medium text-md py-1">
                                                                    You have engaged with no communties
                                                                </h2>
                                                                <p className="w-full text-gray-600 text-center text-sm">
                                                                    No communities available
                                                                </p>
                                                                <Link
                                                                    to="/event"
                                                                    className="text-white primary-bg hover:text-white rounded-sm py-2 px-4 my-2"
                                                                >
                                                                    Create Community
                                                                </Link>
                                                            </div>
                                                        )
                                                    }
                                                    </div>
                                                ) : 
                                                activeTab === 'blogs' ? (
                                                    <div className="w-full">
                                                         {
                                                            !blogs?.length && (
                                                                <div className="w-full flex flex-col items-center py-4">
                                                                    <h2 className="w-full text-center text-black font-medium text-md py-1">
                                                                        You have engaged with no blogs
                                                                    </h2>
                                                                    <p className="w-full text-gray-600 text-center text-sm">
                                                                        No blogs available
                                                                    </p>
                                                                    <Link
                                                                        to="/blog/create"
                                                                        className="text-white primary-bg hover:text-white rounded-sm py-2 px-4 my-2"
                                                                    >
                                                                        Create Your Blog
                                                                    </Link>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            blogs?.map((item, idx) => (
                                                                <BlogItem {...item} key={idx} />
                                                            ))
                                                        }
                                                    </div>
                                                ) : null
                                            }
                                            </div>
                                        )
                                    }
                                </div>
                                {/* TAB PANEL ENDS HERE */}
                            </div>
                            )
                        }
                        
                </div>
            </div>
        </>
    )
}

export default Profile
