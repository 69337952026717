/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React,{ useState, useEffect } from 'react'
import CreatePoster from '../../../components/User/v2/CreatePoster'
import Footer from '../../../components/User/v2/Footer'
import Header from '../../../components/User/v2/Header'
import MainSlider from '../../../components/User/v2/MainSlider'
import apiInstance from '../../../api/'
import { message, Modal, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import {useDataLayerValue} from '../../../DataLayer'
import draftToHtml from 'draftjs-to-html'
import moment from 'moment'

function Events() {
    document.title = "Events - Assistance Org"
    const history = useHistory();

    const [{token, user}] = useDataLayerValue();

    const [events, setEvents] = useState([]);
    const [loadingState, setLoadingState] = useState(true);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventPreviewModal, setEventPreviewModal] = useState(false);

    async function getAllEvents() {
        setLoadingState(true);
        apiInstance
        .get('/event/secure')
        .then(response => {
            if (response.data.status) {
                setEvents(response.data.data);
            } else message.warning(response.data?.data || 'something went wrong');
        }).catch(e => {
            console.log(e);
        }).finally(() => {
            setLoadingState(false);
        });
    }

    useEffect(() => { 
        getAllEvents();
    },[]);

    const handleFetchingEvent = (item) => {
        setSelectedEvent(null);
        if (item) {
            setSelectedEvent(item);
            setEventPreviewModal(true);
        }
    };

    const handleEventEdit = (id) => {
        history.push(`/event/create?type=edit&id=${id}`);
    }

    return (
        <>
            <Header />
            {
                loadingState ? (
                    <div className="w-full main__preLoaderContainer flex items-center justify-center">
                        <img 
                            className="main__preLoader"
                            src="./logo192.png"
                            alt="preloader"
                        />
                    </div>
                ) : (
                    <div className="lg:px-0 px-4 w-full lg:w-10/12 mx-auto flex flex-col items-center py-10">
                
                        <CreatePoster
                        />
                        
                        <MainSlider 
                            showCreateButton={false}
                            title="Upcoming Events"
                            data={events?.filter(item => moment()?.isBetween(moment(item?.startDate), moment(item?.endDate)))}
                            handleFetchEvent={(e) => handleFetchingEvent(e)}
                        />
                        
                        <MainSlider 
                            showCreateButton={false}
                            title="Past Events"
                            data={events?.filter(item => moment(item?.endDate)?.isBefore(moment()))}
                            handleFetchEvent={(e) => handleFetchingEvent(e)}
                        />
                        
                        <MainSlider 
                            showCreateButton={false}
                            title="Community Events"
                        />

                    </div>
                )
            }
            <Modal
                className="apps"
                closable={true}
                visible={eventPreviewModal}
                onCancel={() => setEventPreviewModal(false)}
                title=""
                style={{top: 20}}
                footer={[<Button onClick={() => history.push(`/e/${selectedEvent?.url_key}`)} type="primary">View More</Button>, 
                        token&&selectedEvent?.created_by===user?.id && (<Button onClick={() => handleEventEdit(selectedEvent?.id)} type="dashed">Edit</Button>)
                        ]}>
                <div className="w-full flex flex-col items-start mt-8">
                    {
                        selectedEvent?.image && (
                            <img
                                alt="preview"
                                className="w-full cover h-56"
                                src={`${process.env.REACT_APP_SERVER_URL}/${selectedEvent?.image}`}
                            />
                        )
                    }
                    <h1 className="w-full text-xl text-black font-medium pt-2 mb-1 text-left">
                        {selectedEvent?.title}
                    </h1>
                    {
                            selectedEvent?.description ? (
                                <div dangerouslySetInnerHTML={{__html: draftToHtml(JSON.parse(selectedEvent?.description))}}></div>
                            ) : null
                    }
                    <div className="w-full py-2 flex flex-row items-start justify-between border-t mt-2">
                        <div className="w-1/2 flex flex-col items-start">
                            <p className="w-full text-left py-2 text-black text-md flex flex-row items-center">
                                Hosted by <img src={selectedEvent?.avatar} className="rounded-full ml-2 mr-1 h-4 w-4 contain" /> {selectedEvent?.username}
                            </p>
                            <p className="w-full text-left py-2 text-black text-md">
                                {selectedEvent?.locationDetail || <a href={selectedEvent?.url} className="w-full text-left cursor-pointer underline" target="_blank">Event URL</a> }
                            </p>
                        </div>
                        <div className="w-1/2 flex flex-col items-start border-l pl-3">
                            <p className="w-full text-left py-1 text-black text-md">
                                Starts at: {moment(selectedEvent?.startDate).format('lll')}    
                            </p>
                            <p className="w-full text-left py-1 text-black text-md">
                                Ends at: {moment(selectedEvent?.endDate).format('lll')}    
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
            <Footer />
        </>
    )
}

export default Events
